<template>
  <div class="filter-view">
    <div class="flex-row">
      <div class="row-item">
        <img src="../../assets/images/company.svg">
        <span class="row-item-txt">
          {{$t('all_payments')}}
          </span>
        <img src="../../assets/images/arrowdown.svg">
      </div>

      <div v-show="showCompanyFieldFilter" class="row-item no-text-select" v-click-outside="() => closeFilter('company_field')">
        <div @click="toggleCompanyFieldDropdown" >
          <img src="../../assets/images/filtercompanyfield.svg" class="row-item-icon" />
          <span class="row-item-txt">
            {{$t('company_field')}}{{companyFields.filter(item => item.selected).length > 0 ? ':' : ''}} <span class="show-filters">{{ companyFields.filter(item => item.selected).map(item => item.name).join(', ') }}</span>
          </span>
        </div>
        <CompanyFieldFilter
            v-if="showCompanyFieldDropdown"
            :visible.sync="showCompanyFieldDropdown"
            :items="companyFields"
            :on-filter="handleCompanyFieldFilter"
        />
        <img src="../../assets/images/cross.svg" @click="() => removeFilter('company_field')" />
      </div>

      <div class="row-item no-text-select" v-if="showTherapyNumberFilter" v-click-outside="() => closeFilter('therapy_number')">
        <div @click="toggleTherapyNumberFilter">
          <img src="../../assets/images/filtertherapynumber.svg" class="row-item-icon" />
          <span class="row-item-txt">
          {{$t('therapy_number')}}{{therapyNumberFilterData.min !== undefined || therapyNumberFilterData.max !== undefined ? ':' : ''}} <span class="show-filters">{{therapyNumberFilterData.min ? 'Min: ' + therapyNumberFilterData.min : ''}} {{therapyNumberFilterData.min !== undefined && therapyNumberFilterData.max !== undefined ? ' - ' : ''}} {{therapyNumberFilterData.max ? 'Max: ' + therapyNumberFilterData.max : ''}}</span>
          </span>
        </div>
        <TherapyFilter
            v-if="showTherapyNumberDropdown"
            :visible.sync="showTherapyNumberDropdown"
            :on-filter="handleTherapyNumberFilter"
        />
        <img src="../../assets/images/cross.svg" @click="() => removeFilter('therapy_number')" />
      </div>


      <div class="row-item no-text-select" v-if="showUserAmountFilter"  v-click-outside="() => closeFilter('user_amount')">
        <div @click="toggleUserAmountFilter" >
          <img src="../../assets/images/fitleruser.svg" class="row-item-icon" />
          <span class="row-item-txt">
          {{$t('user_amount')}}{{userAmountFilterData.min !== undefined || userAmountFilterData.max !== undefined ? ':' : ''}} <span class="show-filters">{{userAmountFilterData.min ? 'Min: ' + userAmountFilterData.min : ''}} {{userAmountFilterData.min !== undefined && userAmountFilterData.max !== undefined ? ' - ' : ''}} {{userAmountFilterData.max ? 'Max: ' + userAmountFilterData.max : ''}}</span>
        </span>
        </div>
        <UserAmountFilter
            v-if="showUserAmountDropdown"
            :visible.sync="showUserAmountDropdown"
            :on-filter="handleUserAmountFilter"
        />
        <img src="../../assets/images/cross.svg" @click="() => removeFilter('user_amount')" />
      </div>

      <div class="row-item no-text-select" v-if="showUserPaymentFilter" v-click-outside="() => closeFilter('payment_volume')">
        <div @click="toggleUserPaymentFilter">
          <img src="../../assets/images/filterpayment.svg" class="row-item-icon" />
          <span class="row-item-txt">
            {{$t('payment_volume')}}{{userPaymentFilterData.min !== undefined || userPaymentFilterData.max !== undefined ? ':' : ''}} <span class="show-filters">{{userPaymentFilterData.status ? userPaymentFilterData.status : ''}}{{userPaymentFilterData.status && (userPaymentFilterData.min || userPaymentFilterData.max) ? ' / ' : ''}}{{userPaymentFilterData.min ? 'Min: ' + userPaymentFilterData.min : ''}} {{userPaymentFilterData.min !== undefined && userPaymentFilterData.max !== undefined ? ' - ' : ''}} {{userPaymentFilterData.max ? 'Max: ' + userPaymentFilterData.max : ''}}</span>
          </span>
        </div>
        <PaymentVolume
            v-if="showUserPaymentDropdown"
            :visible.sync="showUserPaymentDropdown"
            :on-filter="handleUserPaymentFilter"
        />
        <img src="../../assets/images/cross.svg" @click="() => removeFilter('payment_volume')" />
      </div>

      <div class="row-item no-text-select" v-if="showDateRangeFilter" v-click-outside="() => closeFilter('date_range')">
        <div @click="toggleDateRangeFilter">
          <img src="../../assets/images/filterdaterange.svg" class="row-item-icon"/>
          <span class="row-item-txt">
          {{$t('calendar')}}{{dateRangeFilterData.startDate || dateRangeFilterData.endDate ? ':' : ''}} <span class="show-filters"> {{dateRangeFilterData.startDate ? dateRangeFilterData.startDate : ''}}{{dateRangeFilterData.startDate && dateRangeFilterData.endDate ? ' / ' : ''}}{{dateRangeFilterData.endDate ? dateRangeFilterData.endDate : ''}}</span>
          </span>
        </div>
        <DateFilter
            v-if="showDateRangeDropdown"
            :visible.sync="showDateRangeDropdown"
            :on-filter="handleApplyDateFilters"
        />
        <img src="../../assets/images/cross.svg" @click="() => removeFilter('date_range')" />
      </div>

      <div class="row-item filter" v-click-outside="() => closeFilter('main')" @click="toggleFilter">
        <img src="../../assets/images/filter.svg">
        <span class="row-item-txt no-text-select">
            {{$t('filter_therapy')}}
        </span>
        <FilterModal :visible.sync="showFilter" @filter-selected="handleFilterSelected"/>
      </div>
    </div>
    <div class="justify-end-div">
      <div class="justify-end-search">
        <ExpandableSearch :searchText="searchText" :on-change="handleSearch" />
      </div>
    </div>

  </div>

  <div class="table-container">
    <table class="content-table">
      <thead class="header-table">
      <tr class="trhead">
        <th>{{$t('payment_method')}}</th>
        <th>{{$t('amount_payment')}}</th>
        <th>{{$t('status_payment')}} <img src="../../assets/images/status.svg"></th>
        <th>{{$t('date')}}</th>
        <th>{{$t('invoice_payment')}}</th>
      </tr>
      </thead>
      <tbody class="tbody-container">
      <tr class="tr-class" v-for="company in filteredCompanies" :key="company.id">
        <td class="td-style">
          <router-link to="#" class="name-row">
            <img v-if="company.method === 'visa'" src="../../assets/images/visa.svg" class="company-icon"/>
            <img v-if="company.method === 'mastercard'" src="../../assets/images/mastercard.svg" class="company-icon"/>
            <span class="table-text">{{ company.card }}</span>
          </router-link>
        </td>
        <td class="td-style"><span class="table-text">{{company.amount}}</span></td>
        <td class="td-style"> <span class="table-text"> <img v-if="company.status==='Successful'" src="../../assets/images/active.svg" /> <img v-if="company.status==='Overdue'" src="../../assets/images/failed.svg" />{{ company.status }}</span></td>
        <td class="td-style"> <span class="table-text"> <img src="../../assets/images/datepicker.svg" />{{ company.date }}</span></td>
        <td class="td-style"> <span class="table-text"> <img src="../../assets/images/pdf.png" class="table-image" />{{ company.invoice }}</span></td>
      </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
import FilterModal from '@/components/tables/Company/CompanyFilterModal.vue';
import CompanyFieldFilter from '@/components/table-filters/CompanyFieldFilter.vue';
import TherapyFilter from "@/components/table-filters/NumberFilter.vue";
import UserAmountFilter from "@/components/table-filters/MinMaxNumberFilter.vue";
import PaymentVolume from "@/components/table-filters/PaymentVolume.vue";
import DateFilter from "@/components/table-filters/DateFilter.vue";
import therapyNumberFilter from "../table-filters/NumberFilter.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";

export default {
  components: {
    DateFilter,
    PaymentVolume,
    UserAmountFilter,
    FilterModal,
    CompanyFieldFilter,
    TherapyFilter,
    ExpandableSearch
  },
  data() {
    return {
      showModal: false,
      showFilter:false,
      searchText: '',

      showCompanyFieldFilter: false,
      showCompanyFieldDropdown: false,

      showTherapyNumberFilter:false,
      showTherapyNumberDropdown:false,
      therapyNumberFilterData: [{
        min: undefined,
        max: undefined,
      }],

      showUserAmountFilter:false,
      showUserAmountDropdown:false,
      userAmountFilterData: [{
        min: undefined,
        max: undefined,
      }],

      showUserPaymentFilter:false,
      showUserPaymentDropdown:false,
      userPaymentFilterData: [{
        status: undefined,
        min: undefined,
        max: undefined,
      }],

      showDateRangeFilter: false,
      showDateRangeDropdown: false,
      dateRangeFilterData: [{
        startDate: undefined,
        endDate: undefined,
      }],

      companyFields:  [
        {
          id:1,
          name:"Accounting",
          selected: false
        },
        {
          id:2,
          name:"Economics",
          selected: false
        },
        {
          id:3,
          name:"Finance",
          selected: false
        },
        {
          id:4,
          name:"Managment",
          selected: false
        }
      ],
      companies: [
        { id: 1, method: "visa", card: "**** 5432", amount: "$ 499", status: "Successful", date: "Sep 12, 2023", invoice: "AS12345.pdf"},
        { id: 2, method: "mastercard", card: "**** 3424", amount: "$ 299", status: "Overdue", date: "Sep 12, 2023", invoice: "AS12345.pdf"},

      ],
    }
  },
  methods: {
    handleFilterSelected(filterName) {
      this.closeFilter('main');
      if (filterName === 'company_field') {
        this.showCompanyFieldFilter = true;
        this.showCompanyFieldDropdown = true;
      } else if (filterName === 'therapy_number'){
        this.showTherapyNumberFilter = true;
        this.showTherapyNumberDropdown = true;
      }else if (filterName === 'user_amount'){
        this.showUserAmountFilter = true;
        this.showUserAmountDropdown = true;
      }else if (filterName === 'payment_volume'){
        this.showUserPaymentFilter = true;
        this.showUserPaymentDropdown = true;
      }else if (filterName === 'date_range'){
        this.showDateRangeFilter = true;
        this.showDateRangeDropdown = true;
      }
    },
    handleCompanyFieldFilter(itemsTemp) {
      this.companyFields = itemsTemp;
      this.showCompanyFieldDropdown = false;
    },
    handleTherapyNumberFilter(filter){
      this.therapyNumberFilterData.max = filter.max;
      this.therapyNumberFilterData.min = filter.min;
      this.showTherapyNumberDropdown = false;
    },
    handleUserAmountFilter(filter){
      this.userAmountFilterData.max = filter.max;
      this.userAmountFilterData.min = filter.min;
      this.showUserAmountDropdown = false;
    },
    handleUserPaymentFilter(filter){
      console.log(filter);
      this.userPaymentFilterData.max = filter.max;
      this.userPaymentFilterData.min = filter.min;
      this.userPaymentFilterData.status = filter.status;
      this.showUserPaymentDropdown = false;
    },

    handleApplyDateFilters(filters){
      this.dateRangeFilterData.startDate = filters.startDate;
      this.dateRangeFilterData.endDate = filters.endDate;
      this.showDateRangeDropdown = false;
    },

    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    closeFilter(filter) {
      if (filter === 'main' && this.showFilter === true) {
        this.showFilter = false;
      } else if (filter === 'company_field' && this.showCompanyFieldFilter === true) {
        this.showCompanyFieldDropdown = false;
      } else if (filter === 'therapy_number' && this.showTherapyNumberDropdown === true) {
        this.showTherapyNumberDropdown = false;
      } else if (filter === 'user_amount' && this.showUserAmountDropdown === true) {
        this.showUserAmountDropdown = false;
      } else if (filter === 'payment_volume' && this.showUserPaymentDropdown === true) {
        this.showUserPaymentDropdown = false;
      } else if (filter === 'date_range' && this.showDateRangeDropdown === true) {
        this.showDateRangeDropdown = false;
      }
    },
    removeFilter(filter) {
      if (filter === 'company_field') {
        this.showCompanyFieldFilter = false;
        this.companyFields.map(item => item.selected = false);
      } else if (filter === 'therapy_number') {
        this.showTherapyNumberFilter = false;
        this.therapyNumberFilterData = [{
          min: undefined,
          max: undefined,
        }];
      } else if (filter === 'user_amount') {
        this.showUserAmountFilter = false;
        this.userAmountFilterData = [{
          min: undefined,
          max: undefined,
        }];
      } else if (filter === 'payment_volume') {
        this.showUserPaymentFilter = false;
        this.userPaymentFilterData = [{
          status: undefined,
          min: undefined,
          max: undefined,
        }];
      } else if (filter === 'date_range') {
        this.showDateRangeFilter = false;
        this.dateRangeFilterData = [{
          start: undefined,
          end: undefined,
        }];
      }
    },
    toggleCompanyFieldDropdown() {
      this.showCompanyFieldDropdown = !this.showCompanyFieldDropdown;
    },
    toggleTherapyNumberFilter() {
      this.showTherapyNumberDropdown = !this.showTherapyNumberDropdown;
    },
    toggleUserAmountFilter() {
      this.showUserAmountDropdown = !this.showUserAmountDropdown;
    },
    toggleUserPaymentFilter(){
      this.showUserPaymentDropdown = !this.showUserPaymentDropdown
    },
    toggleDateRangeFilter() {
      this.showDateRangeDropdown = !this.showDateRangeDropdown;
    },

    isCompanySelected(company) {
      return company.selected;
    },
    hideModal() {
      this.showModal = false;
    },
    handleSearch(event) {
      this.searchText = event.target.value;
    }
  },
  computed: {
    therapyNumberFilter() {
      return therapyNumberFilter
    },
    filteredCompanies() {
      if (this.searchText === "") {
        return this.companies;
      }
      return this.companies.filter(company =>
          company.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  }

}
</script>

<style>
.table-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: left;
  padding: 8px;
}
.trhead{
  background-color: transparent;
}
.tr-class{
  border-bottom-width: 1px;
  border-bottom-color: #F2F4F7;
}

tr td {
  padding-bottom: 12px;
  padding-top: 12px;
  align-items: center;
  white-space: nowrap;
}
.table-container {
  width: 100%;
}
tr th{
  align-items: center;
  font-size: 12px;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.header-table,
.content-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.header-table {
  background-color: #F9FAFB;
  border-radius: 8px;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #F2F4F7;
}

.tbody-container {
  overflow-y: auto;
}
.tr-class td tr {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.table-text{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
}
.flex-row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
}
.row-item{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}

.row-item.filter {
  background-color: transparent;
}

.row-item-txt{
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}

.filter-view{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  min-height: 52px;
}
.justify-end-div{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.justify-end-search{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
}

.company-icon {
  height: 32px;
  width: 32px;
}

.show-filters{
  font-family: euclid_medium, sans-serif;
  color: #4A68F8;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
}

.row-item-icon {
  height: 16px;
  width: 16px;
}

.name-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.name-row span {
  font-family: euclid_medium, sans-serif;
  color: #000;
}

.name-row:hover.name-row span{
  color: #4A68F8;
}

.table-image {
  height: 16px;
  width: 16px;
}
</style>
