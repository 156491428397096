<template>
  <div class="chart-wrapper">
    <apexchart type="line" height="350" :options="{
      ...chartOptions,
      xaxis: {
        categories: data.data.labels,
      },
    }" :series="data.data.series"></apexchart>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        colors: ['#a0c498'],
      },
    };
  }
}
</script>
