import axios from '../axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const notificationContentService = {
    getNotificationContent(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/notification/content/store?page=${page}&limit=20${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/notification/content/store?page=${page}&limit=20`);
        }
    },
    getNotificationContentList(isTemplate=false, lang = 'tr') {
        let url = `/v1/admin/notification/content/store?list=true`;
        if (isTemplate===true){
            url += '&isTemplate=true';
        }
        return axios.get(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    getNotificationDetails(id, lang = 'tr') {
        return axios.get(`/v1/admin/notification/content/getFromId/${id}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    addNotificationContent(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post(`/v1/admin/notification/content/add`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    updateNotificationContent(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post(`/v1/admin/notification/content/update`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },

    getNotificationContentType(type) {
        return axios.get(`/v1/admin/notification/content/getByType/${type}`);
    }
}

export default notificationContentService;
