<script setup>

import {ref, watch} from "vue";
import FileDrop from "@/components/FileDrop.vue";

const props = defineProps({
  currentStep: {
    type: String,
    default: "cropper"
  },
  leftMenuVisible: {
    type: Boolean,
    default: true
  },
  onAction: {
    type: Function,
    default: () => {}
  },
  onSettingsAction: {
    type: Function,
    default: () => {}
  }
});

import ibilityLogo from '@/assets/editor-library/ibility_logo.png';
import arrow1 from '@/assets/editor-library/arrow1.png';
import arrow2 from '@/assets/editor-library/arrow2.png';
import arrow3 from '@/assets/editor-library/arrow3.png';
import arrow4 from '@/assets/editor-library/arrow4.png';
import arrow5 from '@/assets/editor-library/arrow5.png';
import arrow6 from '@/assets/editor-library/arrow6.png';
import arrow7 from '@/assets/editor-library/arrow7.png';
import arrow8 from '@/assets/editor-library/arrow8.png';
import arrow9 from '@/assets/editor-library/arrow9.png';
import arrow10 from '@/assets/editor-library/arrow10.png';
import arrow11 from '@/assets/editor-library/arrow11.png';
import arrow12 from '@/assets/editor-library/arrow12.png';
import arrow13 from '@/assets/editor-library/arrow13.png';
import bubble1 from '@/assets/editor-library/bubble1.png';
import bubble2 from '@/assets/editor-library/bubble2.png';
import bubble3 from '@/assets/editor-library/bubble3.png';
import bubble4 from '@/assets/editor-library/bubble4.png';
import bubble5 from '@/assets/editor-library/bubble5.png';
import bubble6 from '@/assets/editor-library/bubble6.png';
import bubble7 from '@/assets/editor-library/bubble7.png';
import bubble8 from '@/assets/editor-library/bubble8.png';
import bubble9 from '@/assets/editor-library/bubble9.png';
import bubble10 from '@/assets/editor-library/bubble10.png';
import bubble11 from '@/assets/editor-library/bubble11.png';
import bubble12 from '@/assets/editor-library/bubble12.png';
import bubble13 from '@/assets/editor-library/bubble13.png';
import bubble14 from '@/assets/editor-library/bubble14.png';
import bubble15 from '@/assets/editor-library/bubble15.png';
import bubble16 from '@/assets/editor-library/bubble16.png';
import sunburst1 from '@/assets/editor-library/sunburst1.png';
import sunburst2 from '@/assets/editor-library/sunburst2.png';
import sunburst3 from '@/assets/editor-library/sunburst3.png';
import sunburst4 from '@/assets/editor-library/sunburst4.png';
import sunburst5 from '@/assets/editor-library/sunburst5.png';
import sunburst6 from '@/assets/editor-library/sunburst6.png';
import sunburst7 from '@/assets/editor-library/sunburst7.png';
import sunburst8 from '@/assets/editor-library/sunburst8.png';
import sunburst9 from '@/assets/editor-library/sunburst9.png';
import sunburst10 from '@/assets/editor-library/sunburst10.png';
import sunburst11 from '@/assets/editor-library/sunburst11.png';
import sunburst12 from '@/assets/editor-library/sunburst12.png';
import sunburst13 from '@/assets/editor-library/sunburst13.png';
import tornPaper1 from '@/assets/editor-library/tornPaper1.png';
import tornPaper2 from '@/assets/editor-library/tornPaper2.png';
import tornPaper3 from '@/assets/editor-library/tornPaper3.png';
import tornPaper4 from '@/assets/editor-library/tornPaper4.png';
import tornPaper5 from '@/assets/editor-library/tornPaper5.png';
import tornPaper6 from '@/assets/editor-library/tornPaper6.png';
import tornPaper7 from '@/assets/editor-library/tornPaper7.png';
import tornPaper8 from '@/assets/editor-library/tornPaper8.png';
import tornPaper9 from '@/assets/editor-library/tornPaper9.png';
import tornPaper10 from '@/assets/editor-library/tornPaper10.png';
import handDrawnArrow1 from '@/assets/editor-library/handDrawnArrow1.png';
import handDrawnArrow2 from '@/assets/editor-library/handDrawnArrow2.png';
import handDrawnArrow3 from '@/assets/editor-library/handDrawnArrow3.png';
import handDrawnArrow4 from '@/assets/editor-library/handDrawnArrow4.png';
import handDrawnArrow5 from '@/assets/editor-library/handDrawnArrow5.png';
import handDrawnArrow6 from '@/assets/editor-library/handDrawnArrow6.png';
import handDrawnArrow7 from '@/assets/editor-library/handDrawnArrow7.png';
import handDrawnArrow8 from '@/assets/editor-library/handDrawnArrow8.png';
import handDrawnArrow9 from '@/assets/editor-library/handDrawnArrow9.png';
import handDrawnArrow10 from '@/assets/editor-library/handDrawnArrow10.png';
import handDrawnArrow11 from '@/assets/editor-library/handDrawnArrow11.png';
import handDrawnArrow12 from '@/assets/editor-library/handDrawnArrow12.png';
import handDrawnArrow13 from '@/assets/editor-library/handDrawnArrow13.png';
import handDrawnArrow14 from '@/assets/editor-library/handDrawnArrow14.png';
import handDrawnArrow15 from '@/assets/editor-library/handDrawnArrow15.png';
import handDrawnCircle from '@/assets/editor-library/handDrawnCircle.png';
import handDrawnConcern from '@/assets/editor-library/handDrawnConcern.png';
import handDrawnDots from '@/assets/editor-library/handDrawnDots.png';
import handDrawnExclamation from '@/assets/editor-library/handDrawnExclamation.png';
import handDrawnGrass from '@/assets/editor-library/handDrawnGrass.png';
import handDrawnHeart from '@/assets/editor-library/handDrawnHeart.png';
import handDrawnHighlight from '@/assets/editor-library/handDrawnHighlight.png';
import handDrawnHighlight2 from '@/assets/editor-library/handDrawnHighlight2.png';
import handDrawnHighlight3 from '@/assets/editor-library/handDrawnHighlight3.png';
import handDrawnHighlight4 from '@/assets/editor-library/handDrawnHighlight4.png';
import handDrawnNew1 from '@/assets/editor-library/handDrawnNew1.png';
import handDrawnNew2 from '@/assets/editor-library/handDrawnNew2.png';
import handDrawnNoodle from '@/assets/editor-library/handDrawnNoodle.png';
import handDrawnRays from '@/assets/editor-library/handDrawnRays.png';
import handDrawnRibbon from '@/assets/editor-library/handDrawnRibbon.png';
import handDrawnSlash from '@/assets/editor-library/handDrawnSlash.png';
import handDrawnSmile from '@/assets/editor-library/handDrawnSmile.png';
import handDrawnStar from '@/assets/editor-library/handDrawnStar.png';
import handDrawnStripes from '@/assets/editor-library/handDrawnStripes.png';
import handDrawnSwoshes from '@/assets/editor-library/handDrawnSwoshes.png';
import handDrawnSwoshes2 from '@/assets/editor-library/handDrawnSwoshes2.png';
import handDrawnTornado from '@/assets/editor-library/handDrawnTornado.png';
import handDrawnUnderline from '@/assets/editor-library/handDrawnUnderline.png';
import handDrawnUnderline2 from '@/assets/editor-library/handDrawnUnderline2.png';
import handDrawnUnderline3 from '@/assets/editor-library/handDrawnUnderline3.png';
import handDrawnUnderline4 from '@/assets/editor-library/handDrawnUnderline4.png';
import handDrawnWelcome from '@/assets/editor-library/handDrawnWelcome.png';
import square from '@/assets/editor-library/square.png';
import rectangle from '@/assets/editor-library/rectangle.png';
import circle from '@/assets/editor-library/circle.png';
import triangle from '@/assets/editor-library/triangle.png';
import cloud from '@/assets/editor-library/cloud.png';
import cone from '@/assets/editor-library/cone.png';
import crescent from '@/assets/editor-library/crescent.png';
import diamond from '@/assets/editor-library/diamond.png';
import explosion from '@/assets/editor-library/explosion.png';
import hexagon from '@/assets/editor-library/hexagon.png';
import octagon from '@/assets/editor-library/octagon.png';
import oval from '@/assets/editor-library/oval.png';
import pentagon from '@/assets/editor-library/pentagon.png';
import star from '@/assets/editor-library/star.png';
import star2 from '@/assets/editor-library/star2.png';
import parallelogram from '@/assets/editor-library/parallelogram.png';
import semiCircle from '@/assets/editor-library/semiCircle.png';
import sheild from '@/assets/editor-library/sheild.png';
import sheild2 from '@/assets/editor-library/sheild2.png';


const libImages = [
  {
    id: 1,
    name: 'ibility',
    fileName: 'ibility_logo.png',
    image: ibilityLogo,
  },
  {
    id: 2,
    name: 'Arrow 1',
    fileName: 'arrow1.png',
    image: arrow1,
  },
  {
    id: 3,
    name: 'Arrow 2',
    fileName: 'arrow2.png',
    image: arrow2,
  },
  {
    id: 4,
    name: 'Arrow 3',
    fileName: 'arrow3.png',
    image: arrow3,
  },
  {
    id: 5,
    name: 'Arrow 4',
    fileName: 'arrow4.png',
    image: arrow4,
  },
  {
    id: 6,
    name: 'Arrow 5',
    fileName: 'arrow5.png',
    image: arrow5,
  },
  {
    id: 7,
    name: 'Arrow 6',
    fileName: 'arrow6.png',
    image: arrow6,
  },
  {
    id: 8,
    name: 'Arrow 7',
    fileName: 'arrow7.png',
    image: arrow7,
  },
  {
    id: 9,
    name: 'Arrow 8',
    fileName: 'arrow8.png',
    image: arrow8,
  },
  {
    id: 10,
    name: 'Arrow 9',
    fileName: 'arrow9.png',
    image: arrow9,
  },
  {
    id: 11,
    name: 'Arrow 10',
    fileName: 'arrow10.png',
    image: arrow10,
  },
  {
    id: 12,
    name: 'Arrow 11',
    fileName: 'arrow11.png',
    image: arrow11,
  },
  {
    id: 13,
    name: 'Arrow 12',
    fileName: 'arrow12.png',
    image: arrow12,
  },
  {
    id: 14,
    name: 'Arrow 13',
    fileName: 'arrow13.png',
    image: arrow13,
  },
  {
    id: 15,
    name: 'Bubble 1',
    fileName: 'bubble1.png',
    image: bubble1,
  },
  {
    id: 16,
    name: 'Bubble 2',
    fileName: 'bubble2.png',
    image: bubble2,
  },
  {
    id: 17,
    name: 'Bubble 3',
    fileName: 'bubble3.png',
    image: bubble3,
  },
  {
    id: 18,
    name: 'Bubble 4',
    fileName: 'bubble4.png',
    image: bubble4,
  },
  {
    id: 19,
    name: 'Bubble 5',
    fileName: 'bubble5.png',
    image: bubble5,
  },
  {
    id: 20,
    name: 'Bubble 6',
    fileName: 'bubble6.png',
    image: bubble6,
  },
  {
    id: 21,
    name: 'Bubble 7',
    fileName: 'bubble7.png',
    image: bubble7,
  },
  {
    id: 22,
    name: 'Bubble 8',
    fileName: 'bubble8.png',
    image: bubble8,
  },
  {
    id: 23,
    name: 'Bubble 9',
    fileName: 'bubble9.png',
    image: bubble9,
  },
  {
    id: 24,
    name: 'Bubble 10',
    fileName: 'bubble10.png',
    image: bubble10,
  },
  {
    id: 25,
    name: 'Bubble 11',
    fileName: 'bubble11.png',
    image: bubble11,
  },
  {
    id: 26,
    name: 'Bubble 12',
    fileName: 'bubble12.png',
    image: bubble12,
  },
  {
    id: 27,
    name: 'Bubble 13',
    fileName: 'bubble13.png',
    image: bubble13,
  },
  {
    id: 28,
    name: 'Bubble 14',
    fileName: 'bubble14.png',
    image: bubble14,
  },
  {
    id: 29,
    name: 'Bubble 15',
    fileName: 'bubble15.png',
    image: bubble15,
  },
  {
    id: 30,
    name: 'Bubble 16',
    fileName: 'bubble16.png',
    image: bubble16,
  },
  {
    id: 31,
    name: 'Sunburst 1',
    fileName: 'sunburst1.png',
    image: sunburst1,
  },
  {
    id: 32,
    name: 'Sunburst 2',
    fileName: 'sunburst2.png',
    image: sunburst2,
  },
  {
    id: 33,
    name: 'Sunburst 3',
    fileName: 'sunburst3.png',
    image: sunburst3,
  },
  {
    id: 34,
    name: 'Sunburst 4',
    fileName: 'sunburst4.png',
    image: sunburst4,
  },
  {
    id: 35,
    name: 'Sunburst 5',
    fileName: 'sunburst5.png',
    image: sunburst5,
  },
  {
    id: 36,
    name: 'Sunburst 6',
    fileName: 'sunburst6.png',
    image: sunburst6,
  },
  {
    id: 37,
    name: 'Sunburst 7',
    fileName: 'sunburst7.png',
    image: sunburst7,
  },
  {
    id: 38,
    name: 'Sunburst 8',
    fileName: 'sunburst8.png',
    image: sunburst8,
  },
  {
    id: 39,
    name: 'Sunburst 9',
    fileName: 'sunburst9.png',
    image: sunburst9,
  },
  {
    id: 40,
    name: 'Sunburst 10',
    fileName: 'sunburst10.png',
    image: sunburst10,
  },
  {
    id: 41,
    name: 'Sunburst 11',
    fileName: 'sunburst11.png',
    image: sunburst11,
  },
  {
    id: 42,
    name: 'Sunburst 12',
    fileName: 'sunburst12.png',
    image: sunburst12,
  },
  {
    id: 43,
    name: 'Sunburst 13',
    fileName: 'sunburst13.png',
    image: sunburst13,
  },
  {
    id: 44,
    name: 'Torn Paper 1',
    fileName: 'tornPaper1.png',
    image: tornPaper1,
  },
  {
    id: 45,
    name: 'Torn Paper 2',
    fileName: 'tornPaper2.png',
    image: tornPaper2,
  },
  {
    id: 46,
    name: 'Torn Paper 3',
    fileName: 'tornPaper3.png',
    image: tornPaper3,
  },
  {
    id: 47,
    name: 'Torn Paper 4',
    fileName: 'tornPaper4.png',
    image: tornPaper4,
  },
  {
    id: 48,
    name: 'Torn Paper 5',
    fileName: 'tornPaper5.png',
    image: tornPaper5,
  },
  {
    id: 49,
    name: 'Torn Paper 6',
    fileName: 'tornPaper6.png',
    image: tornPaper6,
  },
  {
    id: 50,
    name: 'Torn Paper 7',
    fileName: 'tornPaper7.png',
    image: tornPaper7,
  },
  {
    id: 51,
    name: 'Torn Paper 8',
    fileName: 'tornPaper8.png',
    image: tornPaper8,
  },
  {
    id: 52,
    name: 'Torn Paper 9',
    fileName: 'tornPaper9.png',
    image: tornPaper9,
  },
  {
    id: 53,
    name: 'Torn Paper 10',
    fileName: 'tornPaper10.png',
    image: tornPaper10,
  },
  {
    id: 54,
    name: 'Hand Drawn Arrow 1',
    fileName: 'handDrawnArrow1.png',
    image: handDrawnArrow1,
  },
  {
    id: 55,
    name: 'Hand Drawn Arrow 2',
    fileName: 'handDrawnArrow2.png',
    image: handDrawnArrow2,
  },
  {
    id: 56,
    name: 'Hand Drawn Arrow 3',
    fileName: 'handDrawnArrow3.png',
    image: handDrawnArrow3,
  },
  {
    id: 57,
    name: 'Hand Drawn Arrow 4',
    fileName: 'handDrawnArrow4.png',
    image: handDrawnArrow4,
  },
  {
    id: 58,
    name: 'Hand Drawn Arrow 5',
    fileName: 'handDrawnArrow5.png',
    image: handDrawnArrow5,
  },
  {
    id: 59,
    name: 'Hand Drawn Arrow 6',
    fileName: 'handDrawnArrow6.png',
    image: handDrawnArrow6,
  },
  {
    id: 60,
    name: 'Hand Drawn Arrow 7',
    fileName: 'handDrawnArrow7.png',
    image: handDrawnArrow7,
  },
  {
    id: 61,
    name: 'Hand Drawn Arrow 8',
    fileName: 'handDrawnArrow8.png',
    image: handDrawnArrow8,
  },
  {
    id: 62,
    name: 'Hand Drawn Arrow 9',
    fileName: 'handDrawnArrow9.png',
    image: handDrawnArrow9,
  },
  {
    id: 63,
    name: 'Hand Drawn Arrow 10',
    fileName: 'handDrawnArrow10.png',
    image: handDrawnArrow10,
  },
  {
    id: 64,
    name: 'Hand Drawn Arrow 11',
    fileName: 'handDrawnArrow11.png',
    image: handDrawnArrow11,
  },
  {
    id: 65,
    name: 'Hand Drawn Arrow 12',
    fileName: 'handDrawnArrow12.png',
    image: handDrawnArrow12,
  },
  {
    id: 66,
    name: 'Hand Drawn Arrow 13',
    fileName: 'handDrawnArrow13.png',
    image: handDrawnArrow13,
  },
  {
    id: 67,
    name: 'Hand Drawn Arrow 14',
    fileName: 'handDrawnArrow14.png',
    image: handDrawnArrow14,
  },
  {
    id: 68,
    name: 'Hand Drawn Arrow 15',
    fileName: 'handDrawnArrow15.png',
    image: handDrawnArrow15,
  },
  {
    id: 69,
    name: 'Hand Drawn Circle',
    fileName: 'handDrawnCircle.png',
    image: handDrawnCircle,
  },
  {
    id: 70,
    name: 'Hand Drawn Concern',
    fileName: 'handDrawnConcern.png',
    image: handDrawnConcern,
  },
  {
    id: 71,
    name: 'Hand Drawn Dots',
    fileName: 'handDrawnDots.png',
    image: handDrawnDots,
  },
  {
    id: 72,
    name: 'Hand Drawn Exclamation',
    fileName: 'handDrawnExclamation.png',
    image: handDrawnExclamation,
  },
  {
    id: 73,
    name: 'Hand Drawn Grass',
    fileName: 'handDrawnGrass.png',
    image: handDrawnGrass,
  },
  {
    id: 74,
    name: 'Hand Drawn Heart',
    fileName: 'handDrawnHeart.png',
    image: handDrawnHeart,
  },
  {
    id: 75,
    name: 'Hand Drawn Highlight',
    fileName: 'handDrawnHighlight.png',
    image: handDrawnHighlight,
  },
  {
    id: 76,
    name: 'Hand Drawn Highlight 2',
    fileName: 'handDrawnHighlight2.png',
    image: handDrawnHighlight2,
  },
  {
    id: 77,
    name: 'Hand Drawn Highlight 3',
    fileName: 'handDrawnHighlight3.png',
    image: handDrawnHighlight3,
  },
  {
    id: 78,
    name: 'Hand Drawn Highlight 4',
    fileName: 'handDrawnHighlight4.png',
    image: handDrawnHighlight4,
  },
  {
    id: 79,
    name: 'Hand Drawn New 1',
    fileName: 'handDrawnNew1.png',
    image: handDrawnNew1,
  },
  {
    id: 80,
    name: 'Hand Drawn New 2',
    fileName: 'handDrawnNew2.png',
    image: handDrawnNew2,
  },
  {
    id: 81,
    name: 'Hand Drawn Noodle',
    fileName: 'handDrawnNoodle.png',
    image: handDrawnNoodle,
  },
  {
    id: 82,
    name: 'Hand Drawn Rays',
    fileName: 'handDrawnRays.png',
    image: handDrawnRays,
  },
  {
    id: 83,
    name: 'Hand Drawn Ribbon',
    fileName: 'handDrawnRibbon.png',
    image: handDrawnRibbon,
  },
  {
    id: 84,
    name: 'Hand Drawn Slash',
    fileName: 'handDrawnSlash.png',
    image: handDrawnSlash,
  },
  {
    id: 85,
    name: 'Hand Drawn Smile',
    fileName: 'handDrawnSmile.png',
    image: handDrawnSmile,
  },
  {
    id: 86,
    name: 'Hand Drawn Star',
    fileName: 'handDrawnStar.png',
    image: handDrawnStar,
  },
  {
    id: 87,
    name: 'Hand Drawn Stripes',
    fileName: 'handDrawnStripes.png',
    image: handDrawnStripes,
  },
  {
    id: 88,
    name: 'Hand Drawn Swoshes',
    fileName: 'handDrawnSwoshes.png',
    image: handDrawnSwoshes,
  },
  {
    id: 89,
    name: 'Hand Drawn Swoshes 2',
    fileName: 'handDrawnSwoshes2.png',
    image: handDrawnSwoshes2,
  },
  {
    id: 90,
    name: 'Hand Drawn Tornado',
    fileName: 'handDrawnTornado.png',
    image: handDrawnTornado,
  },
  {
    id: 91,
    name: 'Hand Drawn Underline',
    fileName: 'handDrawnUnderline.png',
    image: handDrawnUnderline,
  },
  {
    id: 92,
    name: 'Hand Drawn Underline 2',
    fileName: 'handDrawnUnderline2.png',
    image: handDrawnUnderline2,
  },
  {
    id: 93,
    name: 'Hand Drawn Underline 3',
    fileName: 'handDrawnUnderline3.png',
    image: handDrawnUnderline3,
  },
  {
    id: 94,
    name: 'Hand Drawn Underline 4',
    fileName: 'handDrawnUnderline4.png',
    image: handDrawnUnderline4,
  },
  {
    id: 95,
    name: 'Hand Drawn Welcome',
    fileName: 'handDrawnWelcome.png',
    image: handDrawnWelcome,
  },
];

const libShapes = [
  {
    id: 1,
    name: 'Square',
    fileName: 'square.png',
    image: square,
  },
  {
    id: 2,
    name: 'Rectangle',
    fileName: 'rectangle.png',
    image: rectangle,
  },
  {
    id: 3,
    name: 'Circle',
    fileName: 'circle.png',
    image: circle,
  },
  {
    id: 4,
    name: 'Triangle',
    fileName: 'triangle.png',
    image: triangle,
  },
  {
    id: 5,
    name: 'Cloud',
    fileName: 'cloud.png',
    image: cloud,
  },
  {
    id: 6,
    name: 'Cone',
    fileName: 'cone.png',
    image: cone,
  },
  {
    id: 7,
    name: 'Crescent',
    fileName: 'crescent.png',
    image: crescent,
  },
  {
    id: 8,
    name: 'Diamond',
    fileName: 'diamond.png',
    image: diamond,
  },
  {
    id: 9,
    name: 'Explosion',
    fileName: 'explosion.png',
    image: explosion,
  },
  {
    id: 10,
    name: 'Hexagon',
    fileName: 'hexagon.png',
    image: hexagon,
  },
  {
    id: 11,
    name: 'Octagon',
    fileName: 'octagon.png',
    image: octagon,
  },
  {
    id: 12,
    name: 'Oval',
    fileName: 'oval.png',
    image: oval,
  },
  {
    id: 13,
    name: 'Pentagon',
    fileName: 'pentagon.png',
    image: pentagon,
  },
  {
    id: 14,
    name: 'Star',
    fileName: 'star.png',
    image: star,
  },
  {
    id: 15,
    name: 'Star 2',
    fileName: 'star2.png',
    image: star2,
  },
  {
    id: 16,
    name: 'Parallelogram',
    fileName: 'parallelogram.png',
    image: parallelogram,
  },
  {
    id: 17,
    name: 'Semi Circle',
    fileName: 'semiCircle.png',
    image: semiCircle,
  },
  {
    id: 18,
    name: 'Sheild',
    fileName: 'sheild.png',
    image: sheild,
  },
  {
    id: 19,
    name: 'Sheild 2',
    fileName: 'sheild2.png',
    image: sheild2,
  },
];

const filteredLibImages = ref(libImages);
const filteredLibShapes = ref(libShapes);

const expandedMenuId = ref(null);
const librarySearch = ref('');
const libraryShapeSearch = ref('');


const toggleMenu = (id) => {
  if (expandedMenuId.value === id) {
    expandedMenuId.value = null;
  } else {
    expandedMenuId.value = id;
  }
};

const closeMenu = () => {
  expandedMenuId.value = null;
};

const onImageSelected = (file) => {
  props.onAction('addImage', file);
  closeMenu();
};

const onImageSelectedBase64 = (base64) => {
  props.onAction('addImageBase64', base64);
  closeMenu();
};

const blobToBase64 = (blob, mimeType)  => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.replace(/^data:.+;base64,/, `data:${mimeType};base64,`);
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const convertPngToBase64 = async (fileName) => {
  const filePath = new URL(`../../assets/editor-library/${fileName}`, import.meta.url).href;
  try {
    const response = await fetch(filePath);
    const blob = await response.blob();
    console.log('Blob:', blob);
    console.log('Blob type:', blob.type);
    return await blobToBase64(blob, 'image/png');
  } catch (error) {
    console.error('Error converting PNG to Base64:', error);
    return null;
  }
};

const handleLibraryImage = async (fileName) => {
  const base64 = await convertPngToBase64(fileName);
  onImageSelectedBase64(base64);
};

watch(() => librarySearch.value, (search) => {
  if (search) {
    filteredLibImages.value = libImages.filter((image) => image.name.toLowerCase().includes(search.toLowerCase()));
  } else {
    filteredLibImages.value = libImages;
  }
});

watch(() => libraryShapeSearch.value, (search) => {
  if (search) {
    filteredLibShapes.value = libShapes.filter((shape) => shape.name.toLowerCase().includes(search.toLowerCase()));
  } else {
    filteredLibShapes.value = libShapes;
  }
});
</script>

<template>
  <div id="app" class="app-container">
    <div class="header">
      <div class="left-action">
        <img src="@/assets/images/iblity_black.svg" class="logo"/>
      </div>
      <div class="right-actions">
        <span
            class="action-link"
            @click="onAction('save')"
            v-if="currentStep === 'editor'"
        >
          {{$t('save_button')}}
        </span>
      </div>
    </div>

    <div class="editor-wrapper">
      <Transition name="slide-fade-rtl" appear>
        <div class="left-part no-text-select" v-if="leftMenuVisible">
          <div class="menu-top">
            <div class="menu-item" @click="() => onAction('annotate')">
              <img src="@/assets/images/annotateOutline.svg" />
              <span>Annotate</span>
            </div>

            <div class="menu-item" @click="() => toggleMenu(1)">
              <img src="@/assets/images/pictureOutline.svg" />
              <span>Image</span>
            </div>

            <div class="menu-item" @click="() => toggleMenu(2)">
              <img src="@/assets/images/shapesOutline.svg" />
              <span>Shapes</span>
            </div>
          </div>

          <div class="menu-bottom">
            <div class="menu-separator"></div>

            <div class="menu-item" @click="() => onSettingsAction('fullScreen')" >
              <img src="@/assets/images/fullScreenOutline.svg" />
              <span>Fill</span>
            </div>
          </div>

          <Transition name="slide-fade-rtl" appear>
            <div class="menu-expanded-wrapper"  v-if="expandedMenuId === 1">
              <div class="menu-expanded">
                <div class="menu-expanded-header">
                  <span>Add Image</span>

                  <div class="close-btn" @click="closeMenu">
                    <img class="cross" src="../../assets/images/cross.svg" />
                  </div>
                </div>

                <div class="menu-expanded-body">
                  <FileDrop
                      :on-file-selected="onImageSelected"
                      :image-editor-enabled="false"
                      size="small"
                  />

                  <h3>
                    Library
                  </h3>

                  <input type="text" v-model="librarySearch" placeholder="Search" class="search-input" />

                  <div class="lib-row">
                    <div class="library-image" v-for="libImage in filteredLibImages" @click="() => {
                    handleLibraryImage(libImage.fileName)
                  }">
                      <img :src="libImage.image"/>
                      <span>
                    {{libImage.name}}
                  </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>

          <Transition name="slide-fade-rtl" appear>
            <div class="menu-expanded-wrapper"  v-if="expandedMenuId === 2">
              <div class="menu-expanded">
                <div class="menu-expanded-header">
                  <span>Shapes</span>

                  <div class="close-btn" @click="closeMenu">
                    <img class="cross" src="../../assets/images/cross.svg" />
                  </div>
                </div>

                <div class="menu-expanded-body">
                  <input type="text" v-model="libraryShapeSearch" placeholder="Search" class="search-input" />

                  <div class="lib-row">
                    <div class="library-image" v-for="libShape in filteredLibShapes" @click="() => {
                    handleLibraryImage(libShape.fileName)
                  }">
                      <img :src="libShape.image"/>
                      <span>
                    {{libShape.name}}
                  </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </Transition>

      <div class="right-part">
        <slot name="cropper" v-if="currentStep === 'cropper'" />
        <slot name="editor"  v-else-if="currentStep === 'editor'" />
      </div>
    </div>
  </div>
</template>


<style scoped>
.app-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: #FCFCFD;
  overflow-y: auto;
}

.header {
  position: fixed;
  width: 100%;
  background-color: #FFFFFF;
  border-bottom: 1px solid #EAECF0;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 999;
}

.editor-wrapper {
  margin-top: 64px;
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: row;
  gap: 0;
}

.right-actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.right-actions span {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
}

.right-actions img {
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.left-action a {
  color: #040C2D;
}

.left-action a:active {
  color: #040C2D;
}

.left-action a:visited {
  color: #040C2D;
}

.left-action a:hover {
  color: #040C2D;
}

.action-link {
  cursor: pointer;
  color: #1C3CD5;
}

.right-part {
  width: 100%;
  height: 100%;
}

.left-part {
  position: relative;
  height: 100%;
  background-color: #ffffff;
  border-right: 1px solid #EAECF0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-expanded-wrapper {
  position: absolute;
  top: 0;
  left: 92px;
  z-index: 999;
  padding: 10px;
  height: 100%;
}

.menu-expanded-wrapper .menu-expanded {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  -webkit-box-shadow: 0 0 6px 1px rgba(0,0,0,0.05);
  -moz-box-shadow: 0 0 6px 1px rgba(0,0,0,0.05);
  box-shadow: 0 0 9px 1px rgba(0,0,0,0.05);
  border-radius: 8px;
  padding: 8px;
  width: 340px;
  height: 100%;
}

.menu-top {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.menu-bottom {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.menu-item {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
}

.menu-item img {
  height: 24px;
  width: 24px;
}

.menu-item span {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #040C2D;
  text-align: center;
}

.menu-item:hover {
  background-color: #F7F8FA;
}

.logo {
  height: 36px;
}

.menu-separator {
  height: 1px;
  width: 100%;
  background-color: #EAECF0;
}


.menu-expanded-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.menu-expanded-header span {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
  text-align: center;
}

.menu-expanded-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  overflow-y: auto;
  width: 100%;
}

h3{
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #040C2D;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #EAECF0;
}

.library-image{
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #EAECF0;
  padding: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.library-image img{
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.library-image span{
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #040C2D;
  text-align: center;
}

.library-image:hover {
  background-color: #EAECF0;
}

.lib-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}

.search-input {
  background-color: #F9FAFB;
  border: 1px solid #EAECF0;
  border-radius: 24px;
  padding: 5px 10px;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
}
</style>


