<template>
  <div class="header-wrapper">
    <div class="header-row">
      <div class="company-info">
        <h2 v-if="!isLoading">{{ title }}</h2>
        <free-style-shimmer
            :is-loading="isLoading"
            height="20px"
            width="250px"
            border-radius="50px"
            color="#dedede"
        />

        <div class="company-status" v-if="!isLoading">
          <img src="@/assets/images/active.svg" v-if="status" />
          <img src="@/assets/images/failed.svg" v-else />
          <span v-if="status">{{$t('active')}}</span>
          <span v-else>{{$t('inactive')}}</span>
        </div>

        <free-style-shimmer
            :is-loading="isLoading"
            height="10px"
            width="100px"
            border-radius="50px"
            color="#dedede"
        />
      </div>

      <div class="action-row">
        <button class="action-button" @click="onDelete">
          <img src="@/assets/images/trash.svg" />
        </button>
       <!-- <button class="action-button" @click="onEdit">
          <img src="@/assets/images/editPen.svg" />
        </button>-->
      </div>
    </div>
    <div class="company-stats">
      <div class="stat-wrapper">
        <img src="@/assets/images/camera.svg" />
        <span v-if="!isLoading">
          <span class="stat-number">{{sessionCount}}</span> {{$t('scheduled_sessions')}}
        </span>

        <free-style-shimmer
            :is-loading="isLoading"
            height="8px"
            width="80px"
            border-radius="50px"
            color="#dedede"
        />
      </div>

      <div class="stat-wrapper">
        <img src="@/assets/images/verified.svg" />
        <span v-if="!isLoading">
          <span class="stat-number">{{therapyCount}}</span> {{$t('program_assigned')}}
        </span>

        <free-style-shimmer
            :is-loading="isLoading"
            height="8px"
            width="80px"
            border-radius="50px"
            color="#dedede"
        />
      </div>

      <div class="stat-wrapper">
        <img src="@/assets/images/datepicker.svg" />
        <span v-if="!isLoading">
          {{ $t('created_at') }} {{ formatDate(createdAt) }}
        </span>

        <free-style-shimmer
            :is-loading="isLoading"
            height="8px"
            width="80px"
            border-radius="50px"
            color="#dedede"
        />
      </div>
    </div>

    <img :src="photo" class="company-image" v-if="!isLoading && photo !== null"/>
    <free-style-shimmer
        class="company-image"
        :is-loading="isLoading"
        height="120px"
        width="120px"
        border-radius="50%"
        color="#dedede"
    />
  </div>
</template>

<script>


export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    onEdit: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    status: {
      type: Boolean,
      default: true
    },
    sessionCount: {
      type: Number,
      default: 0
    },
    therapyCount: {
      type: Number,
      default: 0
    },
    createdAt: {
      type: String,
      default: ''
    },
    photo: {
      type: String,
      default: ''
    },
    onDelete: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {

    }
  },
  methods: {
    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return '-';
      }
    },
  },
}
</script>

<style scoped>
.header-wrapper {
  position: relative;
  background-color: #F9FAFB;
  background-image: url("@/assets/images/companyHeader.svg");
  background-size: cover;
  padding: 40px;
  margin-bottom: 60px;
}
.header-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
h2 {
  font-family: euclid_semi_bold, sans-serif;
  font-size: 24px;
  color: #101828;
  margin: 0;
  padding: 0;
}
.company-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px
}
.company-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.company-status span {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #344054;
}
.action-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.action-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #98A2B3;
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 7px 10px;
  cursor: pointer;
}
.action-button img {
  height: 16px;
  width: 16px;
}
.action-button:hover {
  background-color: #f5f5f5;
}
.company-stats {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
}

.stat-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.stat-wrapper img {
  height: 16px;
  width: 16px;
}

.stat-wrapper span {
  font-size: 12px;
}

.stat-number {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
}

.company-image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  position: absolute;
  bottom: -60px;
  left: 40px;
}
</style>
