<template>
  <Layout>
    <div class="action-bar">
      <button class="outline-action-button" :class="isEditing && 'active'" @click="toggleEdit">
        {{ isEditing ? $t('done') : $t('edit') }}
      </button>

      <button class="action-button" @click="toggleAddWidgetModal">
        {{$t('add_widget')}}
      </button>
    </div>

    <div class="grid-stack">
      <WidgetWrapper
          v-for="chart in widgets"
          :is-loading="isLoading"
          :key="chart.id"
          :data="chart"
          :is-editing="isEditing"
      />
    </div>

    <WidgetModal
        :visible="addWidgetModal"
        :on-hide="toggleAddWidgetModal"
        :program-list="programList"
        :test-list="testList"
        :onboarding-list="onboardingList"
        :on-add="() => getDashboardData()"
    />

  </Layout>
</template>

<script setup>
import { GridStack } from "gridstack";
import Layout from "@/components/layouts/Layout.vue";
import {nextTick, onMounted, ref} from "vue";
import WidgetWrapper from "@/components/dashboard-components/WidgetWrapper.vue";
import {handleErrorResponse} from "@/utils/utils";
import WidgetModal from "@/components/modals/WidgetModal.vue";
import widgetService from "@/service/widgetService";
import therapyService from "@/service/therapyService";
import testService from "@/service/testService";
import onboardingService from "@/service/onboardingService";

const isLoading = ref(true);
const grid = ref(null);
const addWidgetModal = ref(false);
const widgets = ref([
  {
    id: 'loading-1',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 0,
      y: 0,
      w: 3,
      h: 2,
    },
  },
  {
    id: 'loading-2',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 3,
      y: 0,
      w: 3,
      h: 2,
    },
  },
  {
    id: 'loading-3',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 6,
      y: 0,
      w: 3,
      h: 2,
    },
  },
  {
    id: 'loading-4',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 9,
      y: 0,
      w: 3,
      h: 2,
    },
  },
  {
    id: 'loading-5',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 0,
      y: 2,
      w: 6,
      h: 4,
    },
  },
  {
    id: 'loading-6',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 6,
      y: 2,
      w: 6,
      h: 4,
    },
  },
  {
    id: 'loading-7',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 0,
      y: 6,
      w: 8,
      h: 4,
    },
  },
  {
    id: 'loading-8',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 8,
      y: 6,
      w: 4,
      h: 4,
    },
  },
]);
const programList = ref(null);
const testList = ref(null);
const onboardingList = ref(null);


function getProgramList() {
  therapyService.getProgramList().then(response => {
    if (!response.data?.data || response.data?.data?.length === 0) {
      return;
    }

    programList.value = response.data.data;
  }).catch(error => {
    handleErrorResponse(error, $snackbar);
  });
}

function getTestList() {
  testService.getAllTests().then(response => {
    if (!response.data?.data || response.data?.data?.length === 0) {
      return;
    }

    testList.value = response.data.data;
  }).catch(error => {
    handleErrorResponse(error, $snackbar);
  });
}

function getOnboardingList() {
  onboardingService.getAllOnboarding().then(response => {
    if (!response.data?.data || response.data?.data?.length === 0) {
      return;
    }

    onboardingList.value = response.data.data;
  }).catch(error => {
    handleErrorResponse(error, $snackbar);
  });
}

function getDashboardData() {
  initGridStack();
  isLoading.value = true;
  widgetService.getPositions().then(positionResponse => {
    widgetService.getWidgets().then(response => {
      if (response.data?.data && response.data?.data?.length > 0) {
        widgets.value = [];
        response.data.data.map(widget => {
          let position = {
            x: 0,
            y: 0,
            w: 3,
            h: 2,
          }

          try {
            position = positionResponse.data.data.find(p => p.widgetId === widget.widgetId).grid;
          } catch (e) {
            console.warn("Widget position not found", e);
          }

          widgets.value.push({
            id: widget.widgetId,
            data: {
              series: widget.series,
              labels: widget.labels,
            },
            name: widget.name,
            type: widget.type,
            grid: position,
          });
        });

        nextTick(() => {
          initGridStack();
        });
      }
      isLoading.value = false;
    }).catch(error => {
      console.error(error);
      handleErrorResponse(error, $snackbar);
    });
  }).catch(error => {
    console.error(error);
    handleErrorResponse(error, $snackbar);
  });
}


function initGridStack() {
  if (grid.value) {
    grid.value.removeAll();
  }

  grid.value = GridStack.init({
    column: 4,
    cellHeight: 100,
    margin: 10,
    disableResize: !isEditing.value,
    disableDrag: !isEditing.value,
    columnOpts: {
      breakpointForWindow: true,
      breakpoints: [{w:700, c:1},{w:850, c:3},{w:950, c:6},{w:1100, c:8}]
    },
    float: false,
  });
  makeWidgets(widgets.value);
}

function makeWidgets(widgets) {
  widgets.forEach((widget) => {
    makeWidget(widget);
  });
}
function makeWidget(item) {
  if (!item.id) {
    return;
  }

  const elSelector = `#${item.id}`;
  return grid.value.makeWidget(elSelector);
}

function toggleAddWidgetModal() {
  addWidgetModal.value = !addWidgetModal.value;
}

const isEditing = ref(false);

function toggleEdit() {
  if (isEditing.value) {
    grid.value.disable();

    const gridItems = grid.value.getGridItems();
    const tempPositions = [];

    gridItems.map((item) => {
      const widget = widgets.value.find((w) => w.id === item.gridstackNode?.id);

      if (widget) {
        tempPositions.push({
          widgetId: widget.id,
          grid: {
            x: item.gridstackNode.x,
            y: item.gridstackNode.y,
            w: item.gridstackNode.w,
            h: item.gridstackNode.h,
          },
        });
      }
    });

    widgetService.savePositions(tempPositions).then(() => {
      $snackbar.add({
        text: "Dashboard saved",
        type: "success",
      });
    }).catch((error) => {
      handleErrorResponse(error, $snackbar);
    });

  } else {
    grid.value.enable();
  }
  isEditing.value = !isEditing.value;
}

onMounted(() => {
  getProgramList();
  getTestList();
  getOnboardingList();
  getDashboardData();
});
</script>

<style scoped>
.action-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  gap: 10px;
}
</style>
