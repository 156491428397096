<script setup>
import { Handle, Position } from '@vue-flow/core'
import { ref, computed } from 'vue';
import NodeDropdown from "@/components/condition-flow/NodeDropdown.vue";

const props = defineProps(['id', 'data', 'testList', 'onSelect', 'editDisabled']);
const testOptions = computed(() =>
    props.testList.map(test => ({ value: test.id, text: test.name }))
);

const handleTestSelect = (testId) => {
  if (testId === '-1') return;
  const item = props.testList.find((test) => test.id === testId);
  props.onSelect(item, props.id);
}
</script>

<template>
  <div class="node-wrapper">
    <Handle id="input" type="target" :position="Position.Top"/>

    <div class="node-header">
      <img src="../../../assets/images/linkCircleOutlineDark.svg" alt="icon"/>
      <span class="node-type">{{$t('assign')}}</span>
    </div>

    <div class="node-body" @wheel.stop>
      <NodeDropdown :search-enabled="true" theme="dark" :on-select="handleTestSelect" :selected-value="props.data?.id" :options="testOptions" />
    </div>

    <Handle id="output" type="source" :position="Position.Bottom"/>
  </div>
</template>

<style scoped>
.node-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 320px;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #040c2d;
  background-color: #fff;
  border-radius: 6px;
}


.node-header {
  width: 100%;
  background-color: #f1f1f1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 10px;
}

.node-header img {
  width: 24px;
  height: 24px;
}

.node-type {
  font-size: 16px;
  color: #101828;
  font-family: 'euclid_regular', sans-serif;
}

.node-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}
</style>
