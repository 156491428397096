<template>
  <main class="login-main">
    <header>
      <img alt="Ibility logo" class="logo" src="../assets/images/iblity_black.svg" width="60" height="40" />
    </header>
    <div class="login-container">

      <div class="right">
        <div class="login-form">
          <div class="forgot-text">
            <img alt="Ibility dots" class="login-image" src="../assets/images/login_dots.svg" />
            <h2 class="login-form-title">{{$t('forgot_password')}}</h2>
          </div>
          <span class="form-label">{{$t('e_mail')}}</span>
          <div class="input-holder">
            <input class="form-input" v-model="email" :placeholder="this.$t('enter_your_work_e-mail')" />
            <img v-if="isEmailVerified" class="icon" alt="Check" src="../assets/images/check.svg"/>
          </div>
            <button class="circular-btn" @click="confirmModalShow">

              <span v-if="!loading">{{$t('reset_password')}}</span>
              <VueSpinnerIos v-else size="20" height="100%" color="#fff" />
            </button>
          <div class="warning-text">
            {{$t('reset_password_warning')}}
          </div>
          <ConfirmDeleteModal
              :is-loading="loading"
              :reset-modal="true"
              :is-visible="showConfirmModal"
              :on-action="reset"
              :title="this.$t('reset_password')"
              :message="this.$t('reset_password_warning')"
              :on-close="confirmModalHide"
          />

        </div>
      </div>
    </div>
  </main>
</template>


<script>
import {
  VueSpinnerIos
} from 'vue3-spinners'
import {handleErrorResponse} from "@/utils/utils";
import commonService from "@/service/commonService";
import ConfirmDeleteModal from "@/components/modals/ConfirmDeleteModal.vue";

export default {
  name: 'ResetPassword',
  components: {
    ConfirmDeleteModal,
    VueSpinnerIos
  },
  data() {
    return {
      showConfirmModal:false,
      email: '',
      isEmailVerified: false,
      loading: false,
    }
  },
  watch: {
    email(newValue) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isEmailVerified = emailRegex.test(newValue);
    }
  },
  methods: {
    confirmModalShow(){
      if(this.isEmailVerified){
      this.showConfirmModal = true;
      }
      else{
        this.confirmModalHide();
        this.$snackbar.add({
          text: this.$t('InvalidEmail'),
          type:"error",
        })
      }


    },
    confirmModalHide(){
      this.showConfirmModal = false;
    },
    reset() {
      if (this.isEmailVerified && !this.loading) {
        this.loading = true;

        commonService.resetPassword(this.email).then(response => {

          if (response.data.responseType === "success") {
            this.$snackbar.add({
              text: this.$t('reset_password_success'),
              type:"success",
            })
            this.$router.push('/login');
          }

          this.loading = false;
        }).catch(error => {
          this.showConfirmModal=false;
          this.loading = false;
          handleErrorResponse(error, this.$snackbar);
        })
      }
      else{
        this.confirmModalHide();
        this.$snackbar.add({
          text: this.$t('InvalidEmail'),
          type:"error",
        })
      }
    }
  },
}
</script>

<style scoped>

.login-main {
  background-color: var(--vt-c-light-yellow);
}

header {
  position: fixed;
  display: flex;
  width: 100%;
  height: 104px;
  align-items: center;
  margin: 0;
  justify-content: center;
}

.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  align-items: center;
  margin: 0;

  .right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}

.login-form {
  background-color: #FFFFFF;
  box-shadow: 0 192px 77px rgba(171, 171, 171, 0.01), 0 108px 65px rgba(171, 171, 171, 0.05), 0 48px 48px rgba(171, 171, 171, 0.09), 0 12px 26px rgba(171, 171, 171, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 80px 32px;
  width: 60%;
}

.circular-btn {
  margin-top: 1rem;
}

.forgot-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.login-form-title {
  font-family: euclid_semi_bold, 'sans-serif';
  font-size: 32px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.warning-text {
  color: #98a2b3;
  font-family: 'euclid_regular', sans-serif;
  font-size: 12px;
  text-align: center;
  margin-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}




@media (max-width: 1200px) {
  .login-form {
    width: 70%;
  }
}

@media (max-width: 996px) {
  .login-form {
    width: 80%;
  }
}

@media (max-width: 876px) {
  .login-container {
    height: 100%;


    .right {
      width: 100%;
    }
  }

  header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      margin-left: 0;

    }
  }

  .login-form {
    padding: 30px 20px;
  }
}

@media (max-width: 576px) {
  .login-form {
    width: 90%;
  }

  .login-form-title {
    font-size: 26px;
  }

  .login-image {
    width: 100px;
  }

  .circular-btn {
    font-size: 15px;
    height: 40px;
  }

  .warning-text {
    font-size: 10px;
  }

}

@media (max-width: 376px) {
  .warning-text {
    font-size: 9px;
  }

}

</style>
