<template>
  <div :class="['sidebar', { 'collapsed': isCollapsed }]">
    <div class="sidebar-header">
      <img
          v-if="!isCollapsed && companyIcon"
          :src="companyIcon"
          alt="Logo"
          class="logo"
      />
      <img  @click="toggleCollapse" src="../assets/images/sidebar-close.svg" class="sidebar-icon" alt="Dashboard Icon" />
    </div>
    <div class="space-between">
      <ul>
        <template  v-for="item in sidebarRoutes">
        <router-link v-if="!isLoading && allowedIds.includes(item.id)" :to="item.path" class="route-link">
          <li class="menu-li" :class="{'active': checkActive(item.path)}">
          <span>
            <img v-if="checkActive(item.path)" :src="item.activeIcon" :alt="item.name" />
            <img v-else :src="item.icon" :alt="item.name" />
            <span class="li-title"  v-if="!isCollapsed">{{ item.name }}</span>
          </span>
          </li>
        </router-link>
        </template>

        <li class="loading-li" v-if="isLoading" v-for="item in sidebarRoutes">
          <free-style-shimmer
              color="#EAECF0"
              height="20px"
              width="20px"
          />
          <free-style-shimmer
              class="loading-shimmer"
              color="#EAECF0"
              height="15px"
              :width="(78 + Math.floor(Math.random() * 25)) + 'px'"
          />
        </li>

      </ul>
      <ul>
        <router-link to="/settings" class="route-link">
        <li class="menu-li" :class="{'active': checkActive('/settings')}">
          <span>
            <img v-if="checkActive('/settings')" src="../assets/images/settingsActive.svg" alt="Companies Icon" />
            <img v-else src="../assets/images/settings.svg" />
            <span class="li-title"  v-if="!isCollapsed">{{ $t('settings_payment') }}</span>
          </span>
        </li>
        </router-link>
        <li class="menu-li" @click="onLogout">
          <span><img src="../assets/images/logout.svg" alt="Logout Icon" /> <span class="li-title"  v-if="!isCollapsed">{{ $t('logout_profile') }}</span></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import sidebarRoutes from "../router/sidebarRoutes";
import authService from "@/service/authService";
import roleService from "@/service/roleService";
import {handleErrorResponse} from "@/utils/utils";
import settingsService from "@/service/settingsService";

export default {
  data() {
    return {
      isCollapsed: false,
      isLoading: true,
      allowedIds: [],
      sidebarRoutes,
      companyIcon:localStorage.getItem("logoCacheUrl"),
    };
  },
  created() {

    if (window.innerWidth < 672) {
      this.isCollapsed = true;
    }
  },

  mounted() {
    this.fetchMenuModules();

    const cachedLogo = localStorage.getItem('logoCacheUrl')
    if (cachedLogo) {
      this.companyIcon = cachedLogo
    }
    settingsService.getCompanySettings()
        .then(response => {
          const newLogoUrl = response.data.data.appIconUrl
          if (newLogoUrl && newLogoUrl !== this.companyIcon) {
            localStorage.setItem('logoCacheUrl', newLogoUrl)
            this.companyIcon = newLogoUrl
          }
        })
        .catch(error => {
          console.error('Error fetching company settings:', error)
        })
  },
  methods: {
    fetchMenuModules() {
      this.isLoading = true;

      roleService.getMenuModules().then((response) => {
        this.allowedIds = response.data.data.map(item => item.key);
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    checkActive(route) {
      try {
        const currentUrl = new URL(window.location.href);
        const pathnameParts = currentUrl.pathname.split('/');
        const mainPath = '/' + pathnameParts[1];
        return mainPath === route;
      } catch (error) {
        return false;
      }
    },
    onLogout() {
      authService.logout().then(() => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        this.$router.push('/login');
      }).catch(() => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        this.$router.push('/login');
      });
    }
  }
}
</script>

<style scoped>
body, h1, h2, h3, p, ul {
  margin: 0;
  padding: 0;
}
.logo{
  height: 40px;
  cursor: pointer;
}
.sidebar {
  z-index: 1000;
  width: 240px;
  height: 100vh;
  background-color: #FAFAFA;
  transition: width 0.3s;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
  border-right-color: #EAECF0;
  border-right-width: 1px;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
}
.sidebar.collapsed {
  width: 50px;
}
.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FAFAFA;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li.menu-li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 10px 20px;
  padding: 8px 5px;
  border-radius: 8px;
}
li.loading-li {
  margin: 10px 20px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
li span {
  display: flex;
  align-items: center;
}
.route-link {
  text-decoration: none;
}
.li-title {
  font-size: 12px;
  line-height: normal;
  color: #475467;
  font-weight: 500;
  padding-left: 8px;
}

li:hover {
  background-color: #EBEDFB;
}

li.active {
  background-color: #EBEDFB;
}

li img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.sidebar.collapsed li {
  margin: 0;
  justify-content: center;
}
.sidebar.collapsed .sidebar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFA;
  margin-top: 20px;
}
.sidebar.collapsed ul {
  margin: 0;
  justify-content: center;
}
.sidebar.collapsed li img {
  padding-left: 0;
}
.sidebar.collapsed .li-title {
  display: none;
}
.sidebar.collapsed li:hover {
  background-color: inherit;
}
.sidebar-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.space-between{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 64px);
  padding-bottom: 20px;
}
.space-between ul:not(:last-child) {
  margin-bottom: 20px;
}
.sidebar.collapsed .sidebar-header {
  margin-bottom: 20px;
}

.loading-shimmer {
  flex: 1;
}

@media (max-width: 672px) {
  .sidebar {
    position: absolute;
  }

  .sidebar.collapsed {
    width: 0;
  }
}
</style>
