<template>
  <div :class="wrapperClass" @click="handleChange">
    <div :class="thumbClass"></div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';

export default {
  props: {
    modelValue: {
      type: [Boolean, String],
      default: false,
      validator: (value) => {
        return typeof value === 'boolean' || value === '1' || value === '0';
      }
    },
    onToggle: {
      type: Function,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const isChecked = (value) => {
      return value === true || value === '1';
    };

    const checked = ref(isChecked(props.modelValue));

    watch(
        () => props.modelValue,
        (val) => {
          checked.value = isChecked(val);
        }
    );

    const handleChange = () => {
      checked.value = !checked.value;

      // Determine the type of value to emit based on the current prop type
      const newValue = typeof props.modelValue === 'string'
          ? (checked.value ? '1' : '0')
          : checked.value;

      emit('update:modelValue', newValue);
      props.onToggle(newValue);
    };

    const wrapperClass = computed(() => {
      return checked.value ? "switch-wrapper checked" : "switch-wrapper";
    });

    const thumbClass = computed(() => {
      return checked.value ? "thumb checked" : "thumb";
    });

    return {
      checked,
      handleChange,
      wrapperClass,
      thumbClass
    };
  },
};
</script>

<style scoped>
.switch-wrapper {
  position: relative;
  width: 36px;
  height: 20px;
  background-color: #EAECF0;
  border-radius: 12px;
  transition: background-color 0.1s linear;
  cursor: pointer;
}

.switch-wrapper.checked {
  background-color: #6FDDA6;
}

.thumb {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 16px;
  width: 16px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s linear;
  border-radius: 50%;
}

.thumb.checked {
  left: 18px;
}
</style>
