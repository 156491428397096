<template>
  <div class="success-container">
    <div class="checkmark-circle">
      <span class="checkmark">
        <img class="tick" src="@/assets/images/tickWhite.svg">
      </span>
    </div>
    <div class="text">
      <div class="title">{{ $t("step_completed") }} 🙌</div>
      <div class="subtitle">{{ $t("success_message") }}</div>
    </div>
  </div>
</template>
<script>
export default{
  props:{
    submodule:{
      type:Object
    },
    selectedModule:{
      type:Object
    }
  }
}
</script>
<style scoped>
.tick{
  display: flex;
  align-self: center;
  height: 100%;
  width: 100%;
}
.text .title {
  font-family: 'euclid_regular', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}

.text .subtitle {
  font-family: 'euclid_regular', sans-serif;
  font-size: 10px;
  color: #fff;
  margin-top: 10px;
}
.checkmark {
  display: flex;
  width: 100px;
  color: #fff;
  font-size: 50px;
}
.checkmark-circle {
  position: relative;
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  z-index: 999;
}
.success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #1cad79;
  overflow: hidden;
  text-align: center;
}
</style>