<template>
  <Layout>

    <h3>{{$t('reviews_therapy')}}</h3>
    <div class="tab-panel-row">
      <div class="tab-panel">
        <button class="tab-button" :class="{'active': tabIndex === 0}" @click="() => changeTab(0)">
          <span>{{$t('therapy')}}</span>
        </button>
        <button class="tab-button" :class="{'active': tabIndex === 1}" @click="() => changeTab(1)">
          <span>{{$t('expert_title')}}</span>
        </button>
      </div>
    </div>

    <div v-if="tabIndex === 0">
      <div class="reviews-grid">
        <div v-if="!therapyReviewsLoading" v-for="item in therapyReviews">
          <ReviewCard :loading="false" :review-id="item.reviewsId" :published-status="item.published" :avatar="item.userInfo?.avatarUrl" :name="item.userInfo?.name" :review="item.message" :score="item.score" />
        </div>
        <div v-if="therapyReviewsLoading">
          <ReviewCard :loading="true" />
        </div>
        <div v-if="therapyReviewsLoading">
          <ReviewCard :loading="true" />
        </div>
      </div>

      <div class="pagination-align-center mt-5">
        <Pagination :total-items="therapyReviewsTotal" :per-page="20" :current-page="therapyReviewsPage" :on-page-change="handleTherapyPage"/>
      </div>

    </div>

    <div v-if="tabIndex === 1">
      <div class="reviews-grid">
        <div v-if="!expertReviewsLoading" v-for="item in expertReviews">
          <ReviewCard :loading="false" :review-id="item.reviewsId" :published-status="item.published" :avatar="item.userInfo?.avatarUrl" :name="item.userInfo?.name" :review="item.message" :score="item.score" />
        </div>
        <div v-if="expertReviewsLoading">
          <ReviewCard :loading="true" />
        </div>
        <div v-if="expertReviewsLoading">
          <ReviewCard :loading="true" />
        </div>
      </div>

      <div class="pagination-align-center mt-5">
        <Pagination :total-items="expertReviewsTotal" :per-page="20" :current-page="expertReviewsPage" :on-page-change="handleExpertPage"/>
      </div>
    </div>

  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import reviewService from "@/service/reviewService";
import {handleErrorResponse} from "@/utils/utils";
import ReviewCard from "@/components/ReviewCard.vue";
import Pagination from "@/components/Pagination.vue";


export default {
  components: {
    Pagination,
    ReviewCard,
    Layout
  },
  data() {
    return {
      isLoading: true,
      tabIndex: 0,

      therapyReviewsLoading: false,
      therapyReviews: [],
      therapyReviewsPage: 1,
      therapyReviewsTotal: 0,

      expertReviewsLoading: false,
      expertReviews: [],
      expertReviewsPage: 1,
      expertReviewsTotal: 0,
    }
  },
  mounted() {
    this.getTherapyReviews();
    this.getExpertReviews();
  },
  methods: {
    changeTab(index) {
      this.tabIndex = index;
    },
    getTherapyReviews() {
      reviewService.getReviews(this.therapyReviewsPage, 'therapy').then(response => {
        this.therapyReviews = response.data.data.data;
        this.therapyReviewsTotal = response.data.data.total;
        this.therapyReviewsLoading = false;
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      });
    },
    getExpertReviews() {
      reviewService.getReviews(this.expertReviewsPage, 'expert').then(response => {
        this.expertReviews = response.data.data.data;
        this.expertReviewsTotal = response.data.data.total;
        this.expertReviewsLoading = false;
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      });
    },
    handleExpertPage(page) {
      if (this.expertReviewsLoading && page !== 1) return;
      this.expertReviewsLoading = true;
      this.expertReviewsPage = page;
      this.getExpertReviews();
    },
    handleTherapyPage(page) {
      if (this.therapyReviewsLoading && page !== 1) return;
      this.therapyReviewsLoading = true;
      this.therapyReviewsPage = page;
      this.getTherapyReviews();
    }
  },
}

</script>

<style scoped>
.tab-panel {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}

.tab-panel-row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #F2F4F7;
  border-bottom: 1px solid #F2F4F7;
  padding: 10px 0;
  margin-bottom: 20px;
}

.tab-button {
  padding: 6px 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.tab-button span {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #344054;
}

.tab-button.active, .tab-button:hover {
  background-color: #F2F4F7;
}

h3 {
  font-family: euclid_medium, sans-serif;
  font-size: 18px;
  color: #040C2D;
  padding: 0;
  margin-bottom: 20px;
}

.reviews-grid {
  width: 100%;
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}
</style>
