<template>
  <SliderLayout :on-action="handleSave" :on-settings-action="handleSettingsModalShow">
    <div class="tab-panel-wrapper no-text-select">
      <div class="tab-panel-left"></div>

      <div class="tab-panel-right">
        <TranslationDropdown :selected-language="activeLanguage" :on-select="handleLanguageChange"/>
      </div>
    </div>


    <div class="card-wrapper">
      <div class="title-card-border"></div>

      <span class="input-title">{{$t('title_expert')}}</span>
      <input class="form-input w-100 mb-3" :placeholder="this.$t('add_your_slider_title_here')" v-model="sliderData.title"/>

      <div class="card-row mb-2 no-text-select">
        <div class="row-50">
          <span class="input-title">{{$t('description')}}</span>
          <textarea class="form-input w-100" :placeholder="this.$t('add_your_slider_description_here')" v-model="sliderData.description" v-textarea-autoresize></textarea>
        </div>

        <div class="row-50">
          <span class="input-title">{{$t('short_description')}}</span>
          <textarea class="form-input w-100" :placeholder="this.$t('add_your_slider_description_here')" v-model="sliderData.shortDescription" v-textarea-autoresize></textarea>
        </div>
      </div>

      <span class="input-title">{{$t('type')}}</span>
      <SelectBox :options="sliderTypes" :on-select="handleTypeSelect" :selected-value="sliderData.code" />

    </div>

    <div class="card-wrapper mt-3">
      <span class="input-title mb-3">{{$t('image')}}</span>
      <FileDrop :on-file-selected="handleFileSelected" :selected-file="sliderData.imageUrl" :attach="sliderData.image" />
    </div>

    <SliderSettingsModal :visible="settingsModalVisible" :onHide="handleSettingsModalHide" :sliderData="sliderData" :edit-mode="editMode" />
    <LoadingOverlay :is-loading="isLoading" />
  </SliderLayout>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import {VueSpinnerCore} from "vue3-spinners";
import {handleErrorResponse} from "@/utils/utils";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import SliderLayout from "@/components/layouts/SliderLayout.vue";
import sliderService from "@/service/sliderService";
import Switch from "@/components/Switch.vue";
import SliderSettingsModal from "@/components/modals/SliderSettingsModal.vue";
import FileDrop from "@/components/FileDrop.vue";
import SelectBox from "@/components/SelectBox.vue";
import TranslationDropdown from "@/components/TranslationDropdown.vue";

export default {
  name: 'BlogDetails',
  components: {
    TranslationDropdown,
    SelectBox,
    FileDrop,
    SliderSettingsModal,
    Switch,
    SliderLayout,
    LoadingOverlay,
    ExpandableSearch,
    Pagination,
    VueSpinnerCore
  },
  data() {
    return {
      isLoading: true,
      editMode: false,
      activeLanguage: 'tr',
      settingsModalVisible: false,
      sliderData: {
        status: true,
        title: '',
        image: null,
        bgColor: '#000',
        description: '',
        shortDescription: '',
        order: 0,
        code: '',
        where: {
          mode: null,
        }
      },
      sliderTypes: [
        {
          text: 'Home Page',
          value: 'HOME_PAGE'
        },
        {
          text: 'On Boarding',
          value: 'ON_BOARDING'
        },
      ]
    }
  },

  async mounted() {
    const routeParams = this.$route.params;
    this.isLoading = true;

    if (routeParams.id !== "new") {
      this.handleGetDetail();
    } else {
      this.isLoading = false;
      this.editMode = false;
    }
  },
  methods: {
    handleGetDetail() {
      const routeParams = this.$route.params;

      this.editMode = true;
      this.isLoading = true;

      sliderService.getSliderDetail(routeParams.id, this.activeLanguage).then((res) => {
        this.sliderData = res.data.data;
      }).catch((err) => {
        handleErrorResponse(err, this.$snackbar);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    handleSettingsModalShow() {
      this.settingsModalVisible = true;
    },
    handleSettingsModalHide() {
      this.settingsModalVisible = false;
    },
    handleFileSelected(file) {
      this.sliderData.image = file;
    },
    handleTypeSelect(value) {
      this.sliderData.code = value;
    },
    handleSave() {
      this.isLoading = true;

      if (this.editMode) {
        sliderService.updateSlider(this.sliderData, this.activeLanguage).then(response => {
          this.$snackbar.add({
            text: 'Slider updated.',
            type: 'success'
          });
          this.sliderData = response.data.data;
        }).catch((err) => {
          handleErrorResponse(err, this.$snackbar);
        }).finally(() => {
          this.isLoading = false;
        });
      } else {
        sliderService.addSlider(this.sliderData, this.activeLanguage).then(response => {
          this.$snackbar.add({
            text: 'Slider created.',
            type: 'success'
          });
          window.location.href = "/slider/" + response.data.data.sliderId;
        }).catch((err) => {
          handleErrorResponse(err, this.$snackbar);
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },
    handleLanguageChange(lang) {
      const routeParams = this.$route.params;
      this.activeLanguage = lang;

      if (routeParams.id !== "new") {
        this.handleGetDetail();
      }
    },
  }
}
</script>

<style scoped>
.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
}

.title-card-border {
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0;
  left: 0;
  background-color: #1C3CD5;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.card-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 15px;
}

.input-title {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #010511;
  display: block;
  margin-bottom: 5px;
}

.row-50 {
  width: 100%;
}

.dropdown-item img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.tab-panel-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tab-panel-left {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.tab-panel-right {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
