<template>
  <Layout padding="0">
    <CompanyHeader
        :is-loading="isLoading"
        :title="details.name"
        :status="details.status"
        :user-count="details.totalUsers"
        :therapy-count="details.totalTherapies"
        :created-at="details.createdAt"
        :photo="details.avatarUrl"
        :on-edit="showEditModal"
        :on-delete="showDeleteModal"/>
    <div class="main-content">
      <div class="tab-panel-row">
        <div class="tab-panel">
          <button class="tab-button" :class="{'active': tabIndex === 0}" @click="() => changeTab(0)">
            <span>{{ $t('general_tab') }}</span>
          </button>
          <button class="tab-button" :class="{'active': tabIndex === 1}" @click="() => changeTab(1)">
            <span>{{ $t('users_tab') }}</span>
          </button>
          <button class="tab-button" :class="{'active': tabIndex === 2}" @click="() => changeTab(2)">
            <span>{{ $t('therapies_tab') }}</span>
          </button>
          <button class="tab-button" :class="{'active': tabIndex === 3}" @click="() => changeTab(3)">
            <span>{{ $t('tests_tab') }}</span>
          </button>
          <button class="tab-button" :class="{'active': tabIndex === 5}" @click="() => changeTab(5)">
            <span>{{ $t('game_labs_tab') }}</span>
          </button>
          <button class="tab-button" :class="{'active': tabIndex === 6}" @click="() => changeTab(6)">
            <span>{{ $t('departments') }}</span>
          </button>
          <button class="tab-button" :class="{'active': tabIndex === 4}" @click="() => changeTab(4)">
            <span>{{ $t('transactions_tab') }}</span>
          </button>
        </div>

        <div class="tab-actions">
          <div class="action-row" v-if="tabIndex === 1">
            <ExpandableSearch :value="searchText" @input="handleSearch" />
            <div class="justify-end-add-btn no-text-select" @click="showInviteUser">
              <img src="../assets/images/plus.svg">
              <span>{{$t('add_new')}}</span>
            </div>
          </div>
          <div class="action-row" v-if="tabIndex === 2">
            <ExpandableSearch :value="searchText" @input="handleSearch" />
            <button class="dark-button-sm" @click="openAssignModal">{{$t('assign_new')}}</button>
          </div>
          <div class="action-row" v-if="tabIndex === 3">
            <ExpandableSearch :value="searchText" @input="handleSearch" />
            <button class="dark-button-sm" @click="showAssignTestModal">{{$t('assign_new')}}</button>
          </div>
          <div class="action-row" v-if="tabIndex === 5">
            <ExpandableSearch :value="searchText" @input="handleSearch" />
            <button class="dark-button-sm" @click="showAssignGameLabsModal">{{$t('assign_new')}}</button>
          </div>
          <div class="action-row" v-if="tabIndex === 4">
            <ExpandableSearch :value="searchText" @input="handleSearch" />
            <button class="dark-button-sm" @click="showAssignGameLabsModal">{{$t('assign_new')}}</button>
          </div>
          <div class="action-row" v-if="tabIndex === 6">
            <ExpandableSearch :value="searchText" @input="handleSearch" />
            <button class="dark-button-sm" @click="showDepModal">{{$t('add_department')}}</button>
          </div>
        </div>
      </div>

      <div v-if="tabIndex === 0" class="tab-container">
        <h3>{{ $t('company_details') }}</h3>
        <div class="company-details">
          <div class="company-details-grid">

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/buildings.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{ $t('company_name') }}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{details.name}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/calendar.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{ $t('create_date') }}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{formatDate(details.createdAt)}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/message.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                 {{ $t('email_address') }}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{details.authorizedemail}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/phone.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{ $t('phone') }}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{details.phone}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/document.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{ $t('tax_id_number') }}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{details.taxNumber}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/dashboard.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{ $t('tax_office') }}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{details.taxOffice}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/address.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{ $t('address') }}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{details.address}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="tabIndex === 1">
        <UserTable :loading="isLoading" :users="details.users" :right-action="false" :company-id="details.companyId" :code="details.inviteCode"/>
        <InviteUserModal :company-id="details.companyId" :role-list="roleList" :visible="addUserModal" :on-hide="hideInviteUser" :deps="deps" />
      </div>

      <div v-if="tabIndex === 2" class="tab-container">
        <h3>{{ $t('assigned_therapies') }}</h3>

        <div class="therapies-row" v-if="isLoading">
          <TherapyCard :is-loading="true" />
          <TherapyCard :is-loading="true" />
          <TherapyCard :is-loading="true" />
          <TherapyCard :is-loading="true" />
        </div>

        <div class="therapies-row" v-else>
          <TherapyCard
              v-for="therapy in details.therapies"
              :image="therapy.avatar"
              :is-loading="false"
              :title="therapy.name"
              :description="therapy.shortDescription"
              :tags="therapy.tags.map((x) => x.name)"
              :remaining-days="therapy.remainingDay"
              :assigned-at="therapy.assignedAt"
              :to="`/therapy/${therapy.therapyId}`"
              :remaining="therapy.remaining"
              :start-at="therapy.startAt"
              :finish-at="therapy.finishAt"
              :is-publish="therapy.isPublish"

          />
        </div>

        <div v-if="!isLoading && details.therapies.length === 0" class="no-tests-wrapper">
          <img src="../assets/images/noTests.png" class="no-tests-image">
          <span class="no-tests-text">{{$t('not_assigned_programs_yet')}}</span>
          <span class="no-tests-description">{{$t('first_therapy_assign')}}</span>
        </div>

      </div>

      <div v-if="tabIndex === 3" class="tab-container">
        <h3>{{ $t('assigned_tests') }}</h3>

        <div v-if="!isLoading && details.tests.length === 0" class="no-tests-wrapper">
          <img src="../assets/images/noTests.png" class="no-tests-image">
          <span class="no-tests-text">{{ $t('not_assigned_tests_yet') }}</span>
          <span class="no-tests-description">{{  $t('first_test_assign') }}</span>
        </div>

        <div class="tests-grid">
          <router-link v-for="item in details.tests"  v-if="!isLoading" :to="`/test/${item.testId}`">
            <div  class="test-card no-text-select">
              <div class="status-tag" :class="item.isPublish ? 'Published' : 'Draft'">
                <div class="status-dot" :class="item.isPublish ? 'Published' : 'Draft'"></div>
                <span>{{item.isPublish ? $t('published') : $t('draft')}}</span>
              </div>

              <span class="test-date">{{formatDate(item.created_at)}}</span>
              <span class="test-title">{{item.name}}</span>

              <div class="test-info">
                <img src="@/assets/images/datepicker.svg" class="test-info-icon"/>
                <span class="test-info-text">
                  {{ formatDate(item.startAt) }} - {{ formatDate(item.finishAt) }}
                </span>

                <div class="dot"></div>

                <div class="test-time-row" v-if="item.remaining.isPast === 0">
                  <img
                      src="@/assets/images/clockOutlineRed.svg"
                      class="test-info-icon"
                  />
                  <span class="test-info-text red">
                {{ item.remaining.time }}
              {{$t('time_is_up')}}
            </span>
                </div>

                <div class="test-time-row"  v-if="item.remaining.isPast === 1">
                  <img
                      src="@/assets/images/clockOutlineGreen.svg"
                      class="test-info-icon"
                  />
                  <span class="test-info-text green">
              {{ item.remaining.time }}
            </span>
                </div>

                <div class="test-time-row"  v-else-if="item.remaining.isPast === 2">
                  <img
                      src="@/assets/images/clockOutlineYellow.svg"
                      class="test-info-icon"
                  />
                  <span class="test-info-text yellow">
              {{ item.remaining.time }}
            </span>
                </div>
              </div>

              <div class="info-row">
                <span class="info-tag">{{$t('companies_count', {companiesCount: item.compainesCount})}}</span>
                <span class="info-tag">{{$t('question_count', {questionCount: item.questionsCount})}}</span>
              </div>
            </div>
          </router-link>

          <free-style-shimmer
              :is-loading="isLoading"
              height="200px"
              width="100%"
              border-radius="20px"
              color="#EAECF0"
          />

          <free-style-shimmer
              :is-loading="isLoading"
              height="200px"
              width="100%"
              border-radius="20px"
              color="#EAECF0"
          />

          <free-style-shimmer
              :is-loading="isLoading"
              height="200px"
              width="100%"
              border-radius="20px"
              color="#EAECF0"
          />

        </div>

      </div>


      <div v-if="tabIndex === 4" class="tab-container">
        <h3>{{$t('all_transactions')}}</h3>

        <TransactionTable />
      </div>


      <div v-if="tabIndex === 5" class="tab-container">
        <h3>{{ $t('assigned_game_labs') }}</h3>

        <div v-if="!isLoading && details.gameLabs.length === 0" class="no-tests-wrapper">
          <img src="../assets/images/noTests.png" class="no-tests-image">
          <span class="no-tests-text">{{ $t('not_assigned_game_labs_yet') }}</span>
          <span class="no-tests-description">{{ $t('click_on_ata') }}</span>
        </div>

        <div class="gameLabs-grid">
          <router-link v-for="item in details.gameLabs"  v-if="!isLoading" :to="`/game/${item.gameLabsId}`">
            <div  class="test-card no-text-select">
              <div class="status-tag" :class="item.isPublish ? 'Published' : 'Draft'">
                <div class="status-dot" :class="item.isPublish ? 'Published' : 'Draft'"></div>
                <span>{{item.isPublish ? $t('published') : $t('draft')}}</span>
              </div>

              <span class="test-date">{{formatDate(item.created_at)}}</span>
              <span class="test-title">{{item.name}}</span>

              <div class="test-info">
                <img src="@/assets/images/datepicker.svg" class="test-info-icon"/>
                <span class="test-info-text">
                  {{ formatDate(item.startAt) }} - {{ formatDate(item.finishAt) }}
                </span>

                <div class="dot"></div>

                <div class="test-time-row" v-if="item.remaining.isPast === 0">
                  <img
                      src="@/assets/images/clockOutlineRed.svg"
                      class="test-info-icon"
                  />
                  <span class="test-info-text red">
                {{ item.remaining.time }}
               {{$t('time_is_up')}}
            </span>
                </div>

                <div class="test-time-row"  v-if="item.remaining.isPast === 1">
                  <img
                      src="@/assets/images/clockOutlineGreen.svg"
                      class="test-info-icon"
                  />
                  <span class="test-info-text green">
              {{ item.remaining.time }}
            </span>
                </div>

                <div class="test-time-row"  v-else-if="item.remaining.isPast === 2">
                  <img
                      src="@/assets/images/clockOutlineYellow.svg"
                      class="test-info-icon"
                  />
                  <span class="test-info-text yellow">
              {{ item.remaining.time }}
            </span>
                </div>
              </div>

              <div class="info-row">
<!--                <span class="info-tag">{{item.compainesCount}} companies</span>
                <span class="info-tag">{{item.questionsCount}} questions</span>-->
              </div>
            </div>
          </router-link>

          <free-style-shimmer
              :is-loading="isLoading"
              height="200px"
              width="100%"
              border-radius="20px"
              color="#EAECF0"
          />

          <free-style-shimmer
              :is-loading="isLoading"
              height="200px"
              width="100%"
              border-radius="20px"
              color="#EAECF0"
          />

          <free-style-shimmer
              :is-loading="isLoading"
              height="200px"
              width="100%"
              border-radius="20px"
              color="#EAECF0"
          />

        </div>

      </div>

      <div v-if="tabIndex===6" class="tab-container">
        <DepartmentsTable :loading="isLoading" :deps="deps" :add-new-department="() => departmentsModalVisible = true" :edit-department="handleEdit" :delete-department="handleDeleteDepartment" />
        <DepartmentModal :is-visible="departmentsModalVisible" :on-close="() => departmentsModalVisible = false" :refresh-callback="refreshData" :company-id="this.details.companyId" />
        <DepartmentModal :is-visible="departmentEditModalVisible" :on-close="() => departmentEditModalVisible = false" :edit-mode="true" :edit-data="editData" :refresh-callback="refreshData"/>
        <ConfirmDeleteModal
            :is-visible="confirmDeleteModalVisible"
            :on-close="onDepartmentDeleteCancel"
            message="Department will be deleted permanently"
            :on-action="handleDeleteRequest"
        />
      </div>

    </div>

    <CompanyModal
        :edit-mode="true"
        :edit-data="details"
        :visible="editModalVisible"
        :on-hide="hideEditModal"
        :refresh-callback="getDetails"
    />
    <AssignTherapyModal
        :visible="assignmentModal"
        :pre-data="details"
        type="company"
        :on-hide="hideAssignModal"
        :refresh-callback="getDetails"
    />
    <ConfirmDeleteModal
        :title="this.$t('alert_company_messages')"
        :message="this.$t('all_company_information_deleted')"
        :is-visible="deleteModalVisible"
        :on-action="handleDelete"
        :on-close="handleDeleteModalClose"
    />
    <AssignTestModal
        :visible="assignTestModal"
        :pre-data="details"
        type="company"
        :on-hide="hideAssignTestModal"
        :refresh-callback="getDetails"
    />
    <AssignGameLabsModal
        :visible="assignGameLabsModalVisible"
        :pre-data="details"
        type="company"
        :on-hide="hideAssignGameLabsModal"
        :refresh-callback="getDetails"
    />
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import CompanyHeader from "@/components/CompanyHeader.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import UserTable from "@/components/tables/User/UserTable.vue";
import DepartmentsTable from "@/components/tables/Departments/DepartmentsTable.vue";
import TherapyCard from "@/components/TherapyCard.vue";
import TransactionTable from "@/components/tables/TransactionTable.vue";
import InviteUserModal from "@/components/modals/InviteUserModal.vue";
import AssignTherapyModal from "@/components/modals/AssignTherapyModal.vue";
import companyService from "@/service/companyService";
import CompanyModal from "@/components/modals/CompanyModal.vue";
import ConfirmDeleteModal from "@/components/modals/ConfirmDeleteModal.vue";
import AssignTestModal from "@/components/modals/AssignTestModal.vue";
import AssignGameLabsModal from "@/components/modals/AssignGameLabsModal.vue";
import {handleErrorResponse} from "@/utils/utils";
import depService from "@/service/depService";
import DepartmentModal from "@/components/modals/DepartmentModal.vue";
import roleService from "@/service/roleService";

export default {
  components: {
    DepartmentModal,
    DepartmentsTable,
    AssignGameLabsModal,
    AssignTestModal,
    ConfirmDeleteModal,
    CompanyModal,
    AssignTherapyModal,
    InviteUserModal,
    TransactionTable,
    TherapyCard,
    UserTable,
    ExpandableSearch,
    CompanyHeader,
    Layout
  },
  data() {
    return {
      departmentsModalVisible: false,
      isLoading: true,
      searchText: '',
      tabIndex: 0,
      editModalVisible: false,
      deleteModalVisible: false,
      addUserModal: false,
      assignmentModal: false,
      assignTestModal: false,
      assignGameLabsModalVisible:false,
      editData: {},
      departmentEditModalVisible: false,
      deleteId: null,
      confirmDeleteModalVisible: false,
      details: {},
      roleList: [],
      deps:[],
    }
  },
  mounted() {
    this.getDetails();
    this.getDeps();
  },
  methods: {
    handleDeleteRequest() {
      console.log(this.deleteId);
      if (!this.deleteId) {
        return;
      }

      this.confirmDeleteModalVisible = false;
      this.isLoading = true;

      depService.deleteDep(this.deleteId).then(response => {
        this.isLoading = false;
        this.deleteId = null;
        this.refreshData();
      })
    },
    onDepartmentDeleteCancel() {
      this.confirmDeleteModalVisible = false;
      this.deleteId = null;
    },
    handleEdit(data) {
      this.editData = data;
      this.departmentEditModalVisible = true;
    },
    handleDeleteDepartment(depId) {
      event.stopPropagation();
      console.log(depId);
      this.deleteId = depId;
      this.confirmDeleteModalVisible = true;
    },
    handleSearch(e) {
      this.searchText = e.target.value;
    },
    changeTab(index) {
      if (this.isLoading) return;
      this.tabIndex = index;
    },
    showEditModal() {
      this.editModalVisible = true;
    },
    showDeleteModal() {
      this.deleteModalVisible = true;
      event.stopPropagation();
    },
    hideEditModal() {
      this.editModalVisible = false;
    },
    showInviteUser() {
      this.addUserModal = true;
    },
    hideInviteUser() {
      this.addUserModal = false;
    },
    openAssignModal() {
      this.assignmentModal = true;
    },
    hideAssignModal() {
      this.assignmentModal = false;
    },
    showAssignTestModal() {
      this.assignTestModal = true;
    },
    hideAssignTestModal() {
      this.assignTestModal = false;
    },

    showAssignGameLabsModal() {
      this.assignGameLabsModalVisible = true;
    },
    hideAssignGameLabsModal() {
      this.assignGameLabsModalVisible = false;
    },
    showDepModal(){
      this.departmentsModalVisible=true;
    },

    getDetails() {
      const routeParams = this.$route.params;
      this.isLoading = true;
      companyService.getCompanyDetails(routeParams.id).then((response) => {
        this.details = response.data.data;
        this.isLoading = false;
      });

      roleService.getStoreByCompanyRole(routeParams.id).then(response => {
        this.roleList = response.data.data;
      }).catch(err => {
        handleErrorResponse(err, this.$snackbar);
      });
    },

    getDeps() {
      const routeParams = this.$route.params;
      this.isLoading = true;
      depService.getDeps(routeParams.id).then(response => {
        console.log(response);
        this.deps = response.data.data.data;

        this.isLoading = false;
      })
    },
    refreshData() {
      this.getDeps(this.details.companyId);
    },

    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return '-';
      }
    },
    handleDeleteModalClose() {
      this.deleteModalVisible = false;
    },
    handleDelete() {
      this.deleteModalVisible = false;

      companyService.deleteCompany(this.details.companyId).then(() => {
        this.$router.push('/companies');
      }).catch(() => {
        this.$snackbar.add({
          text: 'An error occurred while deleting the company',
          type: 'error',
        });
      })
    },

  }
}
</script>

<style scoped>
.tab-panel {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}
.main-content {
  padding: 25px;
}
.tab-panel-row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #F2F4F7;
  border-bottom: 1px solid #F2F4F7;
  padding: 10px 0;
  margin-bottom: 20px;
}
.tab-button {
  padding: 6px 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.tab-button span {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #344054;
}
.tab-button.active, .tab-button:hover {
  background-color: #F2F4F7;
}
h3 {
  font-family: euclid_medium, sans-serif;
  font-size: 18px;
  color: #040C2D;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.company-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  min-width: 300px;
  column-gap: 50px;
}
.company-detail-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.company-detail-icon {
  background-color: #F5F5F5;
  border-radius: 16px;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-detail-icon img {
  cursor: default;
  width: 20px;
  height: 20px;
}
.detail-title {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #667085;
  font-size: 12px;
}
.detail-text {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #101828;
  font-size: 14px;
}
.company-detail-body {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.company-details {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.therapies-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.action-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.tests-grid {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.test-card {
  width: 100%;
  border: 1px solid #EAECF0;
  background-color: #FCFCFD;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.gameLabs-grid {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.status-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040C2D;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  align-items: center;

}

.status-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.status-tag.Draft {
  background-color: #FAD9C6;
}

.status-tag.Published {
  background-color: #DDFBDE;
}

.status-dot.Draft {
  background-color: #F09460;
}

.status-dot.Published {
  background-color: #3DA33E;
}

.test-date {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #667085;
  margin-top: 30px;
}

.test-title {
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  color: #040C2D;
  margin-bottom: 10px;
}

.info-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.info-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 10px;
  color: #040C2D;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 12px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #F2F4F7;
}


.test-info-text {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #667085;
}

.test-info-text.red {
  color: #ff2121;
}

.test-info-text.green {
  color: #088701;
}

.test-info-text.yellow {
  color: #f1c40f;
}

.dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #d0d5dd;
}

.test-info {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-bottom: 30px;
}

.test-info-icon {
  width: 24px;
  height: 24px;
}

.test-time-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}


@media (max-width: 1024px) {
  .therapies-row {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .therapies-row {
    grid-template-columns: 1fr;
  }
}
</style>
