<template>
  <div class="radial-widget">
    <apexchart type="donut" width="380" :options="{
      ...chartOptions,
      labels: chartData.labels,
    }" :series="chartData.series"></apexchart>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data: {
      handler(newVal) {
        if (!newVal.data) {
          console.warn("data is not defined", newVal);
          return;
        }

        if (!newVal.data.series) {
          console.warn("series is not defined", newVal);
          return;
        }

        if (newVal.data.series.length === 0) {
          console.warn("series is empty");
          return;
        }

        const tempLabels = newVal.data.series.map(item => {
          return item.name;
        });

        const tempSeries = newVal.data.series.map(item => {
          if (!item?.data) {
            return 0;
          }

          if (item.data.length === 0) {
            return 0;
          }

          return item.data.reduce((a, b) => a + b);
        });

        this.chartData = {
          series: tempSeries,
          labels: tempLabels,
        };
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            offsetY: 10,
          },
        },
        grid: {
          padding: {
            bottom: -80,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        colors: ['#90a2eb', '#e8bfa6'],
      },
      chartData: {
        series: [],
        labels: [],
      },
    };
  },
};
</script>

<style scoped>
.radial-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
}
</style>
