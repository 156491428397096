<template>
  <layout padding="0">
    <div class="main-container">
      <div class="column-container">
        <div class="input-holder">
          <span class="input-header">{{ $t('advertisement_title') }}</span>
          <input :placeholder="this.$t('enter_new_position_name')" class="form-input" type="text" v-model="positionData.title">
        </div>
        <div class="input-holder">
          <span class="input-header">{{ $t("recruitment_program") }}</span>
          <div class="dropdown" @click="toggleRecruitmentDropdown" v-click-outside="hideRecruitmentDropdown">
            <span v-if="!selectedTherapy">{{$t("choose_a_program")}}</span>
            <span v-else>{{selectedTherapy.name}}</span>
            <img src="@/assets/images/arrowdown.svg" />
            <div class="dropdown-content" v-if="therapyDropdown">
              <div class="list scroll-design" v-scroll-end="loadMore">
                <div class="item" v-for="item in therapyList" @click="assignTherapy(item)">
                  <span>{{ item.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="input-holder">
          <span class="input-header">{{ $t('description') }}</span>
          <textarea :placeholder="this.$t('enter_position_description')" style="min-height: 100px" class="form-input" type="text" v-model="positionData.description"></textarea>
        </div>

        <div class="action-row">
          <button @click="handleSave" style="width: 100px" class="dark-button">
            <span>{{ $t("save_button") }}</span>
          </button>
        </div>
      </div>

      <div class="column-container">
        <div class="input-holder">
          <span class="preview-header">{{$t('advertisement_preview')}}</span>
          <PositionReviewCard
          :title="positionData.title"
          :description="positionData.description"
          :value1="positionData.targetHiringCount"
          :is-loading="false"
          :isPublish="positionData.published"
          />
        </div>
        <span class="settings-text">{{ $t('settings_title') }}</span>
        <div class="input-holder">
          <span class="settings-header">{{$t('published')}}</span>
          <div class="settings-holder">
            <span class="settings-label">{{ $t("set_status") }}</span>
          <Switch v-model="positionData.published"></Switch>
         </div>
        </div>
        <div class="settings-holder">
        <div class="settings-input-holder">
          <span class="settings-header">{{ $t("target_recruitment_number") }}</span>
          <span class="settings-label">{{ $t("set_target_recruitment_number") }}</span>
        </div>
          <input :placeholder="this.$t('enter_new_position_name')" style="width: 40%" class="form-input" type="number" v-model="positionData.targetHiringCount">
        </div>

        <div class="input-holder">
          <span class="settings-header">{{ $t("start_at") }}</span>
          <div class="settings-holder">
            <span class="settings-label">{{ $t("start_definition") }}</span>
            <div class="text-input">
              <input class="form-input" placeholder="Start Date (DD-MM-YYYY)" type="date" v-model="temporaryStartDate"/>
            </div>
          </div>
        </div>

        <div class="input-holder">
          <span class="settings-header">{{ $t("end_at") }}</span>
          <div class="settings-holder">
            <span class="settings-label">{{$t("end_definition")}}</span>
            <div class="text-input">
              <input class="form-input" placeholder="End Date (DD-MM-YYYY)" type="date" v-model="temporaryEndDate"/>
            </div>
          </div>
        </div>

      </div>
    </div>
    <LoadingOverlay :is-loading="isPageLoading" />
    <LoadingOverlay :is-loading="isTherapyLoading" />

  </layout>
</template>
<script>
import Layout from "@/components/layouts/Layout.vue";
import Switch from "@/components/Switch.vue";
import EndlessScroll from "@/components/EndlessScroll.vue";
import PositionReviewCard from "@/components/PositionReviewCard.vue";
import openPositionsService from "@/service/openPositionsService";
import {handleErrorResponse} from "@/utils/utils";
import Multiselect from "vue-multiselect";
import notificationContentService from "@/service/notificationContentService";
import therapyService from "@/service/therapyService";
import journeyService from "@/service/journeyService";
import LoadingOverlay from "@/components/LoadingOverlay.vue";

export default {
  watch:{
    therapyList:{
      handler: function() {
        if(this.therapyList){
        this.getTherapyFromId(this.positionData.recruitmentProgramId);
        }
      }
    },
  },
 async mounted() {
   this.getNotificationType();
   this.getTherapies();
   const routeParams = this.$route.params;
    if(routeParams.id!=="new"){
      this.editMode=true;
      openPositionsService.getPosition(routeParams.id).then((response) => {
        this.isPageLoading=false;
        this.positionData = response.data.data;
        if(response.data.data.offerNotification){
          this.getNotificationFromId(response.data.data.offerNotification)
        }
        if(response.data.data.recruitmentProgramId){
          this.getTherapyFromId(response.data.data.recruitmentProgramId);
        }
        else{
          this.isTherapyLoading=false;
        }
        if(response.data.data.startDate){
          this.temporaryStartDate=response.data.data.startDate;
        }
        if(response.data.data.endDate){
          this.temporaryEndDate=response.data.data.endDate;
        }

      }).catch(() => {
        this.$snackbar.add({
          text: "Fetching position failed",
          type: "error",
        });
      });
      journeyService.getJourneyByStatus(3).then((response)=>{
      })
    } else{
      this.isTherapyLoading=false;
      this.isPageLoading=false;
    }

  },
  components: {
    LoadingOverlay,
    Multiselect,
    PositionReviewCard,
    EndlessScroll,
    Switch,
    Layout,
  },
  data(){
    return{
      temporaryStartDate:null,
      temporaryEndDate:null,
      isLoading:false,
      isPageLoading:true,
      page:1,
      notificationDropdown: false,
      therapyDropdown:false,
      sendListDropdown:false,
      editMode:false,
      title:null,
      description:null,
      value1:null,
      value2:null,
      positionData: {
        title:null,
        description:null,
        targetHiringCount:null,
        start_date:null,
        end_date:null,
        published:false,
        offerNotification:null,
        recruitmentProgramId:null,
      },
      notificationList:[],
      selectedNotification:null,
      selectedTherapy:null,
      therapyList:[],
      lastPage:false,
      isTherapyLoading:true,
    }
  },
  methods:{
    convertDate(date){
      return `${date}T08:00:00Z`;
    },

   getNotificationFromId(id){
     this.selectedNotification = this.notificationList.find(item => item.notificationId === id);
   },
    getTherapyFromId(id){
     therapyService.getTherapyDetail(id).then((response) => {
       this.selectedTherapy=response.data.data;
       this.isTherapyLoading=false;
     })
    },
   loadMore(){
     if(!this.lastPage && !this.isLoading) {
       this.isLoading = true;
       this.page++;
       this.loadScroll()
     }
   },
    loadScroll(){
      therapyService.getTherapies(this.page).then(response => {
        if (response.data.data.last_page === this.page) {
          this.lastPage = true;
        }
        this.therapyList = [...this.therapyList,...response.data.data.data];
        this.isLoading = false;
      }).catch(error => {
        console.log(error);
      });
    },

    assignTherapy(id){
      this.selectedTherapy=id;
      this.positionData.recruitmentProgramId=id.therapyId;
    },
    getTherapies(){
      therapyService.getTherapies(1).then(res => {
        this.therapyList = res.data.data.data;
      }).catch(err => {
        this.$snackbar.add({
          text: err.response.data.message,
          type: 'danger'
        });
      });
    },
   getNotificationType(){
     notificationContentService.getNotificationContentType("offer").then(response =>{
       this.notificationList=response.data.data;
     })

   },
    toggleRecruitmentDropdown() {
      this.therapyDropdown = !this.therapyDropdown;
    },
    hideRecruitmentDropdown() {
      this.therapyDropdown = false;
    },



    savePosition() {
      this.positionData.start_date=this.convertDate(this.temporaryStartDate);
      this.positionData.end_date=this.convertDate(this.temporaryEndDate);
      openPositionsService.updatePosition(this.positionData).then(response => {
        this.$snackbar.add({
          text: "Position updated successfully",
          type: "success"
        });
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      })
    },

    addPosition(){
      this.positionData.start_date=this.convertDate(this.temporaryStartDate);
      this.positionData.end_date=this.convertDate(this.temporaryEndDate);
      openPositionsService.addPosition(this.positionData).then(response=>{
        this.$snackbar.add({
          text: "Position added successfully",
          type: "success"
        });
        window.location.href = "/positions/"+response.data.data.positionId;
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      })
    },

    deletePosition(){
     openPositionsService.deletePosition(this.positionData.positionId);
     this.$router.go(-1);
    },

    handleSave(){
      if(this.editMode){
        this.savePosition();
      }
      else{
        this.addPosition();
      }
    }
  }
}
</script>

<style scoped>
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #000000;
  padding: 10px;
}

.item:hover {
  background-color: #F5F5F5;
}

.itemNoSelect{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #000000;
  padding: 10px;
}
.itemNoSelect:hover {
  background-color: #F5F5F5;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 200px;
  overflow-y: auto;
}
.dropdown-content {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 10px;
  z-index: 999;
}

.dropdown {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #F5F5F5;
  border-radius: 8px;
  cursor: pointer;
}
.dropdownNoSelect{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #F5F5F5;
  border-radius: 8px;
}
.select-class{
  width: 100%;
}
.settings-text {
  font-family: 'euclid_medium', sans-serif;
  font-size:16px;
  color:#5289EC;
  margin-top:20px;
  margin-bottom:15px;
}
.settings-input-holder{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 10px;
  width: max-content;
  height: min-content;
}
.settings-label{
  font-family:'euclid_medium', sans-serif;
  font-size: 14px;
  color:#73839B;
}
.settings-header{
  font-family:'euclid_semi_bold', sans-serif;
  font-size: 16px;
  color: #000000;
}
.settings-holder{
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}
.preview-header{
  font-family: 'euclid_medium', sans-serif;
  font-size:16px;
  color:#5289EC;
}
.template-text{
  font-family: 'euclid_regular', sans-serif;
  font-size: 16px;
  text-decoration: underline;
  color:#73839B;
  cursor: pointer;
}
.action-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  justify-content: flex-end;
}
.input-header{
  font-family:'euclid_medium', sans-serif;
  font-size: 16px;
  color: #565860;
}
.input-holder{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: min-content;
}
.main-container {
  padding:40px 80px 40px 80px;
  flex-direction: row;
  display: flex;
  width: 100%;
  gap: 40px;
  justify-content: center;
}
.column-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
</style>