<template>
  <div class="flex-row">
    <div class="row-item no-text-select" v-if="showTherapyNumberFilter" v-click-outside="() => closeFilter('therapy_number')">
      <div @click="toggleTherapyNumberFilter">
        <img src="../../../assets/images/filtertherapynumber.svg" class="row-item-icon" />
        <span class="row-item-txt">
          {{$t('therapy_number')}}{{programNumberFilterData.min !== undefined || programNumberFilterData.max !== undefined ? ':' : ''}} <span class="show-filters">{{programNumberFilterData.min ? 'Min: ' + programNumberFilterData.min : ''}} {{programNumberFilterData.min !== undefined && programNumberFilterData.max !== undefined ? ' - ' : ''}} {{programNumberFilterData.max ? 'Max: ' + programNumberFilterData.max : ''}}</span>
          </span>
      </div>
      <MinMaxNumberFilter
          v-if="showTherapyNumberDropdown"
          :visible.sync="showTherapyNumberDropdown"
          :on-filter="handleTherapyNumberFilter"
      />
      <img src="../../../assets/images/cross.svg" @click="() => removeFilter('therapy_number')" />
    </div>

    <div class="row-item no-text-select" v-if="showDateRangeFilter" v-click-outside="() => closeFilter('date_range')">
      <div @click="toggleDateRangeFilter">
        <img src="../../../assets/images/filterdaterange.svg" class="row-item-icon"/>
        <span class="row-item-txt">
          {{$t('calendar')}}{{dateRangeFilterData.startDate || dateRangeFilterData.endDate ? ':' : ''}} <span class="show-filters"> {{dateRangeFilterData.startDate ? dateRangeFilterData.startDate : ''}}{{dateRangeFilterData.startDate && dateRangeFilterData.endDate ? ' / ' : ''}}{{dateRangeFilterData.endDate ? dateRangeFilterData.endDate : ''}}</span>
          </span>
      </div>
      <DateFilter
          v-if="showDateRangeDropdown"
          :visible.sync="showDateRangeDropdown"
          :on-filter="handleApplyDateFilters"
      />
      <img src="../../../assets/images/cross.svg" @click="() => removeFilter('date_range')" />
    </div>

    <div class="row-item no-text-select" v-if="showStatusFilter" v-click-outside="() => closeFilter('status')">
      <div @click="toggleStatusFilter">
        <img src="../../../assets/images/filtertherapynumber.svg" class="row-item-icon" />
        <span class="row-item-txt">
          {{$t('status')}}{{statusFilterData.status===undefined? ':' : ':'}} <span class="show-filters"> {{statusFilterData.status==="1" ? $t('active') :""}} {{statusFilterData.status==="0"? $t('inactive') : "" }}</span>
          </span>
      </div>
      <StatusFilter
          v-if="showStatusFilterDropdown"
          :visible.sync="showStatusFilterDropdown"
          :on-filter="handleStatusFilter"
      />

      <img src="../../../assets/images/cross.svg" @click="() => removeFilter('status')" />
    </div>

    <div class="row-item filter" v-click-outside="() => closeFilter('main')" @click="toggleFilter">
      <img src="../../../assets/images/filter.svg">
      <span class="row-item-txt no-text-select">
            {{ $t('filter_therapy') }}
        </span>
      <FilterModal :visible.sync="showFilter" @filter-selected="handleFilterSelected"/>
    </div>
  </div>
</template>

<script>
import DateFilter from "@/components/table-filters/DateFilter.vue";
import FilterModal from "@/components/tables/User/UserFilterModal.vue";
import MinMaxNumberFilter from "@/components/table-filters/MinMaxNumberFilter.vue";
import StatusFilter from "@/components/table-filters/StatusFilter.vue";

export default {
  components: {
    StatusFilter,
    MinMaxNumberFilter,
    DateFilter,
    FilterModal
  },
  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {
        if (this.$route.query.programNumberMin) {
          this.showTherapyNumberFilter = true;
          this.programNumberFilterData.min = this.$route.query.programNumberMin;
        } else {
          this.programNumberFilterData.min = undefined
        }

        if (this.$route.query.programNumberMax) {
          this.showTherapyNumberFilter = true;
          this.programNumberFilterData.max = this.$route.query.programNumberMax;
        } else {
          this.programNumberFilterData.max = undefined
        }

        if (this.$route.query.addedDate) {
          this.showDateRangeFilter = true;
          this.dateRangeFilterData.startDate = this.$route.query.addedDate;
        } else {
          this.dateRangeFilterData.startDate = undefined
        }

        if (this.$route.query.addedDateEnd) {
          this.showDateRangeFilter = true;
          this.dateRangeFilterData.endDate = this.$route.query.addedDateEnd;
        } else {
          this.dateRangeFilterData.endDate = undefined
        }

        if (this.$route.query.status) {
          this.showStatusFilter = true;
          this.statusFilterData.status = this.$route.query.status;
        } else {
          this.statusFilterData.status = undefined
        }
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      showFilter:false,
      showTherapyNumberFilter:false,
      showTherapyNumberDropdown:false,

      showStatusFilter:false,
      showStatusFilterDropdown:false,
      statusFilterData:[{
        status:undefined,
      }],

      programNumberFilterData: [{
        min: undefined,
        max: undefined,
      }],

      showDateRangeFilter: false,
      showDateRangeDropdown: false,
      dateRangeFilterData: [{
        startDate: undefined,
        endDate: undefined,
      }],
    }
  },
  methods: {
    handleFilterSelected(filterName) {
      this.closeFilter('main');
      if (filterName === 'therapy_number') {
        this.showTherapyNumberFilter = true;
        this.showTherapyNumberDropdown = true;
      } else if (filterName === 'date_range') {
        this.showDateRangeFilter = true;
        this.showDateRangeDropdown = true;
      } else if (filterName === 'status') {
        this.showStatusFilter=true;
        this.showStatusFilterDropdown=true;
      }

    },
    formatDateForBackend(dateString) {
      if (!dateString) return undefined;
      const [day, month, year] = dateString.split('-');
      return `${year}-${month}-${day}`;
    },

    handleTherapyNumberFilter(filter){
      this.programNumberFilterData = filter;
      this.showTherapyNumberDropdown = false;

      this.$router.push({
        query: {
          ...this.$route.query,
          programNumberMin: this.programNumberFilterData.min,
          programNumberMax: this.programNumberFilterData.max
        }
      });
    },
    handleApplyDateFilters(filters){
      this.dateRangeFilterData.startDate = filters.startDate;
      this.dateRangeFilterData.endDate = filters.endDate;
      this.showDateRangeDropdown = false;

      this.$router.push({
        query: {
          ...this.$route.query,
          addedDate: this.formatDateForBackend(filters.startDate),
          addedDateEnd: this.formatDateForBackend(filters.endDate)
        }
      });
    },
    handleStatusFilter(filter){
      this.statusFilterData=filter;
      this.showStatusFilterDropdown = false;
      console.log(this.statusFilterData.status);
      this.$router.push({
        query: {
          ...this.$route.query,
          status:this.statusFilterData.status
        }
      });
    },

    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    toggleTherapyNumberFilter() {
      this.showTherapyNumberDropdown = !this.showTherapyNumberDropdown;
    },
    toggleDateRangeFilter() {
      this.showDateRangeDropdown = !this.showDateRangeDropdown;
    },
    toggleStatusFilter() {
      this.showStatusFilterDropdown = !this.showStatusFilterDropdown;
    },

    closeFilter(filter) {
      if (filter === 'main' && this.showFilter === true) {
        this.showFilter = false;
      } else if (filter === 'therapy_number' && this.showTherapyNumberDropdown === true) {
        this.showTherapyNumberDropdown = false;
      } else if (filter === 'date_range' && this.showDateRangeDropdown === true) {
        this.showDateRangeDropdown = false;
      } else if (filter === 'status' && this.showStatusFilterDropdown === true) {
        this.showStatusFilterDropdown = false;
      }

    },
    removeFilter(filter) {
      if (filter === 'therapy_number') {
        this.showTherapyNumberFilter = false;
        this.programNumberFilterData = [{
          min: undefined,
          max: undefined,
        }];

        this.$router.push({
          query: {
            ...this.$route.query,
            programNumberMin: undefined,
            programNumberMax: undefined
          }
        })
      } else if (filter === 'date_range') {
        this.showDateRangeFilter = false;
        this.dateRangeFilterData = [{
          start: undefined,
          end: undefined,
        }];
        this.$router.push({
          query: {
            ...this.$route.query,
            addedDate: undefined,
            addedDateEnd: undefined
          }
        })
      } else if (filter === 'status') {
        this.showStatusFilter=false;
        this.status = undefined;
        this.$router.push({
          query: {
            ...this.$route.query,
            status: undefined
          }
        })
      }
    },
  }
}
</script>

<style scoped>

.flex-row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
}
.row-item{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}

.row-item.filter {
  background-color: transparent;
}

.row-item-txt{
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}

.show-filters{
  font-family: euclid_medium, sans-serif;
  color: #4A68F8;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
}
</style>
