<template>
  <ImageEditorLayout
      :on-settings-action="onSettingsAction"
      :on-action="onAction"
      :current-step="croppedImage ? 'editor' : 'cropper'"
      :left-menu-visible="croppedImage !== null"
  >
    <template v-slot:cropper>
      <Transition name="slide-fade">
        <div class="toolbar" v-if="showToolbar">
          <div class="toolbar-menu">
            <div class="toolbar-menu-left">
              <button class="crop-aspect-button" @click="() => setCropAspect(1)">
                1:1
              </button>
              <button class="crop-aspect-button" @click="() => setCropAspect(16/9)">
                16:9
              </button>
              <button class="crop-aspect-button" @click="() => setCropAspect(9/16)">
                9:16
              </button>
            </div>
            <div class="toolbar-menu-right">
              <button class="action-text-button" @click="cropImage">Crop Image</button>
            </div>
          </div>
        </div>
      </Transition>
      <div class="editor-container" :style="{
        height: showToolbar ? 'calc(100% - 56px)' : '100%',
      }">
        <div v-if="!selectedImage" class="select-center">
          <div class="file-drop-wrapper">
            <FileDrop :on-file-selected="onFileSelected" :image-editor-enabled="false" />
          </div>
        </div>

        <div v-else-if="!croppedImage" class="crop-wrapper">
          <vue-cropper
              :key="cropAspectRatio"
              ref="cropper"
              :src="selectedImage"
              :aspect-ratio="cropAspectRatio"
              mode="contain"
              class="cropper-container"
          ></vue-cropper>
        </div>
      </div>
    </template>

    <template v-slot:editor>
      <div class="toolbar">
        <div class="toolbar-menu">
          <div class="toolbar-menu-left" v-if="selectedTextNode !== null">
            <select
                v-model="selectedTextNode.fontFamily"
                @change="updateText"
                v-if="selectedTextNode && selectedTextNode.fontFamily"
                class="input-style"
            >
              <option value="euclid_regular">Euclid</option>
              <option value="Arial">Arial</option>
              <option value="Helvetica">Helvetica</option>
              <option value="Times New Roman">Times New Roman</option>
              <option value="Courier New">Courier New</option>
            </select>

            <input
                class="input-style"
                type="number"
                v-if="selectedTextNode && selectedTextNode.fontSize"
                v-model="selectedTextNode.fontSize"
                @change="updateText"
                min="1"
                max="100"
            />

            <input
                class="color-picker"
                type="color"
                v-if="selectedTextNode && selectedTextNode.fill"
                v-model="selectedTextNode.fill"
                @change="updateText"
            />

            <div class="toolbar-menu-group">
              <div
                  class="setting-icon"
                  @click="() => {
                     selectedTextNode.align = 'left';
                     updateText();
                  }"
                  :class="{ selected: selectedTextNode.align === 'left' }"
              >
                <img src="@/assets/images/textLeftOutline.svg"/>
              </div>

              <div
                  class="setting-icon"
                  @click="() => {
                     selectedTextNode.align = 'center';
                     updateText();
                  }"
                  :class="{ selected: selectedTextNode.align === 'center' }"
              >
                <img src="@/assets/images/textCenterOutline.svg"/>
              </div>

              <div
                  class="setting-icon"
                  @click="() => {
                     selectedTextNode.align = 'right';
                     updateText();
                  }"
                  :class="{ selected: selectedTextNode.align === 'right' }"
              >
                <img src="@/assets/images/textRightOutline.svg"/>
              </div>
            </div>

            <div class="toolbar-modal-wrapper"  v-click-outside="() => {
              shadowToggle = false;
            }">
              <div
                  class="setting-icon"
                  @click="() => {
                     shadowToggle = !shadowToggle;
                }"
              >
                <img src="@/assets/images/shadowOutline.svg"/>
              </div>

              <div class="toolbar-modal" v-if="shadowToggle">
                <input
                    class="color-picker"
                    type="color"
                    v-model="selectedTextNode.shadowColor"
                    @change="updateText"
                />

                <Slider
                    label="Shadow Opacity"
                    v-model="selectedTextNode.shadowOpacity"
                    @update:modelValue="updateText"
                    min="0"
                    max="1"
                    step="0.1"
                />
                <Slider
                    label="Shadow Blur"
                    v-model="selectedTextNode.shadowBlur"
                    @update:modelValue="updateText"
                    min="0"
                    max="20"
                    step="1"
                />
                <Slider
                    label="Shadow Offset X"
                    v-model="selectedTextNode.shadowOffsetX"
                    @update:modelValue="updateText"
                    min="-20"
                    max="20"
                    step="1"
                    placeholder="Shadow X"
                />
                <Slider
                    label="Shadow Offset Y"
                    v-model="selectedTextNode.shadowOffsetY"
                    @update:modelValue="updateText"
                    min="-20"
                    max="20"
                    step="1"
                    placeholder="Shadow Y"
                />
              </div>
            </div>

            <div class="toolbar-menu-group">
              <div class="setting-icon"  @click="alignElement('left')">
                <img src="@/assets/images/alignLeftOutline.svg"/>
              </div>

              <div class="setting-icon"  @click="alignElement('center')">
                <img src="@/assets/images/alignCenterOutline.svg"/>
              </div>

              <div class="setting-icon"  @click="alignElement('right')">
                <img src="@/assets/images/alignRightOutline.svg"/>
              </div>
            </div>
          </div>

          <div class="toolbar-menu-left" v-if="selectedImageNode !== null">
            <Slider
                label="Opacity"
                v-model="selectedImageNode.opacity"
                @update:modelValue="updateImage"
                min="0"
                max="1"
                step="0.1"
            />

            <div class="toolbar-menu-group">
              <div class="setting-icon"  @click="alignElement('left')">
                <img src="@/assets/images/alignLeftOutline.svg"/>
              </div>

              <div class="setting-icon"  @click="alignElement('center')">
                <img src="@/assets/images/alignCenterOutline.svg"/>
              </div>

              <div class="setting-icon"  @click="alignElement('right')">
                <img src="@/assets/images/alignRightOutline.svg"/>
              </div>
            </div>
          </div>

          <div class="toolbar-menu-right" v-if="selectedTextNode !== null || selectedImageNode !== null">
            <img class="icon-button" src="@/assets/images/trash.svg" @click="handleDeleteSelected"/>
          </div>
        </div>
      </div>

      <div ref="container" class="editor-container" :style="{
        height: 'calc(100% - 56px)',
      }">
        <v-stage
            ref="stage"
            :config="stageConfig"
            @wheel="handleWheel"
            @click="handleStageClick"
        >
          <v-layer ref="layer">
            <v-image :config="imageConfig" />
            <v-image
                v-for="(image, index) in images"
                :key="index"
                :config="image"
                @transformend="handleImageTransformEnd(index, $event)"
                @click="handleImageClick(index, $event)"
            />
            <v-text
                v-for="(textNode, index) in textNodes"
                :key="index"
                :config="textNode"
                @transformend="handleTransformEnd(index, $event)"
                @dblclick="handleTextDblClick(index, $event)"
                @click="handleTextClick(index, $event)"
            />

            <v-transformer ref="transformer" />
          </v-layer>
        </v-stage>
      </div>
    </template>
  </ImageEditorLayout>
</template>

<script>
import ImageEditorLayout from "@/components/layouts/ImageEditorLayout.vue";
import SelectBox from "@/components/SelectBox.vue";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import FileDrop from "@/components/FileDrop.vue";
import Konva from "konva";
import Slider from "@/components/Slider.vue";

export default {
  name: 'ImageEditor',
  components: {
    Slider,
    FileDrop,
    SelectBox,
    ImageEditorLayout,
    VueCropper
  },
  data() {
    return {
      croppedImage: null,
      selectedImage: null,
      cropAspectRatio: 1,
      showToolbar: false,
      shadowToggle: false,
      stage: null,
      layer: null,
      transformer: null,
      textNodes: [],
      selectedTextNodeIndex: null,
      selectedImageNodeIndex: null,
      images: [],
      stageConfig: {
        width: 800,
        height: 600,
      },
      imageConfig: {
        id: 'background-image',
        image: null,
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    selectedTextNode() {
      return this.selectedTextNodeIndex !== null ? this.textNodes[this.selectedTextNodeIndex] : null;
    },
    selectedImageNode() {
      return this.selectedImageNodeIndex !== null ? this.images[this.selectedImageNodeIndex] : null;
    },
  },
  methods: {
    onFileSelected(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage = reader.result;
        };
        reader.readAsDataURL(file);
        this.showToolbar = true;
      }
    },
    cropImage() {
      const cropper = this.$refs?.cropper;
      if (cropper) {
        this.croppedImage = cropper.getCroppedCanvas().toDataURL('image/png');
        this.onImageUpload(this.croppedImage);
      }
    },
    setCropAspect(aspect) {
      this.cropAspectRatio = aspect;
    },
    onImageUpload(base64) {
      const img = new Image();
      img.onload = () => {
        this.imageConfig.image = img;
        this.imageConfig.width = img.width;
        this.imageConfig.height = img.height;
        this.$nextTick(() => {
          this.fitStageIntoParentContainer();
        });
      };
      img.src = base64;
    },
    saveImage() {
      if (!this.$refs.layer || !this.$refs.transformer) {
        console.error('Layer or Transformer is not ready');
        return;
      }

      // Hide the transformer to avoid saving it in the image
      this.$refs.transformer.getNode().hide();

      // Find the background image
      const backgroundImage = this.$refs.layer.getNode().findOne('#background-image');
      if (!backgroundImage) {
        console.error('Background image not found');
        return;
      }

      // Get the background image size
      const bgWidth = backgroundImage.width();
      const bgHeight = backgroundImage.height();

      // Create a new Konva.Layer to combine all elements
      const tempLayer = new Konva.Layer();

      // Add the cloned background image
      tempLayer.add(backgroundImage.clone());

      // Add additional images
      this.images.forEach(image => {
        const konvaImage = this.$refs.layer.getNode().findOne(`#${image.id}`);
        if (konvaImage) {
          tempLayer.add(konvaImage.clone());
        }
      });

      // Add text nodes
      this.textNodes.forEach(textNode => {
        const konvaText = this.$refs.layer.getNode().findOne(`#${textNode.id}`);
        if (konvaText) {
          tempLayer.add(konvaText.clone());
        }
      });

      // Create a temporary stage with the size of the background image
      const tempStage = new Konva.Stage({
        container: document.createElement('div'),
        width: bgWidth,
        height: bgHeight,
      });

      // Add the temporary layer to the temporary stage
      tempStage.add(tempLayer);

      // Generate the data URL from the temporary stage using background image size
      const dataURL = tempStage.toDataURL({ pixelRatio: 1 });

      // Create a download link
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'edited-image.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up
      tempStage.destroy();
      this.$refs.transformer.getNode().show();
      this.$refs.layer.getNode().draw();
    },

    fitStageIntoParentContainer() {
      if (!this.$refs.stage || !this.$refs.stage.getNode()) {
        console.error('Stage is not ready yet');
        return;
      }
      const containerWidth = this.$refs.container.offsetWidth;
      const containerHeight = this.$refs.container.offsetHeight;
      const scaleX = containerWidth / this.imageConfig.width;
      const scaleY = containerHeight / this.imageConfig.height;
      const scale = Math.min(scaleX, scaleY);

      this.stageConfig.width = containerWidth;
      this.stageConfig.height = containerHeight;
      this.$refs.stage.getNode().width(containerWidth);
      this.$refs.stage.getNode().height(containerHeight);
      this.$refs.stage.getNode().scale({ x: scale, y: scale });
      this.$refs.stage.getNode().position({
        x: (containerWidth - this.imageConfig.width * scale) / 2,
        y: (containerHeight - this.imageConfig.height * scale) / 2,
      });

      this.$refs.stage.getNode().batchDraw();
    },
    handleWheel(e) {
      e.evt.preventDefault();

      const scaleBy = 0.9;
      const stage = e.target.getStage();
      const oldScale = stage.scaleX();
      const pointer = stage.getPointerPosition();

      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };

      const direction = e.evt.deltaY > 0 ? 1 : -1;
      const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      stage.scale({ x: newScale, y: newScale });

      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stage.position(newPos);
      stage.batchDraw();
    },
    selectNode(node) {
      console.log("selectNodeFn", node);

      if (node) {
        this.$refs.transformer.getNode().nodes([node]);
        this.selectedTextNode = this.textNodes.find(t => t.id === node.attrs.id);
      } else {
        this.$refs.transformer.getNode().nodes([]);
        this.selectedTextNode = null;
      }
      this.$refs.layer.getNode().draw();
    },
    addText() {
      const newTextNode = {
        id: `text-${this.textNodes.length + 1}`,
        text: 'Double-click to edit',
        x: 50,
        y: 50 + this.textNodes.length * 30,
        fontSize: 20,
        fontFamily: 'Arial',
        fill: '#000000',
        draggable: true,
        width: 200,
      };
      this.textNodes.push(newTextNode);
      this.selectedTextNodeIndex = this.textNodes.length - 1;

      this.$nextTick(() => {
        const konvaTextNodes = this.$refs.layer.getNode().find('Text');
        const lastTextNode = konvaTextNodes[konvaTextNodes.length - 1];
        this.selectedImageNodeIndex = null;
        this.selectNode(lastTextNode);
      });
    },
    handleTextDblClick(index, e) {
      const textNode = e.target;
      const textPosition = textNode.getAbsolutePosition();
      const stageBox = this.$refs.stage.getNode().container().getBoundingClientRect();
      const areaPosition = {
        x: stageBox.left + textPosition.x,
        y: stageBox.top + textPosition.y,
      };

      const textarea = document.createElement('textarea');
      document.body.appendChild(textarea);

      textarea.value = textNode.text();
      textarea.style.position = 'absolute';
      textarea.style.top = areaPosition.y + 'px';
      textarea.style.left = areaPosition.x + 'px';
      textarea.style.width = textNode.width() - textNode.padding() * 2 + 'px';
      textarea.style.height = 'auto';
      textarea.style.fontSize = textNode.fontSize() + 'px';
      textarea.style.border = 'none';
      textarea.style.padding = '0px';
      textarea.style.margin = '0px';
      textarea.style.overflow = 'hidden';
      textarea.style.background = 'none';
      textarea.style.outline = 'none';
      textarea.style.resize = 'none';
      textarea.style.lineHeight = textNode.lineHeight();
      textarea.style.fontFamily = textNode.fontFamily();
      textarea.style.transformOrigin = 'left top';
      textarea.style.textAlign = textNode.align();
      textarea.style.color = textNode.fill();

      const rotation = textNode.rotation();
      let transform = '';
      if (rotation) {
        transform += 'rotateZ(' + rotation + 'deg)';
      }
      textarea.style.transform = transform;

      textarea.focus();

      const removeTextarea = () => {
        textarea.parentNode.removeChild(textarea);
        window.removeEventListener('click', handleOutsideClick);
        textNode.show();
        this.$refs.transformer.getNode().show();
        this.$refs.transformer.getNode().forceUpdate();
        this.$refs.layer.getNode().draw();
      };

      const handleOutsideClick = (e) => {
        if (e.target !== textarea) {
          this.textNodes[index].text = textarea.value;
          removeTextarea();
        }
      };

      textarea.addEventListener('keydown', (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
          this.textNodes[index].text = textarea.value;
          removeTextarea();
        }
        if (e.keyCode === 27) {
          removeTextarea();
        }
      });

      textarea.addEventListener('input', () => {
        const width = textNode.width();
        textarea.style.width = width + 'px';

        const newHeight = textarea.scrollHeight;
        textarea.style.height = newHeight + 'px';

        this.textNodes[index].width = width;
        this.textNodes[index].height = newHeight;

        this.$refs.layer.getNode().batchDraw();
      });

      setTimeout(() => {
        window.addEventListener('click', handleOutsideClick);
      });

      textNode.hide();
      this.$refs.transformer.getNode().hide();
      this.$refs.layer.getNode().draw();
    },
    handleTextClick(index, e) {
      this.selectedImageNodeIndex = null;
      this.selectedTextNodeIndex = index;
      this.selectNode(e.target);
    },
    handleStageClick(e) {
      // Implement stage click event
    },
    handleTransformEnd(index, e) {
      const textNode = e.target;
      this.textNodes[index] = { ...this.textNodes[index], ...textNode.attrs };
    },
    onAdditionalImageUpload(base64) {
      const img = new Image();
      img.onload = () => {
        const newImage = {
          id: `image-${this.images.length + 1}`,
          image: img,
          x: 50,
          y: 50 + this.images.length * 30,
          width: img.width,
          height: img.height,
          draggable: true,
          opacity: 1,
        };
        this.images.push(newImage);
        this.selectedImageNodeIndex = this.images.length - 1;

        this.$nextTick(() => {
          const konvaImages = this.$refs.layer.getNode().find('Image');
          const lastImage = konvaImages[konvaImages.length - 1];
          this.selectedTextNodeIndex = null;
          this.selectNode(lastImage);
        });
      };
      img.src = base64;
    },
    updateImage() {
      if (this.selectedImageNode) {
        const konvaImageNode = this.$refs.layer.getNode().findOne(`#${this.selectedImageNode.id}`);
        if (konvaImageNode) {
          konvaImageNode.cache();
          konvaImageNode.filters([]);

          if (this.selectedImageNode.brightness !== 0) {
            konvaImageNode.brightness(this.selectedImageNode.brightness);
            konvaImageNode.filters(konvaImageNode.filters().concat([Konva.Filters.Brighten]));
          }

          konvaImageNode.opacity(this.selectedImageNode.opacity);

          this.$refs.layer.getNode().batchDraw();
        }
      }
    },
    alignElement(position) {
      let selectedNode;

      if (this.selectedImageNode !== null) {
        selectedNode = this.$refs.layer.getNode().findOne(`#${this.selectedImageNode.id}`);
      } else if (this.selectedTextNode !== null) {
        selectedNode = this.$refs.layer.getNode().findOne(`#${this.selectedTextNode.id}`);
      }

      if (!selectedNode) return;

      const stage = this.$refs.stage.getNode();
      const backgroundImage = stage.findOne('Image');

      if (!backgroundImage) return;

      const bgWidth = backgroundImage.width();
      const bgHeight = backgroundImage.height();
      const elementWidth = selectedNode.width() * selectedNode.scaleX();
      const elementHeight = selectedNode.height() * selectedNode.scaleY();

      let newX, newY;

      switch (position) {
        case 'left':
          newX = 0;
          newY = selectedNode.y();
          break;
        case 'center':
          newX = (bgWidth - elementWidth) / 2;
          newY = selectedNode.y();
          break;
        case 'right':
          newX = bgWidth - elementWidth;
          newY = selectedNode.y();
          break;
        case 'top':
          newX = selectedNode.x();
          newY = 0;
          break;
        case 'middle':
          newX = selectedNode.x();
          newY = (bgHeight - elementHeight) / 2;
          break;
        case 'bottom':
          newX = selectedNode.x();
          newY = bgHeight - elementHeight;
          break;
      }

      selectedNode.position({ x: newX, y: newY });
      this.$refs.layer.getNode().batchDraw();

      // Update the data properties
      if (this.selectedImageNode !== null) {
        this.selectedImageNode.x = newX;
        this.selectedImageNode.y = newY;
      } else if (this.selectedTextNode !== null) {
        this.selectedTextNode.x = newX;
        this.selectedTextNode.y = newY;
      }
    },
    handleImageClick(index, e) {
      this.selectedTextNodeIndex = null;
      this.selectedImageNodeIndex = index;
      this.selectNode(e.target);
    },
    handleImageTransformEnd(index, e) {
      const imageNode = e.target;
      this.images[index] = { ...this.images[index], ...imageNode.attrs };
    },
    updateText() {
      this.$refs.layer.getNode().draw();
    },
    onSettingsAction(action) {
      if (action === 'fullScreen') {
        this.fitStageIntoParentContainer();
      }
    },
    onAction(action, file) {
      if (action === 'save') {
        this.saveImage();
      } else if (action === 'annotate') {
        this.addText();
      } else if (action === 'addImage') {
        if (file) {
          console.log("file", file);
          const reader = new FileReader();
          reader.onload = () => {
            this.onAdditionalImageUpload(reader.result);
          };
          reader.readAsDataURL(file);
        }
      } else if (action === 'addImageBase64') {
        if (file) {
          console.log("file", file);
          this.onAdditionalImageUpload(file);
        }
      }
    },
    handleDeleteSelected() {
      if (this.selectedTextNode) {
        this.textNodes = this.textNodes.filter(t => t.id !== this.selectedTextNode.id);
        this.selectedTextNodeIndex = null;
      } else if (this.selectedImageNode) {
        this.images.splice(this.selectedImageNodeIndex, 1);
        this.selectedImageNodeIndex = null;
      }
      this.$refs.transformer.getNode().nodes([]);
      this.$refs.layer.getNode().draw();
    },
  },
  mounted() {
    window.addEventListener('resize', this.fitStageIntoParentContainer);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.fitStageIntoParentContainer);
  },
  watch: {
    selectedTextNode: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          console.log("selectedTextNode", newVal);
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #EAECF0;
  background-color: #fff;
  height: 56px;
}

.toolbar-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.toolbar-menu-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.toolbar-menu-right {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex: 1;
  justify-content: flex-end;
}

.editor-container {
  width: 100%;
  background-color: #f5f5f5;
}

.input-style {
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  background: #F5F5F5;
  color: #282A36;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.cropper-container {
  height: calc(100% - 56px);
}

.crop-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 56px);
}

.crop-aspect-button {
  border: 1px solid #040C2D;
  background-color: transparent;
  color: #040C2D;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  border-radius: 8px;
  padding: 5px 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;
}

.crop-aspect-button:hover {
  background-color: #040C2D;
  color: white;
}

.select-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.file-drop-wrapper {
  width: 340px;
}

.action-text-button {
  background-color: #040C2D;
  color: white;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.2s;
}

.action-text-button:hover {
  background-color: #1C3CD5;
}

.setting-icon {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 6px;
}

.setting-icon img {
  height: 16px;
  width: 16px;
}

.setting-icon:hover {
  background-color: #F5F5F5;
}

.setting-icon.selected  {
  background-color: #F5F5F5;
}

.toolbar-menu-group {
  display: flex;
  flex-direction: row;
  gap: 0;
}

.toolbar-modal-wrapper {
  position: relative;
}

.toolbar-modal {
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #EAECF0;
  padding: 15px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

</style>
