<template>
  <div class="audio-content">
    <div class="container">
      <div class="background-image" :style="{ backgroundImage: `url(${selectedModule.content?.attach[1].attachUrl})` }"></div>
      <div class="content">
        <div class="foreground-image">
          <img :src="selectedModule.content?.attach[1].attachUrl" alt="Foreground" />
        </div>
        <div class="text-content">
          <h1>{{ selectedModule.name }}</h1>
          <p>{{ submodule.name }}</p>
        </div>

        <div class="audio-progress-bar">
          <span class="progress"></span>
        </div>
        <div class="audio-control">
          <span class="timer-text">1:00</span>
          <span class="timer-text">2:00</span>
        </div>
      </div>
        <div class="play-button-container">
          <div class="play-button">
            <img class="pause-icon" src="@/assets/images/pauseFill.svg">
          </div>
        </div>

    </div>
  </div>
</template>
<script>
export default{
  props:{
    submodule:{
      type:Object
    },
    selectedModule:{
      type:Object
    },
  }
}
</script>
<style scoped>
.pause-icon{
  width: 24px;
  height: 24px;
}
.content {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
}
.timer-text{
  display: flex;
  align-self: end;
  font-family: 'euclid_regular', sans-serif;
  font-size: 12px;
}
.play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  color: white;
  font-size: 18px;
  cursor: pointer;
}
.play-button-container {
  margin-bottom: 20px;
  z-index: 2;
  display: flex;
  justify-content: center;
  width: 100%;
}
.progress {
  height: 100%;
  width: 50%;
  background-color: #FFFFFF;
  border-radius: 20px;
}
.audio-control {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  height: 7%;
  width: 100%;
}

.audio-progress-bar {
  display: flex;
  align-self: center;
  justify-self: center;
  height: 3px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  width: 100%;
  margin-top: 20px;
}
.audio-content{
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: brightness(0.3);
  z-index: 1;
}
.content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
  height: 100%;
  width: 100%;
}
.foreground-image img{
  position: relative;
  width: 100px;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  overflow: hidden;
  align-self: center;
  justify-self: center;
  margin-top: 40%;
  margin-bottom: 20%;
}
.text-content h1 {
  font-size: 12px;
  font-weight: bold;
}

.text-content p {
  margin-top: 5px;
  font-size: 10px;
  color: #ccc;
}
@media only screen and (max-width: 600px) {
  .foreground-image img{
    width: 80px;
  }
}
@media only screen and (max-width: 1300px) {
  .foreground-image img{
    width: 80px;
  }
}

@media only screen and (max-width: 800px) {
 .play-button{
   width: 40px;
   height: 40px;
 }
  .pause-icon{
    width: 21px;
    height: 21px;
  }
}
@media only screen and (max-width: 405px) {
  .play-button-container{
    margin-bottom: 10px ;
  }
  .foreground-image img{
    width: 55px;
  }
  .play-button{
    width: 35px;
    height: 35px;
  }
  .pause-icon{
    width: 18px;
    height: 18px;
  }
}

@media only screen and (min-height: 700px) {
  .play-button-container{
    margin-bottom: 30px ;
  }
  .foreground-image img{
    width: 80px;
  }
  .play-button{
    width: 40px;
    height: 40px;
  }
  .pause-icon{
    width: 21px;
    height: 21px;
  }
}

@media only screen and (max-height: 600px) {
  .play-button-container{
    margin-bottom: 20px ;
  }
  .foreground-image img{
    width: 50px;
  }
  .play-button{
    width: 35px;
    height: 35px;
  }
  .pause-icon{
    width: 18px;
    height: 18px;
  }
}

</style>