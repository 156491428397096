<template>
  <div
      :id="data.id"
      :gs-id="data.id"
      :gs-x="data.grid.x"
      :gs-y="data.grid.y"
      :gs-w="data.grid.w"
      :gs-h="data.grid.h"
  >
    <div
        class="default-card grid-stack-item-content group relative "
        v-if="!isLoading"
    >
      <h5>{{ data.name }}</h5>

      <!-- <NumberWidget v-if="data?.type === 'number'" :data="getData(data?.id)" /> -->
      <PieChart v-if="data?.type === 'pieChart'" :data="data" />
      <!-- <LinearPercentageBarWidget v-else-if="data?.type === 'linearPercentageBar'" :data="getData(data?.id)" /> -->
      <TableWidget v-else-if="data?.type === 'table'" :data="data" />
      <FunnelWidget v-else-if="data?.type === 'funnelChart'" :data="data" />
      <HalfPieChart v-else-if="data?.type === 'halfPieChart'" :data="data" />
      <LineChart v-else-if="data?.type === 'linearChart'" :data="data" />
      <BarChart v-else-if="data?.type === 'barChart'" :data="data" />

    </div>
    <free-style-shimmer
        v-else
        class="grid-stack-item-content group relative"
        border-radius="20px"
        :is-loading="true"
        color="#EAECF0"
        height="100%"
        width="100%"
    />
  </div>
</template>
<script>
import NumberWidget from "@/components/dashboard-components/NumberWidget.vue";
import PieChart from "@/components/dashboard-components/PieChart.vue";
import LinearPercentageBarWidget from "@/components/dashboard-components/LinearPercentageBarWidget.vue";
import TableWidget from "@/components/dashboard-components/TableWidget.vue";
import FunnelWidget from "@/components/dashboard-components/FunnelWidget.vue";
import HalfPieChart from "@/components/dashboard-components/HalfPieChart.vue";
import LineChart from "@/components/dashboard-components/LineChart.vue";
import BarChart from "@/components/dashboard-components/BarChart.vue";

export default {
  components: {
    BarChart,
    LineChart,
    HalfPieChart,
    FunnelWidget,
    TableWidget,
    LinearPercentageBarWidget,
    PieChart,
    NumberWidget
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    };
  },
}
</script>

<style scoped>

.default-card {
  border: 1px solid #EAECF0;
  border-radius: 20px;
  padding: 20px;
}

.default-card h5 {
  color: #101828;
  font-size: 16px;
  margin-bottom: 15px;
}

.ui-resizable-handle {
  display: none;
}

.default-card::-webkit-scrollbar-track {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 2px;
}

</style>
