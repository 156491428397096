<template>
  <div class="more-pane-wrapper" v-click-outside="hideMorePane">
    <img src="@/assets/images/more.svg" v-if="!disabled" class="arrow" @click="showMorePane" />
    <img src="@/assets/images/moreDisabled.svg" v-else class="arrow" />

    <div class="more-pane" v-if="showMore">
      <div class="pane-item" v-for="item in paneItems" @click="item.action()">
        <img :src="item.icon" class="icon" />
        <span @click="handleAction(item.action)">{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentOutline from '@/assets/images/documentOutline.svg'
export default {
  name: 'MorePane',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    paneItems: {
      type: Array,
      default: [
        {
          text: 'Item 1',
          icon: DocumentOutline,
          action: () => {}
        }
      ]
    }
  },
  data() {
    return {
      showMore: false
    }
  },
  methods: {
    showMorePane() {
      this.showMore = !this.showMore
    },
    hideMorePane() {
      this.showMore = false
    },
    handleAction(action) {
      action()
      this.showMore = false
    }
  }
}
</script>

<style scoped>
.more-pane-wrapper {
  position: relative;
}
.more-pane {
  position: absolute;
  box-sizing: border-box;
  top: 30px;
  right: 0;
  width: 200px;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #EAECF0;
  z-index: 100;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.pane-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  gap: 10px;
  border-radius: 6px;
}

.icon {
  width: 16px;
  height: 16px;
}

.pane-item span {
  font-size: 14px;
  font-family: euclid_regular, sans-serif;
  color: #667085;
}

.pane-item:hover {
  background-color: #f5f7fa;
}
</style>
