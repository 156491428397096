<template>
  <Layout>
    <h3 class="page-title">{{$t('notifications_title')}}</h3>
    <div class="filter-wrapper">
      <div class="filter-row">
        <div class="tab-panel-row">
          <div class="tab-panel">
            <button class="tab-button" :class="{'active': tabIndex === 0}" @click="() => changeTab(0)">
              <span>{{$t('templates')}}</span>
            </button>

            <button class="tab-button" :class="{'active': tabIndex === 1}" @click="() => changeTab(1)">
              <span>{{$t('sent')}}</span>
            </button>
          </div>
        </div>
      </div>

      <div class="justify-end-div">
        <ExpandableSearch />
        <router-link to="/notification/new">
          <div class="justify-end-add-btn no-text-select">
            <img src="../assets/images/plus.svg" />
            <span>{{$t('add_new')}}</span>
          </div>
        </router-link>
      </div>
    </div>

    <div class="table-holder">
      <NotificationTable :data="pageData.data" :loading="isLoading" />
    </div>

    <div class="pagination-align-center">
      <Pagination :current-page="currentPage" :total-items="pageData.total" :per-page="20" :on-page-change="handlePage"/>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import Pagination from "@/components/Pagination.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import {handleErrorResponse} from "@/utils/utils";
import PageCard from "@/components/PageCard.vue";
import notificationContentService from "@/service/notificationContentService";
import NotificationTable from "@/components/tables/NotificationTable.vue";

export default {
  name: 'Notifications',
  components: {
    NotificationTable,
    PageCard,
    ExpandableSearch,
    Pagination,
    Layout
  },
  data() {
    return {
      isLoading: true,
      tabIndex: 0,
      pageData: {
        data: [],
        total: 0
      },
      currentPage: 1
    }
  },
  watch: {
    $route() {
      this.handlePage(1);
    }
  },
  methods: {
    handlePage(page) {
      if (this.isLoading && this.currentPage !== 1) return;

      this.currentPage = page;
      this.isLoading = true;

      const routeQuery = this.$route.query;
      const tempFilters = [];

      if (routeQuery.searchText) {
        tempFilters.push({
          key: 'searchText',
          value: routeQuery.searchText
        });
      }

      if (routeQuery.isTemplate) {
        tempFilters.push({
          key: 'isTemplate',
          value: routeQuery.isTemplate
        });
      } else {
        tempFilters.push({
          key: 'isTemplate',
          value: true
        });
      }

      if (routeQuery.journeyId) {
        tempFilters.push({
          key: 'journeyId',
          value: routeQuery.journeyId
        });
      }


      notificationContentService.getNotificationContent(page, tempFilters)
          .then(response => {
            this.pageData = response.data.data;
            this.isLoading = false;
          })
          .catch(error => {
            handleErrorResponse(error);
            this.isLoading = false;
          });
    },
    changeTab(index) {
      if (this.tabIndex === index) {
        return;
      }

      this.tabIndex = index;

      if (index === 0) {
        this.$router.push({query: {
          ...this.$route.query,
          isTemplate: true
        }});
      } else {
        this.$router.push({query: {
          ...this.$route.query,
          isTemplate: false
        }});
      }
    }
  }
}
</script>

<style scoped>
.tab-panel {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}

.tab-panel-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.tab-button {
  padding: 6px 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.tab-button span {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #344054;
}
.tab-button.active, .tab-button:hover {
  background-color: #F2F4F7;
}

.page-title {
  font-family: euclid_medium, sans-serif;
  font-size: 20px;
  color: #040C2D;
  margin-bottom: 20px;
}
.filter-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.filter-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}



</style>
