import axios from '../axios';

const notificationService = {
    getNotification(page, filters = [], read) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/notification/store?page=${page}&read=${read}&limit=20${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/notification/store?page=${page}&limit=20&read=${read}`);
        }
    },
    getNotificationList() {
        return axios.get(`/v1/admin/notification/store?list=true`);
    },

    readNotification(id){
        return axios.post(`/v1/admin/notification/markNotificationSeenWeb/${id}`);
    },
    deleteNotification(id){
        return axios.delete(`/v1/admin/notification/delete/${id}`);
    },
    readAllNotification(){
        return axios.post(`/v1/admin/notification/markAllNotificationSeenWeb`);
    },

}
export default notificationService;
