<template>
  <div v-if="setting?.type === 'info'" class="info-box">
      <img src="@/assets/images/warning.svg" />

      <p>{{setting?.description}}</p>
  </div>

  <div v-if="setting?.type === 'programSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="programOptions"
        :selected-value="componentData?.selectedProgram"
        :on-select="(val) => {
          componentData.selectedProgram = val;
          emitChanges();
        }"
    />
  </div>

  <div v-if="setting?.type === 'numberInput'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <input
        class="form-input"
        type="number"
        :placeholder="setting?.name"
        :value="componentData[setting.variableName] || ''"
        @input="(e) => {
          if (!componentData[setting.variableName]) {
            componentData[setting.variableName] = '';
          }

          componentData[setting.variableName] = e.target.value;
          emitChanges();
        }"
    />
  </div>

  <div v-if="setting?.type === 'numberComparisonInput'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>

    <div class="input-row">
      <input
          class="form-input"
          type="number"
          :placeholder="setting?.name"
          :value="componentData[setting.variableName]?.value || ''"
          @input="(e) => {
            if (!componentData[setting.variableName]) {
              componentData[setting.variableName] = {
                value: '',
                comparison: '',
              };
            }

            componentData[setting.variableName].value = e.target.value;
            emitChanges();
          }"
      />

      <SelectBox
          placeholder="Comparison"
          :full-width="false"
          :options="comparisonOptions"
          :on-select="val => {
            if (!componentData[setting.variableName]) {
              componentData[setting.variableName] = {
                value: '',
                comparison: '',
              };
            }

            componentData[setting.variableName].comparison = val;
            emitChanges();
          }"
          :selected-value="componentData[setting.variableName]?.comparison"
          :align-right="true"
      />
    </div>
  </div>

  <div v-if="setting?.type === 'starSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>

    <div class="input-row">
      <SelectBox
          :placeholder="setting?.name"
          :full-width="true"
          :options="starOptions"
          :on-select="val => {
            if (!componentData[setting.variableName]) {
              componentData[setting.variableName] = {
                value: '',
                comparison: '',
              };
            }
            componentData[setting.variableName].value = val;
            emitChanges();
          }"
          :selected-value="componentData[setting.variableName]?.value"
      />

      <SelectBox
          placeholder="Comparison"
          :full-width="false"
          :options="comparisonOptions"
          :align-right="true"
          :on-select="val => {
            if (!componentData[setting.variableName]) {
              componentData[setting.variableName] = {
                value: '',
                comparison: '',
              };
            }
            componentData[setting.variableName].comparison = val;
            emitChanges();
          }"
          :selected-value="componentData[setting.variableName]?.comparison"
      />
    </div>
  </div>

  <div v-if="setting?.type === 'testSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="testOptions"
        :on-select="val => {
          if (!componentData[setting.variableName]) {
            componentData[setting.variableName] = '';
          }

          componentData[setting.variableName] = val;
          emitChanges();
        }"
        :selected-value="componentData[setting.variableName]"
    />
  </div>

  <div v-if="setting?.type === 'visualTestSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="visualTestOptions"
        :on-select="val => {
          if (!componentData[setting.variableName]) {
            componentData[setting.variableName] = '';
          }

          componentData[setting.variableName] = val;
          emitChanges();
        }"
        :selected-value="componentData[setting.variableName]"
    />
  </div>

  <div v-if="setting?.type === 'textInput'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <input
        class="form-input"
        type="text"
        :placeholder="setting?.name"
        :value="componentData[setting.variableName] || ''"
        @input="(e) => {
          if (!componentData[setting.variableName]) {
            componentData[setting.variableName] = '';
          }

          componentData[setting.variableName] = e.target.value;
          emitChanges();
        }"
    />
  </div>

  <div v-if="setting?.type === 'textArea'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <textarea
        class="form-textarea w-100"
        :placeholder="setting?.name"
        :value="componentData[setting.variableName] || ''"
        @input="(e) => {
          if (!componentData[setting.variableName]) {
            componentData[setting.variableName] = '';
          }

          componentData[setting.variableName] = e.target.value;
          emitChanges();
        }"
    ></textarea>
  </div>

  <div v-if="setting?.type === 'userGroupSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="userGroupOptions"
        :on-select="val => {
          if (!componentData[setting.variableName]) {
            componentData[setting.variableName] = '';
          }

          componentData[setting.variableName] = val;
          emitChanges();
        }"
        :selected-value="componentData[setting.variableName]"
    />
  </div>

  <div v-if="setting?.type === 'emojiSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="emojiOptions"
        :on-select="val => {
          if (!componentData[setting.variableName]) {
            componentData[setting.variableName] = '';
          }

          componentData[setting.variableName] = val;
          emitChanges();
        }"
        :selected-value="componentData[setting.variableName]"
    />
  </div>

  <div v-if="setting?.type === 'departmentSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="departmentOptions"
        :on-select="val => {
          if (!componentData[setting.variableName]) {
            componentData[setting.variableName] = '';
          }

          componentData[setting.variableName] = val;
          emitChanges();
        }"
        :selected-value="componentData[setting.variableName]"
    />
  </div>

  <div v-if="setting?.type === 'roleSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="roleOptions"
        :on-select="val => {
          if (!componentData[setting.variableName]) {
            componentData[setting.variableName] = '';
          }

          componentData[setting.variableName] = val;
          emitChanges();
        }"
        :selected-value="componentData[setting.variableName]"
    />
  </div>

  <div v-if="setting?.type === 'programStatusSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="programStatusOptions"
        :on-select="val => {
          if (!componentData[setting.variableName]) {
            componentData[setting.variableName] = '';
          }

          componentData[setting.variableName] = val;
          emitChanges();
        }"
        :selected-value="componentData[setting.variableName]"
    />
  </div>
</template>

<script>
import SelectBox from "@/components/SelectBox.vue";

export default {
  name: 'SettingsRenderer',
  components: {SelectBox},
  props: {
    setting: {
      type: Object,
      required: true,
    },
    componentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      programOptions: [
        {text: 'Program 1', value: 'program1'},
        {text: 'Program 2', value: 'program2'},
        {text: 'Program 3', value: 'program3'},
      ],
      starOptions: [
        {text: '1 stars', value: '1'},
        {text: '2 stars', value: '2'},
        {text: '3 stars', value: '3'},
        {text: '4 stars', value: '4'},
        {text: '5 stars', value: '5'},
      ],
      comparisonOptions: [
        {text: '>', value: 'greater'},
        {text: '<', value: 'less'},
        {text: '=', value: 'equal'},
        {text: '>=', value: 'greater-equal'},
        {text: '<=', value: 'less-equal'},
      ],
      testOptions: [
        {text: 'Test 1', value: 'test1'},
        {text: 'Test 2', value: 'test2'},
        {text: 'Test 3', value: 'test3'},
      ],
      visualTestOptions: [
        {text: 'Visual Test 1', value: 'visualTest1'},
        {text: 'Visual Test 2', value: 'visualTest2'},
        {text: 'Visual Test 3', value: 'visualTest3'},
      ],
      userGroupOptions: [
        {text: 'Initiator', value: 'initiator'},
        {text: 'Admin', value: 'admin'},
      ],
      emojiOptions: [
        {text: 'Happy', value: 'happy'},
        {text: 'Angry', value: 'angry'},
        {text: 'Anxious', value: 'anxious'},
        {text: 'Guilty', value: 'guilty'},
        {text: 'Hurt', value: 'hurt'},
      ],
      departmentOptions: [
        {text: 'Department 1', value: 'department1'},
        {text: 'Department 2', value: 'department2'},
        {text: 'Department 3', value: 'department3'},
      ],
      roleOptions: [
        {text: 'Role 1', value: 'role1'},
        {text: 'Role 2', value: 'role2'},
        {text: 'Role 3', value: 'role3'},
      ],
      programStatusOptions: [
        {text: 'Done', value: 'done'},
        {text: 'In Progress', value: 'inProgress'},
        {text: 'Not Started', value: 'notStarted'},
      ],
    };
  },
  methods: {
    emitChanges() {
      this.$emit('change', this.componentData);
    },
  }
}
</script>

<style scoped>
.setting-name {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

.setting-description {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040C2D;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.info-box {
  background-color: #FFF0B3;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.info-box img {
  width: 26px;
  height: 26px;
}

.info-box p {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040C2D;
}

.input-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.required-star {
  color: #F09460;
  display: inline-block;
}
</style>
