<script setup>
import { Handle, Position } from '@vue-flow/core'
import {computed, onMounted, ref, watch} from "vue";
import testDimensionService from "@/service/testDimensionService";
import NodeDropdown from "@/components/condition-flow/NodeDropdown.vue";
import equalIcon from "@/assets/images/equal.svg";
import lessThanIcon from "@/assets/images/lessThan.svg";
import greaterThanIcon from "@/assets/images/greaterThan.svg";
import lessThanEqualIcon from "@/assets/images/lessThanEqual.svg";
import greaterThanEqualIcon from "@/assets/images/greaterThanEqual.svg";

const props = defineProps(['id', 'data', 'testList', 'onSelect', 'editDisabled']);
const questionsLoading = ref(false);
const dimensionsList = ref([]);
const selectedTest = ref(null);
const selectedDimension = ref(null);
const selectedCondition = ref(null);
const selectedScore = ref(null);

const handleTestSelect = async (value) => {
  const testId = value;
  if (testId === '-1') return;

  const item = props.testList.find((test) => test.id === testId);
  selectedTest.value = item;
  questionsLoading.value = true;

  loadDimensions(item.id);
}

const loadDimensions = async (testId) => {
  if (!testId) return;
  await testDimensionService.getDimensions(testId).then((res) => {
    dimensionsList.value = res.data.data;
    questionsLoading.value = false;
  }).catch((err) => {
    console.log(err);
  });
}

const handleDimensionSelect = (value) => {
  const dimensionId = value;
  if (dimensionId === '-1') return;

  selectedDimension.value = dimensionsList.value.find((dimension) => dimension.dimensionalId === dimensionId);
}

const handleConditionSelect = (value) => {
  selectedCondition.value = value;
}


onMounted(async () => {
  if (props.data) {
    selectedTest.value = props.testList.find((test) => test.id === props.data.testId);
    await loadDimensions(props.data.testId);
    console.log(props.data.dimensionalId);
    selectedDimension.value = dimensionsList.value.find((dimension) => dimension.dimensionalId === props.data.dimensionalId);
    selectedCondition.value = props.data.condition;
    selectedScore.value = props.data.score;
  }
});

watch(
    [selectedTest, selectedDimension, selectedCondition, selectedScore],
    ([newTest, newDimension, newCondition, newScore], [oldTest, oldDimension, oldCondition, oldScore]) => {
      if (newTest && newCondition && newScore) {
        const selectedData = {
          testId: newTest.id,
          dimensionalId: newDimension?.dimensionalId,
          score: newScore,
          condition: newCondition,
        }
        props.onSelect(selectedData, props.id);
      }
    },
    { deep: true }
)

const conditionOptions = [
  { value: '=', text: 'Equals to', icon: equalIcon },
  { value: '<', text: 'Less than', icon: lessThanIcon },
  { value: '>', text: 'Greater than', icon: greaterThanIcon },
  { value: '<=', text: 'Less than or equal to', icon: lessThanEqualIcon },
  { value: '>=', text: 'Greater than or equal to', icon: greaterThanEqualIcon }
];

const mapTestListSelect =  computed(() =>
    props.testList.filter(test => test.type === 'test').map(test => ({ value: test.id, text: test.name }))
);

const mapDimensionListSelect = computed(() => {
  if (questionsLoading.value) return [{value: '-1', text: 'Select Dimension'}];
  if (dimensionsList.value.length === 0) {
    return [{value: '-1', text: 'Select Dimension'}];
  }

  return dimensionsList.value.map(dimension => ({value: dimension.dimensionalId, text: dimension.name}));
});
</script>

<template>
  <div class="node-wrapper">
    <Handle id="input" type="target" :position="Position.Top"/>

    <div class="node-header">
      <img src="../../../assets/images/medalOutlineDark.svg" alt="icon"/>
      <span class="node-type">{{$t('test_score')}}</span>
    </div>

    <div class="node-body">
      <div class="select-row" @wheel.stop>
        <NodeDropdown :search-enabled="true" theme="dark" :on-select="handleTestSelect" :selected-value="selectedTest?.id ? selectedTest?.id : '-1'" :options="mapTestListSelect" />

        <NodeDropdown :search-enabled="true" theme="dark" :on-select="handleDimensionSelect" :selected-value="selectedDimension?.dimensionalId ? selectedDimension.dimensionalId : '-1'" :options="mapDimensionListSelect" :loading="questionsLoading" />

        <NodeDropdown theme="dark" :show-value-as-selected="true" :options="conditionOptions" :on-select="handleConditionSelect" :selected-value="selectedCondition ? selectedCondition : '='" :disabled="editDisabled"/>
      </div>

      <input class="node-text-input dark" type="number" :placeholder="$t('enter_score')" v-model="selectedScore" :disabled="editDisabled"/>
    </div>

    <Handle id="output" type="source" :position="Position.Bottom" class="handle-true"/>
    <Handle id="false" type="source" :position="Position.Bottom" class="handle-false"/>
  </div>
</template>

<style scoped>
.node-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 320px;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #040c2d;
  background-color: #fff;
  border-radius: 6px;
}

.node-header {
  width: 100%;
  background-color: #f1f1f1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 10px;
}

.node-header img {
  width: 24px;
  height: 24px;
}

.node-type {
  font-size: 16px;
  color: #101828;
  font-family: 'euclid_regular', sans-serif;
}

.node-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.handle-true {
  position: absolute;
  bottom: -6px;
  left: calc(50% - 10px) ;
  transform: translateX(-50%);
  background-color: #00D68F;
}

.handle-false {
  position: absolute;
  bottom: -6px;
  right: calc(50% - 10px) ;
  transform: translateX(50%);
  background-color: #F44336 !important;
}

.select-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
