<template>
  <div class="content-header">
    <span class="module-header">{{ submodule.name }}</span>
    <span class="welcome-text">{{ $t("scenario_chat") }}</span>
    <div class="progress-container">
      <div class="progress-bar"></div>
    </div>
  </div>
  <div class="scenario-body">
    <div class="chat-holder" v-for="options in selectedModule.content?.options">
      <div class="message-box">
        <span class="message-text">{{ $t('scenario_message', { name: options.name }) }}</span>
      </div>
      <span class="message-time">{{ $t("just_now") }}</span>
      <div class="my-message-box">
        <span class="my-message-text">{{ $t("answer_from_user") }}</span>
      </div>
      <span class="my-message-time">{{ $t("just_now") }}</span>

      <div class="message-box">
        <span class="message-text">{{ $t('scenario_message_2', { name: options.name }) }}</span>
      </div>
      <span class="message-time">{{ $t("just_now") }}</span>

      <div class="my-message-box">
        <span class="my-message-text">{{ $t("answer_from_user") }}</span>
      </div>
      <span class="my-message-time">{{ $t("just_now") }}</span>
    </div>
    <div class="keyboard-holder">
      <span class="note-input">{{ $t("write_a_message") }}</span>
      <img class="share-button" src="@/assets/images/sendCircle.svg"/>
    </div>
  </div>
</template>
<script>
export default{
  props:{
    submodule:{
      type:Object
    },
    selectedModule:{
      type:Object
    },
    goForward:{
      type:Function,
    }
  }
}
</script>

<style scoped>

.share-button{
  margin-right: 7px;
  align-self: center;
  width: 20px;
}
.note-input{
  width: 75%;
  font-size: 10px;
  margin-left: 3%;
  border-radius: 6px;
  background-color: #F5F5F5;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.keyboard-holder{
  position: absolute;
  bottom: 0;
  background-color: #FFFFFF;
  align-items: center;
  border-top: 2px solid #F5F5F5;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 15%;
}
.bold-message{
  font-family: 'euclid_semi_bold', sans-serif;
}

.message-text{
  display: inline-block;
  font-family: 'euclid_regular', sans-serif;
  font-size: 8px;
  text-align: start;
  height: 100%;
}
.message-box{
  width: 80%;
  height: min-content;
  background-color: #FFFFFF;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin: 10px;
  padding: 10px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.my-message-time{
  margin-bottom: 10px;
  position: relative;
  margin-top: 3px;
  margin-right: 10px;
  text-align: end;
  font-family: 'euclid_regular', sans-serif;
  font-size: 7px;
  color: #98A2B3
}
.message-time{
  position: relative;
  margin-top: 3px;
  margin-left: 10px;
  text-align: start;
  font-family: 'euclid_regular', sans-serif;
  font-size: 7px;
  color: #98A2B3
}
.my-message-text{
  align-self: center;
  justify-content: center;
  margin-left: 5px;
  text-align: start;
  display: flex;
  font-family: 'euclid_regular', sans-serif;
  font-size: 8px;
  color: #FFFFFF;
}
.my-message-box{
  align-self: end;
  background-color: #4A68F8;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: 10px;
  width: 50%;
  height: min-content;
  margin: 10px;
  margin-bottom: 0px;
}
.chat-holder{
  display: flex;
  flex-direction: column;
}

.scenario-body{
  padding-bottom: 10%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  background-color: #ececec;
  height: 80%;
  width: 100%;
  align-self: center;
  justify-self: center;
  overflow-y:scroll ;
}
.scenario-body::-webkit-scrollbar {
  width: 0px; /* Scrollbar genişliği */
}



.progress-bar {
  height: 100%;
  background-color: #4A68F8;
  width: 20%;
  transition: width 0.3s ease-in-out;
}
.progress-container {
  display: flex;
  width: 100%;
  height: 3.5px;
  background-color: #ececec;
  align-self: center;
  margin-top: 10px;
}
.welcome-text{
  text-align: center;
  font-family: 'euclid_regular', sans-serif;
  font-size: 7px;
  color: #9194A3;
}
.content-header{
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.module-header{
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 12px;
  color: #040C2D;
  text-align: center;
}
</style>