<template>
  <div class="filter-view">
    <div class="flex-row">

    </div>
    <div class="justify-end-div">
      <div class="justify-end-add-btn no-text-select" @click="addNewTag">
        <img src="../../assets/images/plus.svg">
        <span>{{$t('add_new')}}</span>
      </div>
    </div>

  </div>

  <div class="table-container">
    <table class="content-table">
      <thead class="header-table">
      <tr class="trhead">
        <th>{{$t('name_journeys')}}</th>
        <th>{{$t('status')}}</th>
        <th class="text-right">{{$t('actions_payment')}}</th>
      </tr>
      </thead>
      <tbody class="tbody-container">
      <tr class="tr-class" v-if="!loading" v-for="tag in tags" >
        <td class="td-style">
          <span class="category-tag" :class="tag.color">{{ tag.name }}</span>
        </td>
        <td class="td-style">
          <span class="test-class">{{tag.status ? this.$t('active') : this.$t('disabled')}}</span>
        </td>
        <td class="td-style action-icons text-right">
          <img src="@/assets/images/trashOutline.svg" alt="Delete" class="action-icon" @click="() => deleteTag(tag.tagId)" />
          <img src="@/assets/images/editPenOutline.svg" alt="Edit" class="action-icon" @click="() => editTag(tag)" />
        </td>
      </tr>
      <tr v-else>
        <td colspan="7" class="table-loading">
          <VueSpinner size="16" height="100%" color="#8c8c8c" />
          <span class="text">{{$t('loading')}}</span>
        </td>
      </tr>
      <tr v-if="!loading && tags.length === 0 ">
        <td colspan="7" class="table-loading">
          <span class="text">{{$t('no_data_found')}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AddUserModal from '@/components/modals/InviteUserModal.vue';
import FilterModal from '@/components/tables/Company/CompanyFilterModal.vue';
import CompanyFieldFilter from '@/components/table-filters/CompanyFieldFilter.vue';
import TherapyFilter from "@/components/table-filters/NumberFilter.vue";
import UserAmountFilter from "@/components/table-filters/MinMaxNumberFilter.vue";
import PaymentVolume from "@/components/table-filters/PaymentVolume.vue";
import DateFilter from "@/components/table-filters/DateFilter.vue";
import {VueSpinner} from "vue3-spinners";
import ExpandableSearch from "@/components/ExpandableSearch.vue";

export default {
  components: {
    DateFilter,
    PaymentVolume,
    UserAmountFilter,
    AddUserModal,
    FilterModal,
    CompanyFieldFilter,
    TherapyFilter,
    ExpandableSearch,
    VueSpinner
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    addNewTag: {
      type: Function,
      default: () => {}
    },
    editTag: {
      type: Function,
      default: () => {}
    },
    deleteTag: {
      type: Function,
      default: () => {}
    },
    tags: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      showModal: false,
      selectAll:false,
      showFilter:false,
      searchText: '',
    }
  },
  methods: {


  },

}
</script>

<style>
.table-container {
  width: 100%;
  height: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: left;
  padding: 8px;
}
.trhead{
  background-color: transparent;
}
.tr-class{
  border-bottom-width: 1px;
  border-bottom-color: #F2F4F7;
}

tr td {
  padding-bottom: 12px;
  padding-top: 12px;
  align-items: center;
}
.table-container {
  width: 100%;
}
tr th{
  align-items: center;
  font-size: 12px;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.header-table,
.content-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.header-table {
  background-color: #F9FAFB;
  border-radius: 8px;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #F2F4F7;
}

.tbody-container {
  overflow-y: auto;
}
.tr-class td tr {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.test-class{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
}
.flex-row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
}

.filter-view{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  min-height: 52px;
}
.justify-end-div{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.name-row span {
  font-family: euclid_medium, sans-serif;
  color: #000;
}

.name-row:hover.name-row span{
  color: #4A68F8;
}

.flags img {
  height: 18.66px;
  width: 28px;
  border-radius: 4px;
}

.category-tag {
  margin-right: 5px;
}

.category-tag.primary {
  background-color: #4A68F8;
  color: #FFF;
}

.category-tag.warning {
  background-color: #FF8E55;
  color: #FFF;
}

.category-tag.danger {
  background-color: #FF5B5B;
  color: #FFF;
}

.category-tag.success {
  background-color: #00D68F;
  color: #FFF;
}

.text-right {
  text-align: right;
}

.action-icons {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
}

.action-icon {
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
</style>
