<template>
  <div class="card-wrapper">
    <div class="question-header">
      <div class="left-wrapper">
        <div class="question-number-wrapper">
          <span class="question-number">{{partData.order}}.</span>
        </div>

        <span class="question-type-text">{{$t('step')}}: </span>

        <div class="question-dropdown" v-click-outside="dropdownOutsideClick">
          <div class="dropdown-button" :class="{'active': partDropdownVisible}" @click="partDropdownVisible = !partDropdownVisible">
            <img :src="partTypes.find(type => type.id === selectedTypeID).icon" />
            <span>{{partTypes.find(type => type.id === selectedTypeID).name}}</span>
            <img src="@/assets/images/arrowdown.svg" class="arrow" />
          </div>

          <div class="dropdown-items" v-if="partDropdownVisible">
            <div v-for="item in partTypes" class="dropdown-item" @click="() => selectType(item.id)">
              <img :src="item.icon" />
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="right-wrapper">
        <!-- <Switch v-model="partData.status" /> -->
        <img src="@/assets/images/arrow-up.svg" @click="onChangePosition('up', partData.order)"/>
        <img src="@/assets/images/arrow-down.svg" @click="onChangePosition('down', partData.order)" />
        <img src="@/assets/images/copyOutline.svg" @click="onDuplicateQuestion(partData.order)" />
        <img src="@/assets/images/settingsOutline.svg" @click="partSettingsModal = true" />
        <img src="@/assets/images/trashOutline.svg" @click="handleQuestionDelete" />
      </div>
    </div>

    <Transition name="slide-up">
      <div v-if="expanded" class="question-body">
        <div v-if="selectedTypeID === 1" class="port-body">
          <span class="title-input">{{$t('what_people_will_learn')}}</span>
          <div class="question-wrapper">

            <textarea style="width: 100%" :placeholder="this.$t('enter_your_welcome_text')" rows="5" v-model="partData.name" />

            <div class="input-actions">
              <img src="@/assets/images/addImage.svg" @click="addVisualVisible = true"/>
            </div>
          </div>
        </div>

        <div v-if="selectedTypeID === 2" class="port-body">
          <span class="title-input">{{$t('explanation_text')}}</span>
          <textarea class="form-input" style="width: 100%" :placeholder="this.$t('please_explanation_here')" v-model="partData.name" v-textarea-autoresize></textarea>
          <br/><br/>
          <span class="title-input">{{$t('audio_cover_photo')}}</span>

          <div class="upload-row">
            <FileDrop type="mp3" :on-file-selected="handleAudioFile" :selected-file="partData.content.attach[0].attachUrl" :attach="partData.content.attach[0].attach" :on-delete="() => handleImageDelete(partData.content.attach[0])" />
            <FileDrop :on-file-selected="handleAudioCoverFile"  :selected-file="partData.content.attach[1].attachUrl" :attach="partData.content.attach[1].attach" :on-delete="() => handleImageDelete(partData.content.attach[1])" />
          </div>
        </div>

        <div v-if="selectedTypeID === 3" class="port-body">
          <span class="title-input">{{$t('explanation_text')}}</span>
          <textarea class="form-input" style="width: 100%" :placeholder="this.$t('please_explanation_here')" v-model="partData.name"  v-textarea-autoresize></textarea>
          <br/><br/>
          <span class="title-input">{{$t('video_cover_photo')}}</span>

          <div class="upload-row">
            <FileDrop type="mp4" :on-file-selected="handleVideoFile" :selected-file="partData.content.attach[0].attachUrl" :attach="partData.content.attach[0].attach" :on-delete="() => handleImageDelete(partData.content.attach[0])" />
            <FileDrop :on-file-selected="handleVideoCoverFile"  :selected-file="partData.content.attach[1].attachUrl" :attach="partData.content.attach[1].attach" :on-delete="() => handleImageDelete(partData.content.attach[1])" />
          </div>

          <ExpandableSetting>
            <div class="srt-file-row">
              <div class="srt-info">
                <span class="title">
                  {{$t('srt_file')}}
                </span>
                <span class="description">
                  {{$t('srt_file_description')}}
                </span>
              </div>

              <div class="file-drop">
                <FileDrop
                    type="srt"
                    :on-file-selected="handleSrtFile"
                    :selected-file="partData.content.srt.attachUrl"
                    :attach="partData.content.srt.attach"
                    :on-delete="() => handleSrtDelete(partData.content.srt)"
                />
              </div>
            </div>
          </ExpandableSetting>
        </div>

        <div v-if="selectedTypeID === 4" class="port-body">
          <textarea class="question-input" :placeholder="$t('add_your_question_here')" v-model="partData.name"  v-textarea-autoresize></textarea>

          <div class="answers-body">
            <h4>Answers</h4>
            <div class="answer-input-row" v-for="answer in partData.content.options">
              <img src="@/assets/images/drag.svg" />
              <input class="answer-input" :placeholder="$t('add_your_answer_here')" v-model="answer.name" />
              <img src="@/assets/images/trashOutline.svg" @click="() => handleDelete(answer.id)" />
            </div>

            <button class="add-answer-button no-text-select" @click="addAnswer">
              <img src="@/assets/images/plusBlue.svg" />
              <span>{{$t('add_an_answer')}}</span>
            </button>
          </div>
        </div>

        <div v-if="selectedTypeID === 5" class="port-body">
          <span class="title-input">{{ $t('summary_type') }}</span>
          <div class="question-wrapper mb-2">

            <textarea class="question-input" :placeholder="this.$t('add_your_summary_title')" v-model="partData.name"  v-textarea-autoresize></textarea>

            <div class="input-actions">
              <img src="@/assets/images/addImage.svg" @click="addVisualVisible = true"/>
            </div>
          </div>
          <textarea style="width: 100%" :placeholder="this.$t('add_summary_text')" rows="5" v-model="partData.description" />
        </div>

        <div v-if="selectedTypeID === 6" class="port-body">
          <span class="title-input">{{$t('success_type')}}</span>
          <textarea style="width: 100%" :placeholder="this.$t('add_success_paragraph')" rows="5" v-model="partData.name" />
        </div>

        <div v-if="selectedTypeID === 8" class="port-body">
          <span class="title-input">{{$t('failed_type')}}</span>
          <textarea style="width: 100%" :placeholder="this.$t('add_failed_paragraph')" rows="5" v-model="partData.name" />
        </div>

        <div v-if="selectedTypeID === 7" class="port-body">
          <span class="title-input">{{$t('self_chat_type')}}</span>
          <span class="description-text">{{$t('self_conversation')}}</span>
        </div>

        <div v-if="selectedTypeID === 9" class="port-body">
          <draggable
              v-model="partData.content.options"
              item-key="id">
            <template #item="{element}">
              <div class="answer-input-wrapper">
                <div class="answer-input-row">
                  <img src="@/assets/images/drag.svg" />
                  <input class="answer-input" :placeholder="this.$t('add_your_answer_here')" v-model="element.name" />
                  <img src="@/assets/images/trashOutline.svg" @click="() => handleDelete(element.id)" />
                </div>
              </div>
            </template>
          </draggable>
          <button class="add-answer-button no-text-select" @click="addAnswer">
            <img src="@/assets/images/plusBlue.svg" />
            <span>{{$t('add_an_answer')}}</span>
          </button>
        </div>
      </div>
    </Transition>

    <div class="toggle-card" :class="{'closed': !expanded}" @click="toggleCard">
      <img src="@/assets/images/cross.svg" />
    </div>

    <AddVisualModal :visible="addVisualVisible" :on-hide="hideAddVisual" v-model="partData.content.attach" />
    <PartSettingsModal :visible="partSettingsModal" :on-hide="hidePartSettingsModal" :test-list="testList" :settings="partData.content.settings" :selected-type="selectedTypeID" />
  </div>
</template>

<script>
import textOutline from "@/assets/images/textOutline.svg";
import audioOutline from "@/assets/images/audioOutline.png";
import videoOutline from "@/assets/images/videoOutline.png";
import questionOutline from "@/assets/images/questionOutline.png";
import receipt2Outline from "@/assets/images/receipt2Outline.png";
import shieldOutline from "@/assets/images/shieldOutline.png";
import messageOutline from "@/assets/images/messageOutline.svg";
import failedShieldOutline from "@/assets/images/failedShieldOutline.svg";

import GradeInput from "@/components/GradeInput.vue";
import StarInput from "@/components/StarInput.vue";
import PartSettingsModal from "@/components/modals/PartSettingsModal.vue";
import FileDrop from "@/components/FileDrop.vue";
import AddVisualModal from "@/components/modals/AddVisualModal.vue";
import ExpandableSetting from "@/components/ExpandableSetting.vue";
import SelectBox from "@/components/SelectBox.vue";
import draggable from "vuedraggable";
import AddSingleVisualModal from "@/components/modals/AddSingleVisualModal.vue";

export default {
  components: {AddSingleVisualModal,
    draggable, SelectBox, ExpandableSetting, AddVisualModal, FileDrop, PartSettingsModal, StarInput, GradeInput},
  props: {
    data: {
      type: Object,
      default: {}
    },
    partUpdate: {
      type: Function,
      default: () => {}
    },
    onDelete: {
      type: Function,
      default: () => {}
    },
    onChangePosition: {
      type: Function,
      default: () => {}
    },
    onDuplicateQuestion: {
      type: Function,
      default: () => {}
    },
    testList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      expanded: true,
      selectedTypeID: 1,
      partDropdownVisible: false,
      partSettingsModal: false,
      partOrder: 1,
      partTypes: [
        {
          id: 1,
          name: this.$t('welcome_text'),
          icon: textOutline,
          data:  {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "welcomeText",
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
        {
          id: 2,
          name: this.$t('audio_type'),
          icon: audioOutline,
          data: {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "audio",
              attach: [
                {
                  name: "Audio",
                  attach: null,
                  type: "audio"
                },
                {
                  name: "Cover Photo",
                  attach: null,
                  type: "cover"
                }
              ],
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
        {
          id: 3,
          name: this.$t('video_type'),
          icon: videoOutline,
          data: {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "video",
              attach: [
                {
                  name: "Video",
                  attach: null,
                  type: "video"
                },
                {
                  name: "Cover Photo",
                  attach: null,
                  type: "cover"
                }
              ],
              srt: {
                name: "Srt File",
                type: "srt",
                attach: null
              },
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
        {
          id: 4,
          name: this.$t('question_type_program'),
          icon: questionOutline,
          data: {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "multiple",
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
              options: [
                {
                  id: 0,
                  name: "Option A"
                },
                {
                  id: 1,
                  name: "Option B",
                },
              ]
            },
          }
        },
        {
          id: 5,
          name: this.$t('summary_type'),
          icon: receipt2Outline,
          data:  {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "summary",
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
        {
          id: 7,
          name: this.$t('self_chat_type'),
          icon: messageOutline,
          data:  {
            name: "Self Chat",
            status: 1,
            order: 1,
            content: {
              type: "selfChat",
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
        {
          id: 9,
          name: this.$t('scenario_text'),
          icon: messageOutline,
          data:  {
            name: "Scenario Text",
            status: 1,
            order: 1,
            content: {
              type: "scenarioText",
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
              options: [
                {
                  id: 0,
                  name: "Aşırı talepkarlık"
                },
                {
                  id: 1,
                  name: "Değerini azaltma",
                },
              ]
            }
          }
        },
        {
          id: 6,
          name: this.$t('success_type'),
          icon: shieldOutline,
          data:  {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "success",
              settings: {
                drawer: {
                  header: 'Successfully Completed',
                  body: 'Move to the next step',
                  actions: [
                    {
                      actionType: "primary",
                      actionText: "Continue",
                      action: "next",
                    }
                  ]
                },
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
        {
          id: 8,
          name: this.$t('failed_type'),
          icon: failedShieldOutline,
          data:  {
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "failed",
              settings: {
                drawer: {
                  header: 'Step Failed',
                  body: 'Please repeat the step',
                  actions: [
                    {
                      actionType: "primary",
                      actionText: "Repeat",
                      action: "repeat",
                    }
                  ]
                },
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }
        },
      ],

      partData: {},

      answers: [{
        id: 0,
        value: ""
      }],
      addVisualVisible: false
    }
  },
  created() {
    if (this.data) {
      if (this.data.content.type === "welcome-text" || this.data.content.type === "welcomeText") {
        this.selectedTypeID = 1;
      } else if (this.data.content.type === "audio") {
        this.selectedTypeID = 2;
      } else if (this.data.content.type === "video") {
        this.selectedTypeID = 3;
      } else if (this.data.content.type === "multiple") {
        this.selectedTypeID = 4;
      } else if (this.data.content.type === "summary") {
        this.selectedTypeID = 5;
      } else if (this.data.content.type === "success") {
        this.selectedTypeID = 6;
      } else if (this.data.content.type === "selfChat") {
        this.selectedTypeID = 7;
      } else if (this.data.content.type === "failed") {
        this.selectedTypeID = 8;
      } else if (this.data.content.type === "scenarioText") {
        this.selectedTypeID = 9;
      }

      if (!this.data?.content?.attach) {
        this.data.content.attach  = [];
      }

      this.partData = this.data;
      this.partOrder = this.data.order;
    } else {
      this.partData = this.partTypes.find(type => type.id === this.selectedTypeID).data;
    }
  },
  watch: {
    partData: {
      handler: function (newVal, oldVal) {
        this.partUpdate(newVal);
      },
      deep: true
    }
  },
  methods: {
    dropdownOutsideClick() {
      this.partDropdownVisible = false;
    },
    selectType(typeID) {
      this.selectedTypeID = typeID;
      this.partDropdownVisible = false;

      this.partData = this.partTypes.find(type => type.id === typeID).data;
      this.partData.order = this.partOrder;
    },
    toggleCard() {
      this.expanded = !this.expanded;
    },
    handleQuestionDelete() {
      this.onDelete(this.partData.order);
    },
    hidePartSettingsModal() {
      this.partSettingsModal = false;
    },
    handleAudioFile(file) {
      if (this.partData.content.type !== "audio") {
        return;
      }

      this.partData.content.attach[0].attach = file;
    },
    handleAudioCoverFile(file) {
      if (this.partData.content.type !== "audio") {
        return;
      }
      this.partData.content.attach[1].attach = file;
    },
    handleVideoFile(file) {
      if (this.partData.content.type !== "video") {
        return;
      }

      this.partData.content.attach[0].attach = file;
    },
    handleVideoCoverFile(file) {
      if (this.partData.content.type !== "video") {
        return;
      }
      this.partData.content.attach[1].attach = file;
    },
    handleSrtFile(file) {
      if (this.partData.content.type !== "video") {
        return;
      }

      // modules[0][subModules][0][parts][0][content][srt][name]:Srt File
      // modules[0][subModules][0][parts][0][content][srt][type]: srt
      // modules[0][subModules][0][parts][0][content][srt][attach]: srtfile Url

      this.partData.content.srt = {
        name: "Srt File",
        type: "srt",
        attach: file
      };
    },
    handleImageDelete(element) {
        element.attachDelete = 1;
        element.attach = null;
        element.attachUrl = null;
    },
    handleSrtDelete(element) {
      if (element.attachUrl) {
        element.attachDelete = 1;
        element.attach = null;
        element.attachUrl = null;
      }
    },
    addAnswer() {
      this.partData.content.options.push({
        id: this.partData.content.options.length + 1,
        name: ""
      });
    },
    handleDelete(id) {
      if (this.partData.content.options.length === 1) {
        this.partData.content.options[0] = "";
      } else {
        this.partData.content.options = this.partData.content.options.filter(answer => answer.id !== id);
      }
    },
    hideAddVisual() {
      this.addVisualVisible = false;
    },
  }
}
</script>

<style scoped>
.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
}

.question-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.left-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.right-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px
}

.question-number-wrapper {
  background-color: #040C2D;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-number {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #FFFFFF;
}

.question-body {
  padding: 15px 0;
}

.question-dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.question-dropdown img {
  height: 16px;
  width: 16px;
}

.question-dropdown span {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 12px;
}

.dropdown-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
}

.dropdown-button:hover, .dropdown-button.active {
  background-color: #F2F4F7;
}

.dropdown-button .arrow {
  transition: transform 0.1s linear;
}

.dropdown-button.active .arrow {
  transform: rotate(180deg);
}

.dropdown-items {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 99;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
}

.dropdown-item:hover {
  background-color: #F5F7FA;
}

.question-input, .answer-input  {
  border: none;
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  background-color: #F5F5F5;
  color: #282A36;
  padding: 15px;
  border-radius: 8px;
}

.question-input {
  width: 100%;
}

.answer-input {
  width: 300px;
}

.answer-input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

h4 {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
  margin: 0;
  padding: 0;
}

.answers-body {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-answer-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
  color: #4A68F8;
  margin-top: 10px;
  border: none;
  outline: none;
  background-color: transparent;
}

.add-answer-button:hover {
  background-color: #f3f4ff;
}

.toggle-card {
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  height: 20px;
  width: 20px;
  background-color: #FFFFFF;
  border: 1px solid #D0D5DD;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.toggle-card img {
  height: 9px;
  width: 9px;
}

.toggle-card.closed img {
  transform: rotate(-45deg);
}

.number-input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  height: 48px;
  width: 350px;
}

.number-input-row input {
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  background-color: #ffffff;
  color: #282A36;
  padding: 15px;
  width: 100%;
  border-top: 1px solid #D0D5DD;
  border-bottom: 1px solid #D0D5DD;
  border-left: none;
  border-right: none;
  height: 48px;
}

.number-input-row .left-part, .number-input-row .right-part {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
  background-color: #FCFCFD;
  border: 1px solid #D0D5DD;
}

.number-input-row .left-part img, .number-input-row .right-part img {
  height: 16px;
  width: 16px;
}

.number-input-row .left-part {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.number-input-row .right-part {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.title-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  background-color: transparent;
  color: #040C2D;
  display: block;
  margin-bottom: 10px;
}

.description-text {
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #667085;
  margin-bottom: 10px;
}

.upload-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.question-wrapper {
  position: relative;
  display: flex;
}

.question-wrapper .input-actions {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.srt-file-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.srt-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
}

.srt-info .title {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
}

.srt-info .description {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #667085;
}

.file-drop {
  flex: 1;
}
</style>
