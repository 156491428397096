<template>
  <div class="word-tagger">
    <textarea v-model="inputText" :placeholder="this.$t('enter_text_here')" @input="processText"></textarea>
    <Transition name="slide-up">
      <div class="words-body" v-if="words.length > 0">
        <span class="select-words-text">{{ $t('select_words_to_blank_out') }}</span>
        <div class="tagged-text">
          <span
              v-for="(word, index) in words"
              :key="index"
              :class="{ 'selected': selectedWords.includes(String(index)) || selectedWords.includes(index) }"
              @click="toggleWord(index)"
          >
            {{ word }}
          </span>
        </div>
      </div>
    </Transition>
    <Transition name="slide-up">
      <div class="variations-body" v-if="selectedWords.length > 0 && !freeText">
        <span class="select-words-text">{{ $t('add_other_words') }}</span>
        <div class="word-variations">
          <div class="variation-input-wrapper" v-for="(word, wordIndex) in otherWords" :key="wordIndex">
            <input
                type="text"
                class="variation-input"
                :value="word"
                :placeholder="$t('enter_word')"
                @change="(e) => handleOtherWordChange(wordIndex, e)"
            />
            <img src="../../assets/images/cross.svg" @click="removeOtherWord(wordIndex)" />
          </div>
          <button class="add-variation-button" @click="addOtherWord('')">
            {{ $t('add_word') }}
            <img src="../../assets/images/add.svg" />
          </button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'WordTagger',
  props: {
    freeText: {
      type: Boolean,
      default: false,
    },
    fullText: {
      type: String,
      default: '',
    },
    blankOutWords: {
      type: Array,
      default: [],
    },
    otherWords: {
      type: Array,
      default: [],
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      inputText: '',
      selectedWords: [],
      localOtherWords: [],
    };
  },
  computed: {
    words() {
      return this.inputText.split(/\s+/).filter(word => word.length > 0);
    },
  },
  watch: {
    fullText: {
      immediate: true,
      handler(value) {
        this.inputText = value;
      },
    },
    blankOutWords: {
      immediate: true,
      deep: true,
      handler(value) {
        this.selectedWords = value;
      },
    },
    otherWords: {
      immediate: true,
      handler(value) {
        this.localOtherWords = value;
      },
    },
    inputText(newValue) {
      this.emitChange();
    },
    selectedWords: {
      handler() {
        this.emitChange();
      },
      deep: true
    },
  },
  methods: {
    processText() {
      this.selectedWords = [];
    },
    toggleWord(index) {
      const wordIndex = this.selectedWords.indexOf(index);
      if (wordIndex === -1) {
        this.selectedWords.push(index);
      } else {
        this.selectedWords.splice(wordIndex, 1);
      }
    },
    handleOtherWordChange(index, e) {
      this.localOtherWords[index] = e.target.value;
      this.emitChange();
    },
    addOtherWord(word) {
      this.localOtherWords.push(word);
    },
    removeOtherWord(index) {
      this.localOtherWords.splice(index, 1);
    },
    emitChange() {
      this.onChange({
        fullText: this.inputText,
        selectedWords: this.selectedWords,
        otherWords: this.localOtherWords
      });
    },
  },
};
</script>

<style scoped>
.word-tagger {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

textarea {
  width: 100%;
  min-height: 100px;
  padding: 0.5rem;
}

.tagged-text {
  line-height: 1.5;
}

.tagged-text span {
  display: inline-block;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 12px;
}

.tagged-text span.selected {
  background-color: #DE3163;
  color: white;
}

.word-tagger .select-words-text {
  font-family: 'euclid_medium', sans-serif;
  font-size: 14px;
  color: #282A36;
}

.words-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.variations-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.word-variations {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.word-variations input {
  display: inline-block;
}

.original-word {
  display: inline-block;
  color: #DE3163;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  font-size: 14px;
  font-family: euclid_medium, sans-serif;
}

.variation-input-wrapper {
  position: relative;
}

.variation-input-wrapper img {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

.variation-input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #909aac;
  font-size: 12px;
  padding: 0.3rem 0.5rem;
}

.variation-input::placeholder {
  color: #909aac;
  font-family: 'euclid_regular', sans-serif;
}

.variation-input:focus {
  border-bottom: 1px solid #DE3163;
}

.add-variation-button {
  border-radius: 9px;
  background-color: transparent;
  color: #667085;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 12px;
  transition: background-color 0.2s;
  outline: none;
  padding: 0.3rem 0.5rem;
}

.add-variation-button img {
  width: 16px;
}

.add-variation-button:hover {
  background-color: #f4f4f4;
}
</style>
