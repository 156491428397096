<template>
  <div class="action-wrapper">
    <div class="go-dropdown-button" @click="dropdownActive = !dropdownActive" v-click-outside="actionDropdownOutsideClick">
      {{ this.placeHolder }}
      <img src="@/assets/images/arrowdown.svg" class="dropdown-arrow" :class="dropdownActive && 'arrow-up'"/>
    </div>
    <div class="action-dropdown right" v-if="dropdownActive" v-scroll-end="onScrollEnd">
      <div v-if="type==='therapy'" class="action-list" v-for="action in options" @click="onAction(action.therapyId, action.name); placeHolder=action.name">
        {{ action.name }}
      </div>
      <div v-if="type==='module'" class="action-list" v-for="action in options" @click="onAction(action.therapyModuleId, action.name); placeHolder=action.name">
        {{ action.name }}
      </div>
      <div v-if="isLoading" class="loading-center">
        <VueSpinner size="32" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  VueSpinner,
} from 'vue3-spinners'
export default {
  components: {VueSpinner},
  props: {
    type:{
      type: String,
    },
    placeholder: {
      type: String,
    },
    options: {
      type: Array,
    },
    onScrollEnd: {
      type: Function,
      default: () => {}
    },
    onAction: {
      type: Function,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      placeHolder: "",
      dropdownActive: false,
      chosenTherapy:"Select A Therapy",
    }
  },

  created() {
    this.placeHolder=this.placeholder;
  },

  methods: {
    actionDropdownOutsideClick() {
      this.dropdownActive = false;
    }
}
}
</script>

<style scoped>
.action-wrapper {
  position: relative;
}

.go-dropdown-button {
  cursor: pointer;
  font-size: 12px;
  padding: 7px 15px;
  border-radius: 8px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.action-dropdown {
  position: absolute;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 8px;
  top: 40px;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 99999;
  overflow-y: scroll;
  height: 250px;
}

.action-list {
  cursor: pointer;
  font-size: 12px;
  display: block;
  border-radius: 8px;
}

.action-list:hover {
  background: #ffffff;

}

.dropdown-arrow {
  transition: transform 0.3s;
}

.arrow-up {
  transform: rotate(180deg);
}
</style>
