<template>
  <Transition name="fade">
    <div v-if="isVisible" class="modal-overlay">

      <div class="modal-content" v-click-outside="onClose">
        <img src="../../assets/images/tickxl.svg"/>
        <div class="modalheader">
          <h2>{{$t('congratulations')}}</h2>
        </div>
        <div class="modalbody">
          <p>{{$t('operationSuccess')}}</p>
          <div class="modalbutton" @click="onAction">
            <span>{{$t('close')}}</span>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    onAction: {
      type: Function,
      default: () => {}
    },
    onClose: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:12px
}

.modalheader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalheader h2 {
  color: #040C2D;
  text-align: center;
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  font-style: normal;
  line-height: 24px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modalbody {
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.modalbody p {
  color: #667085;
  text-align: center;
  align-items: center;
  font-family: euclid_regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 24px; /* 150% */
}

.modalbutton {
  display: flex;
  width: 200px;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  background: #040C2D;
  cursor: pointer;
  margin-top: 40px;
}
.modalbutton span {
  color: #FFF;
  text-align: center;
  font-family: euclid_regular, sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
.modal-content img {
  width: 120px;
  height: auto;
}

</style>
