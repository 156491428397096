import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import {BootstrapVue3} from 'bootstrap-vue-3';
import ClickOutside from './directives/clickOutside'
import Focus from './directives/focus'
import ScrollEnd from './directives/scrollEnd'
import Shimmer from 'vue3-shimmer';
import {SnackbarService} from "vue3-snackbar";
import VueAwesomePaginate from "vue-awesome-paginate";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import "vue3-snackbar/styles";
import "vue-awesome-paginate/dist/style.css";
import textareaAutoresize from "@/directives/textareaAutoresize";
import i18n from './i18n';
import {QuillEditor} from "@vueup/vue-quill";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import "gridstack/dist/gridstack.min.css";
import "gridstack/dist/gridstack-extra.min.css";
import './assets/main.css'
import VueApexCharts from "vue3-apexcharts";
import * as Sentry from "@sentry/vue";
import tooltip from "@/directives/tooltip";
import VueKonva from 'vue-konva';


const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://bb341fae91e5966cef8304cb4d366cc4@o4507786058006528.ingest.de.sentry.io/4507786063052880",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost"],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(i18n);
app.use(router);
app.use(BootstrapVue3);
app.use(Shimmer);
app.use(SnackbarService);
app.use(VueAwesomePaginate);
app.use(VueApexCharts);
app.use(VueKonva);
app.directive('click-outside', ClickOutside);
app.directive('focus', Focus);
app.directive('scroll-end', ScrollEnd);
app.directive('textarea-autoresize', textareaAutoresize);
app.directive('tooltip', tooltip);
app.component('QuillEditor', QuillEditor);

app.config.errorHandler = (err, vm, info) => {
    console.error("Error:", err);
    console.error("Vue component:", vm);
    console.error("Additional info:", info);
};

app.mount('#app');

