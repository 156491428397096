<template>
  <div class="flex-row">
    <div class="row-item no-text-select" v-if="showDateRangeFilter" v-click-outside="() => closeFilter('date_range')">
      <div @click="toggleDateRangeFilter">
        <img src="../../../assets/images/filterdaterange.svg" class="row-item-icon"/>
        <span class="row-item-txt">
          {{$t('calendar')}}{{dateRangeFilterData.startAt || dateRangeFilterData.startAtEnd || dateRangeFilterData.finishAt || dateRangeFilterData.finishAtEnd ? ':' : ''}} <span class="show-filters"> {{dateRangeFilterData.startAt && !dateRangeFilterData.startAtEnd? $t('starting_range') + ":" + dateRangeFilterData.startAt +"/..." : ''}}{{dateRangeFilterData.startAt && dateRangeFilterData.startAtEnd ? $t('starting_range') + ':'+ dateRangeFilterData.startAt+ " / " + dateRangeFilterData.startAtEnd : ''}}{{dateRangeFilterData.startAtEnd && !dateRangeFilterData.startAt ? $t('starting_range')+": .../"+ dateRangeFilterData.startAtEnd : ''}}
          {{(dateRangeFilterData.finishAt || dateRangeFilterData.finishAtEnd) && (dateRangeFilterData.startAt || dateRangeFilterData.startAtEnd) ? " | " : ""}}{{dateRangeFilterData.finishAt && !dateRangeFilterData.finishAtEnd? $t('finishing_range') + ":" + dateRangeFilterData.finishAt +"/..." : ''}}{{dateRangeFilterData.finishAt && dateRangeFilterData.finishAtEnd ? $t('finishing_range') +':'+ dateRangeFilterData.finishAt+ " / " + dateRangeFilterData.finishAtEnd : ''}}{{dateRangeFilterData.finishAtEnd && !dateRangeFilterData.finishAt ? $t('finishing_range') +": .../"+ dateRangeFilterData.finishAtEnd : ''}}
        </span>
          </span>
      </div>
      <DateFilterForTest
          v-if="showDateRangeDropdown"
          :visible.sync="showDateRangeDropdown"
          :on-filter="handleApplyDateFilters"
      />
      <img src="../../../assets/images/cross.svg" @click="() => removeFilter('date_range')" />
    </div>
    <div class="row-item no-text-select" v-if="showStatusFilter" v-click-outside="() => closeFilter('status')">
      <div @click="toggleStatusFilter">
        <img src="../../../assets/images/filtertherapynumber.svg" class="row-item-icon" />
        <span class="row-item-txt">
          {{$t('status')}}{{statusFilterData.status=== undefined ? ':' : ':'}} <span class="show-filters"> {{statusFilterData.status==="1" ? $t('published') :""}} {{statusFilterData.status==="0"? $t('draft') : "" }}</span>
          </span>
      </div>
      <StatusFilter
          v-if="showStatusFilterDropdown"
          :visible.sync="showStatusFilterDropdown"
          :on-filter="handleStatusFilter"
          :is-publish-filter="true"
      />

      <img src="../../../assets/images/cross.svg" @click="() => removeFilter('status')" />
    </div>

    <div class="row-item filter" v-click-outside="() => closeFilter('main')" @click="toggleFilter">
      <img src="../../../assets/images/filter.svg">
      <span class="row-item-txt no-text-select">
            {{ $t('filter_therapy') }}
        </span>
      <FilterModal :visible.sync="showFilter" @filter-selected="handleFilterSelected"/>
    </div>
  </div>
</template>

<script>
import DateFilterForTest from "@/components/table-filters/DateFilterForTest.vue";
import FilterModal from "@/components/tables/Test/TestFilterModal.vue";
import MinMaxNumberFilter from "@/components/table-filters/MinMaxNumberFilter.vue";
import StatusFilter from "@/components/table-filters/StatusFilter.vue";

export default {
  components: {
    DateFilterForTest,
    StatusFilter,
    MinMaxNumberFilter,
    FilterModal
  },
  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {

        if (this.$route.query.startAt) {
          this.showDateRangeFilter = true;
          this.dateRangeFilterData.startAt = this.$route.query.startAt;
        } else {
          this.dateRangeFilterData.startAt = undefined
        }

        if (this.$route.query.startAtEnd) {
          this.showDateRangeFilter = true;
          this.dateRangeFilterData.startAtEnd = this.$route.query.startAtEnd;
        } else {
          this.dateRangeFilterData.startAtEnd = undefined
        }

        if (this.$route.query.finishAt) {
          this.showDateRangeFilter = true;
          this.dateRangeFilterData.finishAt = this.$route.query.finishAt;
        } else {
          this.dateRangeFilterData.finishAt = undefined
        }

        if (this.$route.query.finishAtEnd) {
          this.showDateRangeFilter = true;
          this.dateRangeFilterData.finishAtEnd = this.$route.query.finishAtEnd;
        } else {
          this.dateRangeFilterData.finishAtEnd = undefined
        }

        if (this.$route.query.isPublish) {
          this.showStatusFilter = true;
          this.statusFilterData.status = this.$route.query.isPublish;
        } else {
          this.statusFilterData.status = undefined
        }
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      showFilter:false,

      showStatusFilter:false,
      showStatusFilterDropdown:false,
      statusFilterData:[{
        status:undefined,
      }],


      showDateRangeFilter: false,
      showDateRangeDropdown: false,
      dateRangeFilterData: [{
        startAt: undefined,
        startAtEnd:undefined,
        finishAt:undefined,
        finishAtEnd:undefined
      }],
    }
  },
  methods: {
    handleFilterSelected(filterName) {
      this.closeFilter('main');
      if (filterName === 'date_range') {
        this.showDateRangeFilter = true;
        this.showDateRangeDropdown = true;
      } else if (filterName === 'status') {
        this.showStatusFilter=true;
        this.showStatusFilterDropdown=true;
      }
    },
    formatDateForBackend(dateString) {
      if (!dateString) return undefined;
      const [day, month, year] = dateString.split('-');
      return `${year}-${month}-${day}`;
    },
    handleApplyDateFilters(filters){
      this.dateRangeFilterData.startAt = filters.startAt;
      this.dateRangeFilterData.startAtEnd = filters.startAtEnd;
      this.dateRangeFilterData.finishAt = filters.finishAt;
      this.dateRangeFilterData.finishAtEnd = filters.finishAtEnd;
      this.showDateRangeDropdown = false;

      this.$router.push({
        query: {
          ...this.$route.query,
          startAt: this.formatDateForBackend(filters.startAt),
          startAtEnd: this.formatDateForBackend(filters.startAtEnd),
          finishAt: this.formatDateForBackend(filters.finishAt),
          finishAtEnd: this.formatDateForBackend(filters.finishAtEnd),
        }
      });
    },

    handleStatusFilter(filter){
      this.statusFilterData=filter;
      this.showStatusFilterDropdown = false;
      this.$router.push({
        query: {
          ...this.$route.query,
          isPublish:this.statusFilterData.status
        }
      });
    },

    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    toggleDateRangeFilter() {
      this.showDateRangeDropdown = !this.showDateRangeDropdown;
    },

    toggleStatusFilter() {
      this.showStatusFilterDropdown = !this.showStatusFilterDropdown;
    },

    closeFilter(filter) {
      if (filter === 'main' && this.showFilter === true) {
        this.showFilter = false;
      } if (filter === 'date_range' && this.showDateRangeDropdown === true) {
        this.showDateRangeDropdown = false;
      } else if (filter === 'status' && this.showStatusFilterDropdown === true) {
        this.showStatusFilterDropdown = false;
      }
    },
    removeFilter(filter) {
     if (filter === 'date_range') {
        this.showDateRangeFilter = false;
        this.dateRangeFilterData = [{
          startAt: undefined,
          startAtEnd: undefined,
          finishAt: undefined,
          finishAtEnd: undefined,
        }];
        this.$router.push({
          query: {
            ...this.$route.query,
            startAt: undefined,
            startAtEnd: undefined,
            finishAt: undefined,
            finishAtEnd: undefined
          }
        })
      } else if (filter === 'status') {
       this.showStatusFilter=false;
       this.status = undefined;
       this.$router.push({
         query: {
           ...this.$route.query,
           isPublish: undefined
         }
       })
     }
    },
  }
}
</script>

<style scoped>

.flex-row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
}
.row-item{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}

.row-item.filter {
  background-color: transparent;
}

.row-item-txt{
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}

.show-filters{
  font-family: euclid_medium, sans-serif;
  color: #4A68F8;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
}
</style>
