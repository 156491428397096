<template>
  <div class="step-header">
    <div
        class="step"
        v-for="(step, index) in steps"
        :class="currentStep >= (index + 1) && 'selected'"
        :key="index"
    >
      <div class="step-number">{{(index + 1) }}</div>
      <span>{{step}}</span>

      <div
          v-if="index < steps.length - 1"
          class="connecting-line"
      >
        <div
            class="connecting-line-fill"
            :style="{
            transform: currentStep > (index + 1) ? 'scaleX(1)' : 'scaleX(0)',
            transitionDelay: getTransitionDelay(index)
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepProgress',
  props: {
    steps: {
      type: Array,
      default: () => ["Step 1"]
    },
    currentStep: {
      type: Number,
      default: 1
    }
  },
  methods: {
    getTransitionDelay(index) {
      const DELAY_PER_STEP = 0.2;

      // For forward progression
      if (this.currentStep > this.previousStep) {
        return `${index * DELAY_PER_STEP}s`;
      }
      // For backward progression
      else {
        return `${(this.steps.length - 2 - index) * DELAY_PER_STEP}s`;
      }
    }
  },
  data() {
    return {
      previousStep: 1
    }
  },
  watch: {
    currentStep: {
      handler(newVal) {
        // Update the previous step after a brief delay to ensure proper transition direction
        setTimeout(() => {
          this.previousStep = newVal;
        }, 50);
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.step-header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.step-header .step {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.step .step-number {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(4, 12, 45, 0.1);
  font-size: 14px;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  color: rgba(4, 12, 45, 0.1);
}

.step.selected .step-number {
  background-color: #040C2D;
  color: #fff;
  transform: scale(1.05);
}

.step span {
  font-family: euclid_regular, sans-serif;
  color: #040C2D;
  font-size: 12px;
  transition: all 0.3s ease;
}

.step.selected span {
  font-family: euclid_semi_bold, sans-serif;
}

.connecting-line {
  position: absolute;
  top: 12px;
  right: calc(-50% + 12px);
  width: calc(100% - 24px);
  height: 1.5px;
  background-color: rgba(4, 12, 45, 0.1);
}

.connecting-line-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #040C2D;
  transform-origin: left;
  transition: transform 0.4s ease-in-out;
}

</style>
