<template>
  <div class="position-card">
    <router-link :to="to">
        <div class="status-tag" :class="isPublish ? 'Published' : 'Draft'">
          <div class="status-dot" :class="isPublish ? 'Published' : 'Draft'">
          </div>
          <span>{{isPublish ? $t('published') : $t('draft')}}</span>
        </div>


<div class="content-container">
        <span v-if="!isLoading" class="position-card-title">{{title}}</span>
  <free-style-shimmer
      :is-loading="isLoading"
      height="12px"
      width="120px"
      border-radius="50px"
      color="#EAECF0"
  />

  <div v-if="!isLoading" class="position-card-text">
          {{description}}
        </div>
  <free-style-shimmer
      :is-loading="isLoading"
      height="12px"
      width="120px"
      border-radius="50px"
      color="#EAECF0"
  />
<div class="position-values">
        <div class="value-holder" >
          <img class="value-icon" src="../assets/images/shieldOutline.png" />
          <span class="value-text">{{value1}}</span>
          <free-style-shimmer
              :is-loading="isLoading"
              height="12px"
              width="50px"
              border-radius="50px"
              color="#EAECF0"
          />
        </div>
        <div class="value-holder" >
          <img class="value-icon" src="../assets/images/questionOutline.png" />
          <span class="value-text">{{value2}}</span>
          <free-style-shimmer
              :is-loading="isLoading"
              height="12px"
              width="50px"
              border-radius="50px"
              color="#EAECF0"
          />
        </div>
</div>
</div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    image: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default:
          "Lorem ipsum dolor sit amet consectetur. Elit suspendisse habitant vitae...",
    },
    isPublish: {
      type: Boolean,
      default: false,
    },

    to: {
      type: String,
      default: "/positions",
    },
    value1: {
      type: Number,
    },
    value2: {
      type: Number,
    }
  },
  methods: {
    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: "numeric", month: "short", day: "numeric"};
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return "-";
      }
    },
  },
};
</script>

<style scoped>
.content-container{
  gap:5px;
  display: flex;
  flex-direction: column;
}
.value-text {
  font-family: 'euclid_regular', sans-serif;
  font-size: 14px;
  color: #73839B;
}

.value-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.value-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.position-values {
  position: absolute;
  margin-top: 15px;
  flex-direction: row;
  gap: 16px;
  display: flex;
  bottom: 15px;
}

.status-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040c2d;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.status-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.status-tag.Draft {
  background-color: #fad9c6;
}

.status-tag.Published {
  background-color: #ddfbde;
}

.status-dot.Draft {
  background-color: #f09460;
}

.status-dot.Published {
  background-color: #3da33e;
}

.position-card {
  position: relative;
  width: 100%;
  border: 1px solid #EAECF0;
  border-radius: 20px;
  padding: 15px;
  gap: 31px;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  min-height: 180px;
  background-color: #FCFCFD;
}

.position-card-title {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #2E70E8;
  display: -webkit-box;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.position-card-text {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #73839B;
  display: -webkit-box;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
