<template>
  <div class="upload-image" :class="{'active': dragActive, 'small': size === 'small'}" @dragenter.prevent="setActive" @dragover.prevent="setActive" @dragleave.prevent="setInactive" @drop.prevent="onDrop">
    <div class="img-div"  @click="selectFile">
      <input type="file" :id="fileInputId" style="display: none" @change="onFileInputChange" :accept="acceptAttribute">
      <img v-if="file === null" class="upload-image-placeholder" src="../assets/images/upload-image.svg" />
      <img v-else-if="type === 'image'" class="preview-image" :src="imagePreview" :alt="$t('file_preview')" @click="openImagePreviewModal" />
      <img v-else-if="type === 'wav' || type === 'mp3'" src="../assets/images/audioFile.svg" />
      <img v-else-if="type === 'mp4'" src="../assets/images/videoFile.svg" />
      <img v-else-if="type === 'allFiles'" src="../assets/images/documentFile.svg" />
      <img v-else-if="type === 'srt'" src="../assets/images/documentFile.svg" />
    </div>
    <div class="upload-image-attention-div" v-if="file === null">
      <div @click="selectFile"><span class="orange">{{$t('click_to_upload')}}</span> <span class="gray">{{$t('drag_and_drop')}}</span></div>
      <div v-if="type === 'image' && imageEditorEnabled" @click="goToEditor"><span class="blue">Open image editor</span></div>
      <span class="format-txt">{{formatAllowedFormats}}</span>
    </div>

    <div class="upload-info" v-else>
      <div class="info-left">
        <span class="info-name">{{file.name}}</span>
        <span class="info-size">{{formatBytes(file.size)}}</span>
      </div>
      <img src="@/assets/images/trash.svg" @click="removeFile" />
    </div>
  </div>

  <!-- Image Preview Modal -->
  <ImagePreviewModal v-if="showModal" :image="imagePreview" :visible="showModal" @hide="closeImagePreviewModal" />
</template>

<script>
import ImagePreviewModal from '@/components/modals/ImagePreviewModal.vue';

export default {
  name: 'FileDrop',
  components: {
    ImagePreviewModal
  },
  props: {
    onFileSelected: {
      type: Function,
      required: true
    },
    size: {
      type: String,
      default: 'normal',
    },
    type: {
      type: String,
      default: 'image',
    },
    selectedFile: {
      type: String,
      default: ''
    },
    attach: {
      type: File,
      default: null
    },
    onDelete: {
      type: Function,
      default: () => {}
    },
    multiple: {
      type: Boolean,
      default: false
    },
    imageEditorEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dragActive: false,
      file: null,
      imagePreview: null,
      fileInputId: null,
      showModal: false,
    }
  },
  watch: {
    attach(val) {
      this.checkAttach(val);
    },
    selectedFile: {
      handler: function (val, oldVal) {
        this.checkSelectedFile(val);
      },
      deep: true
    }
  },
  created() {
    this.fileInputId = this.randomFileInputId;
    this.checkSelectedFile(this.selectedFile);
    this.checkAttach(this.attach);
  },
  methods: {
    setActive() {
      this.dragActive = true;
    },
    setInactive() {
      this.dragActive = false;
    },
    onDrop(e) {
      this.setInactive();
      const files = e.dataTransfer.files;
      this.handleFileSelection(files);
    },
    onFileInputChange(e) {
      const files = e.target.files;

      if (files.length > 0) {
        this.handleFileSelection(files);
        e.target.value = "";
      }
    },
    selectFile() {
      if(this.showModal === true) {
        return null
      }
      else {
        document.getElementById(this.fileInputId).click();
      }
    },
    checkSelectedFile(selectedFile) {
      try {
        if (selectedFile && selectedFile !== '' && selectedFile.startsWith('http')) {
          this.file = {
            name: selectedFile.split('/').slice(-1)[0],
            size: "-",
          }

          if (this.type === 'image') {
            this.imagePreview = selectedFile;
          }
        }
      } catch (e) {}
    },
    checkAttach(attach) {
      if (attach) {
        this.file = attach;

        if (this.type === 'image') {
          const reader = new FileReader();
          reader.onload = () => {
            this.imagePreview = reader.result;
          };
          reader.readAsDataURL(attach);
        }
      }
    },
    handleFileSelection(files) {
      if (files.length > 0) {
        if (this.type === 'image') {
          if (!this.multiple) {
            this.file = files[0];

            const reader = new FileReader();
            reader.onload = () => {
              this.imagePreview = reader.result;
            };
            reader.readAsDataURL(files[0]);
          }

          this.onFileSelected(files[0]);
        } else if (this.type === 'wav' ||
            files[0].type === 'audio/wav' ||
            files[0].type === 'mp4' ||
            files[0].type === 'video/mp4' ||
            files[0].type === 'mp3' ||
            files[0].type === 'audio/mpeg' ||
            files[0].type === 'mpeg' ||
            files[0].type === 'audio/mp3') {
          if (!this.multiple) this.file = files[0];
          this.onFileSelected(files[0]);
        } else if (this.type === 'allFiles') {
          if (!this.multiple) this.file = files[0];
          this.onFileSelected(files[0]);
        } else if (this.type === 'srt') {
          if (!this.multiple) this.file = files[0];
          this.onFileSelected(files[0]);
        } else {
          this.$snackbar.add({
            text: 'Invalid file format' + files[0].type,
            type: 'warning'
          });
        }
      }
    },
    removeFile() {
      this.file = null;
      this.imagePreview = null;
      this.onDelete();
    },
    formatBytes(bytes) {
      if (bytes < 1024) {
        return bytes + ' B';
      } else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(2) + ' KB';
      } else if (bytes < 1024 * 1024 * 1024) {
        return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
      } else {
        return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
      }
    },
    goToEditor() {
      window.open('/image-editor', '_blank');
    },
    openImagePreviewModal() {
      this.showModal = true;
    },
    closeImagePreviewModal() {
      this.showModal = false;
    }
  },
  computed: {
    acceptAttribute() {
      if (this.type === 'image') {
        return 'image/*';
      } else if (this.type === 'wav') {
        return 'audio/wav';
      } else if (this.type === 'mp3') {
        return 'audio/mp3';
      } else if (this.type === 'mp4') {
        return 'video/mp4';
      } else if (this.type === 'allFiles') {
        return '*/*';
      } else if (this.type === 'srt') {
        return '.srt';
      }
    },
    formatAllowedFormats() {
      if (this.type === 'image') {
        return 'PNG, JPG, SVG, or GIF';
      } else if (this.type === 'wav') {
        return 'WAV';
      } else if (this.type === 'mp3') {
        return 'MP3';
      } else if (this.type === 'mp4') {
        return 'MP4';
      } else if (this.type === 'allFiles') {
        return 'Any file';
      } else if (this.type === 'srt') {
        return 'SRT Subtitle';
      }
    },
    randomFileInputId() {
      return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
  }
}
</script>


<style scoped>
.upload-image{
  border-radius: 12px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  width: 100%;
}
.upload-image.active{
  border-radius: 12px;
  border: 1px solid #4A68F8;
  background: #EBEDFB;
  width: 100%;
}
.upload-image-attention-div{
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 24px;
  text-align: center;
  cursor: pointer;
}
.orange{
  display: inline-block;
  font-family: euclid_semi_bold, sans-serif;
  color: #F09460;
  font-size: 14px;
}

.blue{
  display: inline-block;
  font-family: euclid_semi_bold, sans-serif;
  color: #4A68F8;
  font-size: 10px;
}
.gray{
  display: inline-block;
  color: #667085;
  font-size: 14px;
}
.format-txt{
  color: #98A2B3;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
}
.img-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
}
.upload-image-placeholder{
  width: 72px;
  height: 72px;
}

.upload-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}
.info-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.info-name {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #667085;
  display: block;
  word-break: break-all;
}
.info-size {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #98A2B3;
  display: block;
}
.preview-image {
  max-width: 100%;
  max-height: 100px;
}
.small .upload-image-attention-div {
  gap: 2px;
  padding: 10px;
}
.small .upload-info {
  padding: 10px;
}
.small .upload-image-placeholder{
  width: 40px;
  height: 40px;
}
.small .img-div {
  padding-top: 12px;
}
.small .info-name {
  font-size: 12px;
}
.small .info-size {
  font-size: 10px;
}
.small .orange{
  font-size: 10px;
  line-height: 12px;
}
.small .gray{
  font-size: 10px;
  line-height: 12px;
}

.small .format-txt{
  font-size: 8px;
  line-height: 12px;
}
</style>
