<template>
  <div v-if="visible" class="filter-modal">
    <div class="filter-div">
      <div
           class="filter-item"
           v-for="item in filterItems"
           :key="item.id"
           @click="toggleCheck(item.id)"
      >
        <span class="filter-title">{{ item.name }}</span>
        <img v-if="selectedFilter === item.id" src="../../assets/images/checkboxchecked-circle.svg">
        <img v-else src="../../assets/images/checkbox-circle.svg">
      </div>
      <div class="btn-style" @click="applyFilters">
        <span class="filter-txt">{{$t('filter_therapy')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onFilter: {
      type: Function,
      default: () => {}
    },
    filterItems: {
      type: Array,
    }
  },
  data() {
    return {
      selectedFilter:null,
      status: "",
    };
  },
  methods: {
    toggleCheck(itemId) {
      if (this.selectedFilter === itemId) {
        this.selectedFilter = null;
      } else {
        this.selectedFilter = itemId;
        if (this.selectedFilter === 0) {
          this.status = "0";
        } else if (this.selectedFilter === 1) {
          this.status="1";
        }
        else if (this.selectedFilter === 2) {
          this.status="2";
        } else if (this.selectedFilter === 3) {
          this.status="3";
        }

      }

      event.stopPropagation();
    },
    applyFilters() {
      this.onFilter({
            status: this.status,
          }
      );
    },
  },
};
</script>

<style scoped>
.filter-modal {
  position: absolute;
  top: 40px;
  display: flex;
  left: 0;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08);
  min-width: 230px;
  padding: 12px;
}

.filter-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  overflow: visible;
  width: 100%;
}

.filter-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
}

.filter-title {
  color: #475467;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}

.btn-style {
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #040C2D;
  margin: 12px;
  cursor: pointer;
}

.filter-txt {
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

</style>
