<template>
  <Transition name="fade">
    <div v-if="isVisible" class="modal-overlay">
      <div class="modal-content">
        <div class="modalheader">
          <h2>{{$t('add_new_position')}}</h2>
          <div @click="onClose" class="close-btn">
            <img class="cross" src="@/assets/images/cross.svg" />
          </div>
        </div>
        <div class="modalbody">
          <div class="input-holder">
            <span class="input-header">{{ $t('open_position') }}</span>
          <input :placeholder="this.$t('enter_new_position_name')" class="form-input" v-model="newPositionData.title">
          </div>
          <div class="input-holder">
            <span class="input-header">{{ $t('description') }}</span>
            <textarea :placeholder="this.$t('enter_position_description')" style="min-height: 100px" class="form-input" v-model="newPositionData.description"></textarea>
          </div>
          <div class="action-row">
            <button class="dark-button">
              <span @click="addPosition(newPositionData)">{{$t('add')}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {
  VueSpinnerIos
} from 'vue3-spinners'
import openPositionsService from "@/service/openPositionsService";

export default {
  components: {
    VueSpinnerIos
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      default: () => {}
    },
  },
  data(){
    return {
      newPositionData: {
        title: "",
        description: "",
      }
    }
  },
  methods:{
    addPosition(data){
      openPositionsService.addPosition(data);
    }
  }
}
</script>

<style scoped>
.input-header{
  font-family: 'euclid_regular', sans-serif;
  font-size: 16px;
  color: #565860;
}
.input-holder{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 30px;
  gap: 10px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:12px
}

.modalheader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.modalheader h2 {
  color: #040C2D;
  text-align: left;
  font-family: euclid_medium, sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
}

.close-btn {
  height: 24px;
  width: 24px;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cross{
  height: 10px !important;
  width: 10px !important;
}

.modalbody {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.modalbody p {
  color: #667085;
  text-align: center;
  align-items: center;
  font-family: euclid_regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 24px; /* 150% */
}


.modalbutton span {
  color: #FFF;
  text-align: center;
  font-family: euclid_regular, sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.modalbutton.cancel span {
  color: #667085;
  text-align: center;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
}

.action-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.form-input, .select-box {
  width: 100%;
}

</style>
