<template>
  <div v-if="visible" class="filter-modal">
    <div class="filter-div">
      <span class="filter-title">{{ $t("choose_startAt") }}</span>
      <div class="text-input">
        <input class="text-input-primary textinp" placeholder="Start Date (DD-MM-YYYY)" @input="handleStartAtDate" :value="startAt" type="date"/>
        <input class="text-input-primary textinp" placeholder="End Date (DD-MM-YYYY)" @input=" handleStartAtEndDate" :value="startAtEnd" type="date"/>
      </div>
      <span class="filter-title">{{ $t("choose_finishAt") }}</span>
      <div class="text-input">
        <input class="text-input-primary textinp" placeholder="Start Date (DD-MM-YYYY)" @input="handleFinishAtDate" :value="finishAt" type="date"/>
        <input class="text-input-primary textinp" placeholder="End Date (DD-MM-YYYY)" @input="handleFinishAtEndDate" :value="finishAtEnd" type="date"/>
      </div>
      <div class="btn-style" @click="applyFilters">
        <span class="filter-txt">{{$t('filter_therapy')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onFilter: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      selectedFilter:null,
      isCustom:false,
      startAt: "",
      startAtEnd: "",
      finishAt: "",
      finishAtEnd:"",
    };
  },
  methods: {
    applyFilters() {
      this.onFilter({
            startAt: this.startAt,
            startAtEnd: this.startAtEnd,
            finishAt:this.finishAt,
            finishAtEnd:this.finishAtEnd,
          }
      );
    },
    handleStartAtDate(event) {
      this.startAt = event.target.value;
    },
    handleStartAtEndDate(event) {
      this.startAtEnd = event.target.value;
    },
    handleFinishAtDate(event) {
      this.finishAt = event.target.value;
    },
    handleFinishAtEndDate(event) {
      this.finishAtEnd = event.target.value;
    },
  },
};
</script>

<style scoped>
.filter-modal {
  position: absolute;
  top: 40px;
  display: flex;
  left: 0;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08);
  min-width: 230px;
  padding: 12px;
}
.filter-div{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap:4px;
  overflow: visible;
  width: 100%;
}
.filter-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap:4px;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
}
.filter-title{
  color: #475467;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}
.btn-style{
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #040C2D;
  margin: 12px;
  cursor:pointer;
}
.filter-txt{
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.text-input{
  display: flex;
  padding: 12px;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  gap:12px
}
.textinp{
  height: 32px;
}
</style>
