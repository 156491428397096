<template>
  <OnboardingLayout
      :on-action="handleSave"
      :disabled="disabled"
      :on-copy-onboarding="onCopyAction"
      :onboardingData="onboardingData"
  >
    <div class="tab-panel-wrapper no-text-select">
      <div class="tab-panel-left"></div>

      <div class="tab-panel-right">
        <TranslationDropdown :selected-language="activeLanguage" :on-select="handleLanguageChange"/>
      </div>
    </div>

    <div class="onboarding-card">
      <div class="warning-wrapper" v-if="disabled">
        <div class="left">
          <img src="@/assets/images/warningWhite.svg"/>
        </div>

        <div class="right">
          <h2>{{ $t('view_only_mode') }}</h2>
          <span>
          {{ $t('view_only_mode_description') }}
        </span>
        </div>
      </div>

      <div class="card-wrapper">
        <div class="title-card-border"></div>
        <input class="title-input w-100" :placeholder="$t('add_your_onboarding')" v-model="onboardingData.name"/>
      </div>

      <div class="question-list">
        <OnboardQuestion
            v-for="(question,index) in onboardingData.questions"
            :data="question"
            :question="question"
            :onDelete="handleDelete"
            :onUpdate="handleQuestionUpdate"
            :onChangePosition="changeOrder"
            :onDuplicateQuestion="duplicateQuestion"
            :key="question.order + forceRerender"
            :index="index"
            :questionList="onboardingData.questions"
            :disabled="disabled"
        />
      </div>
      <div class="bottom-actions">
        <button class="add-question-button no-text-select" @click="addQuestion">
          <img src="@/assets/images/plusBlue.svg"/>
          <span>{{ $t('add_a_question') }}</span>
        </button>
      </div>
    </div>

    <LoadingOverlay :is-loading="loading"/>
  </OnboardingLayout>
</template>

<script>
import OnboardingLayout from "@/components/layouts/OnboardingLayout.vue";
import OnboardQuestion from "@/components/OnboardQuestion.vue";
import OnboardingService from "@/service/onboardingService";
import Switch from "@/components/Switch.vue";
import {handleErrorResponse} from "@/utils/utils";
import onboardingService from "@/service/onboardingService";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import TranslationDropdown from "@/components/TranslationDropdown.vue";


export default {
  name: 'OnboardingDetails',
  components: {TranslationDropdown, LoadingOverlay, OnboardQuestion, Switch, OnboardingLayout},
  data() {
    return {
      loading: true,
      activeLanguage: 'tr',
      onboardingData: {
        name: '',
        status: '1',
        questions: [{
          name: '',
          description: '',
          shortDescription: '',
          content: {
            type: 'single',
            options: [
              {
                id: 0,
                name: '',
              },
            ],
          },
        }]
      },
      disabled: false,
      forceRerender: 0
    }
  },
  mounted() {
    const routeParams = this.$route.params;

    if (routeParams.id !== 'new') {
      this.handleGetDetail();
    } else {
      this.loading = false;
    }

    if (!this.onboardingData.status) {
      this.disabled = true;
    }
  },
  methods: {
    handleGetDetail() {
      const routeParams = this.$route.params;

      this.loading = true;

      OnboardingService.getOnboardingDetail(routeParams.id, this.activeLanguage).then((response) => {
        this.onboardingData = response.data.data;
      }).catch((error) => {
        handleErrorResponse(error, this.$snackbar);
      }).finally(() => {
        this.loading = false;
      });
    },
    addQuestion() {
      this.onboardingData.questions.push({
        name: '',
        description: '',
        shortDescription: '',
        content: {
          type: 'single',
          options: [
            {
              id: 0,
              name: '',
            },
          ],
        },
      });
    },
    handleSave() {
      const routeParams = this.$route.params;

      if (routeParams.id === 'new') {
        OnboardingService.addOnboarding(this.onboardingData, this.activeLanguage)
            .then((response) => {
              if (response.data.responseType === 'success') {
                this.$snackbar.add({
                  text: response.data.message || 'Onboarding created successfully!',
                  type: 'success',
                });
                this.$router.push('/onboarding');
              } else {
                this.$snackbar.add({
                  text: response.data.message || 'An error occurred while creating the onboarding.',
                  type: 'error',
                });
              }
            })
            .catch((error) => {
              handleErrorResponse(error, this.$snackbar);
            });
      } else {
        OnboardingService.updateOnboarding(this.onboardingData, this.activeLanguage)
            .then((response) => {
              if (response.data.responseType === 'success') {
                this.$snackbar.add({
                  text: response.data.message || 'Onboarding updated successfully!',
                  type: 'success',
                });
                this.$router.push('/onboarding');
              } else {
                this.$snackbar.add({
                  text: response.data.message || 'An error occurred while updating the onboarding.',
                  type: 'error',
                });
              }
            })
            .catch((error) => {
              handleErrorResponse(error, this.$snackbar);
            });
      }
    },
    onCopyAction() {
      onboardingService.getCopy(this.$route.params.id)
          .then((response) => {
            this.$snackbar.add({
              text: "Onboarding copied successfully",
              type: "success",
            });
            this.$router.push("/onboarding/" + response.data.data.onboardingId);
          })
          .catch((error) => {
            handleErrorResponse(error, this.$snackbar);
          });
    },
    handleDelete(orderId) {
      const foundQuestion = this.onboardingData.questions.find(
          (question) => question.order === orderId
      );

      if (foundQuestion) {
        const index = this.onboardingData.questions.indexOf(foundQuestion);
        this.onboardingData.questions.splice(index, 1);
      }

      this.updateOrder();
    },
    updateOrder() {
      const updatedQuestions = [...this.onboardingData.questions];

      updatedQuestions.forEach((question, index) => {
        question.order = index + 1;
      });

      this.onboardingData.questions = updatedQuestions;
      this.lastOrder = this.onboardingData.questions.length;
      this.forceRerender++;
    },
    changeOrder(dir, orderId) {
      if (this.onboardingData.questions.length === 1) {
        return;
      }

      if (orderId === 1 && dir === 'up') {
        return;
      }

      if (orderId === this.onboardingData.questions.length && dir === 'down') {
        return;
      }

      const arr = this.onboardingData.questions;
      const index = arr.findIndex(item => item.order === orderId);

      if (dir === 'up' && index > 0) {
        // Swap the current item with the one above it
        [arr[index - 1], arr[index]] = [arr[index], arr[index - 1]];

        // Update order values
        const tempOrder = arr[index - 1].order;
        arr[index - 1].order = arr[index].order;
        arr[index].order = tempOrder;


        this.onboardingData.questions = [...arr];
      } else if (dir === 'down' && index < arr.length - 1) {
        // Swap the current item with the one below it
        [arr[index + 1], arr[index]] = [arr[index], arr[index + 1]];

        // Update order values
        const tempOrder = arr[index + 1].order;
        arr[index + 1].order = arr[index].order;
        arr[index].order = tempOrder;

        // Update the original array to trigger reactivity
        this.onboardingData.questions = [...arr];
      }

      this.forceRerender++;
    },
    handleQuestionUpdate(data) {
      const foundQuestion = this.onboardingData.questions.find(
          (question) => question.order === data.order
      );

      if (foundQuestion) {
        const index = this.onboardingData.questions.indexOf(foundQuestion);
        this.onboardingData.questions[index] = data;
      }
    },
    duplicateQuestion(orderId) {
      const foundQuestion = this.onboardingData.questions.find(
          (question) => question.order === orderId
      );

      if (foundQuestion) {
        const index = this.onboardingData.questions.indexOf(foundQuestion);
        const newQuestion = JSON.parse(JSON.stringify(foundQuestion));
        if (newQuestion.questionId) {
          delete newQuestion.questionId;
        }
        newQuestion.order = this.onboardingData.questions.length + 1;
        newQuestion.questionId = null;
        this.onboardingData.questions.splice(index + 1, 0, newQuestion);
      }

      this.updateOrder();
    },
    handleLanguageChange(lang) {
      const routeParams = this.$route.params;
      this.activeLanguage = lang;

      if (routeParams.id !== "new") {
        this.handleGetDetail();
      }
    },
  },
}
</script>

<style scoped>

.onboarding-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
}

.title-card-border {
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0;
  left: 0;
  background-color: #1C3CD5;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.title-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  background-color: transparent;
  color: #040C2D;
  margin-bottom: 15px;
}

.warning-wrapper {
  background-color: #F09460;
  border: 1px solid #df8958;
  padding: 15px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.warning-wrapper .left {
  margin-right: 15px;
}

.warning-wrapper .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.warning-wrapper .left img {
  width: 72px;
  height: 72px;
}

.warning-wrapper h2 {
  font-size: 16px;
  margin: 0;
  font-family: euclid_medium, sans-serif;
  color: #fff;
}

.warning-wrapper span {
  font-size: 12px;
  color: #EAECF0;
}

.add-question-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  color: #4A68F8;
  margin-top: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  width: fit-content;
  transition: background-color 0.2s ease-in-out;
}

.add-question-button:hover {
  background-color: #f3f4ff;
}

.question-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.tab-panel-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tab-panel-left {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.tab-panel-right {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
