<script setup>
import { Handle, Position } from '@vue-flow/core'
import {truncate} from "@/utils/utils";
import textOutline from '@/assets/images/textOutlineDark.svg';
import audioOutline from '@/assets/images/audioOutlineDark.svg';
import videoOutline from '@/assets/images/videoOutlineDark.svg';
import receipt2Outline from '@/assets/images/receipt2OutlineDark.svg';
import messageOutline from '@/assets/images/messageOutlineDark.svg';
import sheildOutline from '@/assets/images/sheildOutlineDark.svg';
import failedSheildOutline from '@/assets/images/failedSheildOutlineDark.svg';
import receiptOutline from '@/assets/images/receiptOutlineDark.svg';

import { useI18n } from "vue-i18n";

const props = defineProps(['id', 'data']);

const { t } = useI18n();

const getType = (type) => {
  if (type === 'welcomeText') {
    return t('welcome_text');
  } else if (type === 'audio') {
    return t('audio_type');
  } else  if (type === 'video') {
    return t('video_type');
  } else if (type === 'summary') {
    return t('summary_type');
  } else if (type === 'selfChat') {
    return t('self_chat_type');
  } else if (type === 'success') {
    return t('success_type');
  } else if (type === 'failed') {
    return t('failed_type');
  } else if (type === 'text') {
    return t('text_question');
  } else if (type === 'scenarioText') {
    return t('scenario_text');
  } else {
    return "Undefined type";
  }
};

const getIcon = (type) => {
  if (type === 'welcomeText') {
    return textOutline;
  } else if (type === 'audio') {
    return audioOutline;
  } else  if (type === 'video') {
    return videoOutline;
  } else if (type === 'summary') {
    return receipt2Outline;
  } else if (type === 'selfChat') {
    return messageOutline;
  } else if (type === 'success') {
    return sheildOutline;
  } else if (type === 'failed') {
    return failedSheildOutline;
  }  else if (type === 'text') {
    return receiptOutline;
  }else if (type === 'scenarioText') {
    return messageOutline;
  } else  {
    return "Undefined type";
  }
};
</script>

<template>
  <div class="question-node">
    <Handle id="input" type="target" :position="Position.Top"/>

    <div class="node-header">
      <img :src="getIcon(props.data.content?.type)" alt="icon"/>
      <span class="node-type">{{getType(props.data.content?.type)}}</span>
    </div>

    <div class="node-body">
      <div class="begin-text-line" />
      <span class="node-title">{{props.data?.name ? truncate(props.data?.name, 50) : props.data?.description ? truncate(props.data?.description, 50) : 'No name'}}</span>
    </div>

    <Handle id="output" type="source" :position="Position.Bottom"/>
  </div>
</template>

<style scoped>
.question-node {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 300px;
  min-width: 140px;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #f09461;
  background-color: #fff;
  border-radius: 6px;
}

.node-header {
  width: 100%;
  background-color: #f1f1f1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 10px;
}

.node-header img {
  width: 24px;
  height: 24px;
}

.node-type {
  font-size: 16px;
  color: #101828;
  font-family: 'euclid_regular', sans-serif;
}

.node-body {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
  align-items: center;
}

.begin-text-line {
  width: 3px;
  height: 20px;
  background-color: #f09461;
  border-radius: 6px;
}
</style>
