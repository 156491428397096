<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-step-1">
          <div class="modal-header">
            <span class="modal-header-txt">{{$t('blog_settings')}}</span>
            <span class="modal-header-description">{{$t('blog_settings_here')}}</span>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('published')}}
              </span>
              <span class="setting-text">
                {{$t('published_draft')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.status" />
            </div>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('blog_image')}}
              </span>
              <span class="setting-text">
                {{$t('add_image_blog')}}
              </span>
            </div>
            <div class="setting-right">
              <FileDrop :on-file-selected="handleBlogFile" :attach="settings.avatar" :selected-file="settings.avatarUrl" :on-delete="handleBlogFileDelete" />
            </div>
          </div>

          <div class="setting-wrapper" v-if="editMode">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('delete_the_blog')}}
              </span>
              <span class="setting-text">
                {{$t('permanent_blog_deletion')}}
              </span>
            </div>
            <div class="setting-right">
              <button :class="deleteSure ? 'delete-button sure' : 'delete-button'" @click="handleDelete">
                {{ deleteSure ? this.$t('are_you_sure') : this.$t('delete_button') }}
              </button>
            </div>
          </div>

          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import Switch from "@/components/Switch.vue";
import testService from "@/service/testService";
import FileDrop from "@/components/FileDrop.vue";
import blogService from "@/service/blogService";
import {handleErrorResponse} from "@/utils/utils";

export default {
  components: {
    FileDrop,
    Switch,
    TextInput,
    SelectBox
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    editMode: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      default: () => ({
        avatarUrl: null,
        avatar: null,
        avatarDelete: false,
        status: false,
      })
    }
  },
  data() {
    return {
      deleteSure: false,
      deleteTimer: null,
    };
  },
  methods: {
    handleBlogFile(file) {
      this.settings.avatarDelete = false;
      this.settings.avatar = file;
      this.settings.avatarUrl = null;
    },
    handleBlogFileDelete() {
      this.settings.avatarDelete = true;
      this.settings.avatar = null;
      this.settings.avatarUrl = null;
    },
    handleDelete() {
      if (!this.editMode || !this.settings.blogId) {
        return;
      }

      if (this.deleteSure) {
        clearTimeout(this.deleteTimer);

        blogService.deleteBlog(this.settings.blogId).then(() => {
          this.onHide();
          this.$snackbar.add({
            text: this.$t('blog_deleted'),
            type: 'warning'
          });

          this.$router.push('/blogs');
        }).catch((err) => {
          handleErrorResponse(err, this.$snackbar);
        });
      } else {
        this.deleteSure = true;

        this.deleteTimer = setTimeout(() => {
          this.deleteSure = false;
        }, 3000);
      }
    }
  },
  computed: {

  }

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  z-index: 99999;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
h5 {
  font-family: euclid_medium, sans-serif;
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.modal-header-description {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.setting-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.setting-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.setting-name {
  font-family: euclid_medium, sans-serif;
  color: #060B27;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
}

.setting-text {
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.delete-button {
  font-family: euclid_regular, sans-serif;
  color: #F44336;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  border: 1px solid #F44336;
  background: none;
  cursor: pointer;
  padding: 5px 20px;
  border-radius: 8px;
  transition: all 0.2s;
}

.delete-button:hover {
  background-color: #F44336;
  color: white;
}

.delete-button.sure {
  background-color: #F44336;
  color: white;
  border: 1px solid #F44336;
}

</style>
