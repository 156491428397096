<template>
  <div class="filter-view">
    <div class="flex-row">
      <UserFilters />
    </div>
    <div v-if="companyId!==null" class="invite-holder">
      <div class="invite-code" data-tooltip="Kullanıcıları bu kod ile davet edebilirsiniz" >
        {{inviteCode}}
        <img src="../../../assets/images/copyOutline.svg" @click="copyCode" class="invite-code-icon">
      </div>
      <img @click="changeCode" class="refresh-icon" src="../../../assets/images/refresh.svg">
    </div>
    <div class="justify-end-div" v-if="rightAction">
      <div class="justify-end-search">
        <ExpandableSearch :searchText="searchText" :on-change="handleSearch" />
      </div>
    </div>

  </div>

  <div class="table-container">
    <table class="content-table">
      <thead class="header-table">
      <tr class="trhead">
        <th><input type="checkbox" :checked="selectAll" @change="toggleAll"></th>
        <th>{{ $t('name_payment') }}</th>
        <th>{{ $t('status_payment_title') }} <img src="../../../assets/images/status.svg"></th>
        <th style="text-align: start">{{ $t('added_date') }} <img src="../../../assets/images/status.svg"></th>
        <th style="text-align: center;justify-content: start;display: flex">{{ $t('total_therapies_tab') }} <img src="../../../assets/images/status.svg"></th>
        <th>{{ $t('email_address') }}</th>
        <th>{{$t('phone_number_person') }}</th>
        <th>{{$t('user_department_name')}}</th>
      </tr>
      </thead>
      <tbody class="tbody-container">
      <tr class="tr-class" v-if="!loading" v-for="user in users" :key="user.userId">
        <td class="td-style"><input type="checkbox"></td>

        <td class="td-style">
          <router-link :to="'/users/detail/' + user.userId" class="name-row">
            <template v-if="user.avatarUrl">
              <img class="company-icon" :src="user.avatarUrl" />
            </template>
            <template v-else>
              <em class="no-data-text">{{$t('no_data')}}</em>
            </template>

            <template v-if="user.name || user.surname">
              <span class="test-class">{{ user.name }} {{ user.surname }}</span>
            </template>
            <template v-else>
              <em class="no-data-text">N/A</em>
            </template>
          </router-link>
        </td>

        <td class="td-style"> <span class="test-class">
          <img v-if="user.status=== true" src="../../../assets/images/active.svg">
          <img v-else src="../../../assets/images/failed.svg">
          <span v-if="user.status">
            {{ $t('active') }}
          </span>
          <span v-else>
            {{ $t('inactive') }}
          </span>
        </span></td>
        <td class="td-style">
          <span class="test-class">
            <img src="../../../assets/images/datepicker.svg">
            {{ user.addedDate }}
          </span>
        </td>
        <td style="text-align: center" class="td-style"> <span class="test-class"> <img src="../../../assets/images/therapies.svg">{{ $t('program_count', {programCount: user.therapiesCount}) }}</span></td>
        <td class="td-style"><span class="test-class">{{ user.email }}</span></td>
        <td class="td-style">
          <template v-if="user.phoneNumber">
            <span class="test-class">{{ user.phoneNumber }}</span>
          </template>
          <template v-else>
            <em class="no-data-text">N/A</em>
          </template>
        </td>
        <td class="td-style">
          <template v-if="user.department?.name">
            <span class="test-class">{{ user.department.name }}</span>
          </template>
          <template v-else>
            <em class="no-data-text">N/A</em>
          </template>
        </td>
      </tr>
      <tr v-else>
        <td colspan="7" class="table-loading">
          <VueSpinner size="16" height="100%" color="#8c8c8c" />
          <span class="text">Loading</span>
        </td>
      </tr>
      <tr v-if="!loading && users.length === 0 ">
        <td colspan="7" class="table-loading">
          <span class="text">No data found</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AddUserModal from '@/components/modals/InviteUserModal.vue';
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import {VueSpinner} from "vue3-spinners";
import UserFilters from "@/components/tables/User/UserFilters.vue";
import companyService from "@/service/companyService";

export default {
  components: {
    UserFilters,
    AddUserModal,
    ExpandableSearch,
    VueSpinner
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    addNewUser: {
      type: Function,
      default: () => {}
    },
    users: {
      type: Array,
      required: true
    },
    rightAction: {
      type: Boolean,
      default: true
    },
    companyId:{
      type:Number,
      default:null
    },
    code: {
      type:Number,
      default:null
    }
  },
  data() {
    return {
      inviteCode:null,
      showModal: false,
      selectAll:false,
      showFilter:false,
      searchText: '',

      showCompanyFieldFilter: false,
      showCompanyFieldDropdown: false,

      showTherapyNumberFilter:false,
      showTherapyNumberDropdown:false,
      therapyNumberFilterData: [{
        min: undefined,
        max: undefined,
      }],

      showUserAmountFilter:false,
      showUserAmountDropdown:false,
      userAmountFilterData: [{
        min: undefined,
        max: undefined,
      }],

      showUserPaymentFilter:false,
      showUserPaymentDropdown:false,
      userPaymentFilterData: [{
        status: undefined,
        min: undefined,
        max: undefined,
      }],

      showDateRangeFilter: false,
      showDateRangeDropdown: false,
      dateRangeFilterData: [{
        startDate: undefined,
        endDate: undefined,
      }],

      companyFields:  [
        {
          id:1,
          name:"Accounting",
          selected: false
        },
        {
          id:2,
          name:"Economics",
          selected: false
        },
        {
          id:3,
          name:"Finance",
          selected: false
        },
        {
          id:4,
          name:"Managment",
          selected: false
        }
      ],
      companies: [
        { id: 1, icon: "https://i.hizliresim.com/n8tzxgk.png", name: "Ellen Rose", status: "Active", addedDate: "Sep 12, 2023", totalTherapies: "10 Therapies", email: "user@ibility.com", phone: "0543 000 00 00", paymentStatus: "Paid" },
        { id: 2, icon: "https://i.hizliresim.com/np072iz.png", name: "Robert Fox", status: "Active", addedDate: "Sep 12, 2023", totalTherapies: "10 Therapies", email: "user@ibility.com", phone: "0543 000 00 00", paymentStatus: "Paid" },

      ],
    }
  },
created() {
    this.inviteCode=this.code;
},
  watch: {
    selectAll(newValue) {
      this.companies.forEach(company => company.selected = newValue);
    },
  },
  methods: {
    copyCode() {
      if(this.inviteCode) {
        navigator.clipboard
            .writeText(this.inviteCode)
            .then(() => {
              this.$snackbar.add({
                text:$t("create_invite_code_success"),
                type: "success"
              });
            })
      }
            if(this.inviteCode===null){
              this.$snackbar.add({
                text:$t("create_invite_code_error"),
                type:"error"
              });
            }

    },
    changeCode(){
      companyService.changeInviteCode(this.companyId).then(response => {
        this.inviteCode=response.data.data.inviteCode;
      });
    },
    handleFilterSelected(filterName) {
      this.closeFilter('main');
      if (filterName === 'company_field') {
        this.showCompanyFieldFilter = true;
        this.showCompanyFieldDropdown = true;
      } else if (filterName === 'therapy_number'){
        this.showTherapyNumberFilter = true;
        this.showTherapyNumberDropdown = true;
      }else if (filterName === 'user_amount'){
        this.showUserAmountFilter = true;
        this.showUserAmountDropdown = true;
      }else if (filterName === 'payment_volume'){
        this.showUserPaymentFilter = true;
        this.showUserPaymentDropdown = true;
      }else if (filterName === 'date_range'){
        this.showDateRangeFilter = true;
        this.showDateRangeDropdown = true;
      }
    },
    handleCompanyFieldFilter(itemsTemp) {
      this.companyFields = itemsTemp;
      this.showCompanyFieldDropdown = false;
    },
    handleTherapyNumberFilter(filter){
      this.therapyNumberFilterData.max = filter.max;
      this.therapyNumberFilterData.min = filter.min;
      this.showTherapyNumberDropdown = false;
    },
    handleUserAmountFilter(filter){
      this.userAmountFilterData.max = filter.max;
      this.userAmountFilterData.min = filter.min;
      this.showUserAmountDropdown = false;
    },
    handleUserPaymentFilter(filter){
      console.log(filter);
      this.userPaymentFilterData.max = filter.max;
      this.userPaymentFilterData.min = filter.min;
      this.userPaymentFilterData.status = filter.status;
      this.showUserPaymentDropdown = false;
    },

    handleApplyDateFilters(filters){
      this.dateRangeFilterData.startDate = filters.startDate;
      this.dateRangeFilterData.endDate = filters.endDate;
      this.showDateRangeDropdown = false;
    },

    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    closeFilter(filter) {
      if (filter === 'main' && this.showFilter === true) {
        this.showFilter = false;
      } else if (filter === 'company_field' && this.showCompanyFieldFilter === true) {
        this.showCompanyFieldDropdown = false;
      } else if (filter === 'therapy_number' && this.showTherapyNumberDropdown === true) {
        this.showTherapyNumberDropdown = false;
      } else if (filter === 'user_amount' && this.showUserAmountDropdown === true) {
        this.showUserAmountDropdown = false;
      } else if (filter === 'payment_volume' && this.showUserPaymentDropdown === true) {
        this.showUserPaymentDropdown = false;
      } else if (filter === 'date_range' && this.showDateRangeDropdown === true) {
        this.showDateRangeDropdown = false;
      }
    },
    removeFilter(filter) {
      if (filter === 'company_field') {
        this.showCompanyFieldFilter = false;
        this.companyFields.map(item => item.selected = false);
      } else if (filter === 'therapy_number') {
        this.showTherapyNumberFilter = false;
        this.therapyNumberFilterData = [{
          min: undefined,
          max: undefined,
        }];
      } else if (filter === 'user_amount') {
        this.showUserAmountFilter = false;
        this.userAmountFilterData = [{
          min: undefined,
          max: undefined,
        }];
      } else if (filter === 'payment_volume') {
        this.showUserPaymentFilter = false;
        this.userPaymentFilterData = [{
          status: undefined,
          min: undefined,
          max: undefined,
        }];
      } else if (filter === 'date_range') {
        this.showDateRangeFilter = false;
        this.dateRangeFilterData = [{
          start: undefined,
          end: undefined,
        }];
      }
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
      this.companies.forEach(company => {
        this.$set(company, 'selected', this.selectAll);
      });
    },
    toggleCompanyFieldDropdown() {
      this.showCompanyFieldDropdown = !this.showCompanyFieldDropdown;
    },
    toggleTherapyNumberFilter() {
      this.showTherapyNumberDropdown = !this.showTherapyNumberDropdown;
    },
    toggleUserAmountFilter() {
      this.showUserAmountDropdown = !this.showUserAmountDropdown;
    },
    toggleUserPaymentFilter(){
      this.showUserPaymentDropdown = !this.showUserPaymentDropdown
    },
    toggleDateRangeFilter() {
      this.showDateRangeDropdown = !this.showDateRangeDropdown;
    },
    isUserSelected(user) {
      return user.selected;
    },
    toggleUserSelection(user) {
      this.$set(user, 'selected', !user.selected);

      this.selectAll = !!this.user.every(c => c.selected);
    },
    hideModal() {
      this.showModal = false;
    },
    handleSearch(event) {
      this.searchText = event.target.value;
    }
  },
  computed: {

  }

}
</script>

<style>
.invite-code-icon{
  align-self: center;
  height: 15px;
  justify-content: center;
}
.refresh-icon{
 height: 20px;
}
.invite-code{
  border: 1px solid #EAECF0;
  background-color: #FCFCFD;
  border-radius: 15px;
  padding: 10px;
  color: #000000;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.invite-code::after {
  content: attr(data-tooltip); /* Açıklama metni data-tooltip özelliğinden alınır */
  position: absolute;
  top: -35px; /* Kutunun span'ın üstünde görünmesi için */
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 10;
  white-space: normal;
  width: 150px;
  font-size: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Daha modern bir görünüm için gölge ekle */
  background-color: #040C2D;

}
.invite-code:hover::after {
  opacity: 1;
  visibility: visible;
}
.invite-holder{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
.table-container {
  width: 100%;
  height: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: left;
  padding: 8px;
}
.trhead{
  background-color: transparent;
}
.tr-class{
  border-bottom-width: 1px;
  border-bottom-color: #F2F4F7;
}

tr td {
  padding-bottom: 12px;
  padding-top: 12px;
  align-items: center;
}
.table-container {
  width: 100%;
}
tr th{
  align-items: center;
  font-size: 12px;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.header-table,
.content-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.header-table {
  background-color: #F9FAFB;
  border-radius: 8px;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #F2F4F7;
}

.tbody-container {
  overflow-y: auto;
}
.tr-class td tr {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.test-class{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
}
.flex-row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
}
.row-item{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}

.row-item.filter {
  background-color: transparent;
}

.row-item-txt{
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}

.filter-view{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  min-height: 52px;
}
.justify-end-div{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.justify-end-search{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
}

.company-icon {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.show-filters{
  font-family: euclid_medium, sans-serif;
  color: #4A68F8;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
}

.row-item-icon {
  height: 16px;
  width: 16px;
}

.name-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.name-row span {
  font-family: euclid_medium, sans-serif;
  color: #000;
}

.name-row:hover.name-row span{
  color: #4A68F8;
}
.td-style {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.name-row {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.company-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
}

.test-class {
  font-weight: bold;
  color: #333;
}

.no-data-text {
  font-style: italic;
  color: #B6C1DB;
  font-size: 12px;
  font-weight: bold;
}
</style>
