<script setup>
import { useRoute } from 'vue-router';

const props = defineProps({
  onAction: {
    type: Function,
    default: () => {},
  },
  onSendNow: {
    type: Function,
    default: () => {},
  },
  onSettingsAction: {
    type: Function,
    default: () => {},
  },
  onListAction:{
    type: Function,
    default: () => {},
  },
  isLoading:{
    type: Boolean,
    default: true
  },
  isTemplate:{
    type: Boolean
  }
});

// URL'deki route bilgilerine erişim
const route = useRoute();

// Journey ID kontrolü
const hasJourneyId = route.query.journeyId !== undefined && route.query.journeyId !== null && route.query.journeyId !== '';
</script>

<template>
  <div id="app" class="app-container">
    <div class="header">
      <div class="left-action">
        <router-link :to="hasJourneyId ? '/journeys/' + route.query.journeyId : '/notifications'">
          <div class="go-back">
            <img src="@/assets/images/arrowLeft.svg" />
            <span>{{$t('go_back_button')}}</span>
          </div>
        </router-link>
      </div>
      <div class="right-actions">
        <img src="@/assets/images/settingsOutline.svg" @click="onSettingsAction()" />
        <img src="@/assets/images/usersOutline.svg" @click="onListAction()" />
        <span class="action-link secondary" @click="onSendNow()" v-if="!isLoading">
          {{(isTemplate === false && route.params?.id !== 'new') ? $t('send_again') : $t('send')}}
        </span>
        <free-style-shimmer
            :is-loading="isLoading"
            height="18px"
            width="30px"
            border-radius="50px"
            color="#EAECF0"
        />
        <span
            class="action-link"
            v-if="!isLoading && (!hasJourneyId && (route.params?.id === 'new' || isTemplate === true))"
            @click="onAction()"
        >
          {{$t('save_as_template')}}
        </span>
        <free-style-shimmer
            :is-loading="isLoading"
            height="18px"
            width="100px"
            border-radius="50px"
            color="#EAECF0"
        />
      </div>
    </div>



    <div class="container">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.app-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: #FCFCFD;
  overflow-y: auto;
}

.header {
  position: fixed;
  width: 100%;
  background-color: #FFFFFF;
  border-bottom: 1px solid #EAECF0;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 9999;
}

.container {
  padding-top: 86px;
  padding-bottom: 30px;
  margin: 0 auto;
  max-width: 960px;
}

.go-back {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 12px;
  font-family: euclid_medium, sans-serif;
}

.right-actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.right-actions span {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
}

.left-action a {
  color: #040C2D;
}

.left-action a:active {
  color: #040C2D;
}

.left-action a:visited {
  color: #040C2D;
}

.left-action a:hover {
  color: #040C2D;
}

.action-link {
  cursor: pointer;
  color: #1C3CD5;
}

.action-link.secondary {
  color: #98A2B3;
}
</style>
