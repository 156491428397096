<script setup>
import { Handle, Position } from '@vue-flow/core'
import NodeDropdown from "@/components/condition-flow/NodeDropdown.vue";
import {onMounted, ref, watch} from "vue";
import {truncate} from "@/utils/utils";
import greaterThanIcon from '@/assets/images/greaterThan.svg';
import equalIcon from '@/assets/images/equal.svg';
import lessThanIcon from '@/assets/images/lessThan.svg';
import lessThanEqualIcon from '@/assets/images/lessThanEqual.svg';
import greaterThanEqualIcon from '@/assets/images/greaterThanEqual.svg';

const props = defineProps(['id', 'data', 'onSelect', 'editDisabled']);

const selectedCondition = ref(null);
const selectedScore = ref(null);

const conditionOptions = [
  { value: '=', text: 'Equals to', icon: equalIcon },
  { value: '<', text: 'Less than', icon: lessThanIcon },
  { value: '>', text: 'Greater than', icon: greaterThanIcon },
  { value: '<=', text: 'Less than or equal to', icon: lessThanEqualIcon },
  { value: '>=', text: 'Greater than or equal to', icon: greaterThanEqualIcon }
];

const getType = (type) => {
  if (type === 'multiple') {
    return "Multiple";
  } else if (type === 'single') {
    return "Single";
  } else  if (type === 'yesNo') {
    return "Yes / No";
  } else if (type === 'single') {
    return "Single";
  } else if (type === 'number') {
    return "Number";
  } else if (type === 'text') {
    return "Text";
  } else if (type === 'grading') {
    return "Grading";
  }  else if (type === 'rating') {
    return "Rating"
  } else if (type === 'dragAndDrop') {
    return "Drag & Drop";
  } else {
    return "Undefined type";
  }
};

const handleConditionSelect = (value) => {
  selectedCondition.value = value;
}


const handleScoreSelect = (event) => {
  const option = event.target.value;
  if (option === '-1') return;

  selectedScore.value = option;
}

onMounted(async () => {
  if (props.data) {
    selectedCondition.value = props.data.condition;
    selectedScore.value = props.data.score;
  }
});

watch(
    [selectedCondition, selectedScore],
    ([newCondition, newScore], [oldCondition, oldScore]) => {
      if (newCondition === oldCondition && newScore === oldScore) return;

      const questionData = {
        ...props.data,
        condition: newCondition,
        score: newScore
      }

      props.onSelect(questionData, props.id);
    }
);
</script>

<template>
  <div class="question-node">
    <Handle id="input" type="target" :position="Position.Top"/>

    <div class="node-header">
      <img src="../../../assets/images/calculatorOutlineDark.svg" alt="icon"/>
      <span class="node-type">{{$t('number_question')}}</span>
    </div>

    <div class="node-body">
      <div class="node-body-title">
        <div class="begin-text-line"></div>
        <span class="question-type">{{ getType(props.data.content?.type) }}</span>
      </div>

      <span class="question-name">{{props.data?.name ? truncate(props.data?.name, 50) : 'No name'}}</span>
    </div>

    <div class="select-row">
      <div class="select-row-input">
        <NodeDropdown :show-value-as-selected="true" :options="conditionOptions" :on-select="handleConditionSelect" :selected-value="selectedCondition" :disabled="editDisabled"/>
      </div>

      <div class="select-row-input">
        <input class="node-text-input" type="number" @change="handleScoreSelect"  :placeholder="$t('enter_score')" :value="selectedScore" :disabled="editDisabled"/>
      </div>
    </div>

    <Handle id="output" type="source" :position="Position.Bottom" class="handle-true"/>
    <Handle id="false" type="source" :position="Position.Bottom" class="handle-false"/>
  </div>
</template>

<style scoped>
.question-node {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 460px;
  min-width: 320px;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #4A68F8;
  background-color: #fff;
  border-radius: 6px;
}

.node-header {
  width: 100%;
  background-color: #f1f1f1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 10px;
}

.node-header img {
  width: 24px;
  height: 24px;
}

.node-type {
  font-size: 16px;
  color: #101828;
  font-family: 'euclid_regular', sans-serif;
}

.node-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.node-body-title {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.begin-text-line {
  width: 3px;
  height: 20px;
  background-color: #4A68F8;
  border-radius: 6px;
}

.question-name {
  font-size: 14px;
  color: #101828;
  font-family: 'euclid_regular', sans-serif;
}

.question-type {
  font-size: 14px;
  color: #667085;
  font-family: 'euclid_regular', sans-serif;
}

.handle-true {
  position: absolute;
  bottom: -6px;
  left: calc(50% - 10px) ;
  transform: translateX(-50%);
  background-color: #00D68F;
}

.handle-false {
  position: absolute;
  bottom: -6px;
  right: calc(50% - 10px) ;
  transform: translateX(50%);
  background-color: #F44336 !important;
}

.select-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
}

.select-row-input {
  width: 50%;
}
</style>
