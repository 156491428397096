<template>
  <div v-if="visible" class="filter-modal">
    <div class="filter-div">
      <endless-scroll :type="'therapy'" :is-loading="isLoading" :placeholder="$t('select_a_therapy')" :on-scroll-end="loadMore" class="selectBox" :on-action="handleModule"  :options="therapyData"  ></endless-scroll>
      <endless-scroll v-if="selectedTherapy" :type="'module'" :is-loading="isLoading" :on-scroll-end="loadMore" class="selectBox" :on-action="handleSelect"  :options="moduleData" :placeholder="$t('select_a_module')"></endless-scroll>
      <div class="btn-style" @click="applyFilters">
        <span class="filter-txt">{{$t('filter_therapy')}}</span>
      </div>
    </div>
  </div>
</template>



<script>
import therapyService from "@/service/therapyService";
import EndlessScroll from "@/components/EndlessScroll.vue";
import {handleErrorResponse} from "@/utils/utils";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onFilter: {
      type: Function,
      default: () => {}
    },
  },
  components: {
    EndlessScroll,
  },
  data(){
    return{
      selectedFilter:null,
      selectedTherapy:null,
      selectedModule:null,
      selectedTherapyName:null,
      selectedModuleName:null,
      therapyData: [],
      moduleData:[],
      currentPage: 1,
      lastPage:false,
      isLoading: false,
    }
  },
  created() {
    this.handlePage(1);
  },
  watch: {
    $route() {
      this.handlePage(1);
    }
  },
  methods:{
    handleSelect(id, name){
      this.selectedModule=id;
      this.selectedModuleName=name;
      console.log(this.selectedModule);
    },

    loadMore() {
      if (!this.lastPage && !this.isLoading) {
        console.log("load more 1")
        this.currentPage++;
        this.handlePage(this.currentPage);
      }
    },
    handlePage(page) {
      if (this.isLoading) return;

      console.log("load more 2")
      this.isLoading = true;
      this.currentPage = page;

      const routeQuery = this.$route.query;
      const tempFilters = [];

      if (routeQuery.searchText) {
        tempFilters.push({
          key: 'searchText',
          value: routeQuery.searchText
        });
      }
      therapyService.getTherapies(page, tempFilters).then(res => {
        this.therapyData = [
            ...this.therapyData,
            ...res.data.data.data
        ];
        if (res.data.data.last_page === this.currentPage) {
          this.lastPage = true;
        }
        this.isLoading = false;
      }).catch(err => {
        handleErrorResponse(err, this.$snackbar)
      });
    },
    handleModule(id, name){
      this.selectedTherapy=id;
      this.selectedTherapyName=name;
      therapyService.getTherapyModules(id).then(res => {
        console.log(res.data.data);
        this.moduleData = res.data.data;
        if (res.data.data.last_page === this.currentPage) {
          this.lastPage = true;
        }
        this.isLoading = false;
      }).catch(err => {
        handleErrorResponse(err, this.$snackbar)
      });


    },
    applyFilters() {
      this.onFilter({
            therapyName:this.selectedTherapyName,
            therapyModuleName:this.selectedModuleName,
            therapyId: this.selectedTherapy,
            therapyModuleId:this.selectedModule,
          }
      );
    },
  }
}
</script>
<style scoped>
.filter-txt {
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.selectBox{
  width: 100%;
}

.filter-modal {
  position: absolute;
  top: 40px;
  display: flex;
  left: 0;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08);
  min-width: 230px;
  padding: 12px;
}

.filter-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  overflow: visible;
  width: 100%;
}

.btn-style {
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #040C2D;
  margin: 12px;
  cursor: pointer;
}
</style>
