<template>
  <div class="file-card" :class="selected && 'selected'" @click="onClick" v-if="!loading">
    <div class="file-card-header">
      <div class="status-tag" :class="file.fileStatus">
        <div class="status-dot" :class="file.fileStatus"></div>
        <span v-if="file.fileStatus === 'approved'">{{$t('approved')}}</span>
        <span v-else-if="file.fileStatus === 'rejected'">{{$t('rejected')}}</span>
        <span v-else-if="file.fileStatus === 'pending'">{{$t('pending')}}</span>
      </div>

      <img class="file-cover" :src="file.fileCover"/>
    </div>

    <div class="file-card-body">
      <div class="file-card-info">
        <div class="file-card-info-item">
          <div class="file-card-info-title" :aria-label="file.fileName">{{truncateMiddle(file.fileName, 24)}}</div>
          <div class="file-card-info-details">{{ formatBytes(file.fileSize) }} - 09.04.2024</div>
          <div class="file-card-info-avatar" v-if="user.avatarUrl && user.name">
            <img :src="user.avatarUrl" class="avatar" />
            <div class="file-card-info-username">{{ user.name }}</div>
          </div>

        </div>
        <div class="file-card-info-item">
          <a :href="file.fileAttachment" target="_blank">
            <button class="icon-button">
              <img src="@/assets/images/arrow-down.svg" />
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>

  <free-style-shimmer
      v-else
      class="file-card"
      :is-loading="true"
      color="#EAECF0"
      height="200px"
      width="100%"
  />
</template>

<script>
import {formatBytes, truncateMiddle} from "@/utils/utils";

export default {
  methods: {truncateMiddle, formatBytes},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    file: {
      type: Object,
      default:  {
        fileStatus: 'pending',
        fileCover: '',
        fileAttachment: '',
        fileName: 'File Name',
        fileSize: '10MB',
        fileType: 'PDF'
      }
    },
    user: {
      type: Object,
      default: {
        avatarUrl: '',
        name: '',
        program: '',
      },
      required: false,
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    selected: {
      type: Boolean,
      default: false
    }

  }
}
</script>

<style scoped>
.file-card {
  border: 1px solid #e0e0e0;
  border-radius: 9px;
  width: 100%;
  cursor: pointer;
}

.file-card.selected {
  border: 1px solid #007AFF;
  box-shadow: 0 0 10px rgba(0, 122, 255, 0.2);
}


.file-card-header {
  position: relative;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 16px;
  font-weight: 500;
  background-color: #F5F5F5;
  border-radius: 9px 9px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}

.file-card-header img {
  width: 48px;
  height: 48px;
}

.file-card-body {
  padding: 10px;

}

.file-card-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.file-card-info-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.file-card-info-title {
  font-size: 14px;
  font-family: euclid_medium, sans-serif;
  color: #101828;
}

.file-card-info-details {
  font-size: 12px;
  color: #6B7C93;
}

.file-card-info-avatar {
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.file-card-info-username {
  font-size: 12px;
  color: #6B7C93;
}

.status-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040c2d;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.status-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.status-tag span {
  font-size: 12px;
}

.header-loading {
  width: 100%;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: 160px;
  object-fit: cover;
}
.status-tag.pending {
  background-color: #fae3c6;
}

.status-tag.approved {
  background-color: #ddfbde;
}

.status-tag.rejected {
  background-color: #fbdddd;
}

.status-dot.pending {
  background-color: #ffa123;
}

.status-dot.approved {
  background-color: #3da33e;
}

.status-dot.rejected {
  background-color: #ed4343;
}

.file-cover {
  object-fit: cover;
  border-radius: 18px;
}


</style>
