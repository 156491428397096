import axios from '../axios';

const companyService = {
    getCompanies(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/company/store?limit=20&page=${page}${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/company/store?limit=20&page=${page}`);
        }
    },
    addCompany(data) {
        return axios.post('/v1/admin/company/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    getCompanyDetails(id) {
        return axios.get(`/v1/admin/company/getFromDetail/${id}`);
    },
    updateCompany(data) {
        return axios.post('/v1/admin/company/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteCompany(id) {
        return axios.delete(`/v1/admin/company/delete/${id}`);
    },
    changeInviteCode(id){
        return axios.post(`/v1/admin/company/refreshInviteCode/${id}`);
    }
}

export default companyService;
