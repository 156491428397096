<template>
  <div class="question-holder">
    <div class="content-header">
      <span class="module-header">{{ submodule.name }}</span>
      <span class="welcome-text">{{ $t("fill_blank_type") }}</span>
      <div class="progress-container">
        <div class="progress-bar"></div>
      </div>
    </div>
    <div class="number-header">{{ selectedModule.name }}</div>
    <div class="blank-question">
      <span class="text-display">
        <span class="each-word" v-for="(word, index) in words" :key="index">
          <span v-if="selectedModule.content.blankOutWords.includes(index.toString())" class="placeholder-box">_____</span>
          <span v-else>{{ word }}</span>
          <span> </span>
        </span>
      </span>
      <div class="word-options">
        <div
            v-for="(word, index) in wordOptions"
            :key="index"
            class="word-box"
        >
          {{ word }}
        </div>
      </div>
    </div>
    <div class="button-holder">
      <span class="back-button">{{ $t("go_back_button") }}</span>
      <span @click="goForward" class="button">{{ $t("next_question") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    submodule: {
      type: Object,
    },
    selectedModule: {
      type: Object,
    },
    goForward: {
      type: Function,
    },
  },
  computed: {
    words() {
      return this.selectedModule.name ? this.selectedModule.name.split(" ") : [];
    },
    wordOptions() {
      const blankedWords = this.selectedModule.content.blankOutWords.map((index) => this.words[index]);
      return [...new Set([...blankedWords, ...this.selectedModule.content.otherWords])];
    },
  },
};
</script>

<style scoped>
.back-button {
  padding: 5px 10px 5px 10px;
  color: black;
  border-radius: 7px;
  border: 1px black solid;
  cursor: pointer;
}
.button {
  text-align: center;
  background-color: #1c3cd5;
  border-radius: 7px;
  padding: 5px 10px 5px 10px;
  color: white;
  cursor: pointer;
}
.button-holder {
  justify-self: end;
  font-size: 11px;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 2px #f5f5f5 solid;
  width: 100%;
  padding: 10px;
  padding-top: 7px;
  padding-bottom: 4px;
}
.each-word {
  margin-right: 3px;
  font-family: 'euclid_regular', sans-serif;
  color: #040C2D;
}
.text-display {
  margin-top: 5px;
  align-self: start;
  font-family: sans-serif;
  font-size: 10px;
  line-height: 1.5;
}

.placeholder-box {
  color: #040C2D;
  display: inline-block;
  background-color: #f0f0f0;
  border: 1px solid #d0d0d0;
  border-radius: 6px;
  padding: 3px 3px;
  font-family: 'euclid_semi_bold', sans-serif;
  letter-spacing: 2px;
}

.word-options {
  align-self: start;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 16px;
}

.word-box {
  background-color: #ebedfa;
  border-radius: 4px;
  padding: 4px 7px;
  font-family: 'euclid_regular', sans-serif;
  font-size: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.word-box:hover {
  background-color: #d8dae8;
}
.blank-question {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-self: center;
  align-content: center;
  align-items: center;
}
.number-header {
  font-family: 'euclid_semi_bold', sans-serif;
  color: #040C2D;
  font-size: 0.75em;
  margin-top: 10px;
  margin-left: 10px;
}
.progress-bar {
  height: 100%;
  background-color: #4a68f8;
  width: 20%;
  transition: width 0.3s ease-in-out;
}
.progress-container {
  display: flex;
  width: 100%;
  height: 3.5px;
  background-color: #ececec;
  align-self: center;
  margin-top: 10px;
}
.welcome-text {
  text-align: center;
  font-family: 'euclid_regular', sans-serif;
  font-size: 0.5em;
  color: #9194a3;
}
.module-header {
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 0.8em;
  color: #040c2d;
  text-align: center;
}
.content-header {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.question-holder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.question-holder::-webkit-scrollbar {
  width: 0px;
}
</style>
