import CheckGreen from "@/assets/images/checkGreen.svg";
import EyeGreen from "@/assets/images/eyeGreen.svg";
import HourglassGreen from "@/assets/images/hourglassGreen.svg";
import StarGreen from "@/assets/images/starGreen.svg";
import EmojiGreen from "@/assets/images/emojiGreen.svg";
import EmailBlue from "@/assets/images/emailBlue.svg";
import BellBlue from "@/assets/images/bellBlue.svg";
import UserIcon from "@/assets/images/userYellow.svg";
import ElseYellow from "@/assets/images/elseYellow.svg";
import ProgramYellow from "@/assets/images/programYellow.svg";
import ProgramBlue from "@/assets/images/programBlue.svg";
import TestYellow from "@/assets/images/testYellow.svg";
import TestBlue from "@/assets/images/testBlue.svg";
import VisualTestYellow from "@/assets/images/visualTestYellow.svg";
import VisualTestBlue from "@/assets/images/visualTestBlue.svg";

export const WHEN_COMPONENTS = {
    program: [
        {
            group: 'program',
            type: 'programCompleted',
            name: 'Program completed',
            description: 'An trigger when a user completes a program',
            icon: CheckGreen,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This trigger will be fired when a user completes a program',
                },
                {
                    name: 'Program',
                    type: 'programSelect',
                    required: true,
                    description: 'Select a program',
                    variableName: 'programId'
                }
            ]
        },
        {
            group: 'program',
            type: 'programViewed',
            name: 'Program viewed',
            description: 'An trigger when a user views a program',
            icon: EyeGreen,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This trigger will be fired when a user views a program',
                },
                {
                    name: 'Program',
                    type: 'programSelect',
                    required: true,
                    description: 'Select a program',
                    variableName: 'programId'
                }
            ]
        },
        {
            group: 'program',
            type: 'programPending',
            name: 'Program pending',
            description: 'An trigger when a user is at pending state of an program',
            icon: HourglassGreen,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This trigger will be fired when a user is at pending state of an program',
                },
                {
                    name: 'Program',
                    type: 'programSelect',
                    required: true,
                    description: 'Select a program',
                    variableName: 'programId'
                },
                {
                    name: 'Time',
                    type: 'numberInput',
                    required: true,
                    description: 'Time that user passed in pending state (hours)',
                    variableName: 'time'
                }
            ]
        },
        {
            group: 'program',
            type: 'reviewAdded',
            name: 'Review added',
            description: 'An trigger when a user adds a review',
            icon: StarGreen,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This trigger will be fired when a user adds a review',
                },
                {
                    name: 'Program',
                    type: 'programSelect',
                    required: true,
                    description: 'Select a program',
                    variableName: 'programId'
                },
                {
                    name: 'Stars (optional)',
                    type: 'starSelect',
                    required: true,
                    description: 'Select a number of stars',
                    variableName: 'stars'
                }
            ]
        }
    ],
        test: [
        {
            group: 'test',
            type: 'testCompleted',
            name: 'Test completed',
            description: 'An trigger when a user completes a test',
            icon: CheckGreen,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This trigger will be fired when a user completes a test',
                },
                {
                    name: 'Test',
                    type: 'testSelect',
                    required: true,
                    description: 'Select a test',
                    variableName: 'testId'
                }
            ]
        },
        {
            group: 'test',
            type: 'testPending',
            name: 'Test pending',
            description: 'An trigger when a user is at pending state of an test',
            icon: HourglassGreen,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This trigger will be fired when a user is at pending state of an test',
                },
                {
                    name: 'Test',
                    type: 'testSelect',
                    required: true,
                    description: 'Select a test',
                    variableName: 'testId'
                },
                {
                    name: 'Time',
                    type: 'numberInput',
                    required: true,
                    description: 'Time that user passed in pending state (hours)',
                    variableName: 'time'
                }
            ]
        }
    ],
        visualTest: [
        {
            group: 'visualTest',
            type: 'visualTestCompleted',
            name: 'Visual test completed',
            description: 'An trigger when a user completes a visual test',
            icon: CheckGreen,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This trigger will be fired when a user completes a visual test',
                },
                {
                    name: 'Visual test',
                    type: 'visualTestSelect',
                    required: true,
                    description: 'Select a visual test',
                    variableName: 'visualTestId'
                }
            ]
        },
        {
            group: 'visualTest',
            type: 'visualTestPending',
            name: 'Visual test pending',
            description: 'An trigger when a user is at pending state of an visual test',
            icon: HourglassGreen,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This trigger will be fired when a user is at pending state of an visual test',
                },
                {
                    name: 'Visual test',
                    type: 'visualTestSelect',
                    required: true,
                    description: 'Select a visual test',
                    variableName: 'visualTestId'
                },
                {
                    name: 'Time',
                    type: 'numberInput',
                    required: true,
                    description: 'Time that user passed in pending state (hours)',
                    variableName: 'time'
                }
            ]
        }
    ],
        mood: [
        {
            group: 'mood',
            type: 'moodSelected',
            name: 'Mood selected',
            description: 'An trigger when a user selects a mood',
            icon: EmojiGreen,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This trigger will be fired when a user selects a mood',
                },
                {
                    name: 'Mood',
                    type: 'emojiSelect',
                    required: true,
                    description: 'Select a mood',
                    variableName: 'moodIndex'
                },
                {
                    name: 'Count',
                    type: 'numberInput',
                    required: false,
                    description: 'Select a number of mood selected in total',
                    variableName: 'count'
                }
            ]
        }
    ]
};

export const THEN_COMPONENTS = {
    communication: [
        {
            group: 'communication',
            type: 'sendEmail',
            name: 'Send email',
            description: 'An action to send an email',
            icon: EmailBlue,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This action will send an email',
                },
                {
                    name: 'To',
                    type: 'userGroupSelect',
                    required: true,
                    description: 'Select a user group to send notification to',
                    variableName: 'userGroup'
                },
                {
                    name: 'Subject',
                    type: 'textInput',
                    required: true,
                    description: 'Enter a subject',
                    variableName: 'subject'
                },
                {
                    name: 'Body',
                    type: 'textArea',
                    required: true,
                    description: 'Enter a body',
                    variableName: 'body'
                }
            ]
        },
        {
            group: 'communication',
            type: 'sendNotification',
            name: 'Send notification',
            description: 'An action to send a mobile notification',
            icon: BellBlue,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This action will send a mobile notification',
                },
                {
                    name: 'To',
                    type: 'userGroupSelect',
                    required: true,
                    description: 'Select a user group to send notification to',
                    variableName: 'userGroup'
                },
                {
                    name: 'Subject',
                    type: 'textInput',
                    required: true,
                    description: 'Enter a subject',
                    variableName: 'subject'
                },
                {
                    name: 'Body',
                    type: 'textArea',
                    required: true,
                    description: 'Enter a body',
                    variableName: 'body'
                }
            ]
        },
    ],
    assign: [
        {
            group: 'assign',
            type: 'assignProgram',
            name: 'Assign a program',
            description: 'An action to assign a program',
            icon: ProgramBlue,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This action will assign a program',
                },
                {
                    name: 'Program',
                    type: 'programSelect',
                    required: true,
                    description: 'Select a program to assign',
                    variableName: 'programId'
                },
            ]
        },
        {
            group: 'assign',
            type: 'assignTest',
            name: 'Assign a test',
            description: 'An action to assign a test',
            icon: TestBlue,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This action will assign a test',
                },
                {
                    name: 'Test',
                    type: 'testSelect',
                    required: true,
                    description: 'Select a test to assign',
                    variableName: 'testId'
                },
            ]
        },
        {
            group: 'assign',
            type: 'assignVisualTest',
            name: 'Assign a visual test',
            description: 'An action to assign a visual test',
            icon: VisualTestBlue,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'This action will assign a visual test',
                },
                {
                    name: 'Visual Test',
                    type: 'visualTestSelect',
                    required: true,
                    description: 'Select a visual test to assign',
                    variableName: 'visualTestId'
                },
            ]
        },
    ]
};

export const IF_COMPONENTS = {
    allComponents: [
        {
            group: 'allComponents',
            type: 'userCondition',
            name: 'User condition',
            description: 'An condition to check user condition',
            shownInMenu: true,
            icon: UserIcon,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'Add components bellow this component to check user condition',
                },
                {
                    name: 'Department',
                    type: 'departmentSelect',
                    required: false,
                    description: 'Select a department to check',
                    variableName: 'departmentId'
                },
                {
                    name: 'User age',
                    type: 'numberComparisonInput',
                    required: false,
                    description: 'Enter a user age to check',
                    variableName: 'age'
                },
                {
                    name: 'User role',
                    type: 'roleSelect',
                    required: false,
                    description: 'Select a user role to check',
                    variableName: 'role'
                },
            ]
        },
        {
            group: 'allComponents',
            type: 'programCondition',
            name: 'Program condition',
            description: 'An condition to check program status for user',
            shownInMenu: true,
            icon: ProgramYellow,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'Add components bellow this component to check user condition',
                },
                {
                    name: 'Program',
                    type: 'programSelect',
                    required: false,
                    description: 'Select a program to check',
                    variableName: 'programId'
                },
                {
                    name: 'Program status',
                    type: 'programStatusSelect',
                    required: false,
                    description: 'Select a program status to check',
                    variableName: 'status'
                }
            ]
        },
        {
            group: 'allComponents',
            type: 'testCondition',
            name: 'Test condition',
            description: 'An condition to check test status for user',
            shownInMenu: true,
            icon: TestYellow,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'Add components bellow this component to check user condition',
                },
                {
                    name: 'Test',
                    type: 'testSelect',
                    required: false,
                    description: 'Select a test to check',
                    variableName: 'testId'
                },
                {
                    name: 'Test status',
                    type: 'programStatusSelect',
                    required: false,
                    description: 'Select a test status to check',
                    variableName: 'status'
                }
            ]
        },
        {
            group: 'allComponents',
            type: 'visualTestCondition',
            name: 'Visual test condition',
            description: 'An condition to check visual test status for user',
            shownInMenu: true,
            icon: VisualTestYellow,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'Add components bellow this component to check user condition',
                },
                {
                    name: 'Visual Test',
                    type: 'visualTestSelect',
                    required: false,
                    description: 'Select a visual test to check',
                    variableName: 'visualTestId'
                },
                {
                    name: 'Visual test status',
                    type: 'programStatusSelect',
                    required: false,
                    description: 'Select a visual test status to check',
                    variableName: 'status'
                }
            ]
        },
        {
            group: 'allComponents',
            type: 'elseIfCondition',
            name: 'ELSE IF condition',
            description: 'An condition to check if condition is met',
            shownInMenu: false,
            icon: ElseYellow,
            settings: [
                {
                    name: 'Info',
                    type: 'info',
                    description: 'Add components bellow this component to check if condition is met',
                },
            ]
        },
    ]
}

export const findWhenComponentByType = (type) => {
    // Loop through each category (program, test, etc.)
    for (const category of Object.values(WHEN_COMPONENTS)) {
        // Find component in current category
        const found = category.find(component => component.type === type);
        if (found) return found;
    }
    return null; // Return null if no matching component is found
};

export const findThenComponentByType = (type) => {
    // Loop through each category (program, test, etc.)
    for (const category of Object.values(THEN_COMPONENTS)) {
        // Find component in current category
        const found = category.find(component => component.type === type);
        if (found) return found;
    }
    return null; // Return null if no matching component is found
};


export const findIfComponentByType = (type) => {
    // Loop through each category (program, test, etc.)
    for (const category of Object.values(IF_COMPONENTS)) {
        // Find component in current category
        const found = category.find(component => component.type === type);
        if (found) return found;
    }
    return null; // Return null if no matching component is found
}
