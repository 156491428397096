<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-header-txt">{{ $t('add_user') }}</span>
        </div>
        <div class="add-user-div">
          <input type="text" class="form-input" :placeholder="$t('enter_user_e_mail')" v-model="inviteData.email"/>

          <SelectBox
              class="select-box"
              :options="selectboxOptions"
              :on-select="handleInviteSelect"
              :selected-value="inviteData.roleId"
              :placeholder="$t('select_a_role')"
          />

          <SelectBox
              class="select-box"
              :options="selectboxOptions2"
              :on-select="handleDepartmentSelect"
              :selected-value="inviteData.departmentId"
              :placeholder="$t('select_a_department')"
          />

          <div class="btn-style-add" @click="onInvite">
            <span class="btn-txt-add" v-if="!addLoading">{{ $t('add') }}</span>
            <VueSpinnerIos v-if="addLoading" size="20" height="100%" color="#fff"/>
          </div>
        </div>
        <div class="import-csv-div">
          <div class="import-toggle" @click="csvImport = !csvImport">
            <button class="csv-button">
              <a href="https://ibilityapp.com/csv/importUsers.csv" target="_blank" download="importUsers.csv">{{$t('download_csv')}}</a>
              <img src="@/assets/images/arrow-down-blue.svg" @click.stop="downloadCsv">
            </button>
              <span>{{ $t('or_import_csv') }}</span>
              <img src="@/assets/images/chevronDownBlue.svg" />
          </div>


          <Transition name="slide-up">
            <div v-if="csvImport" class="import-csv-div">
              <FileDrop :on-file-selected="onFileSelected" type="allFiles"/>

              <button class="dark-button-sm" @click="inviteImport">
                {{ $t('import') }}
              </button>
            </div>
          </Transition>
        </div>
        <div class="list-user-title">
          <span class="list-user-txt">{{ $t('users_title') }}</span>
        </div>
        <div class="list-user-div scroll-design" v-scroll-end="loadMore">
          <div class="list-user-item" v-for="user in usersData" :key="user.userId">
            <div class="user-avatar-div initials">
              {{ getUserInitials(user.email) }}
            </div>

            <span class="user-email">{{ user.email }}</span>

            <SelectBox
                :loading="user.userId === getUserId"
                class="select-box"
                :options="selectboxOptions"
                :on-select="(selectedValue) => handleRoleSelectByUser(selectedValue, user)"
                :selected-value="user.roleId"
                :placeholder="$t('select_a_role')"
                :align-right="true"
            />

            <span v-if="user?.isChanged ?? false" class="save-btn" @click="updateRole(user)">
              {{ $t('save_button') }}
            </span>

            <div v-if="user.userId !== getUserId" class="user-avatar-div" @click="showDeleteModal(user.userId)">
              <img src="../../assets/images/trash.svg">
            </div>
            <div v-else class="user-avatar-div bg-none"></div>
          </div>

          <div v-if="isLoading" class="loading-center">
            <VueSpinner size="32"/>
          </div>

        </div>

        <div class="btn-div2">
          <div></div>
          <div class="btn-style" @click="this.onHide">
            <span class="next-txt">{{ $t('done') }}</span>
          </div>
        </div>
        <SuccessModal :is-Visible="showSuccessModal" :on-close="onClose" :on-action="onClose"/>
        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg"/>
        </div>
      </div>
    </div>
  </Transition>

  <ConfirmDeleteModal
      :title="this.$t('alert_messages')"
      :message="this.$t('alert_messages_two')"
      :is-visible="deleteModalVisible"
      :on-action="onDeleteInvite"
      :on-close="handleDeleteModalClose"
  />


</template>


<script>
import TextInput from "@/components/TextInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import inviteService from "@/service/inviteService";
import {
  VueSpinnerIos
} from 'vue3-spinners'
import {handleErrorResponse} from "@/utils/utils";
import ConfirmDeleteModal from "@/components/modals/ConfirmDeleteModal.vue";
import FileDrop from "@/components/FileDrop.vue";
import roleService from "../../service/roleService";

export default {
  components: {
    FileDrop,
    ConfirmDeleteModal,
    SuccessModal,
    TextInput,
    SelectBox,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {
      }
    },
    companyId: {
      type: Number,
      default: null
    },
    roleList: {
      type: Array,
      default: []
    },
    deps: {
      type: Array,
      default: []
    }
  },

  computed: {
    getUserId() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user.userId;
    }
  },

  data() {
    return {
      addLoading: false,
      isModalVisible: false,
      showSuccessModal: false,
      deleteModalVisible: false,
      csvImport: false,
      isLoading: true,
      currentPage: 1,
      userId: 0,
      csvFile:null,
      selectboxOptions: [],
      selectboxOptions2: [],
      usersData: [],
      inviteData: {
        email: "",
        roleId: "",
        departmentId: "",
      }
    };
  },
  created() {
    if (this.companyId === null || this.companyId === undefined) {
      this.$snackbar.add({
        text: 'Company id is not set',
        type: 'error',
      });
      this.onClose();
    } else {
      inviteService.getInvitedUsers(1, this.companyId).then(response => {
        this.usersData = response.data.data.data;
        this.isLoading = false;
      });
    }

    this.selectboxOptions = this.roleList.map(role => ({
      text: role.name,
      value: role.id,
    }));

    this.selectboxOptions2 = this.deps.map(dep => ({
      text: dep.name,
      value: dep.departmentId,
    }));
  },
  methods: {
    getUserInitials(name) {
      return name.substr(0, 1).toUpperCase();
    },

    onFileSelected(file){
      this.csvFile=file;
    },

    onClose() {
      this.showSuccessModal = false;
    },
    onSuccessModalClose() {
      this.showSuccessModal = false;
    },
    onSave() {
      this.showSuccessModal = true;
      event.stopPropagation();
    },
    handleInviteSelect(value) {
      this.inviteData.roleId = value;
    },
    handleRoleSelectByUser(value, user) {
      user.isChanged = (user?.oldRoleId ?? false) !== value;
      if (!user.oldRoleId) {
        user.oldRoleId = JSON.parse(JSON.stringify(user)).roleId;
      }
      user.roleId = value;
    },
    handleDepartmentSelect(value) {
      this.inviteData.departmentId = value;
    },
    onInvite() {
      if (this.inviteData.email === "") {
        this.$snackbar.add({
          text: "Please enter user email",
          type: 'error',
        });
        return;
      }

      const tempData = {
        ...this.inviteData,
        companyId: this.companyId,
      }

      this.addLoading = true;

      inviteService.inviteUser(tempData).then(response => {
        this.showSuccessModal = true;
        this.inviteData.email = "";
        this.inviteData.roleId = "";
        this.inviteData.departmentId = "";

        inviteService.getInvitedUsers(1, this.companyId).then(response => {
          this.addLoading = false;
          this.usersData = response.data.data.data;
        });
      }).catch(error => {
        this.addLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },

    downloadCsv(event) {
      event.preventDefault();

      const link = document.createElement('a');
      link.href = 'https://ibilityapp.com/csv/importUsers.csv';
      link.download = 'importUsers.csv';
      link.click();
    },

    showDeleteModal(id) {
      this.userId = id;
      this.deleteModalVisible = true;
      event.stopPropagation();
    },
    handleDeleteModalClose() {
      this.deleteModalVisible = false;
    },
    onDeleteInvite() {
      inviteService.deleteInvite(this.userId).then(() => {
        inviteService.getInvitedUsers(1, this.companyId).then(response => {
          this.usersData = response.data.data.data;
          this.deleteModalVisible = false;
        }).catch(() => {
          this.$snackbar.add({
            text: 'An error occurred while deleting the user',
            type: 'error',
          });
        });
      });
    },

    loadMore() {
      if (!this.lastPage && !this.isLoading) {
        this.isLoading = true;
        this.currentPage++;
        this.loadScroll();
      }
    },

    loadScroll() {
      inviteService.getInvitedUsers(this.currentPage, this.companyId).then(response => {
        this.data = response.data;
        if (response.data.data.last_page === this.currentPage) {
          this.lastPage = true;
        }
        this.usersData = [...this.usersData, ...response.data.data.data];
        this.isLoading = false;
      }).catch(error => {
        console.log(error);
      });
    },

    updateRole(user) {
      roleService.updateUserRole({userId: user.userId, roleId: user.roleId}).then(response => {
        this.$snackbar.add({
          text: response.data.message,
          type: 'success',
        })
        user.isChanged = false;
        delete user.oldRoleId;
      }).catch(error => {
        this.$snackbar.add({
          text: 'An error occurred while updating the user role',
          type: 'error',
        });
        console.log(error);
      });
    },

    inviteImport() {
      console.log(this.csvFile)
      let data = {
        companyId: this.companyId,
        attach: this.csvFile
      };
      roleService.inviteImport(data).then(response => {
        this.$snackbar.add({
          text: response.data.message,
          type: 'success',
        })
      }).catch(error => {
        this.$snackbar.add({
          text: 'An error occurred while updating the user role',
          type: 'error',
        });
        console.log(error);
      });
    }
  },

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}

.cross {
  padding: 8px;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}

.modal-header-txt {
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.btn-style {
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
  position: absolute;
}

.btn-style-add {
  display: flex;
  border-radius: 8px;
  background: #1C3CD5;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 48px;
  cursor: pointer;
}

.btn-txt-add {
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.next-txt {
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.form-input {
  flex: 1;
  font-size: 14px;
}

.btn-div2 {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  bottom: 30px;
  left: 24px;
  right: 24px;
}

.add-user-div {
  display: flex;
  flex-direction: row;
  gap: 14px;
  margin-top: 24px;
  align-items: flex-start;
  width: 100%;
}

.user-avatar-div {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 6.667px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 41.667px;
  background: #EAECF0;
  gap: 6.667px;
  flex-shrink: 0;
}

.user-avatar-div.bg-none {
  background: none;
}

.list-user-div {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: flex-start;
  width: 100%;
  overflow: visible;
  margin-bottom: 62px;
  margin-top: 10px;
}

.list-user-title {
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
  width: 100%;
  margin-top: 48px;
}

.list-user-txt {
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

.list-user-item {
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
  width: 100%;
}

.user-email, .user-role {
  flex: 1;
  font-size: 14px;
}

.user-role {
  color: #667085;
}

.import-csv-div {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.import-toggle {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.import-toggle img {
  width: 10px;
  height: 10px;
}

.import-toggle span {
  font-family: euclid_regular, sans-serif;
  color: #1C3CD5;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
}

.csv-button{
  border-radius: 8px;
  border: 1px solid transparent;
  background: transparent;
  padding: 10px 0 10px 10px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.csv-button a{
  background: transparent;
  padding: 10px 0 10px 10px;
  font-size: 14px;
  color: #1C3CD5;
}

@media (max-width: 498px) {
  .initials {
    display: none;
  }

  .btn-style {
    width: 50%;
  }
}

.save-btn {
  font-size: 12px;
  font-family: euclid_medium, sans-serif;
  color: #060B27;
  cursor: pointer;
}

</style>
