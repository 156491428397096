<script setup>
import { Handle, Position } from '@vue-flow/core'
import testService from "@/service/testService";
import {computed, onMounted, ref, watch} from "vue";
import {handleErrorResponse} from "@/utils/utils";
import NodeDropdown from "@/components/condition-flow/NodeDropdown.vue";
import equalIcon from "@/assets/images/equal.svg";
import lessThanIcon from "@/assets/images/lessThan.svg";
import greaterThanIcon from "@/assets/images/greaterThan.svg";
import lessThanEqualIcon from "@/assets/images/lessThanEqual.svg";
import greaterThanEqualIcon from "@/assets/images/greaterThanEqual.svg";

const props = defineProps(['id', 'data', 'testList', 'onSelect', 'editDisabled']);
const questionsLoading = ref(false);
const testQuestionList = ref([]);
const selectedTest = ref(null);
const selectedQuestion = ref(null);
const selectedCondition = ref(null);
const selectedOption = ref(null);
const mcqQuestion = ref(false);

const checkQuestionMCQ = () => {
  if (selectedQuestion.value) {
    mcqQuestion.value = selectedQuestion.value.type === 'single' || selectedQuestion.value.type === 'multiple' || selectedQuestion.value.type === 'yesNo';
  }
}

const handleTestSelect = async (value) => {
  const testId = value;
  if (testId === '-1') return;

  const item = props.testList.find((test) => test.id === testId);
  selectedTest.value = item;
  questionsLoading.value = true;

  await loadQuestions(item.id, item.type);
}

const loadQuestions = async (id, type) => {
  if (!id) return;
  await testService.getQuestion(id, type).then((res) => {
    testQuestionList.value = res.data.data.questions;
    questionsLoading.value = false;
  }).catch((err) => {
    handleErrorResponse(err, $snackbar);
  });
}

const handleQuestionSelect = (value) => {
  const questionId = value;
  if (questionId === '-1') return;

  selectedQuestion.value = testQuestionList.value.find((question) => question?.questionId === questionId);
  checkQuestionMCQ();
}

const handleConditionSelect = (value) => {
  selectedCondition.value = value;
}

const handleOptionSelect = (value, ddown = false) => {
  const option = ddown ? String(value) : String(value.target.value);
  if (option === '-1') return;

  selectedOption.value = option;
}

onMounted(async () => {
  if (props.data) {
    selectedTest.value = props.testList.find((test) => test.id === props.data.testId);
    if (!selectedTest.value) {
      return;
    }
    await loadQuestions(props.data.testId, selectedTest.value.type);

    selectedQuestion.value = testQuestionList.value.find((question) => question.questionId === props.data?.questionId);

    selectedCondition.value = props.data.condition ? props.data.condition : '=';
    selectedOption.value = props.data.option;
    checkQuestionMCQ();
  }
});

watch(
    [selectedTest, selectedQuestion, selectedCondition, selectedOption],
    ([newTest, newQuestion, newCondition, newOption], [oldTest, oldQuestion, oldCondition, oldOption]) => {
      if (newTest && newQuestion && newOption) {
        const selectedData = {
          testId: newTest.id,
          questionId: newQuestion?.questionId,
          option: newOption,
          condition: newCondition ? newCondition : '=',
        }
        props.onSelect(selectedData, props.id);
      }
    },
    { deep: true }
)

const conditionOptions = [
  { value: '=', text: 'Equals to', icon: equalIcon },
  { value: '<', text: 'Less than', icon: lessThanIcon },
  { value: '>', text: 'Greater than', icon: greaterThanIcon },
  { value: '<=', text: 'Less than or equal to', icon: lessThanEqualIcon },
  { value: '>=', text: 'Greater than or equal to', icon: greaterThanEqualIcon }
];


const mapTestListSelect = computed(() =>
    props.testList.filter(test => test.type !== 'gamelabs').map(test => ({ value: test.id, text: test.name }))
);

const mapQuestionListSelect = computed(() => {
  if (questionsLoading.value) return [];
  if (!testQuestionList.value) return [];

  return testQuestionList.value.map(question => ({ value: question.questionId, text: question.name }))
});


const mapOptionListSelect = computed(() => {
  if (!selectedQuestion.value) return [];
  if (!selectedQuestion.value.options) return [];

  return selectedQuestion.value.options.map(option => ({ value: String(option.id), text: option.name }))
});

</script>

<template>
  <div class="node-wrapper">
    <Handle id="input" type="target" :position="Position.Top"/>

    <div class="node-header">
      <img src="../../../assets/images/testOutlineDark.svg" alt="icon"/>
      <span class="node-type">{{$t('answer')}}</span>
    </div>

    <div class="node-body">
      <div class="select-row" @wheel.stop>
        <NodeDropdown :search-enabled="true" theme="dark" :on-select="handleTestSelect" :selected-value="selectedTest?.id ? selectedTest?.id : '-1'" :options="mapTestListSelect" />

        <NodeDropdown :search-enabled="true" theme="dark" :on-select="handleQuestionSelect" :selected-value="selectedQuestion?.questionId" :options="mapQuestionListSelect" />

        <NodeDropdown theme="dark" v-if="!mcqQuestion" :show-value-as-selected="true" :options="conditionOptions" :on-select="handleConditionSelect" :selected-value="selectedCondition ? selectedCondition : '='" :disabled="editDisabled"/>
      </div>

      <NodeDropdown theme="dark" v-if="mcqQuestion" :options="mapOptionListSelect" :on-select="(value) => handleOptionSelect(value, true)" :selected-value="String(selectedOption)" :disabled="editDisabled"/>
      <input v-if="!mcqQuestion" class="node-text-input dark" @change="handleOptionSelect" type="number" :placeholder="$t('enter_value')" :value="selectedOption" :disabled="editDisabled" />
    </div>

    <Handle id="output" type="source" :position="Position.Bottom" class="handle-true"/>
    <Handle id="false" type="source" :position="Position.Bottom" class="handle-false"/>
  </div>
</template>

<style scoped>
.node-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 320px;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #040c2d;
  background-color: #fff;
  border-radius: 6px;
}

.node-header {
  width: 100%;
  background-color: #f1f1f1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 10px;
}

.node-header img {
  width: 24px;
  height: 24px;
}

.node-type {
  font-size: 16px;
  color: #101828;
  font-family: 'euclid_regular', sans-serif;
}

.node-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}


.handle-true {
  position: absolute;
  bottom: -6px;
  left: calc(50% - 10px) ;
  transform: translateX(-50%);
  background-color: #00D68F;
}

.handle-false {
  position: absolute;
  bottom: -6px;
  right: calc(50% - 10px) ;
  transform: translateX(50%);
  background-color: #F44336 !important;
}

.select-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

</style>
