<template>
  <div class="translation-dropdown-wrapper" v-click-outside="handleClickOutside">
    <div class="dropdown" @click="isOpen = !isOpen">
      <span class="selected-language">{{selectedLanguage === 'en' ? 'English' : 'Türkçe'}}</span>
      <img src="@/assets/images/arrowdown.svg" alt="arrow-down" class="arrow-down" :class="isOpen && 'rotate'"/>
    </div>
    <div v-if="isOpen" class="dropdown-content">
      <div class="dropdown-item" :class="selectedLanguage === 'en' && 'selected'" @click="handleSelect('en')" >English</div>
      <div class="dropdown-item" :class="selectedLanguage === 'tr' && 'selected'" @click="handleSelect('tr')">Türkçe</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TranslationDropdown',
  props: {
    selectedLanguage: {
      type: String,
      default: 'tr'
    },
    onSelect: {
      type: Function,
      default: () => {}
    },
    showWarning: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    handleClickOutside() {
      this.isOpen = false;
    },
    handleSelect(language) {
      this.onSelect(language);
      this.isOpen = false;
    }
  }
}
</script>

<style scoped>
.selected-language {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  line-height: 12px;
}

.translation-dropdown-wrapper {
  position: relative;
  display: inline-block;
}

.dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  padding: 10px;
  border-radius: 8px;
  transition: background 0.2s;
}

.dropdown:hover {
  background-color: #F1F1F1;

}

.dropdown-content {
  position: absolute;
  background-color: #FFFFFF;
  border: 1px solid #D0D5DD;
  min-width: 160px;
  padding: 10px;
  border-radius: 16px;
  z-index: 1;
  top: calc(100% + 15px);
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dropdown-item {
  cursor: pointer;
  font-size: 14px;
  padding: 7px 15px;
  border-radius: 12px;
}

.dropdown-item:hover {
  background-color: #F1F1F1;
}

.selected {
  background-color: rgba(28, 60, 213, 0.15) !important;
  font-family: euclid_medium, sans-serif;
}

.arrow-down {
  width: 16px;
  height: 16px;
  transition: transform 0.3s;
}

.rotate {
  transform: rotate(180deg);
}
</style>
