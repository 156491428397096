import axios from '../axios';

const fileService = {
    getFiles(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/files/store?page=${page}&limit=20${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/files/store?page=${page}&limit=20`);
        }
    },
    setFileStatus(data) {
        return axios.put(`/v1/admin/files/updateStatus`, data);
    }
}

export default fileService;
