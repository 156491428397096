import Home from "@/pages/Home.vue";
import Companies from "@/pages/Companies.vue";
import Users from "@/pages/Users.vue";
import Tests from "@/pages/Tests.vue";
import Therapies from "@/pages/Therapies.vue";
import Experts from "@/pages/Experts.vue";
import Accounting from "@/pages/Accounting.vue";
import Tags from "@/pages/Tags.vue";
import GameLabs from "@/pages/GameLabs.vue";
import Reviews from "@/pages/Reviews.vue";
import Blogs from "@/pages/Blogs.vue";
import i18n from '@/i18n.js';
import Pages from "@/pages/Pages.vue";
import Journeys from "@/pages/Journeys.vue";
import Notifications from "@/pages/Notifications.vue";
import Files from "@/pages/Files.vue";
import Reports from "@/pages/Reports.vue";
import Roles from "@/pages/Roles.vue";

import dashboardIcon from "../assets/images/dashboard.svg";
import companyIcon from "../assets/images/companies.svg";
import userIcon from "../assets/images/users.svg";
import testIcon from "../assets/images/test.svg";
import therapyIcon from "../assets/images/therapies.svg";
import journeyIcon from "../assets/images/journey.svg";
import expertIcon from "../assets/images/experts.svg";
import reviewsIcon from "../assets/images/reviewsFill.svg";
import blogsIcon from "../assets/images/blogs.svg";
import pagesIcon from "../assets/images/pages.svg";
import notificationsIcon from "../assets/images/notifications.svg";
import reportsIcon from "../assets/images/reports.svg";
import cardIcon from "../assets/images/cardFill.svg";
import tagsIcon from "../assets/images/tags.svg";
import gameLabIcon from "../assets/images/gameLab.svg";
import filesIcon from "../assets/images/files.svg";
import rolesIcon from "../assets/images/roles.svg";
import slidersIcon from "../assets/images/slider.svg";
import planeIcon from "../assets/images/plane.svg";
import commandIcon from "../assets/images/command.svg";
import briefcaseIcon from "../assets/images/briefcase.svg";
import planeActiveIcon from "../assets/images/planeActive.svg";
import dashboardActiveIcon from "../assets/images/dashboardActive.svg";
import companyActiveIcon from "../assets/images/companiesActive.svg";
import userActiveIcon from "../assets/images/usersActive.svg";
import testActiveIcon from "../assets/images/testActive.svg";
import therapyActiveIcon from "../assets/images/therapiesActive.svg";
import journeyActiveIcon from "../assets/images/journeyActive.svg";
import expertActiveIcon from "../assets/images/expertsActive.svg";
import reviewsActiveIcon from "../assets/images/reviewsActive.svg";
import blogsActiveIcon from "../assets/images/blogsActive.svg";
import pagesActiveIcon from "../assets/images/pagesActive.svg";
import notificationsActiveIcon from "../assets/images/notificationsActive.svg";
import reportsActiveIcon from "../assets/images/reportsActive.svg";
import cardActiveIcon from "../assets/images/cardFillActive.svg";
import tagsActiveIcon from "../assets/images/tagsActive.svg";
import gameLabActiveIcon from "../assets/images/gameLabActive.svg";
import filesActiveIcon from "../assets/images/filesActive.svg";
import rolesActiveIcon from "../assets/images/rolesActive.svg";
import slidersActiveIcon from "../assets/images/sliderActive.svg";
import briefcaseActiveIcon from "../assets/images/breifcaseActive.svg";
import commandActiveIcon from "../assets/images/commandActive.svg";
import Sliders from "@/pages/Sliders.vue";
import Onboarding from "@/pages/Onboarding.vue";
import OpenPositions from "@/pages/OpenPositions.vue";
import Userflow from "@/pages/Userflow.vue";



const sidebarRoutes = [
    {
        path: '/',
        name: i18n.global.t('dashboard'),
        component: Home,
        id: 'dashboard',
        icon: dashboardIcon,
        activeIcon: dashboardActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/companies',
        name: i18n.global.t('companies_title'),
        component: Companies,
        id: 'companies',
        icon: companyIcon,
        activeIcon: companyActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users',
        name: i18n.global.t('users_title'),
        component: Users,
        id: 'users',
        icon: userIcon,
        activeIcon: userActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tests',
        name: i18n.global.t('tests_title'),
        component: Tests,
        id: 'selfAssessments',
        icon: testIcon,
        activeIcon: testActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/therapies',
        name: i18n.global.t('therapies_title'),
        component: Therapies,
        id: 'therapies',
        icon: therapyIcon,
        activeIcon: therapyActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reports',
        name: 'Reports',
        component: Reports,
        id: 'reports',
        icon: reportsIcon,
        activeIcon: reportsActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/journeys',
        name: i18n.global.t('journeys_type'),
        component: Journeys,
        id: 'journeys',
        icon: journeyIcon,
        activeIcon: journeyActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/game-labs',
        name: i18n.global.t('gameLabs_title'),
        component: GameLabs,
        id: 'gameLabs',
        icon: gameLabIcon,
        activeIcon: gameLabActiveIcon,
    },
    {
        path: '/experts',
        name: i18n.global.t('experts_title'),
        component: Experts,
        id: 'experts',
        icon: expertIcon,
        activeIcon: expertActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/blogs',
        name: i18n.global.t('blogs_title'),
        component: Blogs,
        id: 'blogs',
        icon: blogsIcon,
        activeIcon: blogsActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/pages',
        name: i18n.global.t('pages_title'),
        component: Pages,
        id: 'pages',
        icon: pagesIcon,
        activeIcon: pagesActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/notifications',
        name: i18n.global.t('notifications_title'),
        component: Notifications,
        id: 'notifications',
        icon: notificationsIcon,
        activeIcon: notificationsActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/files',
        name: i18n.global.t('files_title'),
        component: Files,
        id: 'files',
        icon: filesIcon,
        activeIcon: filesActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reviews',
        name: i18n.global.t('reviews_title'),
        component: Reviews,
        id: 'reviews',
        icon: reviewsIcon,
        activeIcon: reviewsActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounting',
        name: i18n.global.t('accounting_title'),
        component: Accounting,
        id: 'accounting',
        icon: cardIcon,
        activeIcon: cardActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tags',
        name: i18n.global.t('tags_title'),
        component: Tags,
        id: 'tags',
        icon: tagsIcon,
        activeIcon: tagsActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/sliders',
        name: 'Sliders',
        component: Sliders,
        id: 'sliders',
        icon: slidersIcon,
        activeIcon: slidersActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/roles',
        name: 'Roles',
        component: Roles,
        id: 'roles',
        icon: rolesIcon,
        activeIcon: rolesActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/onboarding',
        name: 'Onboarding',
        component: Onboarding,
        id: 'onboardings',
        icon: planeIcon,
        activeIcon: planeActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/positions',
        name: 'Open Positions',
        component: OpenPositions,
        id: 'openpositions',
        icon: briefcaseIcon,
        activeIcon: briefcaseActiveIcon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/userflow',
        name: 'Userflow',
        component: Userflow,
        id: 'openpositions',
        icon: commandIcon,
        activeIcon: commandActiveIcon,
        meta: {
            requiresAuth: true
        }
    },

];

export default sidebarRoutes;
