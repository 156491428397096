import axios from '../axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const userflowService = {
    addUserflow(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post('/v2/admin/userflow/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
}


export default userflowService;
