<template>
  <apexchart type="bar" height="300" :options="{
    ...chartOptions
  }" :series="chartData.series"></apexchart>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data: {
      handler(newVal) {
        if (!newVal.data) {
          console.warn("data is not defined", newVal);
          return;
        }

        if (!newVal.data.series) {
          console.warn("series is not defined", newVal);
          return;
        }

        if (newVal.data.series.length === 0) {
          console.warn("series is empty");
          return;
        }


        const tempSeries = newVal.data.series.map(item => {
          if (!item?.data) {
            return 0;
          }

          if (item.data.length === 0) {
            return 0;
          }

          const total = item.data.reduce((a, b) => a + b);

          return {
            x: item.name,
            y: total,
          }
        });

        this.chartData = {
          series: [{
            data: tempSeries,
          }],
        };
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            barHeight: '80%',
            isFunnel: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
          },
          dropShadow: {
            enabled: true,
          },
        },
        legend: {
          show: false,
        },
      },
      chartData: {
        series: [],
      },
    };
  }
}
</script>
