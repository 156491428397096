<template>
  <div class="radial-widget">
    <apexchart type="donut" width="380" :options="{
      ...chartOptions,
      labels: chartData.labels,
    }" :series="chartData.series"></apexchart>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data: {
      handler(newVal) {
        if (!newVal.data) {
          console.warn("data is not defined", newVal);
          return;
        }

        if (!newVal.data.series) {
          console.warn("series is not defined", newVal);
          return;
        }

        if (newVal.data.series.length === 0) {
          console.warn("series is empty");
          return;
        }

        const tempLabels = newVal.data.series.map(item => {
          return item.name;
        });

        const tempSeries = newVal.data.series.map(item => {
          if (!item?.data) {
            return 0;
          }

          if (item.data.length === 0) {
            return 0;
          }

          return item.data.reduce((a, b) => a + b);
        });

        this.chartData = {
          series: tempSeries,
          labels: tempLabels,
        };
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'donut',
        },

        stroke: {
          lineCap: "round",
        },
        plotOptions: {
          pie: {
            donut: {
              size: '80%',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'euclid_medium',
                  color: undefined,
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'euclid_medium',
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: 'Total',
                  color: '#373D3F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        }
      },
      chartData: {
        series: [],
        labels: [],
      },
    }
  }
}
</script>

<style scoped>
.radial-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
}
</style>
