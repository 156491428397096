import Login from "@/pages/Login.vue";
import sidebarRoutes from "@/router/sidebarRoutes";
import CompanyDetails from "@/pages/CompanyDetails.vue";
import UserDetails from "@/pages/UserDetails.vue";
import ExpertDetails from "@/pages/ExpertDetails.vue";
import Settings from "@/pages/Settings.vue";
import TestDetails from "@/pages/TestDetails.vue";
import TherapyDetails from "@/pages/TherapyDetails.vue";
import GameLabDetails from "@/pages/GameDetails.vue";
import BlogDetails from "@/pages/BlogDetails.vue";
import PageDetails from "@/pages/PageDetails.vue";
import JourneyDetails from "@/pages/JourneyDetails.vue";
import NotificationDetails from "@/pages/NotificationDetails.vue";
import ReportDetails from "@/pages/ReportDetails.vue";
import SliderDetails from "@/pages/SliderDetails.vue";
import ImageEditor from "@/pages/ImageEditor.vue";
import OnboardingDetails from "@/pages/OnboardingDetails.vue";
import PositionDetails from "@/pages/PositionDetails.vue";
import NotFoundPage from "@/pages/404.vue";
import BadRequestPage from '@/pages/400.vue';
import ServerErrorPage from '@/pages/500.vue';
import UnauthorizedPage from '@/pages/401.vue';
import CalendlyIntegration from "@/pages/Calendly.vue";
import UserflowDetails from "@/pages/UserflowDetails.vue";
import ResetPassword from "@/pages/ResetPassword.vue";



const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/companies/detail/:id',
        name: 'CompanyDetail',
        component: CompanyDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users/detail/:id',
        name: 'UserDetail',
        component: UserDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/test/:id',
        name: 'Test',
        component: TestDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/experts/detail/:id',
        name: 'ExpertDetail',
        component: ExpertDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/notification/:id',
        name: 'NotificationDetail',
        component: NotificationDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/blog/:id',
        name: 'BlogDetail',
        component: BlogDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/page/:id',
        name: 'PageDetail',
        component: PageDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/therapy/:id',
        name: 'TherapyDetail',
        component: TherapyDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reports/:id',
        name: 'ReportDetails',
        component: ReportDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/slider/:id',
        name: 'SliderDetails',
        component: SliderDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/onboarding/:id',
        name: 'OnboardingDetails',
        component: OnboardingDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/journeys/:id',
        name: 'JourneyDetail',
        component: JourneyDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/game/:id',
        name: 'GameDetail',
        component: GameLabDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/userflow/:id',
        name: 'UserflowDetails',
        component: UserflowDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/image-editor',
        name: 'ImageEditor',
        component: ImageEditor,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/positions/:id',
        name: 'PositionDetail',
        component: PositionDetails,
        meta: {
            requiresAuth: true
        }
    },
    ...sidebarRoutes,

    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFoundPage,
        meta: {
            requiresAuth: false,
        },
    },

    {
        path: '/400',
        name: 'BadRequest',
        component: BadRequestPage,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/500',
        name: 'ServerError',
        component: ServerErrorPage,
        meta: {
            requiresAuth: false,
        },
    },

    {
        path: '/401',
        name: 'Unauthorized',
        component: UnauthorizedPage,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/calendly',
        name: 'Calendly',
        component: CalendlyIntegration,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            requiresAuth: false
        }
    },
];

export default routes;
