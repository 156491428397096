<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <div class="tab-panel no-text-select">
            <div class="tab-pane" :class="{'active': tabIndex === 0}" @click="() => changeTab(0)">
              {{$t('assign')}}
            </div>

            <div class="tab-pane" v-if="weightList.length > 0" :class="{'active': tabIndex === 1}" @click="() => changeTab(1)">
              {{$t('test_weights')}}
            </div>
          </div>
        </div>

        <div class="tab-content" v-if="tabIndex === 0">
          <div class="search-wrapper">
            <img src="@/assets/images/search.svg" />
            <input class="search-input" type="text" :placeholder="$t('search_text')" />
          </div>

          <div class="therapy-list scroll-design">
            <div class="therapy-row" v-for="item in tests" :key="item.testId" @click="handleTestSelect(item)" :class="{'checked': selectedTestId === item.testId}">

              <div class="therapy-left">
                <img v-if="selectedTestId !== item.testId" src="@/assets/images/checkbox.svg" />
                <img v-else src="@/assets/images/checkboxChecked.svg" />
                <img v-if="item.avatarUrl !== null" :src="item.avatarUrl" class="therapy-image" />

                <div class="therapy-info">
                  <span class="therapy-title">{{item.name}}</span>
                </div>
              </div>

              <div class="therapy-right">
                <img src="@/assets/images/calendarGray.svg" class="therapy-info-icon" />
                <span class="therapy-date-text">{{formatDate(item.startAt)}}</span>
                -
                <span class="therapy-date-text">{{formatDate(item.finishAt)}}</span>
              </div>

            </div>

            <div v-if="isLoading" class="loading-center">
              <VueSpinner size="32" />
            </div>

          </div>

          <button class="dark-button w-100 mt-2" @click="onSave">
            <span v-if="!addLoading">Assign the Selected</span>
            <VueSpinnerIos v-if="addLoading" size="20" height="100%" color="#fff" />
          </button>
        </div>

        <div class="tab-content" v-if="tabIndex === 1">
          <div v-if="isLoading" class="loading-center">
            <VueSpinner size="32" />
          </div>

          <div v-else>
            <div class="weight-wrapper" v-for="weight in weightList">
              <div class="therapy-row" >

                <div class="therapy-left">
                  <img v-if="getTestById(weight.selfAssessmentId).avatarUrl !== null" :src="getTestById(weight.selfAssessmentId).avatarUrl" class="therapy-image" />

                  <div class="therapy-info">
                    <span class="therapy-title">{{getTestById(weight.selfAssessmentId).name}}</span>
                  </div>
                </div>

                <div class="therapy-right">
                  <input class="form-input" type="number" v-model="weight.weightNote">
                </div>
              </div>
            </div>

            <button class="dark-button w-100 mt-2" @click="handleSaveWeights">
              <span v-if="!weightsSaveLoading">{{$t('save_the_weights')}}</span>
              <VueSpinnerIos v-if="weightsSaveLoading" size="20" height="100%" color="#fff" />
            </button>
          </div>
        </div>

        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>

      </div>
    </div>
  </Transition>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import {
  VueSpinner,
  VueSpinnerIos
} from 'vue3-spinners'
import therapyService from "@/service/therapyService";
import {handleErrorResponse} from "@/utils/utils";
import testService from "@/service/testService";

export default {
  components: {
    TextInput,
    SelectBox,
    VueSpinner,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    therapyId: {
      type: String,
      default: null,
    },
    assignedId: {
      type: String,
      default: null,
    },
    weightList: {
      type: Array,
      default: []
    },
    onSelect: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: true,
      tests: [],
      addLoading: false,
      selectedTestId: null,
      tabIndex: 0,
      weightsSaveLoading: false
    };
  },
  mounted() {
    this.loadTests();
  },
  watch: {
    assignedId: {
      handler: function (val, oldVal) {
        this.selectedTestId = val;
      },
      immediate: true
    }
  },
  methods: {
    loadTests() {
      testService.getAllTests().then(response => {
        this.tests = response.data.data;
        this.isLoading = false;
      }).catch(error => {
        console.log(error);
      });
    },
    handleTestSelect(test) {
      if (this.selectedTestId === test.testId) {
        this.selectedTestId = null;
        return;
      }

      if (test.isPublish === false) {
        this.$snackbar.add({
          text: "Test is not published yet, it will not be visible to the user",
          type: "warning",
          timeout: 3000
        });
      }

      this.selectedTestId = test.testId;
    },
    onSave() {
      this.onSelect(this.selectedTestId);
    },
    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return '-';
      }
    },
    changeTab(index) {
      this.tabIndex = index;
    },
    getTestById(testId) {
      return this.tests.find(test => test.testId === testId);
    },
    handleSaveWeights() {
      if (!this.therapyId) {
        this.$snackbar.add({
          text: "Save the program first",
          type: "error",
        });
        return;
      }

      this.weightsSaveLoading = true;
      therapyService.saveWeights({
        therapyId: this.therapyId,
        content: this.weightList
      }).then(() => {
        this.weightsSaveLoading = false;
        this.$snackbar.add({
          text: "Weights saved successfully",
          type: "success",
        });
      }).catch((error) => {
        this.weightsSaveLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
  },

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  max-height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.search-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #EAECF0;
  background-color: #F9FAFB;
  border-radius: 24px;
  margin-bottom: 20px;
}

.search-wrapper img {
  margin-left: 10px;
}

.search-input {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 10px;
  width: 100%;
}

.therapy-list {
  height: 100%;
  overflow-x: hidden;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.therapy-image {
  height: 80px;
  width: 120px;
  border-radius: 16px;
  border: 1px solid #E9EAEF;
}

.therapy-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #101828;
  background-color: #E9EAEF;
  border-radius: 8px;
  padding: 3px 12px;
  margin-right: 5px;
  margin-bottom: 15px;
}


.therapy-row {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid transparent;
}

.therapy-row.checked {
  border: 1px solid #3DA33E;
  background-color: #f5f5f5;
  border-radius: 16px;
}

.therapy-left {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.therapy-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.therapy-info .therapy-title {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #101828;
}

.therapy-info .therapy-text {
  font-size: 12px;
  color: #667085;
}

.therapy-right {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.therapy-right .therapy-date-text {
  font-size: 12px;
  color: #98A2B3;
  white-space: nowrap;
}

.tab-panel {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
}

.tab-pane {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #667085;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.tab-pane.active {
  border-bottom: 2px solid #4A68F8;
  color: #101828;
}

.tab-content {
  display: flex;
  flex-direction: column;
}


@media screen and (max-width: 768px) {
  .therapy-right {
    display: none;
  }
}

</style>
