export default {
    mounted(el, binding) {
        const handleScroll = () => {
            if (isVerticalScrollAtEnd(el)) {
                binding.value();
            }
        };

        el.__handleScroll = handleScroll;
        el.addEventListener('scroll', handleScroll);
    },
    unmounted(el) {
        el.removeEventListener('scroll', el.__handleScroll);
        delete el.__handleScroll;
    },
};

function isVerticalScrollAtEnd(element) {
    // Add a small threshold to account for floating-point precision
    const threshold = 1;

    const scrollBottom = Math.abs(
        element.scrollHeight - element.scrollTop - element.clientHeight
    );
    const isScrolledToBottom = scrollBottom <= threshold;
    const isAtTop = element.scrollTop === 0;

    return isScrolledToBottom && !isAtTop;
}
