<template>
  <div class="search-wrapper" :class="{'expanded': this.isExpanded}" @click="toggleSearch">
    <img v-if="this.isExpanded" src="@/assets/images/cross.svg" @click="cancelSearch" />
    <img v-if="!this.isExpanded" src="@/assets/images/search.svg"/>
    <span class="search-txt" v-if="!this.isExpanded">{{ $t('search_text') }}</span>
    <input
        v-if="isExpanded"
        v-focus="isExpanded"
        @keyup.enter="this.handleSearch"
        v-model="this.searchQuery"
        class="input"
        type="text"
        :placeholder="$t('search_text')"
        @click.stop />
  </div>
</template>

<script>
import { ref, watch } from "vue";
const isExpanded = ref(false);

export default{
  data(){
    return {
      searchQuery:"",
      isExpanded:false
    }
  },
  methods:{
    toggleSearch(){
      this.isExpanded=!this.isExpanded;
      if (this.isExpanded===false) {
        this.searchQuery= '';
      }
    },
    cancelSearch(){
      this.searchQuery="";
      this.$router.push({
        query: {
          ...this.$route.query,
          searchText: undefined,
        }
      });
    },
    handleSearch() {
      if(this.searchQuery.length > 2){
      this.$router.push({
        query: {
          ...this.$route.query,
          searchText: this.searchQuery,
        }
      });
      }
    }
  }
}

// v-model'ü tanımlamak
//const props = defineProps(['modelValue']);
//const emit = defineEmits(['update:modelValue']);

// Arama sorgusu için reactive bir değer
//const searchQuery = ref(props.modelValue);

// Arama sorgusu değiştiğinde emit etmek
//watch(searchQuery, (newValue) => {
  //emit('update:modelValue', newValue);
//});

//const isExpanded = ref(false);

// Anahtar kelime araması ve genişleme işlevleri
///function toggleSearch() {
 // isExpanded.value = !isExpanded.value;

 // if (!isExpanded.value) {
   // searchQuery.value = '';
   // emit('update:modelValue', ''); // Genişleme kapandığında arama sıfırlanır
 // }
//}

//function onEnter() {
 // if (searchQuery.value.length > 2) {
    // Arama metni yeterince uzun olduğunda işlemi tetikle
    //emit('update:modelValue', searchQuery.value);
  //}
//}

</script>

<style scoped>
.search-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  min-height: 32px;
}
.search-txt {
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
}
.expanded {
  background-color: #F9FAFB;
  border: 1px solid #EAECF0;
  border-radius: 24px;
  padding: 5px 10px;
  cursor: text;
}

input {
  font-size: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

input:focus {
  outline: none;
}
</style>
