<template>
  <div class="header-view">
    <div class="left">
      <img  @click="onMenuClick" src="@/assets/images/menu.svg" class="sidebar-icon" alt="Dashboard Icon" />
      <div class="welcome-view">
        <h3 class="welcome-headertxt">{{ $t('welcome_title') }}, {{getName}}</h3>
        <h3 class="welcome-headerquestion">{{ $t('how_can_we_help') }}</h3>
      </div>
    </div>

    <div class="locale-changer">
      <TranslationDropdown :show-warning="false" :selected-language="$i18n.locale" :on-select="updateLanguage"/>
      <NotificationModal/>
    </div>
  </div>
</template>


<script>
import NotificationModal from "@/components/modals/NotificationModal.vue";
import ActionDropdown from "@/components/ActionDropdown.vue";
import TranslationDropdown from "@/components/TranslationDropdown.vue";

export default {
  name: 'Header',
  components: {TranslationDropdown, ActionDropdown, NotificationModal},
  data() {
    return {
      showNotificationVisible:false,
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Türkçe', code: 'tr' },
      ]
    }
  },
  props: {
    onMenuClick: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    getName() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user.name;
    }
  },
  methods: {
    hideNotification(){
      this.showNotificationVisible=false;
    },
    showNotification(){
      this.showNotificationVisible=!this.showNotificationVisible;
      console.log(this.showNotificationVisible);
    },
    updateLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('userLanguage', lang);
      window.location.reload();
    }
  }
}
</script>



<style scoped>
.header-view{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: white;
  border-style: solid;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-bottom-width: 1px;
  border-bottom-color: #EAECF0;
  height: 72px;
}

.header-view .left{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.welcome-view{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.welcome-headertxt{
  color: #040C2D;
  font-size: 14px;
  font-weight: 500;
  font-family: 'euclid_medium', sans-serif;
}
.welcome-headerquestion{
  color: #535560;
  font-size: 12px;
  font-weight: 400;
  font-family: 'euclid_regular', sans-serif;
}

.sidebar-icon {
  display: none;
}

.lang-select {
  background-color: transparent;
  border: none;
  font-family: 'euclid_medium', sans-serif;
  font-size: 14px;
  color: #535560;
}

.lang-select:focus {
  outline: none;
}

.locale-changer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

@media (max-width: 672px) {
  .sidebar-icon {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
</style>
