<template>
  <div class="card-wrapper">
    <div class="question-header">
      <div class="left-wrapper">
        <div class="question-number-wrapper">
          <span class="question-number">{{(index + 1)}}.</span>
        </div>

        <div class="question-dropdown" v-click-outside="dropdownOutsideClick">
          <div class="dropdown-button" :class="{'active': dropdownVisible}" @click="dropdownVisible = !dropdownVisible">
            <span>{{dimensionTypes.find(type => type.id === selectedTypeID).name}}</span>
            <img src="@/assets/images/arrowdown.svg" class="arrow" />
          </div>

          <div class="dropdown-items" v-if="dropdownVisible">
            <div v-for="item in dimensionTypes" class="dropdown-item" @click="() => selectDimensionType(item.id)">
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>


      <div class="right-wrapper">
        <img src="@/assets/images/trashOutline.svg" @click="handleDelete" />
        <span class="save-text" :class="changesMade && 'highlight'" @click="saveDimension" v-if="!saveLoading">Save</span>
        <VueSpinnerIos v-else size="14"/>
      </div>
    </div>

    <Transition name="slide-up">
      <div v-if="expanded" class="question-body">

        <div class="type-body">
          <textarea class="question-input" :placeholder="$t('dimension_name')" v-model="data.name" v-textarea-autoresize></textarea>

          <div class="input-row">
            <textarea class="question-input" :placeholder="$t('description')" v-model="data.description" v-textarea-autoresize></textarea>
            <textarea class="question-input" :placeholder="$t('short_description')" v-model="data.shortDescription" v-textarea-autoresize></textarea>
          </div>

          <div class="input-row">
            <textarea class="question-input" :placeholder="$t('strengths')" v-model="data.strengthsExplanation" v-textarea-autoresize></textarea>
            <textarea class="question-input" :placeholder="$t('development_areas')" v-model="data.developmentAreasExplanation" v-textarea-autoresize></textarea>
          </div>

          <input class="question-input" type="number" :placeholder="$t('score')" v-model="data.score" />

        </div>

      </div>
    </Transition>

    <div class="toggle-card" :class="{'closed': !expanded}" @click="toggleCard">
      <img src="@/assets/images/cross.svg" />
    </div>

  </div>
</template>

<script>
import testDimensionService from "@/service/testDimensionService";
import {handleErrorResponse} from "@/utils/utils";
import {VueSpinnerIos} from 'vue3-spinners';

export default {
  components: {
    VueSpinnerIos
  },
  data() {
    return {
      changesMade: false,
      saveLoading: false,
      expanded: true,
      dropdownVisible: false,
      selectedTypeID: 1,
      dimensionTypes: [
        { id: 1, name: 'root' },
        { id: 2, name: 'question' },
        { id: 3, name: 'answer'},
      ],
      saveData: {
        name: '',
        description: '',
        shortDescription: '',
        strengthsExplanation: '',
        developmentAreasExplanation: '',
        score: 0
      }
    }
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => {}
    },
    onDelete: {
      type: Function,
      default: () => {}
    },
    requestRefresh: {
      type: Function,
      default: () => {}
    }
  },
  mounted() {
    this.saveData = { ...this.data };

    if (this.data.type === 'root') {
      this.selectedTypeID = 1;
    } else if (this.data.type === 'question') {
      this.selectedTypeID = 2;
    } else if (this.data.type === 'answer') {
      this.selectedTypeID = 3;
    }

    if (this.data.isNew) {
      this.changesMade = true;
    }
  },
  watch: {
    data: {
      handler: function (newVal, oldVal) {
        if (this.saveData.name !== this.data.name || this.saveData.description !== this.data.description || this.saveData.shortDescription !== this.data.shortDescription || this.saveData.strengthsExplanation !== this.data.strengthsExplanation || this.saveData.developmentAreasExplanation !== this.data.developmentAreasExplanation || this.saveData.score !== this.data.score) {
          this.changesMade = true;
        }
        this.saveData = { ...this.data };

        if (this.data.type === 'root') {
          this.selectedTypeID = 1;
        } else if (this.data.type === 'question') {
          this.selectedTypeID = 2;
        } else if (this.data.type === 'answer') {
          this.selectedTypeID = 3;
        }
      },
      deep: true
    },
  },
  methods: {
    toggleCard() {
      this.expanded = !this.expanded;
    },
    selectDimensionType(id) {
      this.selectedTypeID = id;
      this.data.type = this.dimensionTypes.find(type => type.id === id).name.toLowerCase();
      this.dropdownVisible = false;
    },
    dropdownOutsideClick() {
      this.dropdownVisible = false;
    },
    saveDimension() {
      this.saveLoading = true;

      if (this.data.isNew) {
        delete this.saveData.isNew;
        testDimensionService.addDimension(this.saveData).then(() => {
          this.changesMade = false;
        }).catch(error => {
          this.data.isNew = true;
          handleErrorResponse(error, this.$snackbar);
        }).finally(() => {
          this.saveLoading = false;
          this.requestRefresh();
        });
      } else {
        testDimensionService.updateDimension(this.saveData).then(() => {
          this.changesMade = false;
        }).catch(error => {
          handleErrorResponse(error, this.$snackbar);
        }).finally(() => {
          this.saveLoading = false;
        });
      }

    },
    handleDelete() {
      if (this.data.isNew) {
        this.onDelete(this.data);
      } else {
        this.saveLoading = true;
        testDimensionService.deleteDimension(this.data.dimensionalId).then(() => {
          this.onDelete(this.data);
        }).catch(error => {
          handleErrorResponse(error, this.$snackbar);
        }).finally(() => {
          this.saveLoading = false;
          this.requestRefresh();
        });
      }
    }
  }
}
</script>

<style scoped>
.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
}

.question-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.left-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.right-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px
}

.question-number-wrapper {
  background-color: #040C2D;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-number {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #FFFFFF;
}

.question-body {
  padding: 15px 0;
}

.question-dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
  white-space: nowrap;
}

.question-dropdown img {
  height: 16px;
  width: 16px;
}

.question-dropdown span {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 12px;
}

.dropdown-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
}

.dropdown-button:hover, .dropdown-button.active {
  background-color: #F2F4F7;
}

.dropdown-button .arrow {
  transition: transform 0.1s linear;
}

.dropdown-button.active .arrow {
  transform: rotate(180deg);
}

.dropdown-items {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 10px;
  padding: 20px;
  min-width: 130px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 99;
}

.dropdown-items.tags {
  width: 170px;
  height: 200px;
  overflow-y: scroll;
  left: unset;
  right: 0;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
}

.dropdown-item:hover {
  background-color: #F5F7FA;
}

.question-input, .answer-input, .score-input  {
  height: 100%;
  border: none;
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  background-color: #F5F5F5;
  color: #282A36;
  padding: 15px;
  border-radius: 8px;
}

.question-input {
  width: 100%;
  padding-right: 56px;
}

.answer-input {
  width: 300px;
}

.score-input {
  width: 100px;
}

.answer-input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.answer-input-row .input-row{
  width: 100%;
  box-sizing: border-box;
}

.answer-input-row span {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #667085;
}

h4 {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
  margin: 0;
  padding: 0;
}

.type-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-answer-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  color: #4A68F8;
  margin-top: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  width: fit-content;
  transition: background-color 0.2s ease-in-out;
}

.add-answer-button:hover {
  background-color: #f3f4ff;
}

.toggle-card {
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  height: 20px;
  width: 20px;
  background-color: #FFFFFF;
  border: 1px solid #D0D5DD;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.toggle-card img {
  height: 9px;
  width: 9px;
}

.toggle-card.closed img {
  transform: rotate(-45deg);
}


.number-input-row input {
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  background-color: #ffffff;
  color: #282A36;
  padding: 15px;
  width: 100%;
  border-top: 1px solid #D0D5DD;
  border-bottom: 1px solid #D0D5DD;
  border-left: none;
  border-right: none;
  height: 48px;
}

.number-input-row .left-part, .number-input-row .right-part {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
  background-color: #FCFCFD;
  border: 1px solid #D0D5DD;
}

.number-input-row .left-part img, .number-input-row .right-part img {
  height: 16px;
  width: 16px;
}

.number-input-row .left-part {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.number-input-row .right-part {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.question-wrapper {
  position: relative;
  display: flex;
}

.question-wrapper .input-actions {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.description-wrapper {
  display: flex;
  gap: 5px;
  border-bottom: 1px solid #D0D5DD;
  width: 100%;
  padding: 10px 0;
}

.description-wrapper span {
  white-space: nowrap;
  font-size: 14px;
  color: #98A2B3;
}

.description-wrapper input {
  border: none;
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #282A36;
  width: 100%;
}


.header-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
}

.header-button span {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 12px;
}

.header-button:hover {
  background-color: #F2F4F7;
}

.question-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
}

.input-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.save-text {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #667085;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.save-text.highlight {
  color: #4A68F8;
}

@media screen and (max-width: 768px) {
  .question-header {
    flex-direction: column;
    gap: 10px;
  }

  .dropdown-items {
    left: auto;
    right: 0;
  }
}
</style>

