<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">{{ $t("preview_question") }}</span>
        <span class="modal-description">{{ $t("check_look") }}</span>
      </div>
      <div class="question-list">
        <div class="dropdown" @click="toggleDropdown" v-click-outside="hideDropdown">
          <span class="list-text" v-if="selectedModule===null || submodule.parts.length===0">{{$t("choose_an_offer")}}
          <img src="@/assets/images/arrowdown.svg" />
          </span>
          <span class="list-text" v-else>{{$t("question_type_program")}} {{selectedModule?.order}}
          <img src="@/assets/images/arrowdown.svg" /></span>

          <div class="dropdown-content" v-if="dropdown">
            <div class="list scroll-design">
              <span class="item" v-if="submodule.parts.length===0">No step found</span>
              <div class="item" v-for="item in submodule.parts" @click="assignModule(item)">
                <span>{{$t("question_type_program")}} {{item.order}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="arrow">
          <img @click="goBack" class="cross" src="../../assets/images/arrowLeft.svg" />
        </div>
        <div class="preview-holder">
          <img src="../../assets/images/phoneMockup.png" class="phone-image"/>
          <div v-if="submodule.parts?.length===0" class="content-holder">
            <div class="default-holder">
            <img src="../../assets/images/ibility-Logo.svg" style="width: 50px; align-self: center; justify-self: center;"/>
            <span class="choose_text">{{$t("create_first")}}</span>
            </div>
          </div>
          <div v-if="(selectedModule?.content?.type==='welcomeText' || selectedModule?.content?.type==='summary')&& this.showPreview===true " class="content-holder">
            <WelcomeTextType
            :go-forward="goForward"
            :selected-module="selectedModule"
            :submodule="submodule">
            </WelcomeTextType>
          </div>

          <div v-if="selectedModule?.content?.type === 'audio' && this.showPreview===true" class="content-holder">
           <AudioType
               :submodule="submodule"
               :selectedModule="selectedModule"
           ></AudioType>
          </div>

          <div v-if="selectedModule?.content?.type==='scenarioText' && this.showPreview===true" class="content-holder">
            <ScenarioType
                :selectedModule="selectedModule"
                :submodule="submodule"
            ></ScenarioType>
          </div>


          <div v-if="selectedModule?.content?.type==='success' && this.showPreview===true" class="content-holder">
            <SuccessType></SuccessType>
          </div>

          <div v-if="selectedModule?.content?.type==='failed' && this.showPreview===true" class="content-holder">
            <FailedType></FailedType>
          </div>

          <div v-if="selectedModule?.content?.type==='video' && this.showPreview===true" class="content-holder">
            <VideoTypeV2
                :submodule="submodule"
                :selectedModule="selectedModule"
            ></VideoTypeV2>
          </div>

          <div v-if="selectedModule?.content?.type==='selfChat' && this.showPreview===true" class="content-holder">
            <SelfChatType
                :selectedModule="selectedModule"
                :submodule="submodule"
                :go-forward="goForward"
            ></SelfChatType>
          </div>

          <div v-if="selectedModule?.content?.type==='fillTheBlank' && this.showPreview===true" class="content-holder">
            <FillTheBlankType
                :selectedModule="selectedModule"
                :submodule="submodule"
                :go-forward="goForward"
            ></FillTheBlankType>
          </div>

          <div v-if="(selectedModule?.content?.type==='single' || selectedModule?.content?.type==='multiple' || selectedModule?.content?.type==='yesNo') && this.showPreview===true" class="content-holder">
            <SingleType
                :selectedModule="selectedModule"
                :submodule="submodule"
                :go-forward="goForward"
            ></SingleType>
          </div>

          <div v-if="((selectedModule?.content?.type==='number') || (selectedModule?.content?.type==='text') || (selectedModule?.content?.type==='rating') || (selectedModule?.content?.type==='grading') || (selectedModule?.content?.type==='dragAndDrop') || (selectedModule?.content?.type==='matchType'))&& this.showPreview===true" class="content-holder">
            <NumberType
                :selectedModule="selectedModule"
                :submodule="submodule"
                :go-forward="goForward"
            ></NumberType>
          </div>


        </div>
          <div class="arrow">
          <img @click="goForward" src="@/assets/images/arrowLeft.svg" style="transform: scaleX(-1);" />
        </div>
      </div>

      <div class="modal-footer">
      <span class="footer-text" @click="goToStart"> {{ $t("start_from_beginning") }}</span>
      </div>

      <div @click="this.onHide" class="close-btn">
        <img class="cross" src="../../assets/images/cross.svg" />
      </div>

    </div>
    </div>
  </Transition>
</template>



<script>
import WelcomeTextType from "@/components/preview-components/WelcomeTextType.vue";
import AudioType from "@/components/preview-components/AudioType.vue";
import ScenarioType from "@/components/preview-components/ScenarioType.vue";
import SelfChatType from "@/components/preview-components/SelfChatType.vue";
import SuccessType from "@/components/preview-components/SuccessType.vue";
import FailedType from "@/components/preview-components/FailedType.vue";
import VideoTypeV2 from "@/components/preview-components/VideoTypeV2.vue";
import SingleType from "@/components/preview-components/SingleType.vue";
import NumberType from "@/components/preview-components/NumberType.vue";
import FillTheBlankType from "@/components/preview-components/FillTheBlankType.vue";

export default {
  components: {
    FillTheBlankType,
    NumberType,
    SingleType,
    VideoTypeV2,
    FailedType, SuccessType, SelfChatType, ScenarioType, AudioType, WelcomeTextType},
 watch:{
   selectedModule:{
     handler: function() {
       this.current=parseInt(this.selectedModule?.order);
     }
   },
   submodule: {
     handler: function() {
       if (this.submodule.parts.length === 0) {
         this.showPreview=false;
         return;
       }
       else{
         this.showPreview=true;
       }

       this.selectedModule = this.submodule.parts[0]
     },
     deep: true,
     immediate: true
   }
 },
  data() {
    return {
      current:parseInt(this.selectedModule?.order),
      dropdown:false,
      selectedModule:(this.submodule?.parts[0]|| null),
      showPreview:true,
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    submodule: {
      type: Object,
      default: () => {}
    },
  },
  methods:{
    goToStart(){
      this.selectedModule = this.submodule.parts[0]
    },
    toggleDropdown() {
      this.dropdown = !this.dropdown;
    },
    hideDropdown() {
      this.dropdown = false;
    },
    assignModule(item){
      this.selectedModule=item;
    },
    goForward() {
      if (this.selectedModule) {
        const nextOrder = this.selectedModule.order;
        const maxOrder = Object.keys(this.submodule.parts).length - 1;

        if (nextOrder <= maxOrder && this.submodule.parts[nextOrder]) {
          this.selectedModule = this.submodule.parts[nextOrder];
        }
      }
    },

    goBack() {
      if (this.selectedModule) {
        const previousOrder = this.selectedModule.order-2;

        if (previousOrder >= 0 && this.submodule.parts[previousOrder]) {
          this.selectedModule = this.submodule.parts[previousOrder];
        }
      }
    }

  }
}

</script>

<style scoped>
.choose_text{
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  font-family: euclid_semi_bold, 'sans-serif';
  font-size: 10px;
}
.default-holder{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.cross{
  align-self: center;
  justify-self: center;
  display: flex;
  padding: 8px;
}
.content-holder{
  display: flex;
  flex-direction: column;
  position: absolute;
  top:8%;
  left:5.5%;
  right: 10%;
  width: 88.5%;
  height: 85%;

}
.list-text{
  font-family: 'Euclid Circular A', sans-serif;
  font-size: 12px;
  color: #667085;
  width: 100%;
  text-align: center;
}
.modal-description{
  font-family: 'Euclid Circular A', sans-serif;
  font-size: 14px;
  color: #667085;
}
.modal-title{
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 18px;
  color: #040C2D;
}
.list {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
}
.dropdown-content {
  position: absolute;
  top: 40px;
  width: 140px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 10px;
  z-index: 999;
}

.dropdown {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  align-self: center;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #000000;
  padding: 10px;
}

.item:hover {
  background-color: #F5F5F5;
}
.question-list{
  align-self: center;
  align-items: center;
  display: flex;
  width: 20%;
}
.footer-text{
  font-family: 'euclid_medium', sans-serif;
  color: #4A68F8;
  font-size: 12px;
  cursor: pointer;
}
.modal-footer{
  align-items: center;
  justify-content: center;
}
.preview-holder{
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-self: center;
  display: flex;
  height: 100%;
  position: relative;
}
.phone-image{
  height: 100%;
  position: relative;
}
.modal {
  min-width: 400px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  z-index: 99999;
}

.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
  flex-direction: column;
}
.modal-body{
  display: flex;
  height: 50%;
  margin-top: 20px;
  background-color: #f0f0f0;
  align-items: center;
  justify-content: space-between;
}
.close-btn {
  display: flex;
  align-content: center;
  justify-content: center;
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
</style>