<template>
  <div class="scroll-area">
  <div class="space">
  <div class="content-header">
    <span class="module-header">{{ submodule.name }}</span>
    <span class="welcome-text">{{ $t("question_type_program") }}</span>
    <div class="tab-holder">
      <span class="tab1">{{ $t("who_is_talking") }}</span>
      <span class="tab2">{{ $t("me") }}</span>

    </div>
    <div class="progress-container">
      <div class="progress-bar"></div>
    </div>
  </div>
  <div class="scenario-body">
    <div class="chat-holder">
      <div class="message-box">
        <span class="message-text">{{ $t("selfchat_dialog") }}</span>
      </div>
      <span class="message-time">{{$t("just_now")}}</span>
      <div class="my-message-box">
        <span class="my-message-text">{{ $t("selfchat_dialog") }}</span>
      </div>
      <span class="my-message-time">{{$t("just_now")}}</span>
    </div>
  </div>
  </div>
  <div class="selfchat-button-holder">
    <span class="selfchat-button">{{ $t("consult") }}</span>
    <span class="selfchat-button" @click="goForward">{{ $t("next_question") }}</span>
  </div>
  <div class="keyboard-holder">
    <span class="note-input">{{ $t("write_your_note") }}</span>
    <img class="share-button" src="@/assets/images/sendCircle.svg"/>
  </div>
  </div>
</template>
<script>
export default{
  props:{
    submodule:{
      type:Object
    },
    selectedModule:{
      type:Object
    },
    goForward:{
      type:Function,
    }
  }
}
</script>
<style scoped>
.scroll-area{
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
}
.scroll-area::-webkit-scrollbar{
  width: 0;
}
.space{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.space::-webkit-scrollbar {
  width: 0;
}
.share-button{
  margin-left: 5px;
  align-self: center;
  width: 20px;
}
.note-input{
  width: 100%;
  font-size: 10px;
  margin-left: 3%;
  border-radius: 6px;
  background-color: #F5F5F5;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.keyboard-holder{
  background-color: #FFFFFF;
  align-items: center;
  border-top: 2px solid #dedcdc;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 15%;
  margin-bottom: 5px;
  padding: 5px;
}
.message-text{
  display: inline-block;
  font-family: 'euclid_regular', sans-serif;
  font-size: 8px;
  text-align: start;
  height: 100%;
}
.selfchat-button {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 7px 10px;
  color: #fff;
  cursor: pointer;
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  background-color: black;
  transition: all 0.1s ease-in;
  border: 1px solid #040C2D;
  bottom: 5px;
  width: 50%;
}
.selfchat-button-holder{
  background-color: #ececec;
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}
.my-message-box{
  align-self: end;
  background-color: #4A68F8;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: 10px;
  width: 50%;
  height: min-content;
  margin: 10px;
  margin-bottom: 0px;
}
.message-time{
  position: relative;
  margin-top: 3px;
  margin-left: 10px;
  text-align: start;
  font-family: 'euclid_regular', sans-serif;
  font-size: 7px;
  color: #98A2B3
}
.my-message-time{
  margin-bottom: 10px;
  position: relative;
  margin-top: 3px;
  margin-right: 10px;
  text-align: end;
  font-family: 'euclid_regular', sans-serif;
  font-size: 7px;
  color: #98A2B3
}
.my-message-text{
  align-self: center;
  justify-content: center;
  margin-left: 5px;
  text-align: start;
  display: flex;
  font-family: 'euclid_regular', sans-serif;
  font-size: 8px;
  color: #FFFFFF;
}
.message-box{
  width: 80%;
  height: min-content;
  background-color: #FFFFFF;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin: 10px;
  padding: 10px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.chat-holder{
  display: flex;
  flex-direction: column;
}
.scenario-body{
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  background-color: #ececec;
  height: 100%;
  width: 100%;
  align-self: center;
  justify-self: center;
  overflow-y:scroll ;
}
.scenario-body::-webkit-scrollbar {
  width: 0px; /* Scrollbar genişliği */
}
.progress-bar {
  height: 100%;
  background-color: #4A68F8;
  width: 20%;
  transition: width 0.3s ease-in-out;
}
.progress-container {
  display: flex;
  width: 100%;
  height: 3.5px;
  background-color: #ececec;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 2px;
}
.tab2{
  display: flex;
  font-size: 9px;
  background-color: #4A68F8;
  width: 40%;
  height: 15px;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  color: #FFFFFF;
}
.tab1{
  font-size: 9px;
}
.tab-holder{
  align-self: center;
  margin-top: 5%;
  gap:5px;
  padding: 5px;
  display: flex;
  background-color: #ececec;
  width: 90%;
  height: fit-content;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
}
.welcome-text{
  text-align: center;
  font-family: 'euclid_regular', sans-serif;
  font-size: 7px;
  color: #9194A3;
}
.scenario-content{
  display: flex;
  flex-direction: column;
  position: absolute;
  top:10%;
  left:10%;
  right: 10%;
  width: 100%;
  height: 100%;
}
.content-header{
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.module-header{
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 12px;
  color: #040C2D;
  text-align: center;
}
</style>