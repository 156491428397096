<template>
  <Layout>
    <div class="main-wrapper">
      <div class="header">
        <input class="title-input" placeholder="New Rule" v-model="ruleData.name" />

        <div class="justify-end-add-btn no-text-select" @click="handleSave">
          <span>Save Rule</span>
        </div>
      </div>
      <div class="user-flow-wrapper">
        <div class="left">
          <div class="flow-list">
            <template class="flow-item" v-for="component in ruleData.components" :key="component.id">
              <FlowComponent
                  :data="component"
                  :selected="selectedComponentId === component.id"
                  :on-click="() => handleComponentClick(component.id)"
                  :on-delete="() => handleComponentDelete(component.id)"
              />

              <BranchRenderer
                  :handle-component-click="(cid) => handleComponentClick(cid)"
                  :handle-component-delete="(cid) => handleComponentDelete(cid)"
                  :selected-component-id="selectedComponentId"
                  :branch-data="component"
                  :handle-branch-component-add="(compData, side) => handleBranchComponentAdd(compData, side)"
                  :nesting-level="1"
              />
            </template>

            <div class="flow-item">
              <AddComponentButton :disabled="addComponentDisabled" :on-click="handleAddComponent"/>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="component-list" v-if="getComponentById(selectedComponentId)?.type === 'whenStart'">
            <template v-for="(components, groupKey) in whenComponents" :key="groupKey">
              <h3 class="group-title">{{ formatGroupTitle(groupKey) }}</h3>
              <div class="list-items">
                <div
                    v-for="component in components"
                    :key="component.type"
                    class="list-item-wrapper"
                    @click="handleWhenSelect(component)"
                >
                  <div class="icon-wrapper green">
                    <img :src="component.icon" />
                  </div>
                  <span class="name">{{ component.name }}</span>
                </div>
              </div>
            </template>
          </div>

          <div class="component-list" v-else-if="getComponentById(selectedComponentId)?.type === 'default'">
            <h3 class="group-title">Actions</h3>
            <div class="list-items">
              <div class="list-item-wrapper" @click="onThenSelected()">
                <div class="icon-wrapper blue">
                  <img src="@/assets/images/lightningBlue.svg" />
                </div>
                <span class="name">THEN: Add an action</span>
              </div>

              <div class="list-item-wrapper" @click="onIfSelected()">
                <div class="icon-wrapper yellow">
                  <img src="@/assets/images/filterYellow.svg" />
                </div>
                <span class="name">IF: Add a condition</span>
              </div>
            </div>
          </div>


          <div class="component-list" v-if="getComponentById(selectedComponentId)?.type === 'thenStart'">
            <template v-for="(components, groupKey) in thenComponents" :key="groupKey">
              <h3 class="group-title">{{ formatGroupTitle(groupKey) }}</h3>
              <div class="list-items">
                <div
                    v-for="component in components"
                    :key="component.type"
                    class="list-item-wrapper"
                    @click="handleThenSelect(component)"
                >
                  <div class="icon-wrapper blue">
                    <img :src="component.icon" />
                  </div>
                  <span class="name">{{ component.name }}</span>
                </div>
              </div>
            </template>
          </div>

          <div class="component-list" v-if="getComponentById(selectedComponentId)?.type === 'ifStart'">
            <template v-for="(components, groupKey) in ifComponents" :key="groupKey">
              <h3 class="group-title">{{ formatGroupTitle(groupKey) }}</h3>
              <div class="list-items">
                <div v-for="component in components">
                <div
                    v-if="component.shownInMenu"
                    :key="component.type"
                    class="list-item-wrapper"
                    @click="handleIfSelect(component)"
                >
                  <div class="icon-wrapper yellow">
                    <img :src="component.icon" />
                  </div>
                  <span class="name">{{ component.name }}</span>
                </div>
                </div>
              </div>
            </template>
          </div>

          <div class="component-list" v-else-if="findWhenComponentByType(getComponentById(selectedComponentId)?.type)?.settings?.length > 0">
            <h3 class="group-title">Component Settings</h3>

            <div class="setting-list">
              <SettingsRenderer
                  :key="setting.id"
                  :setting="setting"
                  :componentData="getComponentById(selectedComponentId)"
                  v-for="setting in findWhenComponentByType(getComponentById(selectedComponentId)?.type)?.settings"
              />
            </div>
          </div>

          <div class="component-list" v-else-if="findThenComponentByType(getComponentById(selectedComponentId)?.type)?.settings?.length > 0">
            <h3 class="group-title">Component Settings</h3>

            <div class="setting-list">
              <SettingsRenderer
                  :key="setting.id"
                  :setting="setting"
                  :componentData="getComponentById(selectedComponentId)"
                  v-for="setting in findThenComponentByType(getComponentById(selectedComponentId)?.type)?.settings"
              />
            </div>
          </div>

          <div class="component-list" v-else-if="findIfComponentByType(getComponentById(selectedComponentId)?.type)?.settings?.length > 0">
            <h3 class="group-title">Component Settings</h3>

            <div class="setting-list">
              <SettingsRenderer
                  :key="setting.id"
                  :setting="setting"
                  :componentData="getComponentById(selectedComponentId)"
                  v-for="setting in findIfComponentByType(getComponentById(selectedComponentId)?.type)?.settings"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import FlowComponent from "@/components/user-flow/FlowComponent.vue";
import AddComponentButton from "@/components/user-flow/AddComponentButton.vue";
import BranchRenderer from "@/components/user-flow/BranchRenderer.vue";
import {
  findIfComponentByType,
  findThenComponentByType,
  findWhenComponentByType, IF_COMPONENTS,
  THEN_COMPONENTS,
  WHEN_COMPONENTS
} from "@/components/user-flow/constants";
import SettingsRenderer from "@/components/user-flow/SettingsRenderer.vue";
import userflowService from "@/service/userflowService";
import {handleErrorResponse} from "@/utils/utils";

export default {
  name: 'UserflowDetails',
  components: {SettingsRenderer, BranchRenderer, AddComponentButton, FlowComponent, Layout},
  data() {
    return {
      whenComponents: WHEN_COMPONENTS,
      thenComponents: THEN_COMPONENTS,
      ifComponents: IF_COMPONENTS,
      isNew: true,
      selectedComponentId: null,
      ruleData: {
        name: '',
        components: [
          {
            id: 1,
            type: 'whenStart',
            data: {},
          },
        ],
      },

    }
  },
  mounted() {
    const routeParams = this.$route.params;

    if (routeParams.id !== "new") {
      this.isNew = false;
     // this.handleGetDetail();
    } else {
      this.isNew = true;
      this.selectedComponentId = 1;
    }
  },
  methods: {
    findIfComponentByType,
    findWhenComponentByType,
    findThenComponentByType,
    handleComponentClick(id) {
      this.selectedComponentId = id;
    },
    formatGroupTitle(groupKey) {
      return groupKey
          .replace(/([A-Z])/g, ' $1')
          .replace(/^./, str => str.toUpperCase())
          .trim()
    },
    handleWhenSelect(component) {
      this.ruleData.components[0] = {
        type: component.type,
        id: 1
      };
    },
    handleThenSelect(component) {
      const selectedComponent = this.getComponentById(this.selectedComponentId);
      if (selectedComponent) {
        selectedComponent.type = component.type;
      }
    },
    handleIfSelect(component) {
      const selectedComponent = this.getComponentById(this.selectedComponentId);

      if (selectedComponent) {
        selectedComponent.type = 'branch';
        selectedComponent.branches = [
          {
            type: component.type,
            id: this.findLastComponentId() + 1,
            side: 'if'
          }
        ]
      }
    },
    onThenSelected() {
      const selectedComponent = this.getComponentById(this.selectedComponentId);
      if (selectedComponent) {
        selectedComponent.type = 'thenStart';
      }
    },
    onIfSelected() {
      const selectedComponent = this.getComponentById(this.selectedComponentId);
      if (selectedComponent) {
        selectedComponent.type = 'ifStart';
      }
    },
    handleAddComponent() {
      const lastComponentId = this.findLastComponentId();
      this.ruleData.components.push({
        id: lastComponentId + 1,
        type: 'default',
      });
    },
    handleBranchComponentAdd(component, side = 'if') {
      const lastComponentId = this.findLastComponentId();

      if (component) {
        if (side === 'else' && component.branches?.filter(item => item.side === 'else').length === 0) {
          component.branches.push({
            id: lastComponentId + 1,
            type: 'elseIfCondition',
            side: side,
          });

          return;
        }

        component.branches.push({
          id: lastComponentId + 1,
          type: 'default',
          side: side,
        });
      }
    },
    handleComponentDelete(id) {
      if (id === 1) {
        this.ruleData.components[0] = {
          id: 1,
          type: 'whenStart',
        };
      } else {
        this.deleteComponentById(id);
      }
    },
    findLastComponentId() {
      const components = this.ruleData.components;
      let maxId = 0;

      const traverseComponents = (component) => {
        // Update maxId if current component's id is higher
        if (component.id > maxId) {
          maxId = component.id;
        }

        // If component has branches, traverse through them
        if (component.branches && component.branches.length > 0) {
          component.branches.forEach(branch => {
            traverseComponents(branch);
          });
        }
      };

      // If input is array, traverse each component
      if (Array.isArray(components)) {
        components.forEach(component => {
          traverseComponents(component);
        });
      } else {
        // If input is single component, traverse it
        traverseComponents(components);
      }

      return maxId;
    },
    getComponentById(id) {
      const components = this.ruleData.components;
      let foundComponent = null;

      const traverseComponents = (component) => {
        if (component.id === id) {
          foundComponent = component;
        }

        if (component.branches && component.branches.length > 0) {
          component.branches.forEach(branch => {
            traverseComponents(branch);
          });
        }
      };

      if (Array.isArray(components)) {
        components.forEach(component => {
          traverseComponents(component);
        });
      } else {
        traverseComponents(components);
      }

      return foundComponent;
    },
    deleteComponentById(idToDelete) {
      const deleteRecursive = (components) => {
        if (Array.isArray(components)) {
          // Find direct child
          const componentIndex = components.findIndex(component => component.id === idToDelete);

          // If found in direct children, remove it
          if (componentIndex !== -1) {
            components.splice(componentIndex, 1);
            // Also clear selectedComponentId if it was the deleted component
            if (this.selectedComponentId === idToDelete) {
              this.selectedComponentId = null;
            }
            return true;
          }

          // Search in branches
          for (const component of components) {
            if (component.branches && deleteRecursive(component.branches)) {
              return true;
            }
          }
        }
        return false;
      };

      deleteRecursive(this.ruleData.components);
    },
    handleSave() {
      userflowService.addUserflow(this.ruleData)
          .then(() => {
            this.$snackbar.add({
              message: 'Rule saved successfully',
              type: 'success',
            });
          })
          .catch(error => {
            handleErrorResponse(error, this.$snackbar);
          });
    },
  },
  computed: {
    addComponentDisabled() {
      const whenStartComponent = this.ruleData.components.filter(component => component.type === 'whenStart');
      const defaultComponents = this.ruleData.components.filter(component => component.type === 'default');

      if (whenStartComponent?.length > 0) {
        return true;
      }

      if (defaultComponents?.length > 0) {
        return true;
      }

      return false;
    },
  }
}
</script>

<style scoped>
.main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
}

.header {
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.user-flow-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: calc(100% - 94px);
  gap: 10px;
}

.flow-list {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.flow-item {
  position: relative;
  width: 100%;
}

.user-flow-wrapper .left {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 20px;
}

.user-flow-wrapper .right {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #FCFCFD;
  border: 1px solid #EAECF0;
}

.title-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  background-color: transparent;
  color: #040C2D;
  flex: 1;
}

.component-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
  height: 100%;
}

.setting-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
}

.component-list h3 {
  font-family: euclid_semi_bold, sans-serif;
  font-size: 14px;
  color: rgba(4, 12, 45, 0.6);
  text-transform: uppercase;
  padding: 0;
  letter-spacing: 0.6px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
}

.list-item-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
  transition: background-color 0.1s;
}

.list-item-wrapper:hover {
  background-color: #F5F7FA;
}

.list-item-wrapper .name {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: rgba(4, 12, 45, 0.5);
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 12px;
}

.icon-wrapper.green {
  background-color: #e3fcef;
}

.icon-wrapper.yellow {
  background-color: #FFF0B3;
}

.icon-wrapper.blue {
  background-color: #DEEBFF;
}

.icon-wrapper img {
  width: 18px;
  height: 18px;
}
</style>
