<template>
  <Layout>
    <div class="filter-wrapper">
     <div></div>

      <div class="justify-end-div">
        <ExpandableSearch />
        <router-link to="/onboarding/new">
          <div class="justify-end-add-btn no-text-select">
            <img src="../assets/images/plus.svg" />
            <span>{{ $t('add_new') }}</span>
          </div>
        </router-link>
      </div>
    </div>
    <div class="onboarding-grid">

      <router-link v-for="item in onboardings.data"  v-if="!isLoading" :to="`/onboarding/${item.onboardingId}`">

        <div  class="onboarding-card no-text-select">
          <div class="status-tag" :class="item.status ? 'Active' : 'Draft'">
            <div class="status-dot" :class="item.status ? 'Active' : 'Draft'">
            </div>
            <span>{{item.status ? $t('active') : $t('draft')}}</span>
          </div>

          <span class="date">{{formatDate(item.created_at)}}</span>
          <span class="title">{{item.name}}</span>

          <div class="info-row">
            <span class="info-tag">{{$t('version_number', {versionNumber: item.versionNumber})}}</span>
            <span class="info-tag">{{$t('question_count', {questionCount: item.questionCount})}}</span>
            <span class="info-tag">{{$t('answer_count', {answerCount: item.answerCount})}}</span>
          </div>
        </div>
      </router-link>

      <free-style-shimmer
          :is-loading="isLoading"
          height="200px"
          width="100%"
          border-radius="20px"
          color="#EAECF0"
      />

      <free-style-shimmer
          :is-loading="isLoading"
          height="200px"
          width="100%"
          border-radius="20px"
          color="#EAECF0"
      />

      <free-style-shimmer
          :is-loading="isLoading"
          height="200px"
          width="100%"
          border-radius="20px"
          color="#EAECF0"
      />

    </div>
    <div class="pagination-align-center">
      <Pagination :current-page="currentPage" :total-items="onboardings.total" :per-page="20" :on-page-change="handlePage"/>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import testService from "@/service/testService";
import onboardingService from "@/service/onboardingService";
import Pagination from "@/components/Pagination.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import TestFilters from "@/components/tables/Test/TestFilters.vue";

export default {
  name: 'Onboarding',
  components: {
    TestFilters,
    ExpandableSearch,
    Pagination,
    Layout,
  },
  data() {
    return {
      isLoading: false,
      currentPage:1,
      onboardings: [],
    }
  },

  watch: {
    $route() {
      this.handlePage(1);
    }
  },

  methods: {
    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return '-';
      }
    },

    handlePage(page) {
      if (this.isLoading && this.currentPage !== 1) return;
      this.isLoading = true;

      const routeQuery = this.$route.query;
      const tempFilters = [];

      if (routeQuery.searchText) {
        tempFilters.push({
          key: "searchText",
          value: routeQuery.searchText,
        });
      }

      onboardingService.getOnboarding(page, tempFilters).then((response) => {
        this.isLoading = false;
        this.onboardings = response.data.data;
      })
    },

  },
}
</script>

<style scoped>
.onboarding-grid {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.onboarding-card {
  width: 100%;
  height: 100%;
  border: 1px solid #EAECF0;
  background-color: #FCFCFD;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.status-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040c2d;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.status-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.status-tag.Draft {
  background-color: #fad9c6;
}

.status-tag.Published {
  background-color: #ddfbde;
}

.status-dot.Draft {
  background-color: #f09460;
}

.status-dot.Published {
  background-color: #3da33e;
}

.date {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #667085;
  margin-top: 30px;
}

.title {
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  color: #040C2D;
  margin-bottom: 10px;
}

.info-row {
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  gap: 5px;
}

.info-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 10px;
  color: #040C2D;
  border-radius: 8px;
  padding: 3px 15px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #F2F4F7;
}

@media (max-width: 1200px) {
  .onboarding-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .onboarding-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

</style>
