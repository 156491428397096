<template>
  <div class="slider-container">
    <label v-if="label !== ''">{{ label }}</label>
    <input
        type="range"
        :min="min"
        :max="max"
        :step="step"
        v-model="sliderValue"
        @input="updateValue"
        class="slider"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  modelValue: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['update:modelValue']);
const sliderValue = ref(props.modelValue);

const updateValue = () => {
  emit('update:modelValue', sliderValue.value);
};
</script>

<style scoped>
.slider-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.slider-container label {
  font-size: 10px;
  color: #040C2D;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 12px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  background: #040C2D;
  cursor: pointer;
  border-radius: 50px;
}

.slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: #040C2D;
  cursor: pointer;
  border-radius: 50px;
}
</style>
