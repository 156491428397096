<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-top">
          <div class="modal-header">
            <span class="modal-header-txt">{{$t('send_notifiaction')}}</span>
            <span class="modal-header-description">{{$t('send_notifiaction_text')}}</span>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('users_tab')}}
              </span>
              <span class="setting-text">
                {{$t('users_send_notification')}}
              </span>
            </div>
            <div class="setting-right">
            </div>
          </div>
          <multiselect class="mt-3" v-model="userIds" :options="userList" track-by="id" label="name" :multiple="true"></multiselect>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('date_payment')}}
              </span>
              <span class="setting-text">
                {{$t('date_notification')}}
              </span>
            </div>
            <div class="setting-right">
              <input type="datetime-local" class="form-input" v-model="sendDate" :min="minDateTime" />
            </div>
          </div>

          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>

        <div class="modal-bottom">
          <button class="primary-button" @click="sendNotification" :disabled="sendLoading">
            <VueSpinnerIos v-if="sendLoading" />
            <span v-else>{{$t('send')}}</span>
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Switch from "@/components/Switch.vue";
import commonService from "@/service/commonService";
import {deepClone, formatDateDMYHI, handleErrorResponse} from "@/utils/utils";
import notificationContentService from "@/service/notificationContentService";
import {VueSpinnerIos} from 'vue3-spinners';

export default {
  components: {
    Switch,
    Multiselect,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    journeyId: {
      type: String,
      default: ''
    },
    notifData: {
      type: Object,
      default: {}
    },
  },
  data () {
    return {
      userList: [],
      userIds: [],
      sendDate: '',
      sendLoading: false,
    }
  },
  mounted() {
    commonService.getUsers().then(response => {
      this.userList = response.data.data.users.filter(user => user.name && user.name.trim() !== '');
      if (this.$route.query.userIds) {
        try {
          const tempUserIds = this.$route.query.userIds.split(',');

          this.userIds = this.userList.filter(user => {
            return tempUserIds.includes(user.id);
          });
        } catch (e) {}
      }
    }).catch(err => {
      handleErrorResponse(err, this.$snackbar);
    });
  },
  methods: {
    sendNotification() {
      const sendData = deepClone(this.notifData);

      sendData.userIds = this.userIds.map(user => {
        return user.id;
      });

      sendData.journeyId = this.journeyId;

      if (this.sendDate !== '') {
        try {
          sendData.sendDate = formatDateDMYHI(this.sendDate);
        } catch (e) {
          this.$snackbar.add({
            text: 'Invalid send date format.',
            type: 'error'
          });
          return;
        }
      }

      sendData.isTemplate = false;

      if (sendData.content.type === 'documentUpload') {
        sendData.content.options.map(option => {
          option.accepted = option.accepted.map(type => type.id).join(',');
        });
      }

      if (sendData.content.interviewDate && sendData.content.interviewDate !== '') {
        try {
          sendData.content.interviewDate = formatDateDMYHI(sendData.content.interviewDate);
        } catch (e) {
          this.$snackbar.add({
            text: 'Invalid date format.',
            type: 'error'
          });
          return;
        }
      }


      this.sendLoading = true;

      notificationContentService.addNotificationContent(sendData)
          .then(res => {
            this.$snackbar.add({
              text: 'Notification added.',
              type: 'success'
            });
            window.location.href = "/notification/" + res.data.data.notificationId;
          })
          .catch(err => {
            handleErrorResponse(err, this.$snackbar);
          })
          .finally(() => {
            this.sendLoading = false;
            this.onHide();
          });
    },
  },
  computed: {
    minDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    }
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  z-index: 99999;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}

.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
.modal-header-description {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
h5 {
  font-family: euclid_medium, sans-serif;
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.setting-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.setting-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.setting-name {
  font-family: euclid_medium, sans-serif;
  color: #060B27;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
}

.setting-text {
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.modal-top {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}

.primary-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
