<script setup>
import {ref} from "vue";

const props = defineProps(['options', 'selectedValue', 'placeholder', 'onSelect', 'loading', 'alignRight', 'fullWidth']);
const dropdownVisible = ref(false);

const handleChange = (value) => {
  dropdownVisible.value = false;
  props.onSelect(value);
};

const handleClickOutside = () => {
  dropdownVisible.value = false;
}

const toggleDropdown = () => {
  dropdownVisible.value = !dropdownVisible.value;
}

const findSelected = (val) => {
  if (!props.options) {
    return '';
  }

  if (props.options.length === 0) {
    return '';
  }

  return props.options.find(item => item.value === val).text;
}
</script>

<template>
  <div class="select-box-wrapper no-text-select" :class="fullWidth && 'full-width'" v-click-outside="handleClickOutside">

    <div class="select-custom" @click="toggleDropdown">
      <span class="select-placeholder" v-if="!selectedValue">{{ loading ? "Loading..." : placeholder }}</span>
      <span class="select-selected" v-else>{{findSelected(selectedValue)}}</span>
      <img src="@/assets/images/arrowdown.svg" />
    </div>

    <div class="select-custom-content" v-if="dropdownVisible" :class="alignRight ? 'align-right' : 'align-left'">
      <div v-if="options?.length > 0" class="select-custom-item" v-for="option in options" :key="option.value" :class="option.value === selectedValue && 'selected'" @click="handleChange(option.value)">
        {{ option.text }}
      </div>

      <div v-else class="select-custom-item">
        <span>{{$t('no_option_found')}}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.full-width, .full-width .select-custom, .full-width .select-custom-content {
  width: 100%;
}

.select-box-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select-custom {
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  background: #F5F5F5;
  padding: 15px 0 15px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.select-custom span {
  font-size: 14px;
  font-family: 'euclid_regular', sans-serif;
  padding-right: 10px;
}

.select-custom .select-placeholder {
  color: #717171;
}

.select-custom .select-selected {
  font-family: 'euclid_medium', sans-serif;
  color: #434343;
}

.select-custom img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.select-custom-content {
  position: absolute;
  top: calc(100% + 5px);
  width: 200px;
  border: 1px solid #EAECF0;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  z-index: 99999;
  max-height: 200px;
  overflow-y: auto;
}

.select-custom-content.align-right {
  right: 0;
}

.select-custom-content.align-left {
  left: 0;
}

.select-custom-item {
  padding: 10px;
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  cursor: pointer;
  border-radius: 8px;
}

.select-custom-item:hover {
  background-color: #F5F7FA;
}

.select-custom-content::-webkit-scrollbar {
  width: 15px;
}

.select-custom-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #fff;
}

.select-custom-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.select-custom-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

</style>
