<template>
  <div>
    <div @click="this.onHide" class="close-btn">
      <img class="cross" src="../../assets/images/cross.svg" />
    </div>
    <Transition name="slide-fade" appear>
      <div v-if="visible" class="modal" @click="onHide">
        <div class="modal-content" @click.stop>
          <div v-if="image" class="image-preview">
            <img :src="image" alt="Image Preview" />
          </div>
        </div>
      </div>
    </Transition>
  </div>

</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    image: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
  },
  setup(props, { emit }) {
    watch(() => props.visible, (newVal) => {
      if (!newVal) {
        // Reset any logic or states when modal is hidden
      }
    });

    return {
      onHide: props.onHide,
    };
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.modal-content {
  position: relative;
  background-color: white;
  width: 80%;
  max-width: 800px;
  padding: 20px;
  border-radius: 12px;
  overflow: hidden;
}

.modal-content img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  background: #f2f4f7;
  border: none;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100000; /* Ensures the button is above the modal */
}

.close-btn img {
  width: 14px;
  height: 14px;
}

.close-btn:hover {
  background-color: #e0e3e7; /* Slight hover effect */
}
</style>

