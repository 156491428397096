import axios from '../axios';

const openPositionsService = {
    getPositions(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');
            return axios.get(`/v1/admin/openpositions/store?page=${page}&limit=20${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/openpositions/store?page=${page}&limit=20`);
        }
    },

    getPositionsList() {
        return axios.get(`/v1/admin/openpositions/store?list=1`);
    },

    getPosition(id) {
        return axios.get(`/v1/admin/openpositions/getFromId/${id}`);
    },

    updatePosition(data) {
        return axios.post(`/v1/admin/openpositions/update`, data);
    },

    deletePosition(id) {
        return axios.delete(`/v1/admin/openpositions/delete/${id}`);
    },

    addPosition(data) {
        return axios.post(`/v1/admin/openpositions/add`, data);
    },

    openPositionResponse() {
        return axios.get('/v1/notification/openPositionRejected');
    }
}
export default openPositionsService;