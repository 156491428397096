<script setup>

import Switch from "@/components/Switch.vue";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  onAction: {
    type: Function,
    default: () => {}
  },
  onSettingsAction: {
    type: Function,
    default: () => {}
  },
  onCopyOnboarding: {
    type: Function,
    default: () => {}
  },

  onboardingData: {
    type: Object,
  },
});

import {onMounted, ref, watch} from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const content = ref(null);
let isClonable = ref(true);

watch(
    () => route.params, // İzlenecek değer
    (newParams, oldParams) => {
      isClonable.value = newParams.id !== 'new';
    }
);

onMounted(() => {
  isClonable.value = route.params.id !== 'new';
});

const goDown = () => {
  const container = content.value;
  if (container) {
    container.scrollTo({
      top: container.scrollHeight,
      behavior: 'smooth',
    });
  }
};

const goUp = () => {
  const container = content.value;
  if (container) {
    container.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};
</script>

<template>
  <div id="app" class="app-container"  ref="content">
    <div class="header">
      <div class="left-action">
        <router-link to="/onboarding">
          <div class="go-back">
            <img src="@/assets/images/arrowLeft.svg" />
            <span>{{$t('onboarding_tab')}}</span>
          </div>
        </router-link>
      </div>
      <div class="right-actions">
        <!-- <img src="@/assets/images/settingsOutline.svg" @click="onSettingsAction" /> -->
        <Switch v-model="onboardingData.status"/>
        <img src="@/assets/images/copyOutline.svg" v-if="isClonable" @click="onCopyOnboarding" />
        <span class="action-link" :class="disabled && 'disabled'" @click="onAction">{{$t('save_button')}}</span>
      </div>
    </div>

    <div class="container">
      <slot />
    </div>

    <div class="scroll-wrapper">
      <button class="go-button up" @click="goUp">
        <img src="@/assets/images/chevronUp.svg" />
      </button>
      <button class="go-button down" @click="goDown">
        <img src="@/assets/images/chevronDown.svg" />
      </button>
    </div>
  </div>
</template>


<style scoped>
.app-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: #FCFCFD;
  overflow-y: auto;
}

.header {
  position: fixed;
  width: 100%;
  background-color: #FFFFFF;
  border-bottom: 1px solid #EAECF0;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 999;
}

.container {
  padding-top: 86px;
  padding-bottom: 30px;
  margin: 0 auto;
  max-width: 820px;
}

.go-back {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 12px;
  font-family: euclid_medium, sans-serif;
}

.right-actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.right-actions span {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
}

.left-action a {
  color: #040C2D;
}

.left-action a:active {
  color: #040C2D;
}

.left-action a:visited {
  color: #040C2D;
}

.left-action a:hover {
  color: #040C2D;
}

.action-link {
  cursor: pointer;
  color: #1C3CD5;
}

.action-link.disabled {
  color: #B0B7C3;
}
</style>
