<template>
  <div
      class="branch-structure"
      v-if="branchData?.branches?.length > 0"
      v-for="component in getFilteredBranch('if')"
      :key="component.id"
  >
    <div class="branch-row">
      <div
          class="left-spacer"
          v-for="index in getIndentationLevel()"
      >
        <div class="connection-line" />
      </div>
      <FlowComponent
          :data="component"
          :selected="selectedComponentId === component.id"
          :on-click="() => handleComponentClick(component.id)"
          :on-delete="() => handleComponentDelete(component.id)"
          :indentation-level="nestingLevel + 1"
      />
    </div>

    <BranchRenderer
        :handle-component-click="(cid) => handleComponentClick(cid)"
        :handle-component-delete="(cid) => handleComponentDelete(cid)"
        :selected-component-id="selectedComponentId"
        :branch-data="component"
        :handle-branch-component-add="(compData, side) => handleBranchComponentAdd(compData, side)"
        :nesting-level="nestingLevel + 1"
    />
  </div>

  <div class="add-wrapper">
    <div
        v-for="index in getIndentationLevel()"
        class="left-spacer"
        v-if="branchData.type === 'branch'"
    >
      <div class="connection-line" />
    </div>

    <AddComponentButton
        v-if="branchData.type === 'branch'"
        :on-click="() => handleBranchComponentAdd(branchData, 'if')"
    />
  </div>

  <div
      class="branch-structure"
      v-if="branchData?.branches?.length > 0"
      v-for="component in getFilteredBranch('else')"
      :key="component.id"
  >
    <div class="branch-row">
      <div
          class="left-spacer"
          v-for="index in getIndentationLevel()"
      >
        <div class="connection-line" />
      </div>
      <FlowComponent
          :data="component"
          :selected="selectedComponentId === component.id"
          :on-click="() => handleComponentClick(component.id)"
          :on-delete="() => handleComponentDelete(component.id)"
          :indentation-level="nestingLevel + 1"
      />
    </div>

    <BranchRenderer
        :handle-component-click="(cid) => handleComponentClick(cid)"
        :handle-component-delete="(cid) => handleComponentDelete(cid)"
        :selected-component-id="selectedComponentId"
        :branch-data="component"
        :handle-branch-component-add="(compData, side) => handleBranchComponentAdd(compData, side)"
        :nesting-level="nestingLevel + 1"
    />
  </div>

  <div class="add-wrapper">
    <div
        v-for="index in getIndentationLevel()"
        class="left-spacer"
        v-if="branchData.type === 'branch'"
    >
      <div class="connection-line" />
    </div>

    <AddElseButton
        v-if="branchData.type === 'branch' && !checkElseBranch()"
        :on-click="() => handleBranchComponentAdd(branchData, 'else')"
    />

    <AddComponentButton
        v-else-if="branchData.type === 'branch'"
        :on-click="() => handleBranchComponentAdd(branchData, 'else')"
    />
  </div>
</template>

<script>
import FlowComponent from "@/components/user-flow/FlowComponent.vue";
import AddComponentButton from "@/components/user-flow/AddComponentButton.vue";
import AddElseButton from "@/components/user-flow/AddElseButton.vue";

export default {
  name: 'BranchRenderer',
  components: {AddElseButton, AddComponentButton, FlowComponent},
  props: {
    branchData: {
      type: Object,
      required: true,
    },
    selectedComponentId: {
      type: Number,
      required: true,
    },
    handleComponentClick: {
      type: Function,
      default: () => {},
    },
    handleComponentDelete: {
      type: Function,
      default: () => {},
    },
    handleBranchComponentAdd: {
      type: Function,
      default: () => {},
    },
    nestingLevel: {
      type: Number,
      default: 0
    },
  },
  methods: {
    checkElseBranch() {
      return this.branchData.branches.some((branch) => branch.side === 'else');
    },
    getIndentationLevel() {
      return this.nestingLevel;
    },
    getFilteredBranch(side) {
      if (side) {
        return this.branchData.branches.filter((branch) => branch.side === side);
      } else {
        return this.branchData.branches;
      }
    }
  },
}
</script>

<style scoped>
.branch-structure {
  position: relative;
  display: flex;
  flex-direction: column;
}

.branch-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.left-spacer {
  width: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.connection-line {
  width: 2px;
  height: 100%;
  background-color: #EAECF0;
}

.add-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}
</style>
