<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-top">
          <div class="modal-header">
            <span class="modal-header-txt">{{$t('create_a_note')}}</span>
            <span class="modal-header-description">
             {{$t('create_a_user_note')}}
            </span>
          </div>

          <input class="form-input mb-2" type="text" :placeholder="$t('title_expert')" v-model="title" />

          <textarea
            class="form-input h-100"
            :placeholder="$t('add_a_note_here')"
            v-model="note"
          ></textarea>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('public_note')}}
              </span>
              <span class="setting-text">
                {{$t('users_to_see_the_note')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="isPublic" />
            </div>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('files_title')}}
              </span>
              <span class="setting-text">
                {{$t('attach_files_to_this_note')}}
              </span>
            </div>
            <div class="setting-right">
              <FileDrop size="small" :on-file-selected="onFileSelected" :multiple="true" />
            </div>
          </div>

          <div class="file-list">
            <div class="file-item" v-for="file in files">
              <div class="left">
              <span class="file-name">
                {{file.name}}
              </span>
              <span class="file-size">
                {{formatBytes(file.size)}}
              </span>
                </div>

              <div class="right">
                <img  src="@/assets/images/trash.svg" @click="removeFile(file)" />
              </div>
            </div>
          </div>

          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>

        <div class="modal-bottom">
          <button class="primary-button" :disabled="sendLoading" @click="handleAddNote">
            <VueSpinnerIos v-if="sendLoading" />
            <span v-else>{{$t('add')}}</span>
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Switch from "@/components/Switch.vue";
import {VueSpinnerIos} from 'vue3-spinners';
import FileDrop from "@/components/FileDrop.vue";
import journeyService from "@/service/journeyService";
import {formatBytes, handleErrorResponse} from "@/utils/utils";

export default {
  components: {
    FileDrop,
    Switch,
    Multiselect,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    journeyId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      sendLoading: false,
      isPublic: false,
      title: '',
      note: '',
      files: []
    };
  },
  methods: {
    formatBytes,

    // Not ekleme işlemi
    handleAddNote() {
      if (!this.title || !this.note) {
        this.$snackbar.add({
          text: 'Please fill all fields',
          type: 'error',
        });
        return;
      }

      if (!this.journeyId) {
        this.$snackbar.add({
          text: 'Journey ID is required',
          type: 'error',
        });
        return;
      }

      const data = {
        title: this.title,
        note: this.note,
        isPublic: this.isPublic,
        journeyId: this.journeyId,
        files: this.files.length > 0 ? this.files : null,
      };

      this.sendLoading = true;

      // Not ekleme servisini çağırma
      journeyService
          .addJourneyNote(data)
          .then(() => {
            this.sendLoading = false;
            this.resetForm(); // Formu sıfırla
            this.onHide(); // Modalı kapat
            this.$snackbar.add({
              text: 'Note added successfully',
              type: 'success',
            });
          })
          .catch((error) => {
            this.sendLoading = false;
            handleErrorResponse(error, this.$snackbar);
          });
    },

    // Dosya ekleme işlemi
    onFileSelected(file) {
      this.files.push(file);
    },

    // Dosya kaldırma işlemi
    removeFile(file) {
      this.files = this.files.filter((f) => f !== file);
    },

    // Formu sıfırlama metodu
    resetForm() {
      this.title = '';
      this.note = '';
      this.isPublic = false;
      this.files = [];
    },
  },
};
</script>


<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  z-index: 99999;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}

.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
.modal-header-description {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
h5 {
  font-family: euclid_medium, sans-serif;
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.setting-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.setting-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.setting-name {
  font-family: euclid_medium, sans-serif;
  color: #060B27;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
}

.setting-text {
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.modal-top {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}

.primary-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #E5E9F2;
  border-radius: 8px;
  padding: 10px;
}

.file-item .left {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.file-item .right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.file-name {
  font-family: euclid_medium, sans-serif;
  color: #060B27;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.file-size {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 12px;
  font-style: normal;
  line-height: 20px;
}


</style>
