<script setup>
import { Handle, Position } from '@vue-flow/core'
import { ref, computed } from 'vue';
import NodeDropdown from "@/components/condition-flow/NodeDropdown.vue";
import {useI18n} from "vue-i18n";
const { t } = useI18n();

const props = defineProps(['id', 'data', 'moduleList', 'onSelect', 'editDisabled']);

const handleSelect = (value) => {
  if (value === '0') return;

  props.onSelect(value, props.id);
};



const mapSubModuleOptions = () => {
  const subModuleList = [];

  if (!props.moduleList) return subModuleList;
  if (!props.moduleList.length) return subModuleList;

  props.moduleList.map((module) => {
    if (!module.subModules) return;
    if (!module.subModules.length) return;

    module.subModules.map((subModule) => {
      if (!subModule.therapyModuleId) {
        return;
      }

      subModuleList.push({
        value: subModule.therapyModuleId,
        text: subModule.name
      });
    });
  });

  subModuleList.push({
    value: 'module_end',
    text: t('finish_module')
  });

  subModuleList.push({
    value: 'module_repeat',
    text: t('repeat_module')
  });

  return subModuleList;
};


</script>

<template>
  <div class="node-wrapper">
    <Handle id="input" type="target" :position="Position.Top"/>

    <div class="node-header">
      <img src="../../../assets/images/goToOutlineDark.svg" alt="icon"/>
      <span class="node-type">{{$t('go_to_node')}}</span>
    </div>

    <div class="node-body" @wheel.stop>
      <NodeDropdown :search-enabled="true" theme="dark" :selected-value="data?.subModuleId" :options="mapSubModuleOptions()" :on-select="handleSelect"/>
    </div>

    <Handle id="output" type="source" :position="Position.Bottom"/>
  </div>
</template>

<style scoped>
.node-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 320px;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #040c2d;
  background-color: #fff;
  border-radius: 6px;
}


.node-header {
  width: 100%;
  background-color: #f1f1f1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 10px;
}

.node-header img {
  width: 24px;
  height: 24px;
}

.node-type {
  font-size: 16px;
  color: #101828;
  font-family: 'euclid_regular', sans-serif;
}

.node-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}
</style>
