<template>
  <div class="table-container">
    <div v-if="users.length === 0" class="no-data-text">
      {{$t("no_data_found")}}
    </div>

    <table v-else class="table">
      <thead class="custom-thead">
      <tr>
        <th class="trhead custom-th">
          <span class="th-content">{{ $t("full_name") }}</span>
        </th>
        <th class="trhead custom-th">
          <span class="th-content">{{ $t("email_address") }}</span>
        </th>
        <th class="trhead custom-th">
          <span class="th-content">{{ $t("phone") }}</span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="user in users"
          :key="user.userId"
          class="tr-class hover-effect"
      >
        <router-link :to="'/users/detail/' + user.userId" class="router-link-row">
          <td class="td-style name-column">{{ user.name +" "+ user.surname }}</td>
        </router-link>

        <td class="td-style">{{ user.email }}</td>
        <td class="td-style">{{ user.phone }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      default: () => []
    }
  },

}
</script>

<style scoped>
.table-container {
  width: 100%;
  height: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.custom-thead {
  background-color: #F9FAFB;
  border-radius: 8px;
}

.custom-th {
  position: relative;
}

.th-content {
  font-size: 12px;
  color: #000;
  font-weight: 500;
  line-height: 16px;
  position: relative;
}

.th-content::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.custom-th:hover .th-content::after {
  background-color: #060b27;
}

th, td {
  text-align: left;
  padding: 8px;
}

.trhead {
  background-color: transparent;
  border-color: #060b27;
}

.tr-class {
  border-bottom-width: 1px;
  border-bottom-color: #F2F4F7;
  transition: background-color 0.3s ease;
}


tr td {
  padding-bottom: 12px;
  padding-top: 12px;
  align-items: center;
}

.router-link-row {
  display: contents;
}

.name-column {
  cursor: pointer;
  transition: color 0.3s ease;
}

.name-column:hover {
  color: #4A68F8;
}

.td-style {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.td-style:hover{
  background-color: #f5f5f5;

}

.no-data-text {
  font-style: italic;
  color: #B6C1DB;
  font-size: 14px;
  font-family: euclid_regular, sans-serif;
}

.router-link-active {
  text-decoration: none;
}
</style>
