<script setup>
import { Handle, Position } from '@vue-flow/core'
import { ref } from 'vue'

const inputs = ref(["1", "2"]);
const props = defineProps({
  refreshInternals: {
    type: Function,
    default: () => {}
  },
  editDisabled: {
    type: Boolean,
    default: false
  }
});

const addInput = () => {
  if (props.editDisabled) return;

  inputs.value.push(String(inputs.value.length + 1));
  props.refreshInternals();
}

</script>

<template>
  <div class="and-node">
    <div class="handles">
      <div class="handle-row" v-for="(input, index) in inputs">
        <Handle :id="input" type="target" :position="Position.Top" class="handle"/>
      </div>
    </div>

    <div class="node-row">
      <span>AND</span>
      <button class="dark-button-sm" @click="addInput" :disabled="editDisabled">
        <img src="../../../assets/images/addWhite.svg" />
      </button>
    </div>

    <Handle id="output" type="source" :position="Position.Bottom" class="handle-true"/>
    <Handle id="false" type="source" :position="Position.Bottom" class="handle-false"/>
  </div>
</template>

<style scoped>
.and-node {
  height: 100%;
  width: 100%;
  min-width: 150px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #4b68f9;
  color: white;
  border: none;
  border-radius: 9px;
  box-shadow: none;
  font-size: 16px;
  font-family: 'euclid_semi_bold', sans-serif;
}

.node-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}


.handles {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: center;
}

.handles .handle-row {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.handles .handle {
  position: absolute;
  top: -5px;
}

.handle-true {
  position: absolute;
  bottom: -6px;
  left: calc(50% - 10px) ;
  transform: translateX(-50%);
}

.handle-false {
  position: absolute;
  bottom: -6px;
  right: calc(50% - 10px) ;
  transform: translateX(50%);
  background-color: #F44336 !important;
}
</style>
