<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-step-1">
          <div class="modal-header">
            <span class="modal-header-txt">{{$t('test_settings')}}</span>
            <span class="modal-header-description">{{$t('test_settings_text')}}</span>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('published')}}
              </span>
              <span class="setting-text">
                {{$t('set_published_draft_status_test')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.isPublish" />
            </div>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('test_image')}}
              </span>
              <span class="setting-text">
                {{$t('set_card_image')}}
              </span>
            </div>
            <div class="setting-right">
              <FileDrop :on-file-selected="handleTestImage" :selected-file="settings.avatarUrl" :attach="settings.avatar" :on-delete="handleTestImageDelete" />
            </div>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('start_at')}}
              </span>
              <span class="setting-text">
                {{$t('date_test_to_be_submitted')}}
              </span>
            </div>
            <div class="setting-right">
              <input type="datetime-local" class="form-input" @input="handleStartDate" :value="getStartDate" />
            </div>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('finish_at')}}
              </span>
              <span class="setting-text">
                {{$t('test_available_until')}}
              </span>
            </div>
            <div class="setting-right">
              <input type="datetime-local" class="form-input" @input="handleFinishDate" :value="getFinishDate" />
            </div>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('allow_retake')}}
              </span>
              <span class="setting-text">
                {{$t('respondent_can_take_test')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.isRetry" />
            </div>
          </div>


          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('test_duration')}}
              </span>
              <span class="setting-text">
                {{$t('Set_the_duration_of_the_test')}}
              </span>
            </div>
            <div class="setting-right">
              <input type="number" class="form-input" v-model="settings.retryTime" />
            </div>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('randomize_questions')}}
              </span>
              <span class="setting-text">
                {{$t('randomize_question')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.isRandom" />
            </div>
          </div>

          <div class="setting-wrapper" v-if="editMode">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('delete_the_test')}}
              </span>
              <span class="setting-text">
                {{$t('permanent_test_deletion')}}
              </span>
            </div>
            <div class="setting-right">
              <button :class="deleteSure ? 'delete-button sure' : 'delete-button'" @click="handleDelete">
                {{ deleteSure ? this.$t('are_you_sure') : this.$t('delete_button') }}
              </button>
            </div>
          </div>

          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import Switch from "@/components/Switch.vue";
import FileDrop from "@/components/FileDrop.vue";
import therapyService from "../../service/therapyService";
import {handleErrorResponse} from "@/utils/utils";
import testService from "../../service/testService";


export default {
  components: {
    FileDrop,
    Switch,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    settings: {
      type: Object,
      default: () => ({}),
    },

    editMode: {
      type: Boolean,
      default: true
    },

  },
  data() {
    return {
      startDate: '',
      finishDate: '',
      startSet: false,
      finishSet: false,
      deleteSure: false,
      deleteTimer: null,
    }
  },
  methods: {
    handleStartDate(event) {
      this.startSet = true;
      this.settings.startAt = this.formatDate(event.target.value);
    },
    handleFinishDate(event) {
      this.finishSet = true;
      this.settings.finishAt = this.formatDate(event.target.value);
    },
    handleTestImage(file) {
      this.settings.avatarDelete = false;
      this.settings.avatar = file;
      this.settings.avatarUrl = null
    },
    handleTestImageDelete() {
      this.settings.avatarDelete = true;
      this.settings.avatar = null;
      this.settings.avatarUrl = null;
    },


    handleDelete() {
      if (!this.editMode || !this.settings.testId) {
        return;
      }

      if (this.deleteSure) {
        clearTimeout(this.deleteTimer);

        testService.deleteTest(this.settings.testId).then(() => {
          this.onHide();
          this.$snackbar.add({
            text: this.$t('test_deleted'),
            type: 'warning'
          });

          this.$router.push('/tests');
        }).catch((err) => {
          handleErrorResponse(err, this.$snackbar);
        });
      } else {
        this.deleteSure = true;

        this.deleteTimer = setTimeout(() => {
          this.deleteSure = false;
        }, 3000);
      }
    },


    formatDate(date) {
      if (!date) return '';
      if (date === '') return '';
      const selectedDate = new Date(date);
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const formatter = new Intl.DateTimeFormat("en-GB", options);
      return formatter.format(selectedDate).replace(/\//g, '.').replace(/[/,]/g, '');
    },
    formattedToIso(inputString) {
      if (!inputString) return '';
      if (inputString === '') return '';

      const [datePart, timePart] = inputString.split(' ');
      const [day, month, year] = datePart.split('.');
      const [hours, minutes] = timePart.split(':');

      const formattedDate = new Date(year, month - 1, day, hours, minutes);

      return formattedDate.toISOString().slice(0, 16);
    },
  },
  computed: {
    getStartDate() {
      console.log(this.settings.startAt);

      return this.formattedToIso(this.settings.startAt);
    },
    getFinishDate() {
      return this.formattedToIso(this.settings.finishAt);
    }
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  z-index: 99999;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
h5 {
  font-family: euclid_medium, sans-serif;
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.modal-header-description {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.setting-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.setting-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.setting-name {
  font-family: euclid_medium, sans-serif;
  color: #060B27;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
}

.setting-text {
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.condition-div span {
  position: absolute;
  display: block;
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}

.condition-dropdown img, .range-dropdown img {
  width: 18px;
  cursor: pointer;
}

.condition-dropdown .line, .range-dropdown .line {
  position: absolute;
  left: -12px;
  top: 50%;
  width: 12px;
  transform: translateY(-50%);
  height: 1px;
  background-color: #1f2b3b;
}

.range-dropdown .range-left {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-right: 1px solid #e9ecf0;
  padding-right: 20px;
}

.range-left input {
  width: 100%;
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  background-color: transparent;
  line-height: 20px;
  padding: 0;
}

.range-dropdown .range-right {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-left: 20px;
}

.delete-button {
  font-family: euclid_regular, sans-serif;
  color: #F44336;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  border: 1px solid #F44336;
  background: none;
  cursor: pointer;
  padding: 5px 20px;
  border-radius: 8px;
  transition: all 0.2s;
}

.delete-button:hover {
  background-color: #F44336;
  color: white;
}

.delete-button.sure {
  background-color: #F44336;
  color: white;
  border: 1px solid #F44336;
}

</style>
