<template>
  <div class="filter-view">
    <CompanyFilters/>
    <div class="justify-end-div">
      <div class="justify-end-search">
       <ExpandableSearch/>
      </div>
      <div class="justify-end-add-btn no-text-select" @click="showModal = !showModal">
        <img src="../../../assets/images/plus.svg">
        <span>{{ $t('add_new') }}</span>
      </div>
    </div>

  </div>

  <div class="table-container">
    <table class="content-table">
      <thead class="header-table">
      <tr class="trhead">
        <th><input type="checkbox" :checked="selectAll" @change="toggleAll"></th>
        <th>{{ $t('company_name') }}</th>
        <th>{{ $t('status_payment_title') }} <img src="../../../assets/images/status.svg"></th>
        <th style="text-align: start">{{ $t('added_date') }} <img src="../../../assets/images/status.svg"></th>
        <th>{{ $t('total_users_tab') }} <img src="../../../assets/images/status.svg"></th>
        <th style="text-align: center;justify-content: start;display: flex">{{ $t('total_therapies_tab') }} <img src="../../../assets/images/status.svg"></th>
        <!--<th>{{ $t('payment_title') }} <img src="../../../assets/images/status.svg"></th>-->
      </tr>
      </thead>

      <tbody class="tbody-container">
      <tr class="tr-class" v-if="!loading" v-for="company in companies" :key="company.companyId">
        <td class="td-style"><input type="checkbox" :checked="isCompanySelected(company)" @change="toggleCompanySelection(company)"></td>
        <td class="td-style">
          <router-link :to="'/companies/detail/' + company.companyId" class="name-row">
            <img class="company-icon" v-if="company.avatarUrl !== null" :src="company.avatarUrl" />
            <span class="test-class">{{ company.name }}</span>
          </router-link>
        </td>
        <td class="td-style"> <span class="test-class">
          <img v-if="company.status=== true" src="../../../assets/images/active.svg">
          <img v-else src="../../../assets/images/failed.svg">
          <span v-if="company.status">
            {{ $t('active') }}
          </span>
          <span v-else>
            {{ $t('inactive') }}
          </span>
        </span></td>
        <td class="td-style">
          <span class="test-class">
            <img src="../../../assets/images/datepicker.svg">{{ company.addedDate }}
          </span>
        </td>
        <td class="td-style"><span class="test-class"><img src="../../../assets/images/user.svg">{{ $t('user_count', {userCount: company.totalUsers}) }}</span>  </td>
        <td style="text-align: center" class="td-style"> <span class="test-class"> <img src="../../../assets/images/therapies.svg">{{ $t('program_count', {programCount: company.totalTherapies}) }}</span></td>
        <!--
<td class="td-style">
  <span class="test-class">
    <img src="../../../assets/images/card.svg">{{ company.isPaid }}
    <img v-if="company.isPaid==='Paid'" src="../../../assets/images/active.svg">
    <img v-if="company.isPaid==='Failed'" src="../../../assets/images/failed.svg">
  </span>
</td>
-->

      </tr>
      <tr v-else>
        <td colspan="7" class="table-loading">
          <VueSpinner size="16" height="100%" color="#8c8c8c" />
          <span class="text">Loading</span>
        </td>
      </tr>
      <tr v-if="!loading && companies.length === 0 ">
        <td colspan="7" class="table-loading">
          <span class="text">No data found</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <AddCompanyModal :visible="showModal" :on-hide="hideModal" :refresh-callback="refreshCallback"/>

</template>

<script>
import AddCompanyModal from '@/components/modals/CompanyModal.vue';
import ExpandableSearch from "@/components/ExpandableSearch.vue";


import {
  VueSpinner
} from 'vue3-spinners'
import CompanyFilters from "@/components/tables/Company/CompanyFilters.vue";

export default {
  components: {
    CompanyFilters,
    AddCompanyModal,
    ExpandableSearch,
    VueSpinner
  },
  data() {
    return {
      showModal: false,
      selectAll:false,
      searchText: '',
    }
  },
  props: {
    companies: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    refreshCallback: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    selectAll(newValue) {
      this.companies.forEach(company => company.selected = newValue);
    },
  },
  methods: {
    toggleAll() {
      this.selectAll = !this.selectAll;
      this.companies.forEach(company => {
        this.$set(company, 'selected', this.selectAll);
      });
    },

    isCompanySelected(company) {
      return company.selected;
    },
    toggleCompanySelection(company) {
      this.$set(company, 'selected', !company.selected);

      if (this.companies.every(c => c.selected)) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    hideModal() {
      this.showModal = false;
    },

  },

}
</script>

<style>
.table-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: left;
  padding: 8px;
}
.trhead{
  background-color: transparent;
}
.tr-class{
  border-bottom-width: 1px;
  border-bottom-color: #F2F4F7;
}

tr td {
  padding-bottom: 12px;
  padding-top: 12px;
  align-items: center;
}
.table-container {
  width: 100%;
}
tr th{
  align-items: center;
  font-size: 12px;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.header-table,
.content-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.header-table {
  background-color: #F9FAFB;
  border-radius: 8px;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #F2F4F7;
}

.tbody-container {
  overflow-y: auto;
}
.tr-class td tr {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.test-class{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
}

.filter-view{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  min-height: 52px;
}
.justify-end-div{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.justify-end-search{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
}

.company-icon {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}



.row-item-icon {
  height: 16px;
  width: 16px;
}

.name-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.name-row span {
  font-family: euclid_medium, sans-serif;
  color: #000;
}

.name-row:hover.name-row span{
  color: #4A68F8;
}
</style>
