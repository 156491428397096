<template>
  <Layout>
    <div class="filter-view">
      <FileFilters />

      <div class="justify-end-div">
        <div class="justify-end-search">
          <div class="select-actions"  v-if="selectedFiles.length > 0">
            <span class="selected-count">{{$t('selected')}} {{selectedFiles.length}} {{$t('files_title')}}</span>
            <ActionDropdown v-if="!fileActionLoading" :actions="fileActions" :on-action="handleFileAction" :placeholder="$t('actions')"/>
            <VueSpinnerIos v-if="fileActionLoading" size="14"/>
          </div>

          <ExpandableSearch :searchText="searchText" />
        </div>
      </div>
    </div>

    <div class="file-grid">

      <FileCard
          v-if="!isLoading"
          v-for="file in fileData.data"
          :file="{
            fileStatus: file.status === '1' ? 'approved' : file.status === '2' ? 'rejected' : 'pending',
            fileCover: file.coverImageUrl,
            fileAttachment: file.attachUrl,
            fileName: file.name,
            fileSize: file.info?.size,
            fileType: file.info?.extension
          }"
          :user="{
            avatarUrl: file.userInfo?.avatarUrl,
            name: file.userInfo?.name
          }"
          :on-click="() => selectFile(file.fileId)"
          :selected="selectedFiles.includes(file.fileId)"
      />

      <FileCard
          v-if="isLoading"
          :loading="true"
          :file="{
            fileStatus: 'pending',
            fileName: 'Loading',
            fileSize: '0',
            fileType: 'mp4'
          }"
      />
      <FileCard
          v-if="isLoading"
          :loading="true"
          :file="{
            fileStatus: 'pending',
            fileName: 'Loading',
            fileSize: '0',
            fileType: 'mp4'
          }"
      />
      <FileCard
          v-if="isLoading"
          :loading="true"
          :file="{
            fileStatus: 'pending',
            fileName: 'Loading',
            fileSize: '0',
            fileType: 'mp4'
          }"
      />
    </div>

    <div class="pagination-align-center">
      <Pagination :current-page="currentPage" :total-items="fileData.total" :per-page="20" :on-page-change="handlePage"/>

    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import FileCard from "@/components/FileCard.vue";
import ActionDropdown from "@/components/ActionDropdown.vue";
import Pagination from "@/components/Pagination.vue";
import fileService from "@/service/fileService";
import {handleErrorResponse} from "@/utils/utils";
import {VueSpinnerIos} from 'vue3-spinners';
import FileFilters from "@/components/tables/File/FileFilters.vue";

export default {
  name: 'Files',
  components: {
    FileFilters,
    Pagination,
    ActionDropdown,
    FileCard,
    ExpandableSearch,
    Layout,
    VueSpinnerIos
  },
  data() {
    return {
      searchText: '',
      selectedFiles: [],
      fileActions: [
        {
          name: this.$t('set_as_approved'),
          action: 'approve'
        },
        {
          name: this.$t('set_as_rejected'),
          action: 'cancel'
        }
      ],
      fileActionLoading: false,
      isLoading: true,
      fileData: {
        data: [],
        total: 0
      },
      currentPage: 1
    }
  },
  watch: {
    $route() {
      this.handlePage(1);
    }
  },
  methods: {
    handlePage(page) {
      if (this.isLoading && this.currentPage !== 1) return;

      this.isLoading = true;
      this.currentPage = page;

      const routeQuery = this.$route.query;
      const tempFilters = [];

      if (routeQuery.searchText) {
        tempFilters.push({
          key: 'searchText',
          value: routeQuery.searchText
        });
      }

      if (routeQuery.addedDate) {
        tempFilters.push({
          key: 'createdAt',
          value: routeQuery.addedDate
        });
      }

      if (routeQuery.addedDateEnd) {
        tempFilters.push({
          key: 'createdAtEnd',
          value: routeQuery.addedDateEnd
        });
      }

      if (routeQuery.status) {
        if(routeQuery.status === "0") {
          tempFilters.push({
            key: 'status',
            value: "0"

          })}
        else if(routeQuery.status==="1"){
          tempFilters.push({
            key: 'status',
            value: "1"
          })
        }
        else if(routeQuery.status==="2"){
          tempFilters.push({
            key: 'status',
            value: "2"
          })
        }
      }

      fileService.getFiles(page, tempFilters).then((res) => {
        this.fileData = res.data.data;
      }).catch((err) => {
        handleErrorResponse(err, this.$snackbar);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    selectFile(fileId) {
      if (this.selectedFiles.includes(fileId)) {
        this.selectedFiles = this.selectedFiles.filter((id) => id !== fileId);
      } else {
        this.selectedFiles.push(fileId);
      }
    },
    handleFileAction(action) {
      this.fileActionLoading = true;
      fileService.setFileStatus({
        fileIds: this.selectedFiles,
        status: action === 'approve' ? '1' : '2'
      }).then(() => {
        this.selectedFiles = [];
        this.handlePage(this.currentPage);
      }).catch((err) => {
        handleErrorResponse(err, this.$snackbar);
      }).finally(() => {
        this.fileActionLoading = false;
      });
    }
  },
}
</script>

<style scoped>
.filter-view{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  min-height: 52px;
}
.justify-end-div{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.justify-end-search{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
}
.flex-row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
}
.row-item{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}
.row-item.filter {
  background-color: transparent;
}

.file-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.select-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.selected-count {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #667085;
}
</style>
