<template>

    <div class="setting-title-wrapper" @click="expand">
      <img
          src="@/assets/images/chevronDownBlue.svg"
          class="chevron-icon"
          :style="isExpanded ? 'transform: rotate(180deg);' : 'transform: rotate(0deg);'"
      />
      <span class="setting-title no-text-select">{{$t('expand_srt_options')}}</span>
    </div>

  <div class="expand" v-if="isExpanded">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ExpandableSetting',
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    expand() {
      this.isExpanded = !this.isExpanded;
    },
  },
}
</script>

<style scoped>
.setting-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 10px;
  justify-content: flex-end;
}

.setting-title {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #4A68F8;
  cursor: pointer;
}

.chevron-icon {
  width: 14px;
  height: 14px;
}
</style>
