import axios from '../axios';


const widgetService = {
    getWidgets() {
        return axios.get('/v1/admin/widget/dashboard');
    },
    addWidget(data) {
        return axios.post('/v1/admin/widget/add', data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        });
    },
    getPositions() {
        return axios.get('/v1/admin/widget/position/store');
    },
    savePositions(data) {
        return axios.post('/v1/admin/widget/position/save', data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        });
    }
}

export default widgetService;
