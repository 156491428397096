<template>
  <div class="not-found">
    <div class="center">
      <img src="@/assets/images/ibility-Logo.svg" alt="Logo" class="logo" />
      <div class="messages-div">
        <div class="error-code">401</div>
        <p class="error-message">{{$t('four_hundred_one')}}</p>
      </div>
      <div class="actions">
        <router-link to="/" class="btn">{{$t('home_page_back')}}</router-link>
        <router-link to="/support" class="underline-btn">{{$t('support_request')}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UnauthorizedPage',
};
</script>

<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  background-color: transparent;
  font-family: Arial, sans-serif;
  padding: 20px;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.logo {
  width: 90px;
  height: 60px;
  margin-bottom: 100px;
}

.messages-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-code {
  font-size: 150px !important;
  font-weight: bold;
  color: #000;
  margin: 0;
  font-family: 'Euclid Black', sans-serif;
}

.error-message {
  font-size: 20px;
  color: #000000;
  margin: 0px 0 50px;
  font-weight: bold;
  font-family: 'euclid_medium', sans-serif;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.btn {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  border: 1px solid #000;
  border-radius: 8px;
  color: #000;
  background-color: transparent;
  text-align: center;
  transition: all 0.3s ease;
  font-family: 'Euclid Circular A', sans-serif;
}

.btn:hover {
  background-color: #000;
  color: #fff;
}

.underline-btn {
  font-size: 16px;
  color: #000;
  text-decoration: none;
  border: none;
  text-align: center;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  display: inline-block;
  width: fit-content;
  transition: border-color 0.3s ease;
}

.underline-btn:hover {
  border-bottom: 1px solid #555;
}

@media screen and (max-width: 768px) {
  .logo {
    width: 70px;
    height: 50px;
    margin-bottom: 80px;
  }

  .error-code {
    font-size: 150px !important;
    font-weight: bold;
    color: #000;
    margin: 0 0 10px; /* Alt boşluk azaltıldı */
    font-family: 'Euclid Black', sans-serif;
  }

  .error-message {
    font-size: 20px;
    color: #000000;
    margin: 0 0 100px; /* Yukarıdaki boşluk otomatik azaltılmış oldu */
    font-weight: bold;
    font-family: 'euclid_medium', sans-serif;
  }


  .btn {
    font-size: 12px;
    padding: 8px 15px;
  }

  .underline-btn {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .logo {
    width: 60px;
    height: 40px;
    margin-bottom: 60px;
  }

  .error-code {
    font-size: 60px;
    font-family: 'Euclid Black', sans-serif;
  }

  .error-message {
    font-size: 14px;
    margin: 0 0 20px;
    color: #000000;
    font-weight: bold;
    font-family: 'euclid_medium', sans-serif;
  }

  .btn {
    font-size: 10px;
    padding: 6px 10px;
  }

  .underline-btn {
    font-size: 12px;
  }
}
</style>
