export function handleErrorResponse(error, snackbar) {
    try {
        if (error.response.data && error.response.data.errors && Object.keys(error.response.data.errors).length > 0) {
            Object.keys(error.response.data.errors).forEach(key => {
                snackbar.add({
                    text: error.response.data.errors[key][0],
                    type: 'error',
                });
            });
        } else if (error.response.data.message) {
            snackbar.add({
                text: error.response.data.message,
                type: 'error',
            });
        } else if (error.response.message) {
            snackbar.add({
                text: error.response.message,
                type: 'error',
            });
        } else {
            snackbar.add({
                text: 'Undefined error occurred.',
                type: 'error',
            });
        }
    } catch (e) {
        console.log(e)
        snackbar.add({
            text: 'Undefined error occurred.',
            type: 'error',
        });
    }
}

export function convertBooleanValuesToNumbers(data) {
    const convertValue = (value) => {
        if (typeof value === 'boolean') {
            return value ? 1 : 0;
        }

        if (typeof value === 'object') {
            for (const key in value) {
                if (value.hasOwnProperty(key)) {
                    value[key] = convertValue(value[key]);
                }
            }
        }
        return value;
    };

    return convertValue(data);
}

export function isQuestion(type) {
    const questionTypes = ['multiple', 'single', 'yesNo', 'number', 'text', 'grading', 'rating', 'dragAndDrop', 'fillTheBlank', 'matchType', 'swipe'];
    return questionTypes.includes(type);
}

export function stripHtmlTags(text) {
    if (!text) {
        return '';
    }

    let cleanedText = text.replace(/<[^>]+>/g, ' ');

    cleanedText = cleanedText.replace(/\s+/g, ' ').trim();

    return cleanedText;
}

export function truncate(str, n){
    if (!str) {
        return '';
    }

    return (str.length > n) ? str.slice(0, n-1) + '...' : str;
}

export function deepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (obj instanceof File || obj instanceof Blob) {
        return obj; // Return file objects as-is
    }

    if (Array.isArray(obj)) {
        return obj.map(deepClone);
    }

    let cloned = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            cloned[key] = deepClone(obj[key]);
        }
    }
    return cloned;
}


export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function truncateMiddle(str, n){
    if (!str) {
        return '';
    }

    if (str.length <= n) {
        return str;
    }

    const start = str.slice(0, n/2);
    const end = str.slice(-n/2);

    return start + '...' + end;
}


export function formatDateDMYHI(date) {
    const selectedDate = new Date(date);
    const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    };
    const formatter = new Intl.DateTimeFormat("en-GB", options);
    return formatter.format(selectedDate).replace(/\//g, '.').replace(/[/,]/g, '');
}

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export function validateProgramPart(part) {
    const type = part.content?.type;

    switch (type) {
        case 'welcomeText':
            if (!part.name) {
                return {
                    isValid: false,
                    type: 'error',
                    message: 'Name is required.'
                };
            }
            break;

        case 'single':
        case 'multiple':
        case 'yesNo':
        case 'dragAndDrop':
            if (!part.name) {
                return {
                    isValid: false,
                    type: 'error',
                    message: 'Name is required.'
                };
            }

            if (part.content?.options?.length < 2) {
                return {
                    isValid: true,
                    type: 'warning',
                    message: 'At least 2 options are recommended.'
                };
            }
            break;

        case 'rating':
        case 'grading':
        case 'number':
            if (!part.name) {
                return {
                    isValid: false,
                    type: 'error',
                    message: 'Name is required.'
                };
            }

            if (!part.content?.settings?.limitedRange?.min) {
                return {
                    isValid: false,
                    type: 'error',
                    message: 'Minimum value is required.'
                };
            }

            if (!part.content?.settings?.limitedRange?.max) {
                return {
                    isValid: false,
                    type: 'error',
                    message: 'Maximum value is required.'
                };
            }
            break;

        case 'text':
            if (!part.name) {
                return {
                    isValid: false,
                    type: 'error',
                    message: 'Name is required.'
                };
            }

            if (!part.content?.settings?.characterLimit?.min) {
                return {
                    isValid: false,
                    type: 'error',
                    message: 'Minimum character value is required.'
                };
            }

            if (!part.content?.settings?.characterLimit?.max) {
                return {
                    isValid: false,
                    type: 'error',
                    message: 'Maximum character value is required.'
                };
            }
            break;

        case 'summary':
            if (!part.description) {
                return {
                    isValid: false,
                    type: 'error',
                    message: 'Description is required.'
                };
            }

            break;

    }

    return {
        isValid: true,
        type: 'success',
        message: ''
    };
}

export const colorPalette = [
    '#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231',
    '#911eb4', '#42d4f4', '#f032e6', '#bfef45', '#fabed4',
    '#469990', '#dcbeff', '#9A6324', '#fffac8', '#800000',
    '#aaffc3', '#808000', '#ffd8b1', '#000075', '#a9a9a9',
    '#ffffff', '#000000'
];
