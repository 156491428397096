import axios from '../axios';

const commonService = {
    getSchools() {
        return axios.get('/v1/admin/getFromData?type[]=school');
    },
    getDegrees() {
        return axios.get('/v1/admin/getFromData?type[]=degree');
    },
    getUsers() {
        return axios.get('/v1/admin/getFromData?type[]=users');
    },
    getTags() {
        return axios.get('/v1/admin/getFromData?type[]=tag');
    },
    getExperts() {
        return axios.get('/v1/admin/getFromData?type[]=experts');
    },
    getCountries() {
        return axios.get('/v1/common/default?type[]=country');
    },
    getCities() {
        return axios.get('/v1/common/default?type[]=cities');
    },
    resetPassword(email) {
        return axios.post('/v1/common/profile/forgotPassword', {
            email: email
        });
    },
}

export default commonService;
