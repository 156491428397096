<template>
  <div class="page-holder">
    <div class="video-holder">
      <img class="video-image" :src="selectedModule.content?.attach[1].attachUrl">
      <button class="play-button">
        <span>▶</span>
      </button>
    </div>
    <div class="tabs-holder">
      <span class="note-button">{{$t("notes")}}</span>
      <img class="share-button" src="@/assets/images/exportMail.svg"/>
    </div>
    <div class="grey-line"></div>
    <div class="note-holder">
      <img class="no-data-icon" src="@/assets/images/noDataBox.svg">
      <span class="note-text1">{{ $t("no_note") }}</span>
      <span class="note-text2">{{ $t("no_notes_description") }}</span>
    </div>
    <div class="keyboard-holder">
    <span class="note-input">{{ $t("write_your_note") }}</span>
    <img class="share-button" src="@/assets/images/sendCircle.svg"/>
    </div>
  </div>
</template>
<script>
export default{
  props:{
    submodule:{
      type:Object
    },
    selectedModule:{
      type:Object
    }
  }
}
</script>
<style scoped>
.no-data-icon{
  width: 40%;
}
.note-text2{
  font-family: 'euclid_regular', sans-serif;
  font-size: 7px;
  color: #667085;
  text-align: center;
}
.note-text1{
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 10px;
  color: #040C2D;
  text-align: center
}
.note-input{
  width: 75%;
  font-size: 10px;
  margin-left: 3%;
  border-radius: 6px;
  background-color: #F5F5F5;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.share-button{
  margin-right: 7px;
  align-self: center;
  width: 20px;
}
.note-button{
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 7px 20px;
  color: #fff;
  cursor: pointer;
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  background-color: #040C2D;
  border: 1px solid #040C2D;
  width: 40%;
  height: 90%;
}
.play-button {
  position: absolute;
  top: 7.5%;
  left: 35%;
  background: rgba(255, 255, 255, 0.4);
  border: none;
  border-radius: 50%;
  width: 30%;
  height: 15%;
  color: white;
  font-size: 18px;
  cursor: pointer;
  z-index: 999;
}
.video-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-holder{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.video-holder{
  height: 30%;
  align-items: center;
}
.tabs-holder{
  height:8%;
  padding: 3px 5px 3px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.grey-line{
  background-color: #F5F5F5;
  height: 1%;
  width: 100%;
}
.note-holder{
  height: 50%;
  padding: 5px;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.keyboard-holder{
  align-items: center;
  border-top: 2px solid #F5F5F5;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 15%;
}
</style>