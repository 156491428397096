<template>
  <div v-if="isError" class="error-page">
    <div class="container">
      <img class="icon" src="@/assets/images/crossCircle.svg" />
      <h1>{{$t('error_occurred')}}!</h1>
      <p class="message">{{$t('calendly_integration_warning')}}.</p>
      <button class="primary-button mt-4" @click="retryIntegration">{{$t('try_again')}}</button>
    </div>
  </div>

  <div v-else class="success-page">
    <div class="container">
      <img class="icon" src="@/assets/images/check.svg" />
      <h1>{{$t('success_occurred')}}!</h1>
      <p class="message">{{$t('calendly_integration_successfully')}}.</p>
      <button class="primary-button mt-4" @click="goToDashboard">{{$t('back_to_home')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isError: false,
    }
  },
  methods: {
    retryIntegration() {
      window.location.href = '/'
    },
    goToDashboard() {
      window.location.href = '/'
    }
  },
}
</script>

<style scoped>
.error-page {
  background-color: #f8d7da;
  color: #721c24;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.success-page {
  background-color: #f4f4f9;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  text-align: center;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 400px;
}

.error-page .container {
  border: 1px solid #f5c6cb;
}

h1 {
  font-size: 22px;
  font-family: 'euclid_semi_bold', sans-serif;
}

.message {
  margin-top: 10px;
  font-size: 18px;
  font-family: 'euclid_regular', sans-serif;
}

.icon {
  height: 64px;
  width: 64px;
  margin-bottom: 15px;
}
</style>