<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-step-1">
          <div class="modal-header">
            <span class="modal-header-txt">{{$t('question_setting')}}</span>
            <span class="modal-header-description">{{$t('specific_question_settings')}}</span>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('compulsory')}}
              </span>
              <span class="setting-text">
                {{$t('respondent_has_to_answer')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.required" />
            </div>
          </div>

          <div class="setting-wrapper" v-if="selectedType === 1 || selectedType === 2">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('random_order')}}
              </span>
              <span class="setting-text">
                {{$t('change_the_order')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.randomOrder" />
            </div>
          </div>

          <div class="setting-wrapper" v-if="selectedType === 5">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('multiple_paragraph')}}
              </span>
              <span class="setting-text">
                {{$t('participant_paragraph')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.multipleParagraph" />
            </div>
          </div>

          <div class="setting-wrapper" v-if="selectedType === 2 || selectedType === 3 || selectedType === 4">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('other_option')}}
              </span>
              <span class="setting-text">
                {{$t('other_option_shown')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.other" />
            </div>
          </div>

          <div class="setting-wrapper" v-if="selectedType === 11">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('is_blank_fill_free_text')}}
              </span>
              <span class="setting-text">
                {{$t('is_blank_fill_free_text_description')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.blankFillFreeText" />
            </div>
          </div>

          <div class="setting-wrapper" v-if="selectedType === 12">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('matcher_left_part_image')}}
              </span>
              <span class="setting-text">
                {{$t('matcher_left_part_image_description')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.leftPartImage" />
            </div>
          </div>

          <div class="setting-wrapper" v-if="selectedType === 12">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('matcher_right_part_image')}}
              </span>
              <span class="setting-text">
                {{$t('matcher_right_part_image_description')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.rightPartImage" />
            </div>
          </div>

          <div class="setting-wrapper" v-if="!isPartOfTherapy && (selectedType === 5 || selectedType === 4 || selectedType === 3 || selectedType === 2)">
            <div class="setting-left w-50">
              <span class="setting-name">
                {{$t('attach_a_summary')}}
              </span>
              <span class="setting-text">
                {{$t('attach_a_summary_to_question')}}
              </span>
            </div>
            <div class="setting-right w-25">
              <SelectBox
                  :options="formattedOptions"
                  :placeholder="this.$t('summary_selected')"
                  :on-select="handleSummarySelect"
                  :selected-value="settings.summaryId"
              />
            </div>
          </div>

          <div class="setting-wrapper" v-if="isPartOfTherapy">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('drawer_option')}}
              </span>
              <span class="setting-text">
                {{$t('add_drawer')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="addActionVisible"/>
            </div>
          </div>

          <div v-if="addActionVisible && isPartOfTherapy">
            <div class="action-text-row">
              <div class="textarea-wrapper">
                <span class="tertiary-title">{{$t('drawer_header')}}</span>
                <textarea class="form-input textarea" :placeholder="$t('drawer_header')" :value="settings.drawer?.header" @input="handleDrawerHeader"/>
              </div>

              <div class="textarea-wrapper">
                <span class="tertiary-title">{{$t('drawer_body')}}</span>
                <textarea class="form-input textarea" :placeholder="$t('drawer_body')" :value="settings.drawer?.body" @input="handleDrawerBody" />
              </div>
            </div>

            <div class="action-wrapper">
              <div class="action-div">
                <span>{{$t('action_title')}}</span>
              </div>

              <div class="action-input" v-for="action in settings.drawer?.actions" :key="action.name">
                <span class="line" />
                <div class="input-wrapper no-text-select">
                  <div class="input-dropdown"  @click="action.typeDropdownVisible = !action.typeDropdownVisible" v-click-outside="() => action.typeDropdownVisible = false">
                    <span v-if="action.actionType === 'primary'">{{$t('primary_action')}}</span>
                    <span v-else-if="action.actionType === 'secondary'">{{$t('secondary_action')}}</span>
                    <span v-else-if="action.actionType === 'tertiary'">{{$t('tertiary_action')}}</span>
                    <span v-else>Action type</span>
                    <img src="@/assets/images/arrowdown.svg" />
                  </div>

                  <div class="dropdown-body" v-if="action.typeDropdownVisible">
                    <span @click="action.actionType = 'primary'">{{$t('primary_action')}}</span>
                    <span @click="action.actionType = 'secondary'">{{$t('secondary_action')}}</span>
                    <span @click="action.actionType = 'tertiary'">{{$t('tertiary_action')}}</span>
                  </div>
                </div>

                <div class="input-wrapper center">
                  <input class="input-text" :placeholder="$t('action_name')" v-model="action.actionText"/>
                </div>

                <div class="input-wrapper no-text-select">
                  <div class="input-dropdown"  @click="action.connectToDropdownVisible = !action.connectToDropdownVisible" v-click-outside="() => action.connectToDropdownVisible = false">
                    <span v-if="action.action === 'next'">{{$t('next_step')}}</span>
                    <span v-else-if="action.action === 'prev'">{{$t('previous_step')}}</span>
                    <span v-else-if="action.action === 'repeat'">{{$t('repeat')}}</span>
                    <span v-else-if="action.action === 'askExpert'">{{$t('ask_to_expert')}}</span>
                    <span v-else>Connect to</span>

                    <img src="@/assets/images/arrowdown.svg" />
                  </div>

                  <div class="dropdown-body right" v-if="action.connectToDropdownVisible">
                    <span @click="action.action = 'next'">{{$t('next_step')}}</span>
                    <span @click="action.action = 'prev'">{{$t('previous_step')}}</span>
                    <span @click="action.action = 'repeat'">{{$t('repeat')}}</span>
                    <span @click="action.action = 'askExpert'">{{$t('ask_to_expert')}}</span>
                  </div>
                </div>

              </div>
            </div>

            <button class="add-button" @click="addAction">
              <img src="../../assets/images/plusBlue.svg" />
              {{$t('add_action')}}
            </button>
          </div>

          <div class="setting-wrapper" v-if="selectedType === 2 || selectedType === 3">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('grading_question')}}
              </span>
              <span class="setting-text">
                {{$t('add_grading_to_selected_answer')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="gradingVisible"/>
            </div>
          </div>
          <input
              v-if="gradingVisible"
              type="text"
              :placeholder="$t('add_grading')"
              class="form-input mt-2"
              v-model="settings.grading"
          />

          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import Switch from "@/components/Switch.vue";
import SelectBox from "@/components/SelectBox.vue";

export default {
  components: {
    SelectBox,
    Switch,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    settings: {
      type: Object,
      default: () => ({
        required: true,
        randomOrder: false,
        other: false,
        grading: 0,
        where: false,
        chat: false,
        drawer: false,
      }),
    },
    selectedType: {
      type: Number,
      default: 1,
    },
    testList: {
      type: Array,
      default: []
    },
    isPartOfTherapy: {
      type: Boolean,
      default: false,
    },
    questionList: {
      type: Array,
      default: []
    }

  },

  computed: {
    formattedOptions() {
      if (this.questionList?.length === 0) {
        return [];
      }

      const sumList = [];

      this.questionList.map((item, index) => {

        if (item.questionId && item.content.type === 'summary') {
          sumList.push({
            value: item.questionId,
            text: item.name || `Option ${index + 1}`
          })
        }
      });

      return sumList;
    }
  },

  data() {
    return {
      gradingVisible: this.settings.grading > 0,
      testQuestionList: [],
      addActionVisible: false,
    };
  },
  created() {
    if (this.settings.drawer && (this.settings.drawer.header || this.settings.drawer.body)) {
      this.addActionVisible = true;
    }

    if (this.settings.drawer && this.settings.drawer.actions && this.settings.drawer.actions.length > 0) {
      this.addActionVisible = true;
    }
  },
  watch: {
    gradingVisible: {
      handler(newVal) {
        if (!newVal) {
          this.settings.grading = 0;
        }
      }
    },
    addActionVisible: {
      handler(newVal) {
        if (!newVal) {
          this.settings.drawer = false;
        } else if (!this.settings.drawer.actions || this.settings.drawer.actions.length === 0)  {
          this.settings.drawer = {
            header: '',
            body: '',
            actions: [
              {
                actionType: "",
                actionText: "",
                action: "",
                typeDropdownVisible: false,
                connectToDropdownVisible: false
              }
            ]
          };
        }
      }
    },
    settings: {
      handler(newVal) {
        if (newVal.drawer.header || newVal.drawer.body) {
          this.addActionVisible = true;
        }

        if (newVal.drawer.actions && newVal.drawer.actions.length > 0) {
          this.addActionVisible = true;
        }
      },
      deep: true
    }
  },
  methods: {
    handleDrawerHeader(event) {
      if (!this.settings.drawer) {
        this.settings.drawer = {
          header: event.target.value,
          body: '',
          actions: []
        };
      } else {
        this.settings.drawer.header = event.target.value;
      }
    },
    handleDrawerBody(event) {
      if (!this.settings.drawer) {
        this.settings.drawer = {
          header: '',
          body: event.target.value,
          actions: []
        };
      } else {
        this.settings.drawer.body = event.target.value;
      }
    },
    addAction() {
      this.settings.drawer.actions.push({
        actionType: "",
        actionText: "",
        action: "",
        type: "test",
        typeDropdownVisible: false,
        connectToDropdownVisible: false
      });
    },
    handleSummarySelect(value) {
      this.settings.summaryId = value;
    }
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  z-index: 9999;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
h5 {
  font-family: euclid_medium, sans-serif;
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.modal-header-description {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.setting-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.setting-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.setting-name {
  font-family: euclid_medium, sans-serif;
  color: #060B27;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
}

.setting-text {
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  color: #4478FF;
  border-radius: 8px;
  padding: 5px 10px;
  transition: background-color 0.2s ease-in-out;
}

.add-button:hover {
  background-color: #f3f4ff;
}

.tertiary-title {
  color: #282A36;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.action-text-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 20px;
  margin-top: 15px;
}
.textarea-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
.form-input.textarea {
  min-height: 140px;
}
.action-wrapper {
  position: relative;
  margin-top: 30px;
  min-height: 124px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.action-div {
  position: absolute;
  height: 100%;
  min-height: 124px;
  width: 28px;
  border-radius: 8px;
  background-color: #4478ff;
}

.action-div.orange {
  background-color: #FFA500;
}

.action-div span {
  position: absolute;
  display: block;
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}

.action-input {
  position: relative;
  border: 1px solid #e9ecf0;
  padding: 10px;
  margin-left: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.action-input .input-wrapper {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.action-input .input-wrapper.center {
  border-left: 1px solid #e9ecf0;
  border-right: 1px solid #e9ecf0;
}


.action-input .input-wrapper.condition {
  width: auto;
}

.line {
  position: absolute;
  left: -14px;
  top: 50%;
  width: 14px;
  transform: translateY(-50%);
  height: 1px;
  background-color: #98a2b3;
}

.input-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.input-dropdown span {
  font-family: euclid_medium, sans-serif;
  color: #344054;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  white-space: nowrap;
}

.input-dropdown img {
  width: 18px;
  height: 18px;
}

.dropdown-body {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  min-width: 34%;
  max-height: 240px;
  overflow-y: auto;
  background-color: #FFF;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 99999;
}

.dropdown-body.right {
  left: auto;
  right: 0;
}

.dropdown-body.questions {
  left: 25%;
}

.dropdown-body.condition {
  left: 50%;
}

.dropdown-body span {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #475467;
  font-size: 12px;
  font-style: normal;
  line-height: 20px;
  cursor: pointer;
}

.input-text {
  border: none;
  outline: none;
  background-color: transparent;
  font-family: euclid_medium, sans-serif;
  color: #344054;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  width: 100%;
  text-align: center;
}


</style>
