import axios from '../axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const pageService = {
    getPages(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/pages/store?limit=20&page=${page}${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/pages/store?limit=20&page=${page}`);
        }
    },
    getPageDetails(id, lang = 'tr') {
        return axios.get(`/v1/admin/pages/getFromId/${id}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    updatePage(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post('/v1/admin/pages/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    addPage(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post('/v1/admin/pages/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },

    deletePages(id) {
        return axios.delete(`/v1/admin/pages/delete/${id}`);
    },

}

export default pageService;
