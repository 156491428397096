<template>
  <Layout>
    <h3 class="page-title">{{ $t('userflow') }}</h3>
    <div class="filter-wrapper">
      <TherapyFilters />

      <div class="justify-end-div">
        <ExpandableSearch />
        <router-link to="/userflow/new">
          <div class="justify-end-add-btn no-text-select">
            <img src="../assets/images/plus.svg" />
            <span>New Rule</span>
          </div>
        </router-link>
      </div>
    </div>

    <div class="flows-grid">
      <FlowCard name="Lorem ipsum dolor" to="/userflow/new"/>
    </div>

  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import TherapyFilters from "@/components/tables/Therapy/TherapyFilters.vue";
import FlowCard from "@/components/FlowCard.vue";

export default {
  name: 'Userflow',
  components: {FlowCard, TherapyFilters, ExpandableSearch, Layout},
  data() {
    return {

    }
  },
}
</script>

<style scoped>
.page-title {
  font-family: euclid_medium, sans-serif;
  font-size: 20px;
  color: #040C2D;
  margin-bottom: 20px;
}

.filter-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.flows-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media (max-width: 1024px) {
  .flows-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .flows-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
