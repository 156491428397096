<template>
  <router-view/>

  <vue3-snackbar top :duration="4000"></vue3-snackbar>
</template>


<script>
import Sidebar from './components/Sidebar.vue';
import Header from './components/Header.vue';
import { Vue3Snackbar } from "vue3-snackbar";

export default {
  components: {
    Sidebar,
    Header,
    Vue3Snackbar
  },
}
</script>

