<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-step-1">
          <div class="modal-header">
            <span class="modal-header-txt">{{$t('add_visual')}}</span>
            <span class="modal-header-description">{{$t('visual_question_here')}}</span>
          </div>

          <div class="visual-wrapper">
            <div class="visual-wrapper-left">
              <div class="visual-info-row">
                <span class="visual-title">{{$t('image')}}</span>
                <span class="visual-text">{{$t('jpg_png_jpeg')}}</span>
              </div>
            </div>
            <div class="visual-wrapper-right">
              <FileDrop :on-file-selected="handleFile" :selected-file="data.attachUrl" :attach="data.attach" :on-delete="onDelete"/>
            </div>
          </div>

          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import FileDrop from "@/components/FileDrop.vue";
import draggable from "vuedraggable";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    data: {
      type: Object,
      default: {
        attach: null,
        attachUrl: null,
        attachDelete: 0
      }
    },
  },
  components: {
    draggable,
    FileDrop,
    TextInput,
    SelectBox
  },
  methods: {
    handleFile(file) {
      this.data.attachUrl = null;
      this.data.attach = file;
      this.data.attachDelete = 0;
    },
    onDelete() {
      this.data.attachUrl = null;
      this.data.attach = null;
      this.data.attachDelete = 1;
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  z-index: 99999;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}

.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
.modal-header-description {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.btn-style{
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
}

.next-txt{
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.btn-div{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
h5 {
  font-family: euclid_medium, sans-serif;
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.visual-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
}

.visual-wrapper-left {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  width: 100%;
}

.visual-info-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
}

.visual-wrapper-right {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.visual-title {
  font-family: euclid_medium, sans-serif;
  color: #040C2D;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
}

.visual-text {
  font-family: euclid_regular, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.visual-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.add-image-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  background-color: transparent;
  border-radius: 12px;
  color: #4A68F8;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  cursor: pointer;
  border: none;
  outline: none;
}
</style>
