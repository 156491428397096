<template>
  <button class="add-else-button" :class="disabled && 'disabled'" @click="onClick">
    <img src="@/assets/images/plus.svg" />
    <span>Add Else</span>
  </button>
</template>


<script>
export default {
  name: 'AddElseButton',
  props: {
    onClick: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>


<style scoped>
.add-else-button {
  background-color: #040C2D;
  border-radius: 16px;
  padding: 10px 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  align-self: flex-start;
  position: relative;
  outline: none;
  border: none;
  gap: 5px;
  transition: background-color 0.2s;
}

.add-else-button:hover {
  background-color: #1C3CD5;
}


.add-else-button.disabled {
  background-color: #EAECF0;
}


.add-else-button span {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #fff;
}
</style>
